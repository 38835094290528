import { Counters } from "./counters";

export class Clients {
    id: number;
    surname: string;
    name: string;
    mName: string;
    birthdate: Date;
    postcode: string;

    areaId: number;
    areaStr: string;

    cityId: number;
    cityStr: string;

    street: string;
    house: string;
    flat: string;
    phoneConfirmed: string;
    phoneCell: string;
    phoneContact: string;
    eMail: string;
    vehicleTypeId: number;
    vehicleBrandId: string;
    vehicleYear: number;
    tankVolume: number;
    gender: number;
    maritalStatus: number;
    secretWord: string;
    isSubscribed: number;
    pin: string;
    text1: string;
    text2: string;
    text3: string;
    text4: string;

    fillDate: Date;

    isActive: number;
    isActiveBool: boolean;
    isDeleted: number;
    cards: Cards[];
    settings: ClientsSettings;
    insurancePolicy: ClientsInsurancePolicy[];
}

export class ClientsInsurancePolicy {
    id: number;
    clientId: number;
    typePolicy: string;
    extendedInfo: string;
    manuallyEntered: boolean;
    dateTo: Date;
    editDateUtc: Date;
}

export class ClientsSettings {
    deductionZSU: number;
}

export class ClientsSettingsList {
    clientId: number;
    name: string;
    surname: string;
    phonecon: string;
    deductionZsuPercent: number;
}

export class CardOfClient {
    clientID: number;
    name: string;
    mName: string;
    surname: string;
    secretWord: string;
    cardID: number;
    internalNumber: string;
    isDeleted: number;
}

export class ClientCounters{
    id: number;
    counterId: number;
    counter: Counters;
    val: number;
    valPrev: number;
    modifiedDate: Date;
    nextModifyDate: Date;
}

export class Cards {
    id: number;
    visibleNumber: string;
    internalNumber: string;
    //pin: string;
    isActive: number;
    isDeleted: number;
}

export class Duplicates {
    dInternalNumbers: string[];
    dVisibleNumbers: string[];
    dInternal: Cards[];
    dVisible: Cards[];
}

export class CardsBatch {
    visibleNumber1: string;
    internalNumber1: string;
    pin1: string;

    visibleNumber2: string;
    internalNumber2: string;
    pin2: string;

    text1: string;
    text2: string;
    text3: string;
    text4: string;
}

export class Tab {
    id: number;
    text: string;
    icon: string;
}

export class PhoneConfirmSMS {
    clientId: number;
    phone: string;
    sms: string;
}

export class PhoneConfirmCall {
    clientId: number;
    phone: string;
    requestId: string;
}