export class ClientPredict {
  currMonthPetrol: number;
  currMonthDP: number;
  currMonthLPG: number;
  currMonthMarket: number;
  nextMonthPetrol: number;
  nextMonthDP: number;
  nextMonthLPG: number;
  nextMonthMarket: number;
  deductionZSUPercent: number;
}

export class ClientPredictDiscView {
  nameProduct: string;
  currMonthAmount: string;
  nexMonthAmount: string;
}
