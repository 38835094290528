export class ClientRestFW {
    fuel: Fuel;
    amounts: any;
}

class Fuel {
    id: number;
    extId: number;
    name: string;
    isActive: number;
    isActiveBool: boolean;
}

export class ClientRestFWView {
    id: number;
    extId: number;
    fuelName: string;
    isActive: number;
    isActiveBool: boolean;
    datExp: Date;
    amount: number;
}