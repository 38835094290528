import { Terminals } from "./terminals";

export class PromoCalc {
    transId: number;
    calcId: string;
    calcLevelId: number;
    dat: Date;
    internalCardNumber: string;
    visibleCardNumber: string;
    paytypeId: number;
    terminalId: number;
    stationId: number;

    clientId: number;
    clientName: string;
    lines: PromoCalcLine[];
    promoResults: PromoCalcResult[];

    constructor(){
        this.lines = [];
        this.promoResults = [];
    }
}

export class PromoCalcLine{
    id: number;
    name: string;
    amount: number;
    price: number;
    discount: number;
    deductionZSU: number;
}

export class PromoCalcResultLine{
    id: number;
    name: string;
    amount: number;
    price: number;
    prevDiscount: number;
    discount: number;
}

export class PromoCalcResult {
    promoId: number;
    promoName: string;
    behaviorTypeId: number;
    awardTypeId: number;
    isApplied: boolean;
    messages: PromoCalcMessage[];
    lines: PromoCalcResultLine[];
    counterReward: PromoCalcCounterReward;

    constructor(){
        this.messages = [];
        this.lines = [];
        this.counterReward = new PromoCalcCounterReward();
    }
}

export class PromoCalcMessage{
    typeId: number;
    message: string;
}

export class PromoCalcCounterReward{
    counterId: number;
    counterName: string;
    amount: number;
}
