import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DigestInfoComponent } from './digest-info.component';
import { AuthGuard } from '../../_guards/auth.guard';

const routes: Routes = [
  { path: 'digest-info',  component: DigestInfoComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class DigestInfoRoutingModule { }
