export class PromoPeriod {
    dateFrom: Date;
    dateTo: Date;
    allPeriod: boolean;
    timeFromId: number;
    timeToId: number;
    allDay: boolean;
    repeatTypeId: number;
    onMondays: boolean;
    onTuesdays: boolean;
    onWednesdays: boolean;
    onThursdays: boolean;
    onFridays: boolean;
    onSaturdays: boolean;
    onSundays: boolean;
}