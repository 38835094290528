import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {Tab, WalletSellsFuels, WalletSellsTransaction, WalletClientMoveRest, ValueId, WalletFuelsExchangeTransactions, WalletFuelsRestoreTransactions,
WalletFuelsRefundTransactions, WalletFuelsBlockTransactions, 
WalletFuelsRefundPrepare,
WalletFuelsRefundRequest} from '../../_models';
import { TransactionsWalletService } from '../../_services';
import ArrayStore from "devextreme/data/array_store";
import { Router } from '@angular/router';
import notify from 'devextreme/ui/notify';
import DataSource from "devextreme/data/data_source";
import 'devextreme/data/odata/store';
import {DxDataGridComponent, DxPivotGridComponent} from 'devextreme-angular';
import {DatePipe} from '@angular/common';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import CustomStore from 'devextreme/data/custom_store';

@Component({
  templateUrl: 'transactions-wallet.component.html'
})
export class TransactionsWalletComponent implements OnInit, AfterViewInit {
  @ViewChild("transWalletDataGrid") dataTransGrid: DxDataGridComponent;
  @ViewChild("transWalletFuelsDataGrid") dataTransFuelsGrid: DxDataGridComponent;
  //@ViewChild("transWalletClientMoveRestPivotGrid") dataTransMoveRestGrid: DxPivotGridComponent;
  @ViewChild("transWalletClientMoveRestDataGrid") dataTransMoveRestGrid: DxDataGridComponent;
  @ViewChild("transWalletExchangeDataGrid") dataTransExchangeGrid: DxDataGridComponent;
  @ViewChild("transWalletRestoreDataGrid") dataTransRestoreGrid: DxDataGridComponent;
  @ViewChild("transWalletRefundDataGrid") dataTransRefundGrid: DxDataGridComponent;
  @ViewChild("transWalletBlockDataGrid") dataTransBlockGrid: DxDataGridComponent;

  transTabs: Tab[];
  isShowTrans: boolean = false;
  isShowFuels: boolean = false;
  isShowMoveRest: boolean = false;
  isShowTransExchange: boolean = false;
  isShowTransRestore: boolean = false;
  isShowTransRefund: boolean = false;
  isShowTransBlock: boolean = false;

  isAllowRefund: boolean = false;
  countMonthAllowFuelRefund: number = 90;

  isPopupRefundOrder: boolean = false;
  isPopupRefundFuelConfirm: boolean = false;

  clientId: number;
  error = '';

  dateFrom: Date;
  dateTo: Date;

  dsTransLines: WalletSellsTransaction[];
  dsTransFuelsLines: WalletSellsFuels[];
  dsTransClientMoveRest: WalletClientMoveRest[];
  dsPaymentSystem: ValueId[];
  dsTransExchange: WalletFuelsExchangeTransactions[];
  dsExchangeStatuses: ValueId[];
  dsTransRestore: WalletFuelsRestoreTransactions[];
  dsTransRefund: WalletFuelsRefundTransactions[];
  dsTransBlock: WalletFuelsBlockTransactions[];
  dsRestoreStatuses: ValueId[];
  dsRefundStatuses: ValueId[];
  dsBlockStatuses: ValueId[];

  orderRefundPrepare: WalletFuelsRefundPrepare;
  orderRefundRequest: WalletFuelsRefundRequest;

  //dsMoveRest: PivotGridDataSource;
  caption = 'Паливний гаманець';

  public constructor(
    private titleService: Title,
    private transactionsWalletService: TransactionsWalletService,
    private router: Router,
    private datePipe: DatePipe
  )
  {
    this.titleService.setTitle(this.caption);

    // this.dsMoveRest = new PivotGridDataSource({
    //   fields: [{
    //       caption: 'Код клієнта',          
    //       dataField: 'clientId',
    //       area: 'row',
    //       expanded: true
    //   }, {
    //       caption: 'Дата',
    //       dataField: 'date',
    //       dataType: 'date',
    //       format: {type: 'shortDate'},          
    //       area: 'row',
    //       expanded: true          
    //   }, {
    //       caption: 'Пальне',
    //       dataField: 'fullName',          
    //       area: 'column',
    //       expanded: true
    //   }, {
    //     caption: 'Код пального',
    //       dataField: 'fuelExtId'
    //   },
    //   {
    //     caption: 'Прихід, л.',
    //     dataField: 'income',
    //     dataType: 'number',        
    //     area: 'data',
    //     summaryType: 'sum',
    //     format: { type: 'fixedPoint', precision: 2 },
    //   },
    //   {
    //     caption: 'Витрата, л.',
    //     dataField: 'outcome',
    //     dataType: 'number',        
    //     area: 'data',
    //     summaryType: 'sum',
    //     format: { type: 'fixedPoint', precision: 2 },
    //   },
    //   {
    //     caption: 'Оборот, л.',
    //     dataField: 'amount',
    //     dataType: 'number',        
    //     area: 'data',
    //     summaryType: 'sum',        
    //     format: { type: 'fixedPoint', precision: 2 }
    //   },
    //   {
    //     caption: 'Залишок, л.',
    //     dataField: 'rest',
    //     dataType: 'number',        
    //     area: 'data',
    //     summaryType: 'sum',
    //     format: { type: 'fixedPoint', precision: 2 },
    //     showValues: true,
    //     showTotals: false,
    //     showGrandTotals: false
    //   }
      // {
      //     caption: 'Sales',
      //     dataField: 'amount',
      //     dataType: 'number',
      //     summaryType: 'sum',
      //     format: 'currency',
      //     area: 'data'
      // }
    //],
  //     store: new CustomStore({
  //       load: (loadOptions) => {
  //           return this.dsTransClientMoveRest
  //       }
  //   })
  // })
  }

  ngAfterViewInit(): void {

  }

  ngOnInit(): void {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));

    var allowed = [1, 10, 40, 400];
    var allowedRefund = [1, 40];

    if(!allowed.includes(currentUser.UserType)){
      this.router.navigateByUrl("404")
    }

    if(allowedRefund.includes(currentUser.UserType)) {
      this.isAllowRefund = true;
    }

    this.dateFrom = new Date();
    this.dateTo = new Date();
    this.dateFrom = this.add_days(this.dateFrom, -3);

    this.dsPaymentSystem = this.transactionsWalletService.getPaymentSystems();
    this.dsExchangeStatuses = this.transactionsWalletService.getExchangeStasuses();
    this.dsRestoreStatuses = this.transactionsWalletService.getRestoreStasuses();
    this.dsRefundStatuses = this.transactionsWalletService.getRefundStasuses();
    this.dsBlockStatuses = this.transactionsWalletService.getBlockStasuses();
    this.transTabs = this.transactionsWalletService.getTransTabs();
    this.showTransTabId(0);

  }

  add_months(dt, n) {
    return new Date(dt.setMonth(dt.getMonth() + n));
  }

  add_days(dt, n) {
    return new Date(dt.setDate(dt.getDate() + n));
  }

  showTransTabId(id: number) {
    this.isShowTrans = id == 0;
    this.isShowFuels = id == 1;
    this.isShowMoveRest = id == 2;
    this.isShowTransExchange = id == 3;
    this.isShowTransRestore = id == 4;
    this.isShowTransRefund = id == 5;
    this.isShowTransBlock = id == 6;
    if (id == 0) { }
    else if (id == 1) { }
    else if (id == 2) { }
    else if (id == 3) { }
    else if (id == 4) { }
    else if (id == 5) { }
    else if (id == 6) { }
  }

  selectTransTab(e) {
    this.showTransTabId(e.itemData.id);
  }

  onToolbarPreparingTrans(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',

        options: {
          icon: 'fa fa-filter',
          onClick: this.refreshDataTransGrid.bind(this),
          hint: 'Очистити фільтри'
        }
    });
  }

  onToolbarPreparingTransFuels(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',

        options: {
          icon: 'fa fa-filter',
          onClick: this.refreshDataTransFuelsGrid.bind(this),
          hint: 'Очистити фільтри'
        }
    });
  }

  onToolbarPreparingTransExchange(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',

        options: {
          icon: 'fa fa-filter',
          onClick: this.refreshDataTransExchangeGrid.bind(this),
          hint: 'Очистити фільтри'
        }
    });
  }

  onToolbarPreparingTransRestore(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',

        options: {
          icon: 'fa fa-filter',
          onClick: this.refreshDataTransRestoreGrid.bind(this),
          hint: 'Очистити фільтри'
        }
    });
  }

  onToolbarPreparingTransRefund(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',

        options: {
          icon: 'fa fa-filter',
          onClick: this.refreshDataTransRefundGrid.bind(this),
          hint: 'Очистити фільтри'
        }
    });
  }

  onToolbarPreparingTransBlock(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',

        options: {
          icon: 'fa fa-filter',
          onClick: this.refreshDataTransBlockGrid.bind(this),
          hint: 'Очистити фільтри'
        }
    });
  }

  refreshDataTransGrid() {
    if(this.dsTransLines) {
      this.dataTransGrid.instance.clearFilter();
    }
  }

  refreshDataTransFuelsGrid() {
    if(this.dsTransFuelsLines) {
      this.dataTransFuelsGrid.instance.clearFilter();
    }
  }

  refreshDataTransExchangeGrid() {
    if(this.dsTransExchange) {
      this.dataTransExchangeGrid.instance.clearFilter();
    }
  }

  refreshDataTransRestoreGrid() {
    if(this.dsTransRestore) {
      this.dataTransRestoreGrid.instance.clearFilter();
    }
  }

  refreshDataTransMoveRestGrid() {
    if(this.dsTransClientMoveRest) {
      this.dataTransMoveRestGrid.instance.clearFilter();
    }
  }

  refreshDataTransRefundGrid() {
    if(this.dsTransRefund) {
      this.dataTransRefundGrid.instance.clearFilter();
    }
  }

  refreshDataTransBlockGrid() {
    if(this.dsTransBlock) {
      this.dataTransBlockGrid.instance.clearFilter();
    }
  }

  onContentReady(e) {
    if(e.element.id === 'transWalletDataGridContainer' && !this.dsTransLines) {      
      this.getTrans();
    }
    else if(e.element.id === 'transWalletFuelsDataGridContainer' && !this.dsTransFuelsLines) {
      this.getTransFuels();
    }
    // else if(e.element.id === 'transWalletClientMoveRestPivotGridContainer' && !this.dsTransClientMoveRest) {
    //   this.getTransMoveRest();
    // }
    else if(e.element.id === 'transWalletClientMoveRestDataGridContainer' && !this.dsTransClientMoveRest) {
      this.getTransMoveRest();
    }
    else if(e.element.id === 'transWalletExchangeDataGridContainer' && !this.dsTransExchange) {
      this.getTransExchange();
    }
    else if(e.element.id === 'transWalletRestoreDataGridContainer' && !this.dsTransRestore) {
      this.getTransRestore();
    }
    else if(e.element.id === 'transWalletRefundDataGridContainer' && !this.dsTransRefund) {
      this.getTransRefund();
    }
    else if(e.element.id === 'transWalletBlockDataGridContainer' && !this.dsTransBlock) {
      this.getTransBlock();
    }

  }

  onInitialized(e) {
    
  }

  applyTransWallet() {
      if(this.isShowTrans) {
        this.getTrans();
      }
      else if(this.isShowFuels){
        this.getTransFuels();
      }
      else if(this.isShowMoveRest) {
        this.getTransMoveRest();        
      }
      else if(this.isShowTransExchange) {
        this.getTransExchange();
      }
      else if(this.isShowTransRestore) {
        this.getTransRestore();
      }
      else if(this.isShowTransRefund) {
        this.getTransRefund();
      }
      else if(this.isShowTransBlock) {
        this.getTransBlock();
      }
  }

  getTrans() {

    if(!this.dateFrom) {
      notify("Введіть дату З", "error", 1000);
      return;
    }

    if(!this.dateTo) {
      notify("Введіть дату ПО", "error", 1000);
      return;
    }

    this.dataTransGrid.instance.beginCustomLoading('');

    this.transactionsWalletService.getTransWallet(this.datePipe.transform(this.dateFrom, 'yyyy-MM-dd'), this.datePipe.transform(this.dateTo, 'yyyy-MM-dd'))
      .subscribe(item => {
          this.dsTransLines = item;
          this.dataTransGrid.instance.endCustomLoading();
        },
        error => {
          this.error = error;
          this.dataTransGrid.instance.endCustomLoading();
          notify(!error.error ? 'Під час виконання запиту сталаса помилка' : error.error, "error", 1000);
        });

  }

  getTransFuels() {

    if(!this.dateFrom) {
      notify("Введіть дату З", "error", 1000);
      return;
    }

    if(!this.dateTo) {
      notify("Введіть дату ПО", "error", 1000);
      return;
    }

    this.dataTransFuelsGrid.instance.beginCustomLoading('');

    this.transactionsWalletService.getTransWalletFuels(this.datePipe.transform(this.dateFrom, 'yyyy-MM-dd'), this.datePipe.transform(this.dateTo, 'yyyy-MM-dd'))
      .subscribe(item => {
          this.dsTransFuelsLines = item;
          this.dataTransFuelsGrid.instance.endCustomLoading();
        },
        error => {
          this.error = error;
          this.dataTransFuelsGrid.instance.endCustomLoading();
          notify(!error.error ? 'Під час виконання запиту сталаса помилка' : error.error, "error", 1000);
        });

  }

  getTransMoveRest() {
    if(!this.dateFrom) {
      notify("Введіть дату З", "error", 1000);
      return;
    }

    if(!this.dateTo) {
      notify("Введіть дату ПО", "error", 1000);
      return;
    }

    this.dataTransMoveRestGrid.instance.beginCustomLoading('');

    this.transactionsWalletService.getTransClientMoveRest(this.datePipe.transform(this.dateFrom, 'yyyy-MM-dd'), this.datePipe.transform(this.dateTo, 'yyyy-MM-dd'))
      .subscribe(item => {
          this.dsTransClientMoveRest = item;
          //this.dsMoveRest.reload();
          this.dataTransMoveRestGrid.instance.endCustomLoading();
        },
        error => {
          this.error = error;
          this.dataTransMoveRestGrid.instance.endCustomLoading();
          notify(!error.error ? 'Під час виконання запиту сталаса помилка' : error.error, "error", 1000);
        });    
  }

  getTransExchange() {
    if(!this.dateFrom) {
      notify("Введіть дату З", "error", 1000);
      return;
    }

    if(!this.dateTo) {
      notify("Введіть дату ПО", "error", 1000);
      return;
    }

    this.dataTransExchangeGrid.instance.beginCustomLoading('');

    this.transactionsWalletService.getTransFuelExchange(this.datePipe.transform(this.dateFrom, 'yyyy-MM-dd'), this.datePipe.transform(this.dateTo, 'yyyy-MM-dd'))
      .subscribe(item => {
          this.dsTransExchange = item;
          this.dataTransExchangeGrid.instance.endCustomLoading();
        },
        error => {
          this.error = error;
          this.dataTransExchangeGrid.instance.endCustomLoading();
          notify(!error.error ? 'Під час виконання запиту сталаса помилка' : error.error, "error", 1000);
        });
  }

  getTransRestore() {
    if(!this.dateFrom) {
      notify("Введіть дату З", "error", 1000);
      return;
    }

    if(!this.dateTo) {
      notify("Введіть дату ПО", "error", 1000);
      return;
    }

    this.dataTransRestoreGrid.instance.beginCustomLoading('');

    this.transactionsWalletService.getTransFuelRestore(this.datePipe.transform(this.dateFrom, 'yyyy-MM-dd'), this.datePipe.transform(this.dateTo, 'yyyy-MM-dd'))
      .subscribe(item => {
          this.dsTransRestore = item;
          this.dataTransRestoreGrid.instance.endCustomLoading();
        },
        error => {
          this.error = error;
          this.dataTransRestoreGrid.instance.endCustomLoading();
          notify(!error.error ? 'Під час виконання запиту сталаса помилка' : error.error, "error", 1000);
        });
  }

  getTransRefund() {
    if(!this.dateFrom) {
      notify("Введіть дату З", "error", 1000);
      return;
    }

    if(!this.dateTo) {
      notify("Введіть дату ПО", "error", 1000);
      return;
    }

    this.dataTransRefundGrid.instance.beginCustomLoading('');

    this.transactionsWalletService.getTransFuelRefund(this.datePipe.transform(this.dateFrom, 'yyyy-MM-dd'), this.datePipe.transform(this.dateTo, 'yyyy-MM-dd'))
      .subscribe(item => {
          this.dsTransRefund = item;
          this.dataTransRefundGrid.instance.endCustomLoading();
        },
        error => {
          this.error = error;
          this.dataTransRefundGrid.instance.endCustomLoading();
          notify(!error.error ? 'Під час виконання запиту сталаса помилка' : error.error, "error", 1000);
        });
  }

  getTransBlock() {
    if(!this.dateFrom) {
      notify("Введіть дату З", "error", 1000);
      return;
    }

    if(!this.dateTo) {
      notify("Введіть дату ПО", "error", 1000);
      return;
    }

    this.dataTransBlockGrid.instance.beginCustomLoading('');

    this.transactionsWalletService.getTransFuelBlock(this.datePipe.transform(this.dateFrom, 'yyyy-MM-dd'), this.datePipe.transform(this.dateTo, 'yyyy-MM-dd'))
      .subscribe(item => {
          this.dsTransBlock = item;
          this.dataTransBlockGrid.instance.endCustomLoading();
        },
        error => {
          this.error = error;
          this.dataTransBlockGrid.instance.endCustomLoading();
          notify(!error.error ? 'Під час виконання запиту сталаса помилка' : error.error, "error", 1000);
        });
  }

  onAllowRefund(e) {
    if(!this.isAllowRefund) {
      return false;
    }
    
    if(![0,1,3].includes(e.data.paymentSystem)) {
      return false;
    }
    
    let datNow = new Date();
    let datTrans = new Date(e.data.localDate);
    let datAllow = this.add_days(datTrans, this.countMonthAllowFuelRefund);
    return (datAllow > datNow);
  }

  onInitRefund(e) {
    this.dataTransGrid.instance.beginCustomLoading('');
    this.transactionsWalletService.getFuelRefundPrepare(e.data.id)
    .subscribe(item => {
      this.orderRefundPrepare = item;
      this.orderRefundPrepare.amountRefundAvailable = this.orderRefundPrepare.amountPay < this.orderRefundPrepare.amountAvailable ?
                                                      this.orderRefundPrepare.amountPay : this.orderRefundPrepare.amountAvailable;
      this.dataTransGrid.instance.endCustomLoading();
      
      this.orderRefundRequest = new WalletFuelsRefundRequest();
      this.orderRefundRequest.origOrderId = item.origOrderId;
      this.orderRefundRequest.amount = item.amountRefundAvailable;   
      this.orderRefundRequest.summaRefundPredict = this.orderRefundPrepare.amountRefundAvailable * (this.orderRefundPrepare.pricePay - this.orderRefundPrepare.discPerLitr);   
      this.isPopupRefundOrder = true;
    },
    error => {
      this.dataTransGrid.instance.endCustomLoading();
      notify(error.error ? error.error : "Не вдалося отримати інформацію для створення заявки повернення коштів", "error", 1000);
    });
  }

  onRefundFuel() {
    this.isPopupRefundFuelConfirm = true;
  }

  onRefundFuelConfirm() {
    this.transactionsWalletService.fwCreateRefund(this.orderRefundRequest)
    .subscribe((item) => {      
      this.isPopupRefundFuelConfirm = false;
      this.isPopupRefundOrder = false;
      notify("Заявка на повернення коштів успішно створена", "success", 1000);
    },
    error => {
      notify(error.error ? error.error : "Не вдалося створити заявку для повернення коштів", "error", 1000);
      
    });
  }

  onValueChangedAmount(e) {
    this.orderRefundRequest.summaRefundPredict = e.value * (this.orderRefundPrepare.pricePay - this.orderRefundPrepare.discPerLitr);
  }

  onRefundFuelCancel() {
    this.isPopupRefundOrder = false;
  }

  onRefundFuelConfirmCancel() {
    this.isPopupRefundFuelConfirm = false;
  }
}
