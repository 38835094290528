export class Segments {
    id: number;
    name: string;
    comment: string;
    periodTypeId: number;
    dateFrom: Date; 
    dateTo: Date;
    conditionBlocks: SegmentConditionBlocks[];
    isDeleted: number;

    constructor(){
        this.conditionBlocks = [];
    }
}

export class SegmentConditionBlocks{
    conditions: SegmentCondition[];

    constructor(){
        this.conditions = [];
    }
}

export class SegmentCondition {
    typeId: number;
    param: string;
    operator: string;
    val: string;
}

export class SegmentParamStruct{
    id: string;
    caption: string;
    dataTypeId: number;
}

export class SegmentOperatorStruct{
    id: string;
    caption: string;
}

