import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PromotionsListComponent } from './promotions-list.component';
import { PromotionsEditComponent } from './promotions-edit.component';
import { AuthGuard } from '../../_guards/auth.guard';
import { PromotionsOrderComponent } from './promotions-order.component';
import { PromotionsTesterComponent } from './promotions-tester.component';
import { PromotionsOfflineComponent } from './promotions-offline.component';
import { PromotionsDigestComponent } from './promotions-digest.component';

const routes: Routes = [
    { path: 'promotions',  component: PromotionsListComponent, canActivate: [AuthGuard] },
    { path: 'promotions/edit/:id', component: PromotionsEditComponent, canActivate: [AuthGuard] },
    { path: 'promotions/create', component: PromotionsEditComponent, canActivate: [AuthGuard] },
    { path: 'promotions/order', component: PromotionsOrderComponent, canActivate: [AuthGuard] },
    { path: 'promotions/tester', component: PromotionsTesterComponent, canActivate: [AuthGuard] },
    { path: 'promotions/offline', component: PromotionsOfflineComponent, canActivate: [AuthGuard] },
    { path: 'promotions/digest', component: PromotionsDigestComponent, canActivate: [AuthGuard] }
    
  ];
  
  @NgModule({
    imports: [
      RouterModule.forChild(routes)
    ],
    exports: [
      RouterModule
    ]
  })
  export class PromotionsRoutingModule { }