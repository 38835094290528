import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Promotions, ValueId, Id, GoodsGroups, Goods } from '../../_models';
import { PromotionsService, GoodsService, GoodsGroupsService } from '../../_services';

import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";

import notify from 'devextreme/ui/notify';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { PromoOffline, PromoOfflineGoodsDiscount } from 'src/app/_models/promo/promo-offline';
import { PromoExceptGroups } from 'src/app/_models/promo/promo-except-groups';


const ICON_SPINNER: string = "fa fa-lg fa-spinner fa-spin";
const ICON_FLOPPY: string = "fa fa-lg fa-floppy-o";

@Component({
    templateUrl: 'promotions-offline.component.html',
    styleUrls: ['./promotions-edit.css']
})
export class PromotionsOfflineComponent implements OnInit, AfterViewInit {


    iconSave: string = ICON_FLOPPY;

    loading = false;
    error = '';
    saving = false;
    item: PromoOffline;
    offlineDiscountTypes: ValueId[];

    goods: Goods[] = [];
    groups: GoodsGroups[] = [];

    goodsDataSource: DataSource;
    groupsDataSource: DataSource;

    popupGoodsVisible: boolean = false;
    popupGroupsVisible: boolean = false;

    public constructor(
        private titleService: Title,
        private location: Location,
        private dataService: PromotionsService,
        private goodsService: GoodsService,
        private goodsGroupsService: GoodsGroupsService,
        private router: Router
    ) {
        this.titleService.setTitle("Offline налаштування");
    }

    ngAfterViewInit(): void {
        this.offlineDiscountTypes = this.dataService.getOfflineDiscountTypes();
    }
    ngOnInit(): void {
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        var allowed = [1];

        if(!allowed.includes(currentUser.UserType)){
            this.router.navigateByUrl("404")
        }


        this.dataService.getOfflineSettings()
            .subscribe(items => {
                this.item = items;
                if(this.item.excludedPrefixCards == null) {
                    this.item.excludedPrefixCards = [];
                }
            },
                error => {
                    this.error = error;
                });


        this.goodsService.getAll()
            .subscribe(items => {
                this.goods = items;
                this.initGoodsDataSource();
            },
                error => {
                    this.error = error;
                });

        this.goodsGroupsService.getAll()
            .subscribe(items => {
                this.groups = items;
                this.initGroupsDataSource();
            },
                error => {
                    this.error = error;
                    this.loading = false;
                });


        //this.item = new PromoOffline();
        // this.loading = true;

        // this.dataService.getAll()
        // .subscribe(items => {
        //     this.items = items;
        //     this.initDataSource();
        //     this.loading = false;
        // },
        // error => {
        //     this.error = error;
        //     this.loading = false;
        // });
    }

    initGoodsDataSource() {
        this.goodsDataSource = new DataSource({
            store: new ArrayStore({
                key: "id",
                data: this.goods,
                // Other ArrayStore options go here
            }),
            filter: ["isDeleted", "=", "0"]
        });
    }


    initGroupsDataSource() {
        this.groupsDataSource = new DataSource({
            store: new ArrayStore({
                key: "id",
                data: this.groups,
                // Other ArrayStore options go here
            }),
            filter: ["isDeleted", "=", "0"]
        });
    }


    goBack() {
        this.location.back();
    }

    update() {
        this.iconSave = ICON_SPINNER;

        this.dataService.updateOfflineSettings(this.item)
        .subscribe(res => {
          this.saving = false;
          notify("Дані збережено", "success", 600);
          this.goBack();
        },
        error => {
          this.error = error;
          this.iconSave = ICON_FLOPPY;
        });


    }

    chooseGoods(value: number) {
        //console.log("Choose goods " + value);
        let index = this.goods.findIndex(x => x.id == value);
        if (index < 0) {
            notify("Помилка при підборі товару", "error", 600);
            return;
        }
        let goods = this.goods[index];

        index = this.item.goodsDiscounts.findIndex(x => x.id == value);
        if (index >= 0) {
            notify("Товар '" + goods.name + "' вже є в списку", "error", 600);
            return;
        }
        let newDiscount = new PromoOfflineGoodsDiscount();
        newDiscount.id = goods.id;
        newDiscount.extId = goods.extId;
        newDiscount.name = goods.name;
        this.item.goodsDiscounts.push(newDiscount);
        notify("Товар '" + goods.name + "' успішно доданий", "success", 600);
    }

    onGoodsDiscountAdd() {
        this.popupGoodsVisible = true;
    }

    onExceptGroupAdd() {
        this.popupGroupsVisible = true;
    }

    deleteGoodsDiscount(discount) {
        let index = this.item.goodsDiscounts.indexOf(discount);
        this.item.goodsDiscounts.splice(index, 1);
    }

    deleteExceptGroup(group) {
        let index = this.item.exceptGroups.indexOf(group);
        this.item.exceptGroups.splice(index, 1);
    }

    addExceptGroup(value) {
        let index = this.groups.findIndex(x => x.id == value);
        if (index < 0) {
            notify("Помилка при підборі групи", "error", 600);
            return;
        }
        let group = this.groups[index];

        index = this.item.exceptGroups.findIndex(x => x.id == value);
        if (index >= 0) {
            notify("Група '" + group.name + "' вже є в списку", "error", 600);
            return;
        }
        let exceptGroup = new PromoExceptGroups();
        exceptGroup.id = group.id;
        exceptGroup.extId = group.extId;
        exceptGroup.name = group.name;
        this.item.exceptGroups.push(exceptGroup);
        notify("Група '" + group.name + "' успішно додана", "success", 600);
    }

    treeView_itemSelectionChanged(e) {
        if (e.component.getSelectedNodesKeys() != 0) {
            let groupId = e.component.getSelectedNodesKeys()[0];

            this.addExceptGroup(groupId);
        }
    }

    deletePrefixExcluded(e) {               
        this.item.excludedPrefixCards.splice(e, 1);
    }

    addPrefixExcluded() {        
        this.item.excludedPrefixCards.push('');
    }

    changePrefixExcluded(e) {
        let index = Number(e.element.id);
        this.item.excludedPrefixCards[index] = e.value;        
    }

}
