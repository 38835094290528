import { Component, OnInit, AfterViewInit, ViewChild, ViewChildren } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Location, DatePipe } from '@angular/common';
import { Clients, ValueId, AddressArea, AddressFindStreetRequest, VehicleBrands, Tab, Cards, ClientCounters, VmClientTransLine, 
  VmClientTransCounter, VmClientTransPromoLine, VmClientTransGifts, PhoneConfirmSMS, ClientTransFW, ClientRestFWView, FuelPricesFW, ClientFWBlock, ClientWalletFuelsBlockRequest, 
   ClientFWExchange, ClientWalletFuelsExchangeRequest, ClientWalletFuelsRestoreRequest, ClientPaymentTransFW, WalletFuelsRefundPrepare, WalletFuelsRefundRequest, ClientLimitsFS, ClientPredictDiscView, PhoneConfirmCall, 
   WalletBonusOperations, WalletBonusBalances, ValueIdStr } from '../../_models';
import { ClientsService, AddressService, VehicleBrandsService, SegmentsService, TransactionsWalletService, ConfigService, TransactionsWalletBonusService } from '../../_services';
import { Guid } from 'guid-typescript';

const ICON_SPINNER: string = "fa fa-lg fa-spinner fa-spin";
const ICON_FLOPPY: string = "fa fa-lg fa-floppy-o";

import notify from 'devextreme/ui/notify';
import { Observable } from 'rxjs';
import DataSource from "devextreme/data/data_source";
import ArrayStore from "devextreme/data/array_store";
import { DxValidatorComponent, DxDataGridComponent } from 'devextreme-angular';
import DataGrid from "devextreme/ui/data_grid";
import { Router } from '@angular/router';
import { isUndefined } from 'util';
import { resolve } from 'url';

const ICON_SEND: string = "fa fa-lg fa-send";

@Component({
  templateUrl: 'clients-edit.component.html',
  styleUrls: ['./clients-edit.component.css'],
  providers: [DatePipe]

})
export class ClientsEditComponent implements OnInit, AfterViewInit {

  iconSend: string = ICON_SEND;
  onlyNumbersPattern = "^[0-9]+$";
  loading = false;
  loading_phone = false;
  saving = false;
  error = '';
  caption: string = "";
  private id;
  item: Clients;
  iconSave: string = ICON_FLOPPY;
  isNewItem: boolean = false;
  streetsDataSource: DataSource;

  dsCards: DataSource;
  findCard: Cards;
  cardPopupCaption: string;
  isNewCard: boolean = false;
  reEnabled: boolean = false;

  checked: boolean = false;
  needApprove: boolean = false;
  theSameClient: boolean = false;
  duplicateInternal: boolean = false;

  nextClientId: number;
  nextClientName: string;
  nextClientSecret: string;

  showFisrtCheck: boolean = true;
  showSecondCheck: boolean = true;

  streets: string[];

  dsPaymentSystem: ValueId[];
  dsFWDocPaymentsTypes: ValueId[];
  dsWalletBonusOperTypes: ValueIdStr[];

  tabs: Tab[];
  transTabs: Tab[];
  fwTabs: Tab[];
  predictTabs: Tab[];
  profileTabs: Tab[];
  walletBonusTabs: Tab[];

  allowSave: boolean = false;

  isShowProfile: boolean = false;
  isShowProfileForm: boolean = false;
  isShowProfileSettings: boolean = false;
  isShowProfileInsurancePolicy: boolean = false;
  isShowCards: boolean = false;
  isShowSegments: boolean = false;
  isShowCounters: boolean = false;
  isShowTransactions: boolean = false;
  isShowFW: boolean = false;
  isShowPredictions: boolean = false;
  isShowPhoneChangeConfirm: boolean = false;
  isShowWalletBonus: boolean = false;

  isShowPredictDisc: boolean = false;
  isShowPredictLimits: boolean = false;

  isShowWalletBonusTrans: boolean = false;
  isShowWalletBonusBalance: boolean = false;

  isShowTransLines: boolean = false;
  isShowTransPromoLines: boolean = false;
  isShowTransCounters: boolean = false;
  isShowTransGifts: boolean = false;

  isShowPaymentTransFw: boolean = false;
  isShowTransFw: boolean = false;
  isShowRestFw: boolean = false;

  isPopupDeleteCard: boolean = false;
  isPopupEditCard: boolean = false;

  isPopupExchangeFuel: boolean = false;
  isPopupExchangeFuelConfirm: boolean = false;

  isPopupBlockFuel: boolean = false;
  isPopupBlockFuelConfirm: boolean = false;
  textFWBlockReason: any;

  isPopupRestoreFuelConfirm: boolean = false;

  isAllowRefund: boolean = false;
  countMonthAllowFuelRefund: number = 90;

  isPopupRefundOrder: boolean = false;
  isPopupRefundFuelConfirm: boolean = false;

  orderRefundPrepare: WalletFuelsRefundPrepare;
  orderRefundRequest: WalletFuelsRefundRequest;

  clientCounters: ClientCounters[];
  clientSegments: ValueId[];
  clientTransLines: VmClientTransLine[];
  clientTransPromoLines: VmClientTransPromoLine[];
  clientTransCounters: VmClientTransCounter[];
  clientTransGifts: VmClientTransGifts[];
  clientPaymentTransFW: ClientPaymentTransFW[];
  clientTransFW: ClientTransFW[];
  clientRestFWView: ClientRestFWView[];
  clientFWExchangeFuel: ClientFWExchange[];
  clientFWBlockFuel: ClientFWBlock;

  clientPredictDisc: ClientPredictDiscView[] = [];
  clientPredictLimits: ClientLimitsFS[] = [];

  clientWalletBonusTrans: WalletBonusOperations[];
  clientWalletBonusBalance: WalletBonusBalances[];
  
  clientFWExchangeView : any;
  clientFWExchangeRequest: ClientWalletFuelsExchangeRequest;
  clientFWRestoreRequest: ClientWalletFuelsRestoreRequest;
  clientFWBlockRequest: ClientWalletFuelsBlockRequest;

  countMonthAllowFuelRestore: number = 2;

  areas: any;
  cities: any;
  vehicleBrands: any;

  loadingCities: boolean = false;

  vehicleYears: ValueId[];
  genderList: ValueId[];
  vehicleTypeList: ValueId[];
  maritalStatusList: ValueId[];
  cardActives: ValueId[];
  dsFWDocTypes: ValueId[];

  transDatFrom: Date;
  transDatTo: Date;

  transFWDateFrom: Date;
  transFWDateTo: Date;

  transWalletBonusDateFrom: Date;
  transWalletBonusDateTo: Date;

  predictCurrMonthPetrol: string;
  predictCurrMonthDP: string;
  predictCurrMonthLPG: string;
  predictCurrMonthMarket: string;

  predictNextMonthPetrol: string;
  predictNextMonthDP: string;
  predictNextMonthLPG: string;
  predictNextMonthMarket: string;

  phoneNumberConfirm: string;
  codeSMS: string;
  rules: any;
  showSMSCode: boolean = false;
  showForceRequest: boolean = false;
  phoneConfirmSMS : PhoneConfirmSMS;
  phoneConfirmCall : PhoneConfirmCall;
  showCallAuthComplete : boolean = false;

  authType : number = 0; // sms - default
  deductionZSUShow : number = 0;

  public constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private location: Location,
    private clientsService: ClientsService,
    private configService: ConfigService,
    private transactionsWalletService: TransactionsWalletService,
    private transactionsWalletBonusService: TransactionsWalletBonusService,
    private addressService: AddressService,
    private vehicleBrandsService: VehicleBrandsService,
    private sergmentsService: SegmentsService,
    private datePipe: DatePipe,
    private router: Router
  ) {
    this.rules = { "X": /[0]/ };
  }

  ngAfterViewInit(): void {

  }


  ngOnInit(): void {

    let currentUser = JSON.parse(localStorage.getItem('currentUser'));

    var allowed = [1, 10, 40];
    var allowedRefund = [1, 40];

    if(!allowed.includes(currentUser.UserType)){
        this.router.navigateByUrl("404")
    }

    if(allowedRefund.includes(currentUser.UserType)) {
      this.isAllowRefund = true;
    }

    this.configService.getConfig().subscribe(res => {
      if(res.authType) {
        this.authType = res.authType;        
      }
      if(res.deductionZsuShow) {
        this.deductionZSUShow = res.deductionZsuShow;
      }
    });

    this.initVehicleYears();

    this.transDatFrom = new Date();
    this.transDatTo = new Date();
    this.transDatFrom = this.add_months(this.transDatFrom, -3);

    this.transFWDateFrom = new Date();
    this.transFWDateTo = new Date();
    this.transFWDateFrom = this.add_months(this.transFWDateFrom, -3);

    this.transWalletBonusDateFrom = new Date();
    this.transWalletBonusDateTo = new Date();
    this.transWalletBonusDateFrom = this.add_months(this.transFWDateFrom, -3);

    this.tabs = this.clientsService.getTabs();
    this.transTabs = this.clientsService.getTransTabs();
    this.fwTabs = this.clientsService.getFWTabs();
    this.predictTabs = this.clientsService.getPredictTabs();
    this.profileTabs = this.clientsService.getProfileTabs();
    this.walletBonusTabs = this.clientsService.getWalletBonusTabs();

    this.showTabId(0);
    this.showProfileTabId(0);

    this.addressService.getAreas().toPromise().then((r) => this.areas = r);
    this.vehicleBrandsService.getAll().toPromise().then((r) => this.vehicleBrands = r);

    this.genderList = this.clientsService.getGenders();
    this.vehicleTypeList = this.clientsService.getVehicleTypes();
    this.maritalStatusList = this.clientsService.getMaritalStatuses();
    this.cardActives = this.clientsService.getCardActive();
    this.dsFWDocTypes = this.clientsService.getFWDocTypes();
    this.dsPaymentSystem = this.transactionsWalletService.getPaymentSystems();
    this.dsFWDocPaymentsTypes = this.clientsService.getFWDocPaymentsTypes();
    this.dsWalletBonusOperTypes = this.transactionsWalletBonusService.getOperationTypes();

    this.route.params.subscribe(params => {
      this.id = +params['id']; // (+) converts string 'id' to a number
    });

    if (Number.isNaN(this.id)) {
      this.caption = "Додавання клієнта";
      this.titleService.setTitle(this.caption);
      this.item = new Clients();
      this.item.id = 0;

      this.item.cards = [];

      this.isNewItem = true;
      this.initCardsDataSource();
    } else {

      this.loading = true;
      this.caption = "Інформація про клієнта";
      this.titleService.setTitle(this.caption);

      this.clientsService.getItem(this.id)
        .subscribe(item => {
          this.item = item;
          if (this.item.areaId && this.item.areaId > 0) {
            this.loadCitiesByArea(this.item.areaId);
          }
          this.initCardsDataSource();
          this.loading = false;
        },
          error => {
            this.error = error;
            this.loading = false;
          });    
    }
  }

  initCardsDataSource() {
    this.dsCards = new DataSource({
      store: new ArrayStore({
        key: "id",
        data: this.item.cards,
        // Other ArrayStore options go here
      }),
      filter: ["isDeleted", "=", "0"]
    });
  }

  initVehicleYears() {
    let year = (new Date()).getFullYear();

    this.vehicleYears = [];
    for (var i = 1900; i <= year; i++) {
      let val = new ValueId();
      val.id = i;
      val.value = i.toString();
      this.vehicleYears.push(val);
    }
  }

  add_months(dt, n) {
    return new Date(dt.setMonth(dt.getMonth() + n));
  }

  add_days(dt, n) {
    return new Date(dt.setDate(dt.getDate() + n));
  }

  applyTransDatFilter() {
    if (this.isShowTransLines) {
      this.loadClientTransLines();
    }
    if (this.isShowTransCounters) {
      this.loadClientTransCounters();
    }
  }

  applyTransFWDatFilter() {
    if(this.isShowTransFw) {
      this.loadClientFWTrans();
    }
    else if(this.isShowPaymentTransFw) {
      this.loadClientFWPaymentsTrans();
    }
  }

  applyTransWalletBonusDatFilter() {
    if(this.isShowWalletBonusTrans) {
      this.loadClientWalletBonusTrans();
    }
  }

  goBack() {
    this.location.back();
  }


  update() {
    console.log("update begin");
    this.iconSave = ICON_SPINNER;
    this.clientsService.updateItem(this.item)
      .subscribe(res => {
        this.saving = false;
        notify("Дані збережено", "success", 600);
        this.goBack();
      },
        error => {
          this.error = error;
          this.iconSave = ICON_FLOPPY;
        });
  }

  loadCitiesByArea(areaId: number) {
    if (areaId) {
      this.loadingCities = true;
      this.addressService.getCities(areaId)
        .subscribe(item => {
          this.cities = item;
          this.loadingCities = false;
        },
          error => {
            this.error = error;
            this.loadingCities = false;
          });
    }
  }

  onAreaChanged(e) {
    this.loadCitiesByArea(e.value);
  }

  onStreetValueChanged(e) {
    if (!e || !e.value) {
      this.streets = [];
      return;
    }
    if (e.value.length == 1 && !e.previousValue) {
      if (this.item.cityId > 0) {
        let streetRequest = new AddressFindStreetRequest();
        streetRequest.cityId = this.item.cityId;
        streetRequest.locale = "ua";
        streetRequest.query = e.value;
        streetRequest.maxResultCnt = 1000;

        this.addressService.findStreet(streetRequest)
          .subscribe(item => {
            this.streets = item;
            e.component.dataSource = this.streets;
          },
            error => {
              this.error = error;

            });
      }
      else {
        return;
      }

    }

  }

  onFormSubmit(e, valGroup) {
    this.update();
  }

  handleEnterKeyPress(e) {
    // if (e.keyCode == 13) {
    // }
  }


  showTabId(id: number) {
    this.isShowProfile = id == 0;
    this.isShowCards = id == 1;
    this.isShowSegments = id == 2;
    this.isShowCounters = id == 3;
    this.isShowTransactions = id == 4;
    this.isShowPredictions = id == 5;
    this.isShowFW = id == 6;
    this.isShowWalletBonus = id == 7;

    if(id == 0 || id == 1) {
      this.allowSave = true;
    }
    else {
      this.allowSave = false;
    }

    if (id == 4) {
      if (!this.isShowTransLines && !this.isShowTransPromoLines && !this.isShowTransCounters && !this.isShowTransGifts) {
        this.showTransTabId(0);
      }
    }
    if (id == 3) {
      this.loadClientCounters();
    }
    if (id == 2) {
      this.loadClientSegments();
    }
    if(id == 5) {
      if(!this.isShowPredictDisc && !this.isShowPredictLimits) {
        this.showPredictTabId(0);
      }      
    }
    if(id == 6) {
      if(!this.isShowPaymentTransFw && !this.isShowTransFw && !this.isShowRestFw) {
        this.showFWTabId(0);
      }
    }
    if(id == 7) {
      if(!this.isShowWalletBonusTrans && !this.isShowWalletBonusBalance) {
        this.showWalletBonusTabId(0);
      }
    }
  }

  showTransTabId(id: number) {
    this.isShowTransLines = id == 0;
    this.isShowTransPromoLines = id == 1;
    this.isShowTransCounters = id == 2;
    this.isShowTransGifts = id == 3;
    if (id == 0) {
      this.loadClientTransLines();
    }
    if (id == 1) {
      this.loadClientTransPromoLines();
    }
    if (id == 2) {
      this.loadClientTransCounters();
    }
    if (id == 3) {
      this.loadClientTransGifts();
    }
  }

  showFWTabId(id: number) {
    this.isShowPaymentTransFw = id == 0;
    this.isShowTransFw = id == 1;
    this.isShowRestFw = id == 2;

    if(id == 0) {
      this.loadClientFWPaymentsTrans();
    }
    else if(id == 1) {
      this.loadClientFWTrans();
    }
    else {
      this.loadClientFWRest();
    }
  }

  showWalletBonusTabId(id: number) {
    this.isShowWalletBonusTrans = id == 0;
    this.isShowWalletBonusBalance = id == 1;

    if(id == 0) {
      this.loadClientWalletBonusTrans();
    }
    else if(id == 1) {
      this.loadClientWalletBonusBalance();
    }
  }

  showPredictTabId(id: number) {
    this.isShowPredictDisc = id == 0;
    this.isShowPredictLimits = id == 1;

    if(id == 0) {
      this.loadClientPredictions();
    }
    else if(id == 1) {
      this.loadClientLimitsFS();
    }

  }

  showProfileTabId(id: number) {
    this.isShowProfileForm = id == 0;
    this.isShowProfileSettings = id == 1;
    this.isShowProfileInsurancePolicy = id == 2;

    if(id == 0) {

    }
    else if(id == 1) {

    }
    else if(id == 2) {

    }

  }

  loadClientWalletBonusTrans() {
    if (this.item.id == 0) {
      return;
    }
    let datFromStr = this.datePipe.transform(this.transWalletBonusDateFrom, 'yyyy-MM-dd');
    let datToStr = this.datePipe.transform(this.transWalletBonusDateTo, 'yyyy-MM-dd');
    this.transactionsWalletBonusService.getTransWalletBonus(this.item.id, datFromStr, datToStr, true)
      .subscribe(item => {
        this.clientWalletBonusTrans = item;
        this.loading = false;
      },
        error => {
          this.error = error;       
          this.loading = false;
        });
  }

  loadClientWalletBonusBalance() {
    if (this.item.id == 0) {
      return;
    }
    this.transactionsWalletBonusService.getTransWalletBonusBalances(this.item.id)
    .subscribe(item => {
      this.clientWalletBonusBalance = item;
      this.loading = false;
    },
      error => {
        this.error = error;       
        this.loading = false;
      });
  }

  calcAmount(e) {
    if(e.amount != null) {
      return (e.amount / 100);
    }
    return e.amount;
  }

  calcFinalAmount(e) {
    if(e.finalAmount != null) {
      return (e.finalAmount / 100);
    }
    return e.finalAmount;
  }

  loadClientFWPaymentsTrans() {
    if (this.item.id == 0) {
      return;
    }
    let datFromStr = this.datePipe.transform(this.transFWDateFrom, 'yyyy-MM-dd');
    let datToStr = this.datePipe.transform(this.transFWDateTo, 'yyyy-MM-dd');
    this.clientsService.getClientPaymentTransFW(this.item.id, datFromStr, datToStr)
      .subscribe(item => {
        this.clientPaymentTransFW = item;
        this.loading = false;
      },
        error => {
          if(error.error === "Client has no taxiID") {
            notify("Клієнт не має активного паливного гаманця", "error", 2000);
          }
          else {
            this.error = error;
          }        
          this.loading = false;
        });
  }

  loadClientFWTrans() {
    if (this.item.id == 0) {
      return;
    }
    let datFromStr = this.datePipe.transform(this.transFWDateFrom, 'yyyy-MM-dd');
    let datToStr = this.datePipe.transform(this.transFWDateTo, 'yyyy-MM-dd');
    this.clientsService.getClientTransFW(this.item.id, datFromStr, datToStr)
      .subscribe(item => {
        this.clientTransFW = item;
        this.loading = false;
      },
        error => {
          if(error.error === "Client has no taxiID") {
            notify("Клієнт не має активного паливного гаманця", "error", 2000);
          }
          else {
            this.error = error;
          }        
          this.loading = false;
        });

  }

  loadClientFWRest() {
    if (this.item.id == 0) {
      return;
    }    

    this.clientRestFWView = [];
    this.clientsService.getClientRestFW(this.item.id)
      .subscribe(item => {
        item.forEach(element => {          
            for (let key in element.amounts) {              
              let value = element.amounts[key];
              const _row: ClientRestFWView = new ClientRestFWView();
              _row.id = element.fuel.id;
              _row.extId = element.fuel.extId;
              _row.fuelName = element.fuel.name;            
              _row.datExp = new Date(key);
              _row.amount = value;
              this.clientRestFWView.push(_row);
             }
        });

        this.loading = false;
      },
        error => {
          if(error.error === "Client has no taxiID") {
            notify("Клієнт не має активного паливного гаманця", "error", 2000);
          }
          else {
            this.error = error;
          }        
          this.loading = false;
        });
  }

  loadClientTransLines() {
    if (this.item.id == 0) {
      return;
    }
    let datFromStr = this.datePipe.transform(this.transDatFrom, 'yyyy-MM-dd');
    let datToStr = this.datePipe.transform(this.transDatTo, 'yyyy-MM-dd');
    this.clientsService.getClientTransLines(this.item.id, datFromStr, datToStr)
      .subscribe(item => {
        this.clientTransLines = item;
        this.loading = false;
      },
        error => {
          this.error = error;
          this.loading = false;
        });
  }

  loadClientTransGifts() {
    if (this.item.id == 0) {
      return;
    }
    let datFromStr = this.datePipe.transform(this.transDatFrom, 'yyyy-MM-dd');
    let datToStr = this.datePipe.transform(this.transDatTo, 'yyyy-MM-dd');
    this.clientsService.getClientTransGifts(this.item.id, datFromStr, datToStr)
      .subscribe(item => {
        this.clientTransGifts = item;
        this.loading = false;
      },
        error => {
          this.error = error;
          this.loading = false;
        });
  }

  loadClientTransPromoLines() {
    if (this.item.id == 0) {
      return;
    }
    let datFromStr = this.datePipe.transform(this.transDatFrom, 'yyyy-MM-dd');
    let datToStr = this.datePipe.transform(this.transDatTo, 'yyyy-MM-dd');
    this.clientsService.getClientTransPromoLines(this.item.id, datFromStr, datToStr)
      .subscribe(item => {
        this.clientTransPromoLines = item;
        this.loading = false;
      },
        error => {
          this.error = error;
          this.loading = false;
        });
  }

  loadClientTransCounters() {
    if (this.item.id == 0) {
      return;
    }
    let datFromStr = this.datePipe.transform(this.transDatFrom, 'yyyy-MM-dd');
    let datToStr = this.datePipe.transform(this.transDatTo, 'yyyy-MM-dd');
    this.clientsService.getClientTransCounters(this.item.id, datFromStr, datToStr)
      .subscribe(item => {
        this.clientTransCounters = item;
        this.loading = false;
      },
        error => {
          this.error = error;
          this.loading = false;
        });
  }

  loadClientSegments() {
    if (this.clientSegments == null) {
      if (this.item.id == 0) {
        return;
      }
      this.sergmentsService.getAssignedSegments(this.item.id)
        .subscribe(item => {
          this.clientSegments = item;
          this.loading = false;
        },
          error => {
            this.error = error;
            this.loading = false;
          });
    }
  }

  loadClientCounters() {
    if (this.clientCounters == null) {
      if (this.item.id == 0) {
        return;
      }
      this.clientsService.getCounters(this.item.id)
        .subscribe(item => {
          this.clientCounters = item;
          this.loading = false;
        },
          error => {
            this.error = error;
            this.loading = false;
          });
    }
  }

  selectTab(e) {
    this.showTabId(e.itemData.id);
  }

  selectTransTab(e) {
    this.showTransTabId(e.itemData.id);
  }

  selectFWTab(e) {
    this.showFWTabId(e.itemData.id);
  }

  selectPredictTab(e) {
    this.showPredictTabId(e.itemData.id);
  }

  selectProfileTab(e) {
    this.showProfileTabId(e.itemData.id);
  }

  selectWalletBonusTabs(e) {
    this.showWalletBonusTabId(e.itemData.id);
  }

  editCardPopup(e) {
    this.findCard = e.data as Cards;
    this.needApprove = false;
    this.checked = false;
    this.theSameClient = false;
    this.isPopupEditCard = true;
    this.reEnabled = false;
    this.duplicateInternal = false;
    this.showFisrtCheck = true;
    this.showSecondCheck = true;

    if (this.findCard) {
      this.cardPopupCaption = "Редагування картки";
      this.isNewCard = false;

    }
    else {
      this.cardPopupCaption = "Додавання картки";
      this.findCard = new Cards();
      this.findCard.id = 0;
      this.findCard.isActive = 1;
      this.findCard.isDeleted = 0;
      this.isNewCard = true;

    }

  }

  checkCardNumber(){
    this.checked = false;
    this.theSameClient = false;
    this.duplicateInternal = false;

    if (isUndefined(this.findCard.visibleNumber)) {
      return;
    }

    this.findCard.visibleNumber = this.findCard.visibleNumber.trim().toUpperCase();
    if (this.findCard.visibleNumber == "") {
      return;
    }

    this.showFisrtCheck = false;

    this.clientsService.getByVisibleNumber(this.findCard.visibleNumber)
        .subscribe(res => {
          if (res == null) { // это полностью новая карта
            this.reEnabled = true;

            if (isUndefined(this.findCard.internalNumber)) {
              this.findCard.internalNumber = "R_"
              return;
            } else {
              this.findCard.internalNumber = this.findCard.internalNumber.trim().toUpperCase(); // в верхний регистр!
              if (this.findCard.internalNumber == "") {
                this.findCard.internalNumber = "R_"
                return;
              }
              if (!this.findCard.internalNumber.startsWith("R_")){
                {
                  this.findCard.internalNumber = "R_";
                  return;}
              }
              this.showSecondCheck = false;
              this.clientsService.getByInternalNumber(this.findCard.internalNumber).subscribe(rescard =>
                {
                  if (rescard == null) {
                    this.checked = true;
                    return;
                  } else {
                    this.duplicateInternal = true;
                    this.reEnabled = true;
                    this.isNewCard = false;
                    return;
                  }
                },
                error => {
                  this.error = error;
                });
              }
          } else {
          this.findCard.internalNumber = res.internalNumber;
          this.reEnabled = true;
          if (res.clientID == this.item.id) {
            this.theSameClient = true; // это тот же клиент
            this.isNewCard = false;
            return;
          } else {
          this.nextClientId = res.clientID;
          this.nextClientName = (" "+ ((res.surname==null)?"":(res.surname+" ")) + ((res.name==null)?"":(res.name+" ")) + ((res.mName==null)?"":res.mName)).trim();
          this.nextClientSecret = (res.secretWord==null)?"":res.secretWord;
          this.needApprove = true;
          this.findCard.id = res.cardID;
          this.findCard.isDeleted = 0;
          this.isNewCard = true;
          }
        }

        },
          error => {
            this.error = error;
          });
  }

  clearCardNumber(){
    this.duplicateInternal = false;
    this.checked = false;
    this.theSameClient = false;
    this.isNewCard = true;
    this.reEnabled = false;
    this.findCard.visibleNumber = "";
    this.findCard.internalNumber = "";
    this.needApprove = false;
    this.nextClientId = 0;
    this.nextClientName = "";
    this.nextClientSecret = "";
    this.findCard.id = 0;
    this.findCard.isDeleted = 0;
    this.showFisrtCheck = true;
    this.showSecondCheck = true;



  }

  modifyCardConfirm() {
    if (this.findCard.internalNumber == null) {return;}
    this.findCard.internalNumber = this.findCard.internalNumber.trim()
    if (this.findCard.internalNumber == ""){return;}
    if (this.findCard.id == 0) {
      this.item.cards.push(this.findCard);
      this.initCardsDataSource();
    }
    else {
      this.item.cards.push(this.findCard);
      this.initCardsDataSource();
    }
    this.findCard = null;
    this.isPopupEditCard = false;
  }

  modifyCardCancel() {
    this.findCard = null;
    this.isPopupEditCard = false;
  }

  deleteCardPopup(e) {
    this.findCard = e.data as Cards;
    this.isPopupDeleteCard = true;
  }

  deleteCardConfirm() {
    this.findCard.isActive = 1;
    this.initCardsDataSource();
    this.isPopupDeleteCard = false;
  }

  deleteCardCancel() {
    this.findCard = null;
    this.isPopupDeleteCard = false;
  }

  onToolbarPreparing(e, grid) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'fa fa-filter',
        onClick: this.clearFilter.bind(this, e.component),
        hint: 'Очистити фільтри'
      }
    });

    if(e.element.id === 'clientPredictionsGridContainer') {
      e.toolbarOptions.items.unshift({
        location: 'before',
        widget: 'dxTextBox',
        cssClass: 'grid-options-custom-label',
        options: {
          hint: 'Відрахування ЗСУ',
          text: 'На ЗСУ: ' + this.item.settings.deductionZSU + '%',
          readOnly: true,
          visible: this.isShowPredictDisc && this.deductionZSUShow === 1 && this.item.settings.deductionZSU
        }
      });
    };
  }

  clearFilter(e) {
    e.clearFilter();
  }

  onContentReady(e) {
    console.log("onContentReady" + e.component);
  }

  onInitialized(e) {
    console.log("onInitialized" + e.component);
  }

  showPhoneChangeConfirm() {
    this.isShowPhoneChangeConfirm = true;
    this.showSMSCode = false;
    this.phoneNumberConfirm = "";
    this.codeSMS = "";
  }

  cancelPhoneChangeConfirm() {
    this.isShowPhoneChangeConfirm = false;
    this.showSMSCode = false;
    this.showForceRequest = false;
    this.showCallAuthComplete = false;
  }

  phoneChangeRequest() {
    if(this.phoneNumberConfirm.length != 10) {
      notify('Введіть новий номер клієнта', "error", 1000);
      return;
    }

    this.loading_phone = true;

    if(this.authType == 0) {
      this.phoneConfirmSMS = new PhoneConfirmSMS();
      this.phoneConfirmSMS.clientId = this.id;
      this.phoneConfirmSMS.phone = this.phoneNumberConfirm;
      this.clientsService.requestConfirmPhoneSMS(this.phoneConfirmSMS)
        .subscribe(item => {        
          this.loading_phone = false;
          this.showSMSCode = true;        
        },
        error => {
          this.loading_phone = false;
          let _error = error.error;
          console.log(_error);
          if(_error) {
            if(_error === "This phone already confirmed by the client.") {
              notify('Номер телефону вже підтверджений для клієнта', "error", 1000);
            }
            else if(_error === "This phone already has been used by other client.") {
              this.showForceRequest = true;            
              //notify('Номер телефону підтверджений для іншого клієнта', "error", 1000);
            }
            else {
              notify('Сталася невідома помилка ...', "error", 1000);
            }
          }
          else {
            notify('Сталася невідома помилка ...', "error", 1000);
          }
        });
    }
    else {
      this.phoneConfirmCall = new PhoneConfirmCall();
      this.phoneConfirmCall.clientId = this.id;
      this.phoneConfirmCall.phone = "+38" + this.phoneNumberConfirm;
      this.phoneConfirmCall.requestId = this.replaceAll(Guid.create().toString(), '-', '');
      this.clientsService.requestConfirmPhoneCall(this.phoneConfirmCall)
        .subscribe(item => {        
          this.loading_phone = false;
          this.showCallAuthComplete = true;
        },
        error => {
          this.loading_phone = false;
          let _error = error.error;
          console.log(_error);
          if(_error) {
            if(_error === "This phone already confirmed by the client.") {
              notify('Номер телефону вже підтверджений для клієнта', "error", 1000);
            }
            else if(_error === "This phone already has been used by other client.") {
              this.showForceRequest = true;            
            }
            else {
              notify('Сталася невідома помилка ...', "error", 1000);
            }
          }
          else {
            notify('Сталася невідома помилка ...', "error", 1000);
          }
        });
    }
  }

  replaceAll(str, find, replace) {
    var escapedFind=find.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
    return str.replace(new RegExp(escapedFind, 'g'), replace);
}

  forcePhoneChangeRequest() {
    this.loading_phone = true;

    if(this.authType == 0) {
      this.phoneConfirmSMS = new PhoneConfirmSMS();
      this.phoneConfirmSMS.clientId = this.id;
      this.phoneConfirmSMS.phone = this.phoneNumberConfirm;
      this.clientsService.forceRequestConfirmPhoneSMS(this.phoneConfirmSMS)
        .subscribe(item => {        
          this.loading_phone = false;
          this.showSMSCode = true;        
        },
        error => {
          this.loading_phone = false;
          let _error = error.error;
          console.log(_error);
          if(_error) {
            if(_error === "This phone already confirmed by the client.") {
              notify('Номер телефону вже підтверджений для клієнта', "error", 1000);
            }
            // else if(_error === "This phone already has been used by other client.") {
            //   this.showForceRequest = true;            
            //   //notify('Номер телефону підтверджений для іншого клієнта', "error", 1000);
            // }
            else {
              notify('Сталася невідома помилка ...', "error", 1000);
            }
          }
          else {
            notify('Сталася невідома помилка ...', "error", 1000);
          }
        });
    }
    else {
      this.phoneConfirmCall = new PhoneConfirmCall();
      this.phoneConfirmCall.clientId = this.id;
      this.phoneConfirmCall.phone = "+38" + this.phoneNumberConfirm;
      this.phoneConfirmCall.requestId = this.replaceAll(Guid.create().toString(), '-', '');
      this.clientsService.forceRequestConfirmPhoneCall(this.phoneConfirmCall)
        .subscribe(item => {        
          this.loading_phone = false;
          this.showCallAuthComplete = true;
        },
        error => {
          this.loading_phone = false;
          let _error = error.error;
          console.log(_error);
          if(_error) {
            if(_error === "This phone already confirmed by the client.") {
              notify('Номер телефону вже підтверджений для клієнта', "error", 1000);
            }
            else {
              notify('Сталася невідома помилка ...', "error", 1000);
            }
          }
          else {
            notify('Сталася невідома помилка ...', "error", 1000);
          }
        });
    }
  }

  phoneChangeConfirm() {
    if(this.codeSMS.length < 4) {
      notify('Введіть код з смс', "error", 1000);
      return;
    }

    this.loading_phone = true;
    this.phoneConfirmSMS.sms = this.codeSMS;
    this.clientsService.phoneConfirmSMS(this.phoneConfirmSMS)
      .subscribe(item => {        
        this.loading_phone = false;
        this.item = item;
        this.cancelPhoneChangeConfirm();
        notify('Номер телефону успішно змінено', "success", 1000);       
      },
      error => {
        this.loading_phone = false;
        let _error = error.error;
        console.log(_error);
        if(_error) {
          if(_error === "There is no such phone awaiting confirmation") {
            notify('Цей номер телефона не очікує підтвердження', "error", 1000);
          }
          else if(_error === "Incorrect a confirmation code") {
            notify('Код підтвердження не вірний', "error", 1000);
          }
          else {
            notify('Сталася невідома помилка ...', "error", 1000);
          }
        }
        else {
          notify('Сталася невідома помилка ...', "error", 1000);
        }
      });

  }


  loadClientPredictions() {
    if (this.item.id == 0) {
      return;
    }

    this.loading = true;

    this.clientsService.getClientPredictions(this.item.id)
      .subscribe(item => {

          this.clientPredictDisc = [];
          this.clientPredictDisc.push({nameProduct : "Бензин", currMonthAmount : item.currMonthPetrol.toString() + " грн.", nexMonthAmount : item.nextMonthPetrol.toString() + " грн."});
          this.clientPredictDisc.push({nameProduct : "Дизель", currMonthAmount : item.currMonthDP.toString() + " грн.", nexMonthAmount : item.nextMonthDP.toString() + " грн."});
          this.clientPredictDisc.push({nameProduct : "Газ", currMonthAmount : item.currMonthLPG.toString() + " грн.", nexMonthAmount : item.nextMonthLPG.toString() + " грн."});
          this.clientPredictDisc.push({nameProduct : "Товари", currMonthAmount : item.currMonthMarket.toString() + "%", nexMonthAmount : item.nextMonthMarket.toString() + "%"});

          this.loading = false;
        },
        error => {
          //this.error = error;
          this.loading = false;
          notify(!error.error ? 'Під час виконання запиту сталаса помилка' : error.error, "error", 1000);
        });
  }

  loadClientLimitsFS() {
    if (this.item.id == 0) {
      return;
    }

    this.loading = true;

    this.clientsService.getClientLimitFS(this.item.id)
      .subscribe(item => {
          this.clientPredictLimits = item;
          this.loading = false;
        },
        error => {
          //this.error = error;
          this.loading = false;
          notify(!error.error ? 'Під час виконання запиту сталаса помилка' : error.error, "error", 1000);
        });

  }

  onShowExchangeFuel(e) {
    //console.log(this.id);
    //console.log(e);
    this.loading = true;
    this.clientFWExchangeRequest = new ClientWalletFuelsExchangeRequest();
    this.clientFWExchangeRequest.clientId = this.id;
    this.clientFWExchangeRequest.fromFuelId = e.data.extId;
    this.clientFWExchangeRequest.fromAmount = e.data.amount;

    this.clientFWExchangeView = {};
    this.clientFWExchangeView.fromFuelId = e.data.extId;
    this.clientFWExchangeView.fromAmount = e.data.amount;

    this.clientFWExchangeFuel = [];

    this.clientsService.getFWFuelPrices()
    .subscribe(item => {
      let _fp = item.filter(x => x.price > 0);
      if(_fp.length == 0) {
        this.loading = false;
        notify("Не вдалося отрмати актуальні ціни на пальне", "error", 1000);
        return;
      }

      let _currFp = item.filter(x => x.fuelExtId === this.clientFWExchangeRequest.fromFuelId);
      if(_currFp.length > 0) {
        this.clientFWExchangeView.fromPrice = _currFp[0].price;
        this.clientFWExchangeView.fromFuelName = _currFp[0].fuelFullName;
      }
      else {
        this.loading = false;
        notify("Не вдалося отрмати актуальні ціни на пальне", "error", 1000);
        return;
      }

      let _summ = this.clientFWExchangeView.fromPrice * this.clientFWExchangeView.fromAmount;

      _fp.forEach((el) => {
        let item = new ClientFWExchange();        
        item.fuelId = el.fuelExtId;
        item.fuelName = el.fuelName;
        item.fuelFullName = el.fuelFullName;
        item.price = el.price;

        if(el.fuelExtId !== this.clientFWExchangeView.fromFuelId) {
          item.predictAmount = _summ / item.price;
          this.clientFWExchangeFuel.push(item);
        }
      });

      this.loading = false;
      //console.log(this.clientFWExchangeRequest);
      //console.log(this.clientFWExchangeView);
      //console.log(this.clientFWExchangeFuel);
      this.isPopupExchangeFuel = true;
    }, 
    error => {
      this.loading = false;
      console.log(error);
      notify("Не вдалося отрмати актуальні ціни на пальне", "error", 1000);
      return;
    });
  }

  onExchangeFuel(e) {
    this.clientFWExchangeRequest.toFuelId = e.data.fuelId;
    this.clientFWExchangeView.toFuelId = e.data.fuelId;
    this.clientFWExchangeView.toFuelName = e.data.fuelFullName;
    this.clientFWExchangeView.toAmount = '~ ' + (parseFloat(e.data.predictAmount)
      .toFixed(2).toString().replace('.00',''));
    this.isPopupExchangeFuelConfirm = true;
  }

  onExchangeFuelConfirm() {
    this.loading = true;    
    this.clientsService.fwCreateExchange(this.clientFWExchangeRequest)
    .subscribe((item) => {      
      this.isPopupExchangeFuel = false;
      this.isPopupExchangeFuelConfirm = false;
      this.loading = false;
      notify("Заявка на заміну пального успішно створена", "success", 1000);
    },
    error => {
      this.loading = false;
      notify(error.error ? error.error : "Не вдалося створити заявку для заміни пального", "error", 1000);
    });
  }

  onExchangeFuelCancel() {
    this.isPopupExchangeFuelConfirm = false;
  }

  onShowRestoreFuel(e) {

    if(e.data.docType !== 7) {
      return false;
    }

    let datNow = new Date();
    let datTrans = new Date(e.data.date);
    let datAllow = this.add_months(datTrans, this.countMonthAllowFuelRestore);
    return (datAllow > datNow);
  }

  onRestoreFuel(e) {
    this.clientFWRestoreRequest = new ClientWalletFuelsRestoreRequest();
    this.clientFWRestoreRequest.clientId = this.id;
    this.clientFWRestoreRequest.blockDocId = e.data.docId;
    this.clientFWRestoreRequest.fuelId = e.data.fuelId;
    this.clientFWRestoreRequest.fuelName = e.data.fullFuelName;
    this.clientFWRestoreRequest.amount = e.data.amount * -1;
    this.isPopupRestoreFuelConfirm = true;
  }

  onRestoreFuelConfirm() {
    this.loading = true;    
    this.clientsService.fwCreateRestore(this.clientFWRestoreRequest)
    .subscribe((item) => {      
      this.isPopupRestoreFuelConfirm = false;
      this.loading = false;
      notify("Заявка на відновлення пального успішно створена", "success", 1000);
    },
    error => {
      this.loading = false;
      notify(error.error ? error.error : "Не вдалося створити заявку для відновлення пального", "error", 1000);
      
    });
  }

  onRestoreFuelCancel() {
    this.isPopupRestoreFuelConfirm = false;
  }

  onAllowRefund(e) {
    if(!this.isAllowRefund) {
      return false;
    }
    
    if(![0,1,3].includes(e.data.paymentSystem)) {
      return false;
    }
    
    let datNow = new Date();
    let datTrans = new Date(e.data.localDate);
    let datAllow = this.add_days(datTrans, this.countMonthAllowFuelRefund);
    return (datAllow > datNow);
  }

  onInitRefund(e) {
    this.loading = true;
    this.transactionsWalletService.getFuelRefundPrepare(e.data.id)
    .subscribe(item => {
      this.orderRefundPrepare = item;
      this.orderRefundPrepare.amountRefundAvailable = this.orderRefundPrepare.amountPay < this.orderRefundPrepare.amountAvailable ?
                                                      this.orderRefundPrepare.amountPay : this.orderRefundPrepare.amountAvailable;
      this.loading = false;
      
      this.orderRefundRequest = new WalletFuelsRefundRequest();
      this.orderRefundRequest.origOrderId = item.origOrderId;
      this.orderRefundRequest.amount = item.amountRefundAvailable;   
      this.orderRefundRequest.summaRefundPredict = this.orderRefundPrepare.amountRefundAvailable * (this.orderRefundPrepare.pricePay - this.orderRefundPrepare.discPerLitr);   
      this.isPopupRefundOrder = true;
    },
    error => {
      this.loading = false;
      notify(error.error ? error.error : "Не вдалося отримати інформацію для створення заявки повернення коштів", "error", 1000);
    });
  }

  onRefundFuel() {
    this.isPopupRefundFuelConfirm = true;
  }

  onRefundFuelConfirm() {
    this.transactionsWalletService.fwCreateRefund(this.orderRefundRequest)
    .subscribe((item) => {      
      this.isPopupRefundFuelConfirm = false;
      this.isPopupRefundOrder = false;
      notify("Заявка на повернення коштів успішно створена", "success", 1000);
    },
    error => {
      notify(error.error ? error.error : "Не вдалося створити заявку для повернення коштів", "error", 1000);
      
    });
  }

  onRefundValueChangedAmount(e) {
    this.orderRefundRequest.summaRefundPredict = e.value * (this.orderRefundPrepare.pricePay - this.orderRefundPrepare.discPerLitr);
  }

  onRefundFuelCancel() {
    this.isPopupRefundOrder = false;
  }

  onRefundFuelConfirmCancel() {
    this.isPopupRefundFuelConfirm = false;
  }


  onShowBlockFuel(e) {
    //console.log(this.id);
    //console.log(e);
    this.clientFWBlockRequest = new ClientWalletFuelsBlockRequest();
    this.clientFWBlockRequest.clientId = this.id;
    this.clientFWBlockRequest.fuelId = e.data.extId;
    this.clientFWBlockRequest.amount = 0;
    this.clientFWBlockRequest.reason = "";

    this.clientFWBlockFuel = new ClientFWBlock();
    this.clientFWBlockFuel.fuelId = e.data.extId;
    this.clientFWBlockFuel.fuelName = e.data.fuelName;

    let _maxAmount = 0;
    this.clientRestFWView.map( x => {
      if(x.extId === e.data.extId) _maxAmount += x.amount
    });

    _maxAmount = Math.round(_maxAmount * 100)/100;

    this.clientFWBlockRequest.amount = _maxAmount;
    this.clientFWBlockFuel.maxAmountBlock = _maxAmount;

    this.isPopupBlockFuel = true;
    
  }

  getInstanceFWBlockReason(e) {
    this.textFWBlockReason = e.component;    
  }

  onBlockFuel(e) {
    if(this.clientFWBlockRequest.amount > this.clientFWBlockFuel.maxAmountBlock) {
      notify('Кількість літрів для блокування повина бути <= ' + this.clientFWBlockFuel.maxAmountBlock, 'error');
      return;
    }

    if(this.clientFWBlockRequest.reason === '' || this.clientFWBlockRequest.reason.length < 3) {
      notify('Вкажіть причину блокування пального', 'error');
      this.textFWBlockReason.focus();
      return;
    }

   this.isPopupBlockFuelConfirm = true;

  }

  onBlockFuelConfirm() {
    this.loading = true;    
    this.clientsService.fwCreateBlock(this.clientFWBlockRequest)
    .subscribe((item) => {      
      this.isPopupBlockFuel = false;
      this.isPopupBlockFuelConfirm = false;
      this.loading = false;
      notify("Заявка на блокування пального успішно створена", "success", 1000);
    },
    error => {
      this.loading = false;
      notify(error.error ? error.error : "Не вдалося створити заявку для блокування пального", "error", 1000);
    });
  }

  onBlockFuelCancel() {
    this.isPopupBlockFuel = false;
    this.isPopupBlockFuelConfirm = false;
  }

}
