import { NgModule }       from '@angular/core';
import { CommonModule }       from '@angular/common';
import { FormsModule }    from '@angular/forms';
import { TransactionsGiftsComponent } from './transactions-gifts.component';
import {DxButtonModule, DxTextBoxModule, DxPopupModule, DxDataGridModule, DxTabsModule, DevExtremeModule} from 'devextreme-angular';
import {TransactionsGiftsRoutingModule} from './transactions-gifts-routing.module';

@NgModule({
  imports: [
    TransactionsGiftsRoutingModule,
    CommonModule,
    FormsModule,
    DxButtonModule,
    DxTextBoxModule,
    DxPopupModule,
    DxDataGridModule,
    DxTabsModule,
    DevExtremeModule
  ],
  declarations: [
    TransactionsGiftsComponent
  ]
})
export class TransactionsGiftsModule {

}
