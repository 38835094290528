import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Tab, TransWalletSpillsAvg, TransWalletSpillsRest, TransWalletSpillsStations, ValueId } from '../_models';
import { Globals } from '../globals';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Headers, RequestOptions } from '@angular/http';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

let transTabs: Tab[] = [
  {
    id: 0,
    text: "За номером транзакції",
    icon: "fa fa-list"
  },
  {
    id: 1,
    text: "По клієнтам",
    icon: "fa fa-address-card-o"
  },
  {
    id: 2,
    text: "По АЗК",
    icon: "fa fa-building-o"
  },
  {
    id: 3,
    text: "Ціна проливу",
    icon: "fa fa-credit-card"
  },
  {
    id: 4,
    text: "Залишки (ціна)",
    icon: "fa fa-bar-chart"
  },
  {
    id: 5,
    text: "Залишки (актуальні)",
    icon: "fa fa-area-chart"
  }
];

let transTypes: ValueId[] =
  [
      {
        id: 5,
        value: "Розблоковано (повернено) АЗС"
      },
      {
        id: 6,
        value: "Заблоковано АЗС"
      }
  ];

@Injectable({ providedIn: 'root' })
export class TransactionsWalletSpillsService {
  constructor(private http: HttpClient, private globals: Globals) { }

  getTransTabs(): Tab[]{
    return transTabs;
  }

  getTransType(): ValueId[]{
    return transTypes;
  }
  

  getTransByParams(transNum: number, clientId: number, visibleNumber: string, stationId: number, dayReport: string): Observable<TransWalletSpillsStations[]>{
    return this.http
      .get<TransWalletSpillsStations[]>(this.globals.getApiString() + '/TransactionsFWSpillsRest/TaxiTransStations?trans_id=' + transNum + '&client_id=' + clientId + '&visible_number=' + visibleNumber + '&station_ext_id=' + stationId + '&day_report=' + dayReport,
        httpOptions);
  }

  getTransWalletSpillsAvg(dateTo: string): Observable<TransWalletSpillsAvg[]>{
    return this.http
      .get<TransWalletSpillsAvg[]>(this.globals.getApiString() + '/TransactionsFWSpillsRest/TaxiAvgSpills?dateTo=' + dateTo,
        httpOptions);
  }

  getTransWalletRest(dateTo: string): Observable<TransWalletSpillsRest[]>{
    return this.http
      .get<TransWalletSpillsRest[]>(this.globals.getApiString() + '/TransactionsFWSpillsRest/TaxiRests?dateTo=' + dateTo,
        httpOptions);
  }

  getTransWalletRestAmounts(dateTo: string): Observable<TransWalletSpillsRest[]>{
    return this.http
      .get<TransWalletSpillsRest[]>(this.globals.getApiString() + '/TransactionsFWSpillsRest/TaxiRestAmounts?dateTo=' + dateTo,
        httpOptions);
  }

}
