export class DeductionZSUTransactions {
    clientId: number;
    day: Date;
    name: string;
    surname: string;
    phonecon: string;
    deductionZSU: number;
    procent: number;
}

export class AddSumaMonobanka {
    sumaAdditional: number;
    monoSuma: number;
    totalSuma: string;    
    dataMonoBanka: MonoBankaSettings;
}

export class MonoBankaSettings {
    enableSync: boolean;
    monoBankaUrl: string;
    bodyRequest: string;
    lastUpdate: string;
    updateInterval: number;
    lastUpdateView: string;    
}