import { Goods } from './goods';

export class PromotionsWholesaleDocs {
  id: number;
  createDate: string;
  editDate: string;
  docName: string;
  docDescription: string;
  lines: PromoWholesaleDocLine[];
  isActive: boolean;
}

class PromoWholesaleDocLine {
  id: number;
  promotionsWholesaleDocId: number;
  goodsId: number;
  goods: Goods;
  countFrom: number;
  discountCurrency: number;
  isActive: boolean;
}