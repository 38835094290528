import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Counters, ValueId } from '../_models';
import { Globals } from '../globals';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Headers, RequestOptions } from '@angular/http';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  let periodsTypes: ValueId[] =
    [
        {
            id: 1,
            value: "День"
        },
        {
            id: 2,
            value: "Тиждень"
        },
        {
            id: 3,
            value: "Місяць"
        },
        {
            id: 4,
            value: "Рік"
        }
    ];

    let counterTypes: ValueId[] =
    [
        {
            id: 1,
            value: "Обнуляємий"
        },
        {
            id: 2,
            value: "Необнуляємий"
        }
    ];

@Injectable({ providedIn: 'root' })
export class CountersService {
    constructor(private http: HttpClient, private globals: Globals) { }

    getAll() {
        return this.http.get<Counters[]>(this.globals.getApiString() + `/Counters`, {});
    }

    getItem(id: number){
        return this.http.get<Counters>(this.globals.getApiString() + `/Counters/`+ id, {});
    }

    updateItem(item: Counters): Observable<Counters>{
        return this.http
            .post<Counters>(this.globals.getApiString() + '/Counters',
                JSON.stringify(item),
                httpOptions);
    }

    deleteItem(id: number): Observable<void>{
        return this.http
            .delete<void>(this.globals.getApiString() + '/Counters/' + id,
                httpOptions);
    }

    getPeriods(): ValueId[] {
        return periodsTypes;
    }

    getTypes(): ValueId[] {
        return counterTypes;
    }
}
