import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Tab, WalletBonusBalances, WalletBonusOperations, ValueIdStr } from '../../_models';
import { TransactionsWalletBonusService } from '../../_services';
import { Router } from '@angular/router';
import notify from 'devextreme/ui/notify';
import 'devextreme/data/odata/store';
import {DxDataGridComponent, DxPivotGridComponent} from 'devextreme-angular';
import {DatePipe} from '@angular/common';

@Component({
  templateUrl: 'transactions-wallet-bonus.component.html'
})
export class TransactionsWalletBonusComponent implements OnInit, AfterViewInit {
  @ViewChild("transWalletBonusDataGrid") dataTransGrid: DxDataGridComponent;
  @ViewChild("transWalletBonusBalancesDataGrid") dataBalancesGrid: DxDataGridComponent;

  transTabs: Tab[];
  isShowTrans: boolean = false;
  isShowBalances: boolean = false;
  clientId: number;
  error = '';

  dateFrom: Date;
  dateTo: Date;

  dsTransLines: WalletBonusOperations[];
  dsBalances: WalletBonusBalances[];
  dsOperationTypes: ValueIdStr[];

  //dsMoveRest: PivotGridDataSource;
  caption = 'Скарбничка';

  public constructor(
    private titleService: Title,
    private transactionsWalletBonusService: TransactionsWalletBonusService,
    private router: Router,
    private datePipe: DatePipe
  )
  {
    this.titleService.setTitle(this.caption);
  }

  ngAfterViewInit(): void {

  }

  ngOnInit(): void {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));

    var allowed = [1, 10, 40, 400];

    if(!allowed.includes(currentUser.UserType)){
      this.router.navigateByUrl("404")
    }

    this.dateFrom = new Date();
    this.dateTo = new Date();
    this.dateFrom = this.add_days(this.dateFrom, -3);

    this.dsOperationTypes = this.transactionsWalletBonusService.getOperationTypes();
    this.transTabs = this.transactionsWalletBonusService.getTransWalletBonusTabs();
    this.showTransTabId(0);

  }

  add_months(dt, n) {
    return new Date(dt.setMonth(dt.getMonth() + n));
  }

  add_days(dt, n) {
    return new Date(dt.setDate(dt.getDate() + n));
  }

  showTransTabId(id: number) {
    this.isShowTrans = id == 0;
    this.isShowBalances = id == 1;

    if (id == 0) { }
    else if (id == 1) { }
  }

  selectTransTab(e) {
    this.showTransTabId(e.itemData.id);
  }

  onToolbarPreparingTrans(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',

        options: {
          icon: 'fa fa-filter',
          onClick: this.refreshDataTransGrid.bind(this),
          hint: 'Очистити фільтри'
        }
    });
  }

  onToolbarPreparingBalances(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',

        options: {
          icon: 'fa fa-filter',
          onClick: this.refreshDataBalancesGrid.bind(this),
          hint: 'Очистити фільтри'
        }
    });
  }

  refreshDataTransGrid() {
    if(this.dsTransLines) {
      this.dataTransGrid.instance.clearFilter();
    }
  }

  refreshDataBalancesGrid() {
    if(this.dsBalances) {
      this.dataBalancesGrid.instance.clearFilter();
    }
  }

  onContentReady(e) {
    if(e.element.id === 'transWalletBonusDataGridContainer' && !this.dsTransLines) {      
      this.getTrans();
    }
    else if(e.element.id === 'transWalletBonusBalancesFuelsDataGridContainer' && !this.dsBalances) {
      this.getBalances();
    }

  }

  calcAmount(e) {
    if(e.amount != null) {
      return (e.amount / 100);
    }
    return e.amount;
  }

  calcFinalAmount(e) {
    if(e.finalAmount != null) {
      return (e.finalAmount / 100);
    }
    return e.finalAmount;
  }

  onInitialized(e) {
    
  }

  apply() {
      if(this.isShowTrans) {
        this.getTrans();
      }
  }

  getTrans() {

    if(!this.dateFrom) {
      notify("Введіть дату З", "error", 1000);
      return;
    }

    if(!this.dateTo) {
      notify("Введіть дату ПО", "error", 1000);
      return;
    }

    this.dataTransGrid.instance.beginCustomLoading('');

    this.transactionsWalletBonusService.getTransWalletBonus(null, this.datePipe.transform(this.dateFrom, 'yyyy-MM-dd'), this.datePipe.transform(this.dateTo, 'yyyy-MM-dd'), false)
      .subscribe(item => {
          this.dsTransLines = item;
          this.dataTransGrid.instance.endCustomLoading();
        },
        error => {
          this.error = error;
          this.dataTransGrid.instance.endCustomLoading();
          notify(!error.error ? 'Під час виконання запиту сталаса помилка' : error.error, "error", 1000);
        });

  }
  
  getBalances() {

    this.dataBalancesGrid.instance.beginCustomLoading('');

    this.transactionsWalletBonusService.getTransWalletBonusBalances(null)
      .subscribe(item => {
          this.dsBalances = item;
          this.dataBalancesGrid.instance.endCustomLoading();
        },
        error => {
          this.error = error;
          this.dataBalancesGrid.instance.endCustomLoading();
          notify(!error.error ? 'Під час виконання запиту сталаса помилка' : error.error, "error", 1000);
        });    
  }
}
