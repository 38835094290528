import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Tab, TriggerFcmSchedule, TriggerMessageTemplate, TriggerTaskPlan, ValueId } from '../../_models';
import { PushesTriggersService } from '../../_services';
import { Router } from '@angular/router';
import notify from 'devextreme/ui/notify';
import 'devextreme/data/odata/store';
import {DxDataGridComponent, DxPivotGridComponent} from 'devextreme-angular';
import {DatePipe} from '@angular/common';
import { saveAs } from 'file-saver';

@Component({
  templateUrl: 'triggers-pushes.component.html'
})
export class TriggersPushesComponent implements OnInit, AfterViewInit {
  @ViewChild("taskDataGrid") dataTaskGrid: DxDataGridComponent;
  @ViewChild("templatesDataGrid") dataTemplatesGrid: DxDataGridComponent;
  @ViewChild("taskPlanDataGrid") dataTaskPlanGrid: DxDataGridComponent;

  trPsTabs: Tab[];
  isShowTask: boolean = false;
  isShowTemplates: boolean = false;
  isShowTaskPlan: boolean = false;
  clientId: number;
  loading = false;
  error = '';

  dateFrom: Date;
  dateTo: Date;

  dsTaskLines: TriggerFcmSchedule[];
  dsTemplates: TriggerMessageTemplate[];
  dsTaskPlanes: TriggerTaskPlan[];  
  dsStatuses: ValueId[];
  dsExecutionTypes: ValueId[];
  dsTriggerTypes: ValueId[];
  dsTriggerContentTypes: ValueId[];
  dsTriggerTaskPlaneTypesExecution: ValueId[];
  
  delTemplate: TriggerMessageTemplate;
  delTaskPlan: TriggerTaskPlan;
  isDeleteTemplatePopupVisible: boolean = false;
  isDeleteTaskPlanPopupVisible: boolean = false;

  //dsMoveRest: PivotGridDataSource;
  caption = 'Тригерні повідомлення';

  public constructor(
    private titleService: Title,
    private pushesTriggersService: PushesTriggersService,
    private router: Router,
    private datePipe: DatePipe
  )
  {
    this.titleService.setTitle(this.caption);
  }

  ngAfterViewInit(): void {

  }

  ngOnInit(): void {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));

    var allowed = [1, 10, 40, 400];

    if(!allowed.includes(currentUser.UserType)){
      this.router.navigateByUrl("404")
    }

    this.dateFrom = new Date();
    this.dateTo = new Date();
    this.dateFrom = this.add_days(this.dateFrom, -3);

    this.dsStatuses = this.pushesTriggersService.pushesTriggerStatuses();
    this.dsExecutionTypes = this.pushesTriggersService.pushesTriggerTypeExecution();
    this.dsTriggerTypes = this.pushesTriggersService.pushesTriggerTypes();
    this.dsTriggerContentTypes = this.pushesTriggersService.pushesTriggerContentTypes();
    this.trPsTabs = this.pushesTriggersService.pushesTriggerTabs();
    this.dsTriggerTaskPlaneTypesExecution = this.pushesTriggersService.triggerTaskPlanTypeExecution();    
    this.showTrPsTabId(0);

  }

  add_months(dt, n) {
    return new Date(dt.setMonth(dt.getMonth() + n));
  }

  add_days(dt, n) {
    return new Date(dt.setDate(dt.getDate() + n));
  }

  showTrPsTabId(id: number) {
    this.isShowTask = id == 0;
    this.isShowTemplates = id == 1;
    this.isShowTaskPlan = id == 2;

    if (id == 0) {
     }
    else if (id == 1) {
     }
    else if (id == 2) {
    }     
  }

  selectTrPsTab(e) {
    this.showTrPsTabId(e.itemData.id);
  }

  onToolbarPreparingTask(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',

        options: {
          icon: 'fa fa-filter',
          onClick: this.refreshTaskGrid.bind(this),
          hint: 'Очистити фільтри'
        }
    });
  }

  onToolbarPreparingTemplates(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',

        options: {
          icon: 'fa fa-filter',
          onClick: this.refreshTemplatesGrid.bind(this),
          hint: 'Очистити фільтри'
        }
    });
  }

  onToolbarPreparingTaskPlan(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',

        options: {
          icon: 'fa fa-filter',
          onClick: this.refreshTaskPlanGrid.bind(this),
          hint: 'Очистити фільтри'
        }
    });
  }

  refreshTaskGrid() {
    if(this.dsTaskLines) {
      this.dataTaskGrid.instance.clearFilter();
    }
  }

  refreshTemplatesGrid() {
    if(this.dsTemplates) {
      this.dataTemplatesGrid.instance.clearFilter();
    }
  }

  refreshTaskPlanGrid() {
    if(this.dsTaskPlanes) {
      this.dataTaskPlanGrid.instance.clearFilter();
    }
  }

  onContentReady(e) {
    if(e.element.id === 'taskDataGridContainer' && !this.dsTaskLines) {      
      this.getTasks();
    }
    else if(e.element.id === 'templatesDataGridContainer' && !this.dsTemplates) {
      this.getTamplates();
    }
    else if(e.element.id === 'taskPlanDataGridContainer' && !this.dsTaskPlanes) {
      this.getTaskPlanes();
    }

  }

  onInitialized(e) {
    
  }

  apply() {
      if(this.isShowTask) {
        this.getTasks();
      }
  }

  getTasks() {

    if(!this.dateFrom) {
      notify("Введіть дату З", "error", 1000);
      return;
    }

    if(!this.dateTo) {
      notify("Введіть дату ПО", "error", 1000);
      return;
    }

    this.dataTaskGrid.instance.beginCustomLoading('');

    this.pushesTriggersService.getSchedules(this.datePipe.transform(this.dateFrom, 'yyyy-MM-dd'), this.datePipe.transform(this.dateTo, 'yyyy-MM-dd'))
      .subscribe(item => {
          this.dsTaskLines = item;
          this.dataTaskGrid.instance.endCustomLoading();
        },
        error => {
          this.error = error;
          this.dataTaskGrid.instance.endCustomLoading();
          notify(!error.error ? 'Під час виконання запиту сталаса помилка' : error.error, "error", 1000);
        });

  }
  
  getTamplates() {

    this.dataTemplatesGrid.instance.beginCustomLoading('');

    this.pushesTriggersService.getTemplates()
      .subscribe(item => {
          this.dsTemplates = item;
          this.dataTemplatesGrid.instance.endCustomLoading();
        },
        error => {
          this.error = error;
          this.dataTemplatesGrid.instance.endCustomLoading();
          notify(!error.error ? 'Під час виконання запиту сталаса помилка' : error.error, "error", 1000);
        });    
  }

  deleteTemplate(data){
    this.delTemplate = data.data as TriggerMessageTemplate;
    this.isDeleteTemplatePopupVisible = true;
  }

  deleteTemplateCancel(){
      this.delTemplate = null;
      this.isDeleteTemplatePopupVisible = false;
  }

  deleteTemplateConfirm(){
      this.loading = true;
      this.pushesTriggersService.deleteTemplate(this.delTemplate.id)
      .subscribe(item => {
          this.refreshTemplatesGrid();
          this.getTamplates();
          this.deleteTemplateCancel();
          notify("Шаблон успішно видалено", "success", 600);
          this.loading = false;
      },
      error => {
          this.error = error;
          this.loading = false;
      });
  }

  getTaskPlanes() {

    this.dataTaskPlanGrid.instance.beginCustomLoading('');

    this.pushesTriggersService.getTaskPlanes()
      .subscribe(item => {
          this.dsTaskPlanes = item;
          this.dataTaskPlanGrid.instance.endCustomLoading();
        },
        error => {
          this.error = error;
          this.dataTaskPlanGrid.instance.endCustomLoading();
          notify(!error.error ? 'Під час виконання запиту сталаса помилка' : error.error, "error", 1000);
        });    
  }

  deleteTaskPlan(data){
    this.delTaskPlan = data.data as TriggerTaskPlan;
    this.isDeleteTaskPlanPopupVisible = true;
  }

  deleteTaskPlanCancel(){
      this.delTaskPlan = null;
      this.isDeleteTaskPlanPopupVisible = false;
  }

  deleteTaskPlanConfirm(){
      this.loading = true;
      this.pushesTriggersService.deleteTaskPlane(this.delTaskPlan.id)
      .subscribe(item => {
          this.refreshTaskPlanGrid();
          this.getTaskPlanes();
          this.deleteTaskPlanCancel();
          notify("Планувальник успішно видалено", "success", 600);
          this.loading = false;
      },
      error => {
          this.error = error;
          this.loading = false;
      });
  }

  calcConditions(rowData: any): boolean {
    return rowData.conditions.conditionBlocks !== null && rowData.conditions.conditionBlocks.length > 0;
  }

  downloadTaskPlanResFile(e) {
    try {
      saveAs(this.pushesTriggersService.getTaskPlanFileResult(e.data.fileResGuId), e.data.fileResName);
    }
    catch {
      notify("Не вдалося завантажити файл", "error", 1000);
    }
  }

}
