import { NumberValueAccessor } from "@angular/forms/src/directives";
import { PromoData } from "./promo/promo-data";

export class Promotions {
    id: number;
    name: string;
    extName: string;
    comment: string;
    typeId: number;
    statusId: number;
    dateFrom: Date; 
    dateTo: Date;
    isDeleted: number;
    data: PromoData;
    promoOrder: number;

    constructor(){
        this.data = new PromoData();
        this.statusId = 10; //Черновик
    }
}

