import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { GoodsGroups, Goods } from '../../_models';
import { GoodsGroupsService } from '../../_services';
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";

import { DxTreeListComponent } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';
import { Router } from '@angular/router';

@Component({
    templateUrl: 'goods-groups-list.component.html'
  })
  export class GoodsGroupsListComponent implements OnInit, AfterViewInit {
    @ViewChild(DxTreeListComponent) treeList: DxTreeListComponent;

    items: GoodsGroups[] = [];
    loading = false;
    error = '';

    store: ArrayStore;
    dataSource: DataSource;

    delItem: GoodsGroups;
    isDeletePopupVisible: boolean = false;

    public constructor(
        private titleService: Title,
        private goodsGroupsService: GoodsGroupsService,
        private router: Router
    )
    {
        this.titleService.setTitle("Групи товарів");
    }

    ngAfterViewInit(): void {

    }
    ngOnInit(): void {
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));

        var allowed = [1];

        if(!allowed.includes(currentUser.UserType)){
            this.router.navigateByUrl("404")
        }
        this.loadDataSrouce();
    }

    loadDataSrouce(){
        this.goodsGroupsService.getAll()
        .subscribe(items => {
            this.items = items;
            this.initDataSource();
            this.loading = false;
        },
        error => {
            this.error = error;
            this.loading = false;
        });
    }

    initDataSource(){
        this.dataSource = new DataSource({
            store: new ArrayStore({
                key: "id",
                data: this.items,
                // Other ArrayStore options go here
            }),
            filter: [ "isDeleted", "=", "0" ]
        });
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(  {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'fa fa-filter',
                    onClick: this.refreshDataGrid.bind(this),
                    hint: 'Очистити фільтри'
                }
            });
    }

    refreshDataGrid() {
        //this.dataGrid.instance.refresh();
        this.treeList.instance.clearFilter();
      }

    deleteItem(data){
        this.delItem = data.data as GoodsGroups;
        this.isDeletePopupVisible = true;
    }

    deleteCancel(){
        this.delItem = null;
        this.isDeletePopupVisible = false;
    }

    deleteConfirm(){
        this.loading = true;
        this.goodsGroupsService.deleteItem(this.delItem.id)
        .subscribe(item => {
            // let index = this.items.findIndex(x => x.id == this.delItem.id);
            // this.items[index].isDeleted = 1;

            // this.initDataSource();

            this.loadDataSrouce();
            this.deleteCancel();
            notify("Запис успішно видалено", "success", 600);
            this.loading = false;
        },
        error => {
            this.error = error;
            this.loading = false;
        });
    }
  }
