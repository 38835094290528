import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AddressArea, AddressCity, AddressFindStreetRequest } from '../_models';
import { Globals } from '../globals';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Headers, RequestOptions } from '@angular/http';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

@Injectable({ providedIn: 'root' })
export class AddressService {
    constructor(private http: HttpClient, private globals: Globals) { }

   getAreas() {
        return this.http.get<AddressArea[]>(this.globals.getApiString() + `/Addresses/areas`, {});
   }

   getCities(areaId: number){
    return this.http.get<AddressCity[]>(this.globals.getApiString() + `/Addresses/cities?areaId=` + areaId, {});
   }

   findStreet(request: AddressFindStreetRequest): Observable<string[]>{
      return this.http
        .post<string[]>(this.globals.getApiString() + '/Addresses/findstreet', 
            JSON.stringify(request), 
            httpOptions);
   }
}