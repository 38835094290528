﻿import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { OnExecuteData, ReCaptchaV3Service } from 'ng-recaptcha';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../../_services';
import { ok } from 'assert';


@Component({
    templateUrl: 'login.component.html',
    styleUrls: ['./login.component.css']
  })


  export class LoginComponent implements OnInit {
    model: any = {};
    loading = false;
    error = '';
	public recentToken: string = '';
    public readonly executionLog: OnExecuteData[] = [];

    private allExecutionsSubscription: Subscription;
    private singleExecutionSubscription: Subscription;


         constructor(
          private router: Router,
          private route: ActivatedRoute,
		      private recaptchaV3Service: ReCaptchaV3Service,
	        private authenticationService: AuthenticationService) {

          }


    ngOnInit() {
          // reset login status
          this.authenticationService.logout();
		  this.allExecutionsSubscription = this.recaptchaV3Service.onExecute
      .subscribe((data) => this.executionLog.push(data));

      }

	public ngOnDestroy() {
    if (this.allExecutionsSubscription) {
      this.allExecutionsSubscription.unsubscribe();
    }
    if (this.singleExecutionSubscription) {
      this.singleExecutionSubscription.unsubscribe();
    }
  }

	public captcha() {
	   if (this.singleExecutionSubscription) {
      this.singleExecutionSubscription.unsubscribe();
    }
    this.singleExecutionSubscription = this.recaptchaV3Service.execute('login')
      .subscribe((token) => this.login(token));
	  }

   login(token: string) {
    let captchaOk: boolean = false;
    this.authenticationService.checkcaptcha(token).subscribe(res => {

      if (res.text() == 'true') {
        let returnUrl = this.route.snapshot.queryParams ['returnUrl'];
        this.loading = true;        
        this.authenticationService.login(this.model.username, this.model.password)
                .subscribe(
                       data => {
                           this.loading = false;
                           let currentUser = JSON.parse(localStorage.getItem('currentUser'));
                           if (currentUser && currentUser.AccessToken) {
                             this.router.navigateByUrl(returnUrl);
                           }
                           else
                           {
                             this.error = 'Невідома помилка при авторизації';
                           }
                       },
                       error => {
                           let res = error.json();
                                if(res.message){
                             this.error = res.message;
                           } else {
                             this.error = 'Помилка при авторизації';
                           }

                           this.loading = false;
                       });
      } else
      {
        this.error = 'Не вдалося перевірити ReCaptcha на сервері';
        this.loading = false;
      }

    },
    error => {
      this.error = 'Невідома помилка при перевірці ReCaptcha';
      this.loading = false;
    });

   }
  }

