import { NgModule }       from '@angular/core';
import { CommonModule }       from '@angular/common';

import { FormsModule }    from '@angular/forms';

import { SegmentsRoutingModule } from './segments-routing.module';
import { SegmentsListComponent } from './segments-list.component';
import { SegmentsEditComponent } from './segments-edit.component';

import { DxButtonModule, DxTextBoxModule, DxPopupModule, DxDataGridModule,DxTextAreaModule, DxSelectBoxModule, DxDateBoxModule, DxTreeViewModule, DxNumberBoxModule } from 'devextreme-angular';


@NgModule({
    imports: [
      SegmentsRoutingModule,
      CommonModule,
      FormsModule,
      DxButtonModule,
      DxTextBoxModule,
      DxNumberBoxModule,
      DxPopupModule,
      DxDataGridModule,
      DxTextAreaModule,
      DxSelectBoxModule,
      DxDateBoxModule,
      DxTreeViewModule,
    ],
    declarations: [
      SegmentsListComponent,
      SegmentsEditComponent   
    ]
  })
  export class SegmentsModule {

  }
