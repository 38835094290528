import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CountersListComponent } from './counters-list.component';
import { CountersEditComponent } from './counters-edit.component';
import { AuthGuard } from '../../_guards/auth.guard';

const routes: Routes = [
    { path: 'counters',  component: CountersListComponent, canActivate: [AuthGuard] },
    { path: 'counters/edit/:id', component: CountersEditComponent, canActivate: [AuthGuard] },
    { path: 'counters/create', component: CountersEditComponent, canActivate: [AuthGuard] }
  ];
  
  @NgModule({
    imports: [
      RouterModule.forChild(routes)
    ],
    exports: [
      RouterModule
    ]
  })
  export class CountersRoutingModule { }