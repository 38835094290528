import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Tab, WalletBonusBalances , WalletBonusOperations, ValueIdStr } from '../_models';
import { Globals } from '../globals';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Headers, RequestOptions } from '@angular/http';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

let transTabs: Tab[] = [
  {
    id: 0,
    text: "Транзакції",
    icon: "fa fa-list-ol"
  },
  {
    id: 1,
    text: "Баланс по рахунках",
    icon: "fa fa-bar-chart"
  }
];

let operationTypes: ValueIdStr[] = [
  {
    id: "PAY",
    value: "Сплата"
  },
  {
    id: "REPLENISH",
    value: "Поповнення"
  },
  {
    id: "BURN",
    value: "Автосписання"
  },
  {
    id: "TRANSFER",
    value: "Переказ"
  },
  {
    id: "CANCEL",
    value: "Повернення"
  }
];

@Injectable({ providedIn: 'root' })
export class TransactionsWalletBonusService {
  constructor(private http: HttpClient, private globals: Globals) { }

  getTransWalletBonusTabs(): Tab[]{
    return transTabs;
  }

  getOperationTypes(): ValueIdStr[] {
    return operationTypes;
  }

  getTransWalletBonus(clientId: number, dateFrom: string, dateTo: string, withBasket: boolean): Observable<WalletBonusOperations[]>{
    clientId = clientId == null ? 0 : clientId;
    return this.http
      .get<WalletBonusOperations[]>(this.globals.getApiString() + '/Wallet/DetailWalletInfo?dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&clientId=' + clientId + '&withBasket=' + withBasket,
        httpOptions);
  }

  getTransWalletBonusBalances(clientId: number): Observable<WalletBonusBalances[]>{
    clientId = clientId == null ? 0 : clientId;
    return this.http
      .get<WalletBonusBalances[]>(this.globals.getApiString() + '/Wallet/WalletBalances?nonZero=true&clientId=' + clientId,
        httpOptions);
  }

}
