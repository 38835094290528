import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './_components/home';
import { LoginComponent } from './_components/login';
import { AuthGuard } from './_guards';
import { PageNotFoundComponent } from './_components/not-found/not-found.component';

const appRoutes: Routes = [
    { path: '', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'login', component: LoginComponent },

    {path: '404', component: PageNotFoundComponent, canActivate: [AuthGuard] },
    { path: '**', redirectTo: '404', canActivate: [AuthGuard] }
];

export const routing = RouterModule.forRoot(appRoutes);