import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PromotionsWholesaleDocsListComponent } from './promotions-wholesale-docs-list.component';
import { AuthGuard } from '../../_guards/auth.guard';

const routes: Routes = [
    { path: 'wholesale-discounts',  component: PromotionsWholesaleDocsListComponent, canActivate: [AuthGuard] }
  ];
  
  @NgModule({
    imports: [
      RouterModule.forChild(routes)
    ],
    exports: [
      RouterModule
    ]
  })
  export class PromotionsWholesaleDocsRoutingModule { }