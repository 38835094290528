import { NgModule }       from '@angular/core';
import { CommonModule }       from '@angular/common';
import { FormsModule }    from '@angular/forms';
import { TransactionsWalletBonusComponent } from './transactions-wallet-bonus.component';
import {DxButtonModule, DxTextBoxModule, DxPopupModule, DxDataGridModule, DxTabsModule, DevExtremeModule} from 'devextreme-angular';
import {TransactionsWalletBonusRoutingModule} from './transactions-wallet-bonus.routing.module';

@NgModule({
  imports: [
    TransactionsWalletBonusRoutingModule,
    CommonModule,
    FormsModule,
    DxButtonModule,
    DxTextBoxModule,
    DxPopupModule,
    DxDataGridModule,
    DxTabsModule,
    DevExtremeModule
  ],
  declarations: [
    TransactionsWalletBonusComponent
  ]
})
export class TransactionsWalletBonusModule {

}
