import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PushesListComponent } from './pushes-list.component';
import { PushesEditComponent } from './pushes-edit.component';
import { AuthGuard } from '../../_guards/auth.guard';

const routes: Routes = [
    { path: 'mailings',  component: PushesListComponent, canActivate: [AuthGuard] },
    { path: 'mailings/edit/:id', component: PushesEditComponent, canActivate: [AuthGuard] },
    { path: 'mailings/create', component: PushesEditComponent, canActivate: [AuthGuard] }
  ];
  
  @NgModule({
    imports: [
      RouterModule.forChild(routes)
    ],
    exports: [
      RouterModule
    ]
  })
  export class PushesRoutingModule { }