import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CountersManualDocsListComponent } from './counters-manual-docs-list.component';
import { CountersManualDocsEditComponent } from './counters-manual-docs-edit.component';
import { AuthGuard } from '../../_guards/auth.guard';

const routes: Routes = [
    { path: 'counters-manual-docs',  component: CountersManualDocsListComponent, canActivate: [AuthGuard] },
    { path: 'counters-manual-docs/view/:id', component: CountersManualDocsEditComponent, canActivate: [AuthGuard] },
    { path: 'counters-manual-docs/create', component: CountersManualDocsEditComponent, canActivate: [AuthGuard] }
   
  ];
  
  @NgModule({
    imports: [
      RouterModule.forChild(routes)
    ],
    exports: [
      RouterModule
    ]
  })
  export class CountersManualDocsRoutingModule { }