import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TriggerFcmSchedule, TriggerMessageTemplate } from '../../_models';
import { PushesTriggersService, AuthenticationService, ConfigService } from '../../_services';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import notify from 'devextreme/ui/notify';
import { Router } from '@angular/router';
import { ValueId } from '../../_models/value-id';
import { Globals } from '../../globals';
import { DxTextAreaComponent, DxTextBoxComponent, DxDataGridComponent, DxNumberBoxComponent } from 'devextreme-angular';
import DataSource from "devextreme/data/data_source";
import { DatePipe } from '@angular/common';

@Component({
  templateUrl: 'triggers-pushes-templates-edit.component.html',
  styleUrls: ['./triggers-pushes-edit.css']
})

export class TriggersPushesTemplatesEditComponent implements OnInit, AfterViewInit {
  @ViewChild('name') public name: DxTextBoxComponent;
  @ViewChild('title') public title: DxTextBoxComponent;
  @ViewChild('header') public header: DxTextBoxComponent;
  @ViewChild('content') public content: DxTextAreaComponent;
  @ViewChild('link') public link: DxTextBoxComponent;
  @ViewChild('delaySend') public delaySend: DxNumberBoxComponent;
  
  _shouldClose: boolean = false;

  loading = false;
  saving = false;
  error: string = "";

  caption: string = "";
  private isNewItem: boolean = false;
  iconSave: string;
  isMessageContent: boolean = true;
  isStaticContent: boolean = true;

  dsPushesTriggerTypes: ValueId[];
  dsPushesContentTypes: ValueId[];
  
  private id;
  private copy_from;
  item: TriggerMessageTemplate;

  public constructor(
    private titleService: Title,
    private pushesTriggersService: PushesTriggersService,
    private authenticationService: AuthenticationService,
    private configService: ConfigService,
    private route: ActivatedRoute,
    private location: Location,
    private globals: Globals,
    private router: Router,
    private datepipe: DatePipe
  ) {

  }

  refreshButtonIcon(){
    if(this.saving){
      this.iconSave = this.globals.ICON_SPINNER;
    } else {
      this.iconSave = this.globals.ICON_FLOPPY;
    }
  }

  onOpened(e) {
    this._shouldClose = true;
  }

  ngAfterViewInit(): void {

  }

  ngOnInit(): void {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));

        var allowed = [1];

        if(!allowed.includes(currentUser.UserType)){
            this.router.navigateByUrl("404")
        }
    this.refreshButtonIcon();

    this.route.params.subscribe(params => {
      this.id = +params['id']; // (+) converts string 'id' to a number      
    });

    this.route.queryParams.subscribe(params => {
      this.copy_from = +params['copy_from'];
    });

    this.dsPushesTriggerTypes = this.pushesTriggersService.pushesTriggerTypes();
    this.dsPushesContentTypes = this.pushesTriggersService.pushesTriggerContentTypes();

    if (Number.isNaN(this.id)) {

      this.caption = 'Додавання шаблону';
      this.isNewItem = true;
      this.titleService.setTitle(this.caption);      
      this.item = new TriggerMessageTemplate();    
      this.item.id = 0;
      this.item.delayMinutesSend = 0;
      this.item.type = 0;
      this.item.triggerType = 0;
      this.item.isActive = false;

      if(!Number.isNaN(this.copy_from)) {
        this.loading = true;
        this.pushesTriggersService.getTemplatesById(this.copy_from)
        .subscribe(item => {
          this.loading = false;
          this.item.type = item.type;
          this.item.name = item.name;
          this.item.title = item.title;
          this.item.header = item.header;
          this.item.content = item.content;
          this.item.delayMinutesSend = item.delayMinutesSend;
          this.item.triggerType = item.triggerType;
          this.item.footer = item.footer;
          this.item.image = item.image;
          this.item.link = item.link;
          
          if(this.item.type === 0) {
            this.isMessageContent = true;
          }
          else {
            this.isMessageContent = false;
          }
          
          if(this.item.triggerType === 0) {
            this.isStaticContent = true;
          }
          else {
            this.isStaticContent = false;
          }
        },
          error => {
            this.error = error.error;
            this.loading = false;
          });
      }

    }
    else {
      this.caption = 'Редагування шаблону';
      this.titleService.setTitle(this.caption);

      this.pushesTriggersService.getTemplatesById(this.id)
        .subscribe(item => {
          this.loading = false;

          this.item = item; 
          
          if(this.item.type === 0) {
            this.isMessageContent = true;
          }
          else {
            this.isMessageContent = false;
          }
          
          if(this.item.triggerType === 0) {
            this.isStaticContent = true;
          }
          else {
            this.isStaticContent = false;
          }

        },
          error => {
            this.error = error.error;
            this.loading = false;
          });
    }
  }

  goBack() {
    this.location.back();
  }

  save() {

    if(!this.validationForm()) {
      return;
    }

    if(this.isNewItem) {
      this.newPush();
    }
    else {
      this.updatePush();
    }
  }

  validationForm() {

    if(!this.item.name) {
      notify("Заповніть назву шаблону", "error", 1000);
      this.name.instance.focus();
      return false;
    }

    if(!this.item.title) {
      notify("Заповніть заголовок", "error", 1000);
      this.title.instance.focus();
      return false;
    }

    if(!this.item.header) {
      notify("Заповніть вступ", "error", 1000);
      this.header.instance.focus();
      return false;
    }

    if(this.item.type === 0) {
      if(!this.item.content) {
        notify("Заповніть повний текст", "error", 1000);
        this.content.instance.focus();
        return false;
      }      
    }

    if(this.item.type === 1) {
      if(!this.item.link) {
        notify("Заповніть посилання", "error", 1000);
        this.link.instance.focus();
        return false;
      }      
    }

    if((!this.item.delayMinutesSend && this.item.delayMinutesSend !== 0) || this.item.delayMinutesSend < 0) {
      notify("Введіть коректне значення для часу затримки відправки", "error", 1000);
      this.delaySend.instance.focus();
      return false;
    }

    return true;
  }

  newPush() {
    this.saving = true;    
    this.refreshButtonIcon();    
    this.pushesTriggersService.newTemplate(this.item)
      .subscribe(res => {
//        console.log("update id: " + res.id);
        this.saving = false;
        this.refreshButtonIcon();
        notify("Дані збережено", "success", 600);
        this.goBack();
      },
        error => {
          this.saving = false;
          this.refreshButtonIcon();
          this.error = error.error;
        });
  }

  updatePush() {
    this.saving = true;
    this.refreshButtonIcon();
    this.pushesTriggersService.updateTemplate(this.item)
      .subscribe(res => {
//        console.log("update id: " + res.id);
        this.saving = false;
        this.refreshButtonIcon();
        notify("Дані збережено", "success", 600);
        this.goBack();
      },
        error => {          
          this.saving = false;
          this.refreshButtonIcon();
          this.error = error.error;

        });
  }

  onPushContentTypeChanged(e) {
    if (e.value == 0) // контент
    {
      this.isMessageContent = true;
    } 
    else  // ссылка
    {
      this.isMessageContent = false;
    }
  }

  onPushTriggerTypeChanged(e) {
    if (e.value == 0) // статичне повідомлення
    {
      this.isStaticContent = true;
    } 
    else  // бали та інше
    {
      this.isStaticContent = false;
    }

  }

}
