export class ClientWalletFuelsExchangeRequest {
    clientId: number;
    fromFuelId: number;
    fromAmount: number;
    toFuelId: number;
}

export class ClientFWExchange {
    fuelId: number;
    fuelName: string;
    fuelFullName: string;
    price: number;
    predictAmount: number;
}