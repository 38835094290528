export class TransWalletSpillsStations {
    id: number;
    date : Date;
    localDate : Date;
    clientId: number;
    stationExtId: number;
    stationName: string;
    stationAddress: string;
    transType: number;
    fuelExtId: number;
    fuelName: string;
    amount: number;
}