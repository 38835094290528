import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';


@Component({templateUrl: 'home.component.html'})
export class HomeComponent implements OnInit {

    visible = true;

    constructor(
        private router: Router
    ) {}

    ngOnInit() {
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        var allowed = [1];
        if(currentUser.UserType == 20){
            this.router.navigateByUrl("/clients/fill-form")
        } else if(currentUser.UserType == 10){
            this.router.navigateByUrl("/clients")
        } else if(currentUser.UserType == 40){
            this.router.navigateByUrl("/clients")
        } else if(currentUser.UserType == 400){
            this.router.navigateByUrl("/stations")
        } else if(!allowed.includes(currentUser.UserType)){
            this.router.navigateByUrl("404")
        }
    }
}