import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Stations } from '../../_models';
import { StationsService } from '../../_services';
import DataSource from "devextreme/data/data_source";
import ArrayStore from "devextreme/data/array_store";
import { DxDataGridComponent } from 'devextreme-angular';
import { Router } from '@angular/router';
import notify from 'devextreme/ui/notify';

@Component({
    templateUrl: 'stations-list.component.html'
  })
  export class StationsListComponent implements OnInit, AfterViewInit {
    @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;

    items: Stations[] = [];
    loading = false;
    error = '';

    store: ArrayStore;
    dataSource: DataSource;

    delItem: Stations;
    isDeletePopupVisible: boolean = false;

    public constructor(
        private titleService: Title,
        private stationsService: StationsService,
        private router: Router
    )
    {
        this.titleService.setTitle("АЗК");
    }

    ngAfterViewInit(): void {

    }
    ngOnInit(): void {
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        var allowed = [1, 400];

        if(!allowed.includes(currentUser.UserType)){
            this.router.navigateByUrl("404")
        }
        this.loading = true;
        this.stationsService.getAll()
        .subscribe(items => {
            this.items = items;
            this.initDataSource();
            this.loading = false;
        },
        error => {
            this.error = error;
            this.loading = false;
        });
    }

    initDataSource(){
        this.dataSource = new DataSource({
            store: new ArrayStore({
                key: "id",
                data: this.items,
                // Other ArrayStore options go here
            }),
            filter: [ "isDeleted", "=", "0" ]
        });
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(  {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'fa fa-filter',
                    onClick: this.refreshDataGrid.bind(this),
                    hint: 'Очистити фільтри'
                }
            });
    }

    refreshDataGrid() {
        this.dataGrid.instance.clearFilter();
      }

    deleteItem(data){
        this.delItem = data.data as Stations;
        this.isDeletePopupVisible = true;
    }

    deleteCancel(){
        this.delItem = null;
        this.isDeletePopupVisible = false;
    }

    deleteConfirm(){
        this.loading = true;
        this.stationsService.deleteItem(this.delItem.id)
        .subscribe(item => {
            let index = this.items.findIndex(x => x.id == this.delItem.id);
            this.items[index].isDeleted = 1;

            this.initDataSource();
            this.deleteCancel();
            notify("Запис успішно видалена", "success", 600);
            this.loading = false;
        },
        error => {
            this.error = error;
            this.loading = false;
        });
    }
  }
