import { NgModule }       from '@angular/core';
import { CommonModule }       from '@angular/common';
import { FormsModule }    from '@angular/forms';
import { TriggersPushesComponent } from './triggers-pushes.component';
import { TriggersPushesTemplatesEditComponent } from './triggers-pushes-templates-edit.component';
import {DxButtonModule, DxTextBoxModule, DxPopupModule, DxDataGridModule, DxTabsModule, DevExtremeModule} from 'devextreme-angular';
import {TriggersPushesRoutingModule} from './triggers-pushes.routing.module';
import { TriggersPushesTaskPlanEditComponent } from './triggers-pushes-taskplan-edit.component';

@NgModule({
  imports: [
    TriggersPushesRoutingModule,
    CommonModule,
    FormsModule,
    DxButtonModule,
    DxTextBoxModule,
    DxPopupModule,
    DxDataGridModule,
    DxTabsModule,
    DevExtremeModule
  ],
  declarations: [
    TriggersPushesComponent,
    TriggersPushesTemplatesEditComponent,
    TriggersPushesTaskPlanEditComponent
  ]
})
export class TriggersPushesModule {

}
