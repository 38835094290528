import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { GoodsGroups } from '../_models';
import { Globals } from '../globals';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Headers, RequestOptions } from '@angular/http';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

@Injectable({ providedIn: 'root' })
export class GoodsGroupsService {
    constructor(private http: HttpClient, private globals: Globals) { }

    getAll() {
        return this.http.get<GoodsGroups[]>(this.globals.getApiString() + `/GoodsGroups`, {});
    }

    getItem(id: number){
        return this.http.get<GoodsGroups>(this.globals.getApiString() + `/GoodsGroups/`+ id, {});
    }

    updateItem(item: GoodsGroups): Observable<GoodsGroups>{
        return this.http
            .post<GoodsGroups>(this.globals.getApiString() + '/GoodsGroups', 
                JSON.stringify(item), 
                httpOptions);
    }

    deleteItem(id: number): Observable<void>{
        return this.http
            .delete<void>(this.globals.getApiString() + '/GoodsGroups/' + id, 
                httpOptions); 
    }
}