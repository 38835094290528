import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GoodsListComponent } from './goods-list.component';
import { GoodsEditComponent } from './goods-edit.component';
import { AuthGuard } from '../../_guards/auth.guard';

const routes: Routes = [
    { path: 'goods',  component: GoodsListComponent, canActivate: [AuthGuard] },
    { path: 'goods/edit/:id', component: GoodsEditComponent, canActivate: [AuthGuard] },
    { path: 'goods/create', component: GoodsEditComponent, canActivate: [AuthGuard] }
  ];
  
  @NgModule({
    imports: [
      RouterModule.forChild(routes)
    ],
    exports: [
      RouterModule
    ]
  })
  export class GoodsRoutingModule { }