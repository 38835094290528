import {NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule, FormsModule }    from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { AppComponent }  from './app.component';
import { routing }        from './app.routing';

import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { HomeComponent } from './_components/home';
import { LoginComponent } from './_components/login';

import { Globals } from './globals';
import { AuthGuard } from './_guards';
import { HttpModule } from '@angular/http';
import { NavbarComponent } from './_components/navbar/navbar.component';
import { PageNotFoundComponent } from './_components/not-found/not-found.component';

import { GoodsGroupsModule } from './_components/goods-groups/goods-groups.module';

import { locale, loadMessages, formatMessage } from 'devextreme/localization';
import 'devextreme-intl';

//import  ruMessages from 'devextreme/localization/messages/ru.json';
import { GoodsModule } from './_components/goods/goods.module';
import { UsersModule } from './_components/users/users.module';
import { StationsModule } from './_components/stations/stations.module';
import { CountersModule } from './_components/counters/counters.module';
import { ClientsModule } from './_components/clients/clients.module';
import { TransactionsModule } from './_components/transactions/transactions.module';
import { TransactionsWalletModule } from './_components/transactions-wallet/transactions-wallet.module';
import { TransactionsDeductionZSUModule } from './_components/transactions-deduction-zsu/transactions-deduction-zsu.module';
import { TransactionsGiftsModule } from './_components/transactions-gifts/transactions-gifts.module';
import { TransactionsWalletSpillsModule } from './_components/transactions-wallet-spills/transactions-wallet-spills.module';
import { TelegramBotModule } from './_components/telegram-bot/telegram-bot.module';
import { SegmentsModule } from './_components/segments/segments.module';
import { VehicleBrandsModule } from './_components/vehicle-brands/vehicle-brands.module';
import { CountersManualDocsModule } from './_components/counters-manual-docs/counters-manual-docs.module';
import { PromotionsModule } from './_components/promotions/promotions.module';
import { CalendarModule } from './_components/calendar/calendar.module';
import { PushesModule } from './_components/pushes/pushes.module';
import { PromoDocsModule } from './_components/promo-docs/promo-docs.module';
import { PromotionsWholesaleDocsModule } from './_components/promotions-wholesale-docs/promotions-wholesale-docs.module';
import { DigestInfoModule } from './_components/digest/digest-info.module';
import { TransactionsWalletBonusModule } from './_components/transactions-wallet-bonus/transactions-wallet-bonus.module';
import { TriggersPushesModule } from './_components/triggers-pushes/triggers-pushes.module';
import { DatePipe } from '@angular/common';
import  uaMessages from './assets/dx/default.json';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { InsurancePolicyModule } from './_components/insurance-policy/insurance-policy.module';


loadMessages(uaMessages);
//locale(navigator.language);
locale("uk-UK");

@NgModule({
    imports: [
		RecaptchaV3Module,
		BrowserModule,
        ReactiveFormsModule,
        HttpModule,
        HttpClientModule,
        FormsModule,
        routing,
        GoodsGroupsModule,        
        GoodsModule,
        UsersModule,
        TransactionsModule,
        TransactionsWalletModule,
        TransactionsGiftsModule,
        TransactionsWalletSpillsModule,
        TransactionsDeductionZSUModule,
        TelegramBotModule,
        StationsModule,
        CountersModule,
        ClientsModule,
        SegmentsModule,
        VehicleBrandsModule,
        CountersManualDocsModule,
        PromotionsModule,
        CalendarModule,
        PushesModule,
        PromoDocsModule,
        PromotionsWholesaleDocsModule,
        DigestInfoModule,
        TransactionsWalletBonusModule,
        TriggersPushesModule,
        InsurancePolicyModule
    ]
    ,
    declarations: [
        AppComponent,
        HomeComponent,
        LoginComponent,
        NavbarComponent,
        PageNotFoundComponent
    ],
    providers: [
        AuthGuard,
        Globals,
        DatePipe,
		{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
		{ provide: RECAPTCHA_V3_SITE_KEY, useValue: '6LfjOAAVAAAAAKuOtgDOjeFFGH4CX0dw9e2vlp25' },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }
