import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Stations, Terminals } from '../_models';
import { Globals } from '../globals';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Headers, RequestOptions } from '@angular/http';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

@Injectable({ providedIn: 'root' })
export class StationsService {
    constructor(private http: HttpClient, private globals: Globals) { }

    getAll() {
        return this.http.get<Stations[]>(this.globals.getApiString() + '/Stations', {});
    }

  getActual() {
    return this.http.get<Stations[]>(this.globals.getApiString() + '/Stations/actual', {});
  }

    getItem(id: number){
        return this.http.get<Stations>(this.globals.getApiString() + '/Stations/' + id, {});
    }

    updateItem(item: Stations): Observable<Stations>{
        return this.http
            .post<Stations>(this.globals.getApiString() + '/Stations',
                JSON.stringify(item),
                httpOptions);
    }

    createTerminalUser(item: Terminals): Observable<Terminals>{
        return this.http
            .post<Terminals>(this.globals.getApiString() + '/Stations/create-terminal-user',
                JSON.stringify(item),
                httpOptions);

    }

    deleteItem(id: number): Observable<void>{
        return this.http
            .delete<void>(this.globals.getApiString() + '/Stations/' + id,
                httpOptions);
    }


}
