import { NgModule }       from '@angular/core';
import { CommonModule }       from '@angular/common';

import { FormsModule }    from '@angular/forms';

import { CountersRoutingModule } from './counters-routing.module';
import { CountersListComponent } from './counters-list.component';
import { CountersEditComponent } from './counters-edit.component';

import { DxSelectBoxModule, DxDataGridModule, DxButtonModule, DxTextBoxModule, DxPopupModule, DxTextAreaModule } from 'devextreme-angular';

@NgModule({
    imports: [
      CountersRoutingModule,
      CommonModule,
      FormsModule,
      DxSelectBoxModule,
      DxDataGridModule,
      DxButtonModule,
      DxTextBoxModule,
      DxPopupModule,
      DxTextAreaModule
    ],
    declarations: [
      CountersEditComponent,
      CountersListComponent   
    ]
  })
  export class CountersModule {

  }
