import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Promotions, Tab, ValueId, Id, ValueIdStr } from '../_models';
import { Globals } from '../globals';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Headers, RequestOptions } from '@angular/http';
import { PromoOffline } from '../_models/promo/promo-offline';
import { PromoDigest} from '../_models/promo/promo-digest';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

let schemaRangeCriteriaTypes: ValueId[] =
    [
        {
            id: 1,
            value: "Лічильник"
        },
        {
            id: 2,
            value: "Сума"
        },
        {
            id: 3,
            value: "Кількість"
        }
    ];

let schemaRangeCriteriaPeriods: ValueId[] =
    [
        {
            id: 1,
            value: "За минулий тиждень"
        },
        {
            id: 2,
            value: "За минулий місяць"
        },
        {
            id: 3,
            value: "Всього"
        },
        {
            id: 4,
            value: "Поточне значення"
        },
        {
            id: 5,
            value: "Попереднє значення"
        }
    ];

let schemaAwardTypes: ValueId[] =
    [
        {
            id: 0,
            value: "Без нагороди"
        },
        {
            id: 1,
            value: "Знижка"
        },
        {
            id: 2,
            value: "Бали"
        },
        // {
        //     id: 3,
        //     value: "Бонуси"
        // }         
    ];

let schemaAwardApplies: ValueId[] =
    [
        {
            id: 1,
            value: "Весь чек"
        },
        {
            id: 2,
            value: "Товари"
        },
        {
            id: 3,
            value: "Набір товарів"
        }
    ];

let offlineDiscountTypes: ValueId[] =
[
    {
        id: 1,
        value: "Постійна"
    },
    {
        id: 2,
        value: "Відсоткова"
    }
];

let schemaAwardCalculations: ValueId[] =
    [
        {
            id: 1,
            value: "Постійна"
        },
        {
            id: 2,
            value: "Відсоткова"
        },
        {
            id: 3,
            value: "Нова ціна"
        },
        {
            id: 4,
            value: "Оптові знижки"
        }
    ];

let schemaAwardProductConditions: ValueId[] =
    [
        {
            id: 1,
            value: "Різне кожному товару"
        },
        {
            id: 2,
            value: "Те ж до всіх товарів"
        }
    ];

let schemaAwardConditions: ValueId[] =
    [
        {
            id: 1,
            value: "Діапазони"
        },
        {
            id: 2,
            value: "Фіксоване"
        }
    ];


let promoTypes: ValueId[] =
    [
        {
            id: 1,
            value: "Акція покупки"
        }
        // ,
        // {
        //     id: 2,
        //     value: "Постійна акція"
        // },
        // {
        //     id: 3,
        //     value: "Розрахунок бонусними балами"
        // }
    ];

let promoStatuses: ValueId[] =
[
    {
        id: 10,
        value: "Чернетка"
    },
    {
        id: 20,
        value: "Тест"
    },
    {
        id: 30,
        value: "Продакшен"
    },
    {
        id: 40,
        value: "Не активна"
    },
    {
        id: 50,
        value: "Закінчена"
    }
];

let includeTypes: ValueId[] =
    [
        {
            id: 1,
            value: "Будь-який"
        },
        {
            id: 2,
            value: "Всі"
        }
    ];

let behaviorTypes: ValueId[] =
    [
        {
            id: 1,
            value: "Вжити продукти з кошика"
        },
        {
            id: 2,
            value: "Змінити ціни"
        },
        {
            id: 3,
            value: "Нічого не робити"
        }
    ];


let limitsTypes: ValueId[] =
    [
        {
            id: 1,
            value: "Максимальна загальна знижка"
        },
        {
            id: 2,
            value: "Максимальна негайна знижка"
        },
        {
            id: 3,
            value: "Кількість застосувань акції"
        },
        {
            id: 4,
            value: "Загальна знижка на окремі товари"
        },
        {
            id: 5,
            value: "Обсяг реалізованої продукції"
        }
    ];

let limitApplyTypes: ValueId[] =
    [
        {
            id: 1,
            value: "Для всієї акції"
        },
        {
            id: 2,
            value: "Для клієнта"
        }
    ];

let limitPeriods: ValueId[] =
    [
        {
            id: 1,
            value: "Місяць"
        },
        {
            id: 2,
            value: "Тиждень"
        },
        {
            id: 3,
            value: "День"
        },
        {
            id: 4,
            value: "За весь час"
        },
        {
            id: 5,
            value: "Рік"
        }
    ];

let otherConditionsTypes: ValueId[] =
    [
        {
            id: 1,
            value: "Сума кошика"
        },
        {
            id: 2,
            value: "Відвідування тієї ж торгової точки"
        },
        {
            id: 3,
            value: "Лічильники"
        },
        {
            id: 4,
            value: "День народження"
        },
        {
            id: 5,
            value: "Для нових карток"
        },
        {
            id: 6,
            value: "Метод оплати"
        },
        {
            id: 7,
            value: "Загальна кіл-ть товару у кошику"
        },
        {
            id: 8,
            value: "Кіл-ть товару у кошику серед необхідних"
        },
        {
            id: 9,
            value: "Внутр. № картки, що проскановано"
        }
    ];

let otherConditionsPeriods: ValueId[] =
    [
        {
            id: 1,
            value: "За минулий тиждень"
        },
        {
            id: 2,
            value: "За минулий місяць"
        }
    ];

    let otherConditionsPeriodsCounters: ValueId[] =
    [
        {
            id: 1,
            value: "За минулий тиждень"
        },
        {
            id: 2,
            value: "За минулий місяць"
        },
        {
            id: 3,
            value: "Всього"
        },
        {
            id: 4,
            value: "Поточне значення"
        },
        {
            id: 5,
            value: "Попереднє значення"
        }
    ];    

let otherConditionsOperators: ValueId[] =
    [
        {
            id: 1,
            value: "Менше"
        },
        {
            id: 2,
            value: "Більше"
        },
        {
            id: 3,
            value: "Дорівнює"
        }
    ];

 let otherConditionsStringOperators: ValueIdStr[] =
    [
        {
            id: "contains",
            value: "Містить"
        },
        {
            id: "notContains",
            value: "Не містить"
        },
        {
            id: "beginWith",
            value: "Починається з"
        },
        {
            id: "equal",
            value: "Дорівнює"
        },
        {
            id: "notEqual",
            value: "Не дорівнює"
        }
    ];    

let otherConditionsPaytypes: ValueId[] =
    [
        {
            id: 1,
            value: "Готівка"
        },
        {
            id: 2,
            value: "Банківська картка"
        },
        {
            id: 3,
            value: "БК з видач.готівки"
        },
        {
            id: 4,
            value: "Бонуси скарбничкі"
        }
    ];


let repeatTypes: ValueId[] =
    [
        {
            id: 1,
            value: "Щодня"
        },
        {
            id: 2,
            value: "Щотижня"
        },
        {
            id: 3,
            value: "Робочі дні"
        },
        {
            id: 4,
            value: "Вихідні та святкові дні"
        }
    ];

let segmentsTabs: Tab[] = [
        {
            id: 0,
            text: "Необхідні сегменти",
            icon: ""
        },
        {
            id: 1,
            text: "Виключені сегменти",
            icon: ""
        }
    ];

let conditionTabs: Tab[] = [
    {
        id: 0,
        text: "Необхідні товари",
        icon: ""
    },
    {
        id: 1,
        text: "Виключені товари",
        icon: ""
    },
    {
        id: 2,
        text: "Інші умови",
        icon: ""
    }
];

let coordinationTabs: Tab[] = [
    {
        id: 0,
        text: "Порядок акцій",
        icon: ""
    },
    {
        id: 1,
        text: "Блокування акцій",
        icon: ""
    },
    {
        id: 2,
        text: "Акція заблокована акцією",
        icon: ""
    },
    {
        id: 3,
        text: "Застосувати тільки разом з",
        icon: ""
    }
];

let otherAfterExecTypes: ValueId[] = [
    {
        id: 1,
        value: "Заблокувати"
    },
    {
        id: 2,
        value: "Callback-повідомлення"
    },
    {
        id: 3,
        value: "Розіграти призи"
    },
    {
        id: 4,
        value: "Лічильники. Нове значення"
    },
    {
        id: 5,
        value: "Друк месседжа у чек"
    },
    {
        id: 6,
        value: "Push-повідомлення"
    }
];

let otherAfterExecBlockTypes: ValueId[] = [
    {
        id: 1,
        value: "Клієнта"
    },
    {
        id: 2,
        value: "Картку"
    }
];

let otherAfterExecCallbackTypes: ValueId[] = [
    {
        id: 1,
        value: "FCSD"
    }
];

let otherAfterExecGiftsTypes: ValueId[] = [
    {
        id: 1,
        value: "Новий Рік 2022"
    }
];

let otherAfterExecCountersOperTypes: ValueId[] = [
    {
        id: 1,
        value: "Поточне значення"
    },
    {
        id: 2,
        value: "Попереднє значення"
    }
];

let tabs: Tab[] = [
    {
        id: 0,
        text: "Основні",
        icon: ""
    },
    {
        id: 1,
        text: "Сегменти",
        icon: ""
    },
    {
        id: 2,
        text: "Торгові точки",
        icon: ""
    },
    {
        id: 3,
        text: "Періоди",
        icon: ""
    },
    {
        id: 4,
        text: "Умови",
        icon: ""
    },
    {
        id: 5,
        text: "Схема",
        icon: ""
    },
    {
        id: 6,
        text: "Ліміти",
        icon: ""
    },
    {
        id: 7,
        text: "Координація",
        icon: ""
    },
    {
        id: 8,
        text: "Інше",
        icon: ""
    }
];

@Injectable({ providedIn: 'root' })
export class PromotionsService {
    constructor(private http: HttpClient, private globals: Globals) { }

    getAll() {
        return this.http.get<Promotions[]>(this.globals.getApiString() + `/Promotions`, {});
    }

    getItem(id: number) {
        return this.http.get<Promotions>(this.globals.getApiString() + `/Promotions/` + id, {});
    }

    updateItem(item: Promotions): Observable<Promotions> {
        return this.http
            .post<Promotions>(this.globals.getApiString() + '/Promotions',
                JSON.stringify(item),
                httpOptions);
    }

    updateOrder(ids: Id[]): Observable<void> {
        return this.http
            .post<void>(this.globals.getApiString() + '/Promotions/order',
                JSON.stringify(ids),
                httpOptions);
    }

    deleteItem(id: number): Observable<void> {
        return this.http
            .delete<void>(this.globals.getApiString() + '/Promotions/' + id,
                httpOptions);
    }

    getDigest(): Observable<PromoDigest[]> {
        return this.http.get<PromoDigest[]>(this.globals.getApiString() + `/Promotions/digest`, {});
    }

    getOfflineSettings(): Observable<PromoOffline> {
        return this.http.get<PromoOffline>(this.globals.getApiString() + `/Promotions/offline-settings`, {});
    }

    updateOfflineSettings(item: PromoOffline): Observable<PromoOffline> {
        return this.http
            .post<PromoOffline>(this.globals.getApiString() + '/Promotions/offline-settings',
                JSON.stringify(item),
                httpOptions);
    }

    getTabs(): Tab[] {
        return tabs;
    }

    getSegmentsTabs(): Tab[] {
        return segmentsTabs;
    }

    getConditionTabs(): Tab[] {
        return conditionTabs;
    }

    getPromoTypes(): ValueId[] {
        return promoTypes;
    }

    getPromoStatuses(): ValueId[] {
        return promoStatuses;
    }

    getTimePeriods(): ValueId[] {
        let res = [];
        let dat = new Date(2000, 1);
        let curDat = dat;
        for (let i = 0; i < 1440; i = i + 5) {
            let period = new ValueId();
            period.id = i;
            curDat.setTime(i * 60 * 1000);
            period.value = ("0" + curDat.getUTCHours()).slice(-2) + ":" + ("0" + curDat.getUTCMinutes()).slice(-2);
            res.push(period);
        }

        return res;
    }

    getRepeatTypes(): ValueId[] {
        return repeatTypes;
    }

    getIncludeTypes(): ValueId[] {
        return includeTypes;
    }

    getOtherConditionsTypes(): ValueId[] {
        return otherConditionsTypes;
    }

    getOtherConditionsPeriods(): ValueId[] {
        return otherConditionsPeriods;
    }

    getOtherConditionsPeriodsCounters(): ValueId[] {
        return otherConditionsPeriodsCounters;
    }

    getOtherConditionsOperators(): ValueId[] {
        return otherConditionsOperators;
    }

    getOtherConditionsStringOperators(): ValueIdStr[] {
        return otherConditionsStringOperators;
    }

    getOtherConditionsPaytypes(): ValueId[] {
        return otherConditionsPaytypes;
    }

    getLimitTypes(): ValueId[] {
        return limitsTypes;
    }

    getLimitApplyTypes(): ValueId[] {
        return limitApplyTypes;
    }

    getLimitPeriods(): ValueId[] {
        return limitPeriods;
    }

    getBehaviorTypes(): ValueId[] {
        return behaviorTypes;
    }

    getCoordinationTabs(): Tab[] {
        return coordinationTabs;
    }

    getSchemaAwardTypes() {
        return schemaAwardTypes;
    }

    getSchemaAwardApplies() {
        return schemaAwardApplies;
    }

    getSchemaAwardCalculations() {
        return schemaAwardCalculations;
    }

    getSchemaAwardProductConditions() {
        return schemaAwardProductConditions;
    }

    getSchemaAwardConditions() {
        return schemaAwardConditions;
    }

    getSchemaRangeCriteriaTypes() {
        return schemaRangeCriteriaTypes;
    }

    getSchemaRangeCriteriaPeriods() {
        return schemaRangeCriteriaPeriods;
    }

    getOfflineDiscountTypes(){
        return offlineDiscountTypes;
    }

    getOtherAfterExecTypes() {
        return otherAfterExecTypes;
    }

    getOtherAfterExecBlockTypes() {
        return otherAfterExecBlockTypes;
    }

    getOtherAfterExecCallbackTypes() {
        return otherAfterExecCallbackTypes;
    }

    getOtherAfterExecGiftsTypes() {
        return otherAfterExecGiftsTypes;
    }

    getOtherAfterExecCountersOperTypes() {
        return otherAfterExecCountersOperTypes;
    }
}
