export class VmClientTransGifts {
    loyaltyTransId: number;
    giftsTransId: number;
    dat: Date;
    clientId: number;
    stationId: number;
    stationName: string;
    stationAddress: string;
    stationCode: number;
    giftSchema: string;
    giftsCategory: string;
    giftId: number;
    giftName: string;
}