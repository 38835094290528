import { NgModule }       from '@angular/core';
import { CommonModule }       from '@angular/common';

import { FormsModule }    from '@angular/forms';

import { GoodsRoutingModule } from './goods-routing.module';

import { GoodsListComponent } from './goods-list.component';
import { GoodsEditComponent } from './goods-edit.component';
import { DxDataGridModule, DxSelectBoxModule, DxButtonModule, DxPopupModule, DxTreeViewModule, DxTextBoxModule, DxContextMenuModule, DxTemplateModule, DxNumberBoxModule } from 'devextreme-angular';

@NgModule({
    imports: [
      GoodsRoutingModule,
      CommonModule,
      FormsModule,
      DxDataGridModule,
      DxSelectBoxModule,
      DxButtonModule,
      DxNumberBoxModule,
      DxPopupModule,
      DxTreeViewModule,
      DxTextBoxModule
    ],
    declarations: [
        GoodsEditComponent,
        GoodsListComponent   
    ]
  })
  export class GoodsModule {

  }
