import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Users } from '../../_models';
import { UsersService } from '../../_services';
import DataSource from "devextreme/data/data_source";
import notify from 'devextreme/ui/notify';
import { Globals } from '../../globals';
import { Router } from '@angular/router';

@Component({
  templateUrl: 'users-create.component.html'
})
export class UsersCreateComponent implements OnInit, AfterViewInit {

  passwordCopy: string;
  loading = false;
  saving = false;
  error = '';
  caption: string = "";
  private id;
  item: Users;
  dsUserTypes: DataSource;

  isUserTypeSelected: boolean;
  isShowPassword: boolean;
  isPasswordError: boolean = false;

  iconSave: string;

  private commonPasswordPatterns = /passw.*|123.*|098.*|111.*|222.*|333.*|444.*|555.*|666.*|777.*|888.*|999.*|qwe.*|asd.*|zxc.*|qaz.*|rfv.*|wsx.*|edc.*/;

  passwordErrors: string[] = [];

  public constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private location: Location,
    private usersServices: UsersService,
    private globals: Globals,
    private router: Router
  ) {
    this.passwordErrors = [];
  }


  ngAfterViewInit(): void {

  }
  ngOnInit(): void {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    var allowed = [1];

    if(!allowed.includes(currentUser.UserType)){
        this.router.navigateByUrl("404")
    }
    this.item = new Users();
    this.refreshIcons();

    this.dsUserTypes = new DataSource({
      store: {
        type: "array",
        key: "id",
        data: this.usersServices.getUserTypes()
      }
    });
  }

  onUserTypeChanged(e) {
    if (e.value == 1 || e.value == 10 || e.value == 40 || e.value == 400) {
      this.isUserTypeSelected = true;
      this.isShowPassword = true;
    } else if (e.value == 200 || e.value == 210 || e.value == 300 || e.value == 500 || e.value == 1000) {
      this.isUserTypeSelected = true;
      this.isShowPassword = false;
    }

  }

  goBack() {
    this.location.back();
  }

  refreshIcons() {
    if (this.saving) {
      this.iconSave = this.globals.ICON_SPINNER;
    } else {
      this.iconSave = this.globals.ICON_FLOPPY;
    }
  }

  update() {
    // if(!this.checkInput()){
    //   return;
    // }
    this.passwordErrors = [];
    this.isPasswordError = false;

    this.saving = true;
    this.refreshIcons();
    this.usersServices.checkPassword(this.item.password)
      .subscribe(res => {
        this.passwordErrors = res;
        if (this.passwordErrors.length > 0) {
          this.isPasswordError = true;
          this.saving = false;
          this.refreshIcons();
        } else {
          this.usersServices.createItem(this.item)
            .subscribe(res => {
              this.saving = false;
              this.refreshIcons();
              notify("Дані збережено", "success", 600);
              this.goBack();
            },
              error => {
                this.saving = false;
                this.refreshIcons();
                this.error = error;
                //console.log(this.error);
              });
        }
      },
        error => {
          this.saving = false;
          this.refreshIcons();
          this.error = error;
          //console.log(this.error);
        });



  }



}
