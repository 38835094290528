import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Counters, VehicleBrands } from '../../_models';
import { VehicleBrandsService } from '../../_services';
import { Router } from '@angular/router';

const ICON_SPINNER: string = "fa fa-lg fa-spinner fa-spin";
const ICON_FLOPPY: string = "fa fa-lg fa-floppy-o";
import notify from 'devextreme/ui/notify';

@Component({
    templateUrl: 'vehicle-brands-edit.component.html'
  })
  export class VehicleBrandsEditComponent implements OnInit, AfterViewInit {

    loading = false;
    saving = false;
    error = '';
    caption : string = "";
    private id;
    item: VehicleBrands;
    iconSave: string = ICON_FLOPPY;
    isNewItem: boolean = false;


      public constructor(
      private titleService: Title,
      private route: ActivatedRoute,
      private location: Location,
      private vehicleBrandsService: VehicleBrandsService,
      private router: Router
    )
    {

    }

    ngAfterViewInit(): void {

    }
    ngOnInit(): void {
      let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    var allowed = [1];

    if(!allowed.includes(currentUser.UserType)){
        this.router.navigateByUrl("404")
    }
      this.route.params.subscribe(params => {
        this.id = +params['id']; // (+) converts string 'id' to a number
      });

      if(Number.isNaN(this.id))
      {
        this.caption = "Додавання марки трансп. засобу";
        this.titleService.setTitle(this.caption);
        this.item = new Counters();
        this.item.id = 0;

        this.isNewItem = true;

      } else {

        this.loading = true;
        this.caption = "Редагування марки трансп. засобу";
        this.titleService.setTitle(this.caption);

        this.vehicleBrandsService.getItem(this.id)
          .subscribe(item => {
            this.item = item;
            this.loading = false;
        },
        error => {
            this.error = error;
            this.loading = false;
        });

      }

    }


    goBack(){
      this.location.back();
    }


    update(){
      this.iconSave = ICON_SPINNER;
      this.vehicleBrandsService.updateItem(this.item)
        .subscribe(res => {
          this.saving = false;
          notify("Дані збережено", "success", 600);
          this.goBack();
        },
        error => {
          this.error = error;
          this.iconSave = ICON_FLOPPY;
        });
    }



  }
