import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Clients } from '../../_models';
import { Location } from '@angular/common';
import { ClientsService } from '../../_services';

const ICON_SPINNER: string = "fa fa-lg fa-spinner fa-spin";
const ICON_FIND: string = "fa fa-lg fa-search";

import notify from 'devextreme/ui/notify';
import { Router } from '@angular/router';

@Component({
  templateUrl: 'clients-find.component.html'
})
export class ClientsFindComponent implements OnInit, AfterViewInit {

  onlyNumbersPattern = "^[0-9]+$";
  loading = false;
  saving = false;
  error: any;
  caption: string = "";
  item: Clients;
  iconFind: string = ICON_FIND;
  rules: any;
  rules2: any;

  idClient: number;
  internalCardNumber: string;
  visibleCardNumber: string;
  phoneNumber: string;
  phoneNumberConfirm: string;

  allowedFullFields = false;

  public constructor(
    private location: Location,
    private clientsService: ClientsService,
    private router: Router
  ) {
    this.rules = { "X": /[0]/ };
  }

  ngAfterViewInit(): void {

  }

  ngOnInit(): void {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));

    var allowed = [1, 10, 40];

    if(!allowed.includes(currentUser.UserType)){
        this.router.navigateByUrl("404")
    }

    if(currentUser.UserType == 1) {
      this.allowedFullFields = true;
    }

    this.caption = "Пошук клієнта";
  }

  tbInternalNumFocusIn() {
    if(!this.internalCardNumber) {
      this.internalCardNumber = "R_";
    }
  }

  findClient() {
    if(this.idClient) {
      this.findByIdClient();
    }
    else if(this.visibleCardNumber) {
      this.findByClientCard(0);
    }
    else if(this.internalCardNumber) {
      this.findByClientCard(1);
    }
    else if(this.phoneNumber) {
      this.findByClientPhoneNumber();
    }
    else if(this.phoneNumberConfirm) {
      this.findByClientPhoneNumberConfirm();
    }
    else {
      notify('Вкажіть ID, або номер картки, або номер телефону', 'error');
    }    
  }

  findByClientCard(type) {
    this.loading = true;
    if(type === 0) {
      this.clientsService.getByFindVisibleNumber(this.visibleCardNumber)
      .subscribe(data => {
        this.error = null;
        this.item = data;
        this.loading = false;
        this.router.navigateByUrl("/clients/edit/" + this.item.id);
      },
        error => {
          this.error = error;
          if(error.status === 404) {
            this.error.error = 'Клієнта не знайдено';  
          }
          this.loading = false;          
        });
    }
    else if(type === 1) {
      this.clientsService.getByFindInternalNumber(this.internalCardNumber)
      .subscribe(data => {
        this.error = null;
        this.item = data;
        this.loading = false;
        this.router.navigateByUrl("/clients/edit/" + this.item.id);
      },
        error => {
          this.error = error;
          if(error.status === 404) {
            this.error.error = 'Клієнта не знайдено';  
          }
          this.loading = false;          
        });
    }
    else {
      this.loading = false;
      this.error.error = 'Клієнта не знайдено';
    }
  }

  findByIdClient() {
    this.loading = true;
    this.clientsService.getByFindIdClient(this.idClient)
      .subscribe(data => {
        this.error = null;
        this.item = data;
        this.loading = false;
        this.router.navigateByUrl("/clients/edit/" + this.item.id);
      },
        error => {
          this.error = error;
          if(error.status === 404) {
            this.error.error = 'Клієнта не знайдено';  
          }      
          this.loading = false;          
        });
  }

  findByClientPhoneNumber() {
    this.loading = true;
    //this.clientsService.getByFindPhoneNumberConfirmed(this.phoneNumber)
    this.clientsService.getByFindPhoneNumber(this.phoneNumber)
      .subscribe(data => {
        this.error = null;
        this.item = data;
        this.loading = false;
        this.router.navigateByUrl("/clients/edit/" + this.item.id);
      },
        error => {
          this.error = error;
          if(error.status === 404) {
            this.error.error = 'Клієнта не знайдено';  
          }      
          this.loading = false;          
        });
  }

  findByClientPhoneNumberConfirm() {
    this.loading = true;
    //this.clientsService.getByFindPhoneNumberConfirmed(this.phoneNumber)
    this.clientsService.getByFindPhoneNumberConfirmed(this.phoneNumberConfirm)
      .subscribe(data => {
        this.error = null;
        this.item = data;
        this.loading = false;
        this.router.navigateByUrl("/clients/edit/" + this.item.id);
      },
        error => {
          this.error = error;
          if(error.status === 404) {
            this.error.error = 'Клієнта не знайдено';  
          }      
          this.loading = false;          
        });
  }

  goBack() {
    this.location.back();
  }

}
