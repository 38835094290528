export class ClientWalletFuelsBlockRequest {
    clientId: number;
    fuelId: number;
    amount: number;
    reason: string;
}

export class ClientFWBlock {
    fuelId: number;
    fuelName: string;
    maxAmountBlock: number;
}