import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Clients, ValueId, AddressFindStreetRequest } from '../../_models';
import { ClientsService, AddressService, VehicleBrandsService } from '../../_services';

const ICON_SPINNER: string = "fa fa-lg fa-spinner fa-spin";
const ICON_FLOPPY: string = "fa fa-lg fa-floppy-o";
const ICON_FIND: string = "fa fa-lg fa-search";

import notify from 'devextreme/ui/notify';
import { Observable } from 'rxjs';
import DataSource from "devextreme/data/data_source";
import ArrayStore from "devextreme/data/array_store";
import { DxValidatorComponent } from 'devextreme-angular';
import { Router } from '@angular/router';

@Component({
  templateUrl: 'clients-fill-form.component.html',
  styleUrls: ['./clients-fill-form.component.css']
})
export class ClientsFillFormComponent implements OnInit, AfterViewInit {

  onlyNumbersPattern = "^[0-9]+$";
  loading = false;
  saving = false;
  error = '';
  caption: string = "";
  private id;
  item: Clients;
  iconSave: string = ICON_FLOPPY;
  iconFind: string = ICON_FIND;

  isNewItem: boolean = false;
  streetsDataSource: DataSource;

  visibleCardNumber: string;
  isFindByCard: boolean = false;

  streets: string[];


  areas: any;
  cities: any;
  vehicleBrands: any;

  loadingCities: boolean = false;

  vehicleYears: ValueId[];
  genderList: ValueId[];
  maritalStatusList: ValueId[];
  vehicleTypeList: ValueId[];

  public constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private location: Location,
    private clientsService: ClientsService,
    private addressService: AddressService,
    private vehicleBrandsService: VehicleBrandsService,
    private router: Router
  ) {

  }

  ngAfterViewInit(): void {

  }
  ngOnInit(): void {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));

    // запрещаем в связи с изменениями в авторизации (по телефону)
    // временно оставляем
    var allowed = [1, 20];
    //var allowed = [];

    if(!allowed.includes(currentUser.UserType)){
        this.router.navigateByUrl("404")
    }
    this.initVehicleYears();
    this.addressService.getAreas().toPromise().then((r) => this.areas = r);
    this.vehicleBrandsService.getAll().toPromise().then((r) => this.vehicleBrands = r);

    this.genderList = this.clientsService.getGenders();
    this.maritalStatusList = this.clientsService.getMaritalStatuses();
    this.vehicleTypeList = this.clientsService.getVehicleTypes();

    this.caption = "Заповнення анкети";

    this.initFindByCard(true);
  }

  findByClientCard() {
    this.loading = true;
    this.clientsService.getEmptyClientVisibleNumber(this.visibleCardNumber)
      .subscribe(data => {
        this.error = null;
        this.initFindByCard(false);
        this.item = data;
        this.loading = false;
      },
        error => {
          this.error = error;
          this.loading = false;
        });
  }

  initFindByCard(val) {
    if (val) {
      this.isFindByCard = true;
      this.item = null;
    } else {
      this.isFindByCard = false;
      this.item = new Clients();
      this.visibleCardNumber = null;
    }
  }

  initVehicleYears() {
    let year = (new Date()).getFullYear();

    this.vehicleYears = [];
    for (var i = 1900; i <= year; i++) {
      let val = new ValueId();
      val.id = i;
      val.value = i.toString();
      this.vehicleYears.push(val);
    }
  }

  goBack() {
    this.location.back();
  }


  update() {
    this.iconSave = ICON_SPINNER;
    this.clientsService.updateItemFillForm(this.item)
      .subscribe(res => {
        this.saving = false;
        notify("Дані збережено", "success", 600);
        this.initFindByCard(true);
      },
        error => {
          this.error = error;
          this.iconSave = ICON_FLOPPY;
        });
  }

  loadCitiesByArea(areaId: number) {
    if (areaId) {
      this.loadingCities = true;
      this.addressService.getCities(areaId)
        .subscribe(item => {
          this.cities = item;
          this.loadingCities = false;
        },
          error => {
            this.error = error;
            this.loadingCities = false;
          });
    }
  }

  onAreaChanged(e) {
    this.loadCitiesByArea(e.value);
  }

  onStreetValueChanged(e) {
    if (!e || !e.value) {
      this.streets = [];
      return;
    }
    if (e.value.length == 1 && !e.previousValue) {
      if (this.item.cityId > 0) {
        let streetRequest = new AddressFindStreetRequest();
        streetRequest.cityId = this.item.cityId;
        streetRequest.locale = "ua";
        streetRequest.query = e.value;
        streetRequest.maxResultCnt = 1000;

        this.addressService.findStreet(streetRequest)
          .subscribe(item => {
            this.streets = item;
            e.component.dataSource = this.streets;
          },
            error => {
              this.error = error;

            });
      }
      else {
        return;
      }

    }

  }

  onFormSubmit(e, valGroup) {
    e.preventDefault();
    if (valGroup.instance.validate().isValid){
      this.update();
    }

  }

  handleEnterKeyPress(e) {
    //console.log("key pressed " + e);
    //if (e.keyCode == 13) {
    //  alert('you just clicked enter');
      // rest of your code
    //}

  }

  cancelFillForm(){
    this.initFindByCard(true);
  }



}
