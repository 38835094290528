import { PromoExceptGroups } from "./promo-except-groups";

export class PromoOffline {
    goodsDiscounts: PromoOfflineGoodsDiscount[];
    exceptGroups: PromoExceptGroups[];
    excludedPrefixCards: string[];
    defaultDiscountTypeId: number;
    defaultDiscount: number;

    constructor(){
        this.goodsDiscounts = [];
        this.exceptGroups = [];
        this.excludedPrefixCards = [];
    }
 }


 export class PromoOfflineGoodsDiscount {
    id: number;
    name: string;
    extId: string;
    discountTypeId: number; 
    discount: number;
 }