import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';

import { CalendarRoutingModule } from './calendar-routing.module';
import { CalendarComponent } from './calendar.component';

import { DxSelectBoxModule, DxButtonModule, DxCalendarModule, DxTemplateModule } from 'devextreme-angular';
import { Router, RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CalendarRoutingModule,
    CommonModule,
    FormsModule,
    DxSelectBoxModule,
    DxButtonModule,
    DxCalendarModule,
    DxTemplateModule,
    RouterModule
  ],
  declarations: [
    CalendarComponent
  ]
})
export class CalendarModule {
  
}
