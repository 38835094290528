import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UsersListComponent } from './users-list.component';
import { UsersEditComponent } from './users-edit.component';
import { UsersCreateComponent } from './users-create.component';
import { AuthGuard } from '../../_guards/auth.guard';

const routes: Routes = [
    { path: 'users',  component: UsersListComponent, canActivate: [AuthGuard] },
    { path: 'users/edit/:id', component: UsersEditComponent, canActivate: [AuthGuard] },
    { path: 'users/create', component: UsersCreateComponent, canActivate: [AuthGuard] }
  ];
  
  @NgModule({
    imports: [
      RouterModule.forChild(routes)
    ],
    exports: [
      RouterModule
    ]
  })
  export class UsersRoutingModule { }