import { NgModule }       from '@angular/core';
import { CommonModule }       from '@angular/common';
import { FormsModule }    from '@angular/forms';
import { TransactionsWalletSpillsComponent } from './transactions-wallet-spills.component';
import {DxButtonModule, DxTextBoxModule, DxPopupModule, DxDataGridModule, DxTabsModule, DevExtremeModule} from 'devextreme-angular';
import {TransactionsWalletSpillsRoutingModule} from './transactions-wallet-spills-routing.module';

@NgModule({
  imports: [
    TransactionsWalletSpillsRoutingModule,
    CommonModule,
    FormsModule,
    DxButtonModule,
    DxTextBoxModule,
    DxPopupModule,
    DxDataGridModule,
    DxTabsModule,
    DevExtremeModule
  ],
  declarations: [
    TransactionsWalletSpillsComponent
  ]
})
export class TransactionsWalletSpillsModule {

}
