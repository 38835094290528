import { NgModule }       from '@angular/core';
import { CommonModule }       from '@angular/common';
import { FormsModule }    from '@angular/forms';
import { TransactionsListComponent } from './transactions-list.component';
import {DxButtonModule, DxTextBoxModule, DxPopupModule, DxDataGridModule, DxTabsModule, DevExtremeModule} from 'devextreme-angular';
import {TransactionsRoutingModule} from './transactions-routing.module';

@NgModule({
  imports: [
    TransactionsRoutingModule,
    CommonModule,
    FormsModule,
    DxButtonModule,
    DxTextBoxModule,
    DxPopupModule,
    DxDataGridModule,
    DxTabsModule,
    DevExtremeModule
  ],
  declarations: [
    TransactionsListComponent
  ]
})
export class TransactionsModule {

}
