import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import {
  Clients,
  ValueId,
  CardsBatch,
  Duplicates,
  Tab,
  ClientCounters,
  VmClientTransLine,
  VmClientTransCounter,
  VmClientTransPromoLine,
  VmClientTransGifts,
  CardOfClient,
  ClientPredict,
  ClientLimitsFS,
  PhoneConfirmSMS,
  ClientTransFW,
  ClientPaymentTransFW,
  ClientRestFW,
  FuelPricesFW,
  ClientWalletFuelsExchangeRequest,
  ClientWalletFuelsRestoreRequest,
  ClientWalletFuelsBlockRequest,
  PhoneConfirmCall,
  ClientsSettingsList,
  ClientsInsurancePolicy
} from '../_models';
import { Globals } from '../globals';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Headers, RequestOptions } from '@angular/http';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })    
  };

  const httpOptionsText = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    responseType: 'text' as 'json'    
  }; 

let genders: ValueId[] =
[
    {
        id: 1,
        value: "Чоловіча"
    },
    {
        id: 2,
        value: "Жіноча"
    }
];

let cardActive: ValueId[] =
[
    {
        id: 1,
        value: "Так"
    },
    {
        id: 0,
        value: "Ні"
    }
];

let maritalStatuses: ValueId[] =
[
    {
        id: 1,
        value: "одружений / одружена"
    },
    {
        id: 2,
        value: "неодружений / незаміжня"
    }
];

let vehicleTypes: ValueId[] =
[
    {
        id: 1,
        value: "Легковий автомобіль"
    },
    {
        id: 2,
        value: "Позашляховик"
    },
    {
        id: 3,
        value: "Вантажний автомобіль"
    },
    {
        id: 4,
        value: "Автобус"
    },
    {
        id: 5,
        value: "Мікроавтобус"
    },
    {
        id: 6,
        value: "Мотоцикл"
    }
];

let transTabs: Tab[] = [
    {
        id: 0,
        text: "По товарам",
        icon: "fa"
    },
    {
        id: 1,
        text: "По акціям",
        icon: "fa"
    },
    {
        id: 2,
        text: "Зміни лічильників",
        icon: "fa"
    },
    {
        id: 3,
        text: "Подарунки",
        icon: "fa"
    }
];

let fwTabs: Tab[] = [
    {
        id: 0,
        text: "Платіжні транзакції",
        icon: "fa"
    },
    {
        id: 1,
        text: "Транзакції пального",
        icon: "fa"
    },
    {
        id: 2,
        text: "Залишки пального",
        icon: "fa"
    }
];

let tabs: Tab[] = [
    {
        id: 0,
        text: "Анкета",
        icon: "fa fa-address-card-o"
    },
    {
        id: 1,
        text: "Картки",
        icon: "fa fa-credit-card"
    },
    {
        id: 2,
        text: "Сегменти",
        icon: "fa fa-database"
    },
    {
        id: 3,
        text: "Лічильники",
        icon: "fa fa-calculator"
    },
    {
        id: 4,
        text: "Транзакції ПЛ",
        icon: "fa fa-list"
    },
    {
        id: 5,
        text: "Прогноз",
        icon: "fa fa-bar-chart"
    },
    {
        id: 6,
        text: "Пальне",
        icon: "fa fa-tint"
    },
    {
        id: 7,
        text: "Скарбничка",
        icon: "fa fa-money"
    }
];

let operationFWTypes: ValueId[] =
  [
      {
          id: 1,
          value: "Збільшення залишку"
      },
      {
          id: 2,
          value: "Зменшення залишку"
      },
      {
        id: 0,
        value: "Видано в роздріб"
      },
      {
        id: -1,
        value: "Повернено з роздрібу"
      },
      {
        id: 5,
        value: "Розблоковано (повернено) АЗС"
      },
      {
        id: 6,
        value: "Заблоковано АЗС"
      },
      {
        id: 7,
        value: "Автоматичне списання"
      },
      {
        id: -2,
        value: "Обмін (заблоковано)"
      },
      {
        id: 20,
        value: "Обмін (видано в роздріб)"
      },
      {
        id: 30,
        value: "Відновлення"
      },
      {
        id: -3,
        value: "Блокування"
      }
  ];

  let operationFWPaymentsTypes: ValueId[] =
  [
      {
          id: 0,
          value: "Покупка"
      },
      {
          id: -1,
          value: "Повернення"
      }
  ];

  let predictTabs: Tab[] = [
    {
        id: 0,
        text: "Знижки",
        icon: "fa"
    },
    {
        id: 1,
        text: "Ліміти",
        icon: "fa"
    }
];

let profileTabs: Tab[] = [
    {
        id: 0,
        text: "Особисті дані",
        icon: "fa"
    },
    {
        id: 1,
        text: "Налаштування",
        icon: "fa"
    },
    {
        id: 2,
        text: "Страхування",
        icon: "fa"
    }
];

let walletBonusTabs: Tab[] = [
    {
        id: 0,
        text: "Транзакції",
        icon: "fa"
    },
    {
        id: 1,
        text: "Баланс",
        icon: "fa"
    }
];

@Injectable({ providedIn: 'root' })
export class ClientsService {
    constructor(private http: HttpClient, private globals: Globals) { }

    getAll() {
        return this.http.get<Clients[]>(this.globals.getApiString() + `/Clients`, {});
    }

    getByVisibleNumbers(visibleNumber: string[]): Observable<Clients[]>{
        return this.http
            .post<Clients[]>(this.globals.getApiString() + '/Clients/get-visible-numbers',
                JSON.stringify(visibleNumber),
                httpOptions);
    }

     getByVisibleNumber(visibleNumber: string): Observable<CardOfClient>{
         return this.http.get<CardOfClient>(this.globals.getApiString() + `/Clients/find-visible-number/`+ visibleNumber, {});
    }

    getByFindVisibleNumber(visibleNumber: string): Observable<Clients>{
        return this.http.get<Clients>(this.globals.getApiString() + `/ExtClients/FindByCardVisibleNumber/`+ visibleNumber, {});
   }

   getByFindIdClient(idClient: number): Observable<Clients>{
    return this.http.get<Clients>(this.globals.getApiString() + `/ExtClients/FindByClientId/`+ idClient, {});
}

   getByFindInternalNumber(internalNumber: string): Observable<Clients>{
    return this.http.get<Clients>(this.globals.getApiString() + `/ExtClients/FindByCardInternalNumber/`+ internalNumber, {});
}

   getByFindPhoneNumber(phoneNumber: string): Observable<Clients>{
    return this.http.get<Clients>(this.globals.getApiString() + `/ExtClients/FindByPhoneNumber/`+ phoneNumber, {});
}

getByFindPhoneNumberConfirmed(phoneNumber: string): Observable<Clients>{
    return this.http.get<Clients>(this.globals.getApiString() + `/ExtClients/FindByConfirmedPhoneNumber/`+ phoneNumber, {});
}

    getByInternalNumber(internalNumber: string): Observable<CardOfClient>{
        return this.http.get<CardOfClient>(this.globals.getApiString() + `/Clients/card/`+ internalNumber, {});
   }

    getItem(id: number){
        return this.http.get<Clients>(this.globals.getApiString() + `/Clients/`+ id, {});
    }

    updateItem(item: Clients): Observable<Clients>{
        return this.http
            .post<Clients>(this.globals.getApiString() + '/Clients',
                JSON.stringify(item),
                httpOptions);
    }

    updateItemFillForm(item: Clients): Observable<Clients>{
        return this.http
        .post<Clients>(this.globals.getApiString() + '/Clients/fill-form',
            JSON.stringify(item),
            httpOptions);
    }

    requestConfirmPhoneSMS(item: PhoneConfirmSMS) {
        return this.http.post<String>(this.globals.getApiString() + '/Clients/RequestConfirmPhone', JSON.stringify(item), httpOptionsText);
    }

    forceRequestConfirmPhoneSMS(item: PhoneConfirmSMS) {
        return this.http.post<String>(this.globals.getApiString() + '/Clients/ForceRequestConfirmPhone', JSON.stringify(item), httpOptionsText);
    }

    phoneConfirmSMS(item: PhoneConfirmSMS): Observable<Clients> {
        return this.http.post<Clients>(this.globals.getApiString() + '/Clients/PhoneConfirm', JSON.stringify(item), httpOptions);
    }

    requestConfirmPhoneCall(item: PhoneConfirmCall) {
        return this.http.post<String>(this.globals.getApiString() + '/Clients/call_auth_request', JSON.stringify(item), httpOptionsText);
    }

    forceRequestConfirmPhoneCall(item: PhoneConfirmCall) {
        return this.http.post<String>(this.globals.getApiString() + '/Clients/force_call_auth_request', JSON.stringify(item), httpOptionsText);
    }

    deleteItem(id: number): Observable<void>{
        return this.http
            .delete<void>(this.globals.getApiString() + '/Clients/' + id,
                httpOptions);
    }

    getMaritalStatuses(): ValueId[]{
        return maritalStatuses;
    }

    getGenders(): ValueId[]{
        return genders;
    }

    getVehicleTypes(): ValueId[]{
        return vehicleTypes;
    }

    getFWDocTypes(): ValueId[] {
        return operationFWTypes;
    }

    getFWDocPaymentsTypes(): ValueId[] {
        return operationFWPaymentsTypes;
    }

    batchCreate(items: CardsBatch[]): Observable<Duplicates>{
        return this.http
            .put<Duplicates>(this.globals.getApiString() + '/Clients/batch-create',
                JSON.stringify(items),
                httpOptions);
    }

    getEmptyIdVisibleNumber(visibleNumber: string): Observable<number>{
        return this.http
            .get<number>(this.globals.getApiString() + '/Clients/get-empty-id/' + visibleNumber,
                httpOptions);
    }

    getEmptyClientVisibleNumber(visibleNumber: string): Observable<Clients>{
        return this.http
            .get<Clients>(this.globals.getApiString() + '/Clients/get-empty-client/' + visibleNumber,
                httpOptions);
    }

    getTabs(): Tab[]{
        return tabs;
    }

    getTransTabs(): Tab[]{
        return transTabs;
    }

    getFWTabs(): Tab[] {
        return fwTabs;
    }

    getCardActive(): ValueId[]{
        return cardActive;
    }

    getPredictTabs(): Tab[] {
        return predictTabs;
    }

    getProfileTabs(): Tab[] {
        return profileTabs;
    }

    getWalletBonusTabs(): Tab[] {
        return walletBonusTabs;
    }

    getCounters(clientId: number): Observable<ClientCounters[]>{
        return this.http
            .get<ClientCounters[]>(this.globals.getApiString() + '/Clients/counters/' + clientId,
            httpOptions);
    }

    getClientTransLines(clientId: number, dateFrom: string, dateTo: string): Observable<VmClientTransLine[]>{
        return this.http
            .get<VmClientTransLine[]>(this.globals.getApiString() + '/Clients/trans-lines?clientId=' + clientId + "&dateFrom=" + dateFrom + "&dateTo=" + dateTo,
            httpOptions);
    }

    getClientTransCounters(clientId: number, dateFrom: string, dateTo: string): Observable<VmClientTransCounter[]>{
        return this.http
            .get<VmClientTransCounter[]>(this.globals.getApiString() + '/Clients/trans-counters?clientId=' + clientId + "&dateFrom=" + dateFrom + "&dateTo=" + dateTo,
            httpOptions);
    }

    getClientTransPromoLines(clientId: number, dateFrom: string, dateTo: string): Observable<VmClientTransPromoLine[]>{
        return this.http
            .get<VmClientTransPromoLine[]>(this.globals.getApiString() + '/Clients/trans-promo-lines?clientId=' + clientId + "&dateFrom=" + dateFrom + "&dateTo=" + dateTo,
            httpOptions);
    }

    getClientTransGifts(clientId: number, dateFrom: string, dateTo: string): Observable<VmClientTransGifts[]>{
        return this.http
            .get<VmClientTransGifts[]>(this.globals.getApiString() + '/Clients/trans-gifts?clientId=' + clientId + "&dateFrom=" + dateFrom + "&dateTo=" + dateTo,
            httpOptions);
    }    

  getClientPredictions(clientId: number): Observable<ClientPredict>{
    return this.http
      .get<ClientPredict>(this.globals.getApiString() + '/Clients/prediction?client_id=' + clientId,
        httpOptions);
  }

  getClientLimitFS(clientId: number): Observable<ClientLimitsFS[]>{
    return this.http
      .get<ClientLimitsFS[]>(this.globals.getApiString() + '/Clients/limitsFS?client_id=' + clientId,
        httpOptions);
  }

  getClientTransFW(clientId: number, dateFrom: string, dateTo: string): Observable<ClientTransFW[]>{
    return this.http
      .get<ClientTransFW[]>(this.globals.getApiString() + '/extClients/TaxiTransaction/' + clientId + "?dateFrom=" + dateFrom + "&dateTo=" + dateTo,
        httpOptions);
  }

  getClientRestFW(clientId: number): Observable<ClientRestFW[]>{
    return this.http
      .get<ClientRestFW[]>(this.globals.getApiString() + '/extClients/TaxiDetailedRest/' + clientId,
        httpOptions);
  }

  getFWFuelPrices(): Observable<FuelPricesFW[]>{
    return this.http
      .get<FuelPricesFW[]>(this.globals.getApiString() + '/extClients/FuelPricesFW',
        httpOptions);
  }

  fwCreateExchange(item: ClientWalletFuelsExchangeRequest) {
    return this.http.post(this.globals.getApiString() + '/Clients/WalletFuelsExchange', JSON.stringify(item), httpOptions);
  }

  fwCreateRestore(item: ClientWalletFuelsRestoreRequest) {
        return this.http.post(this.globals.getApiString() + '/Clients/WalletFuelsRestore', JSON.stringify(item), httpOptions);
  }

  getClientPaymentTransFW(clientId: number, dateFrom: string, dateTo: string): Observable<ClientPaymentTransFW[]>{
    return this.http
      .get<ClientPaymentTransFW[]>(this.globals.getApiString() + '/extClients/FuelSalesPaymentTransaction/' + clientId + "?dateFrom=" + dateFrom + "&dateTo=" + dateTo,
        httpOptions);
  }

  fwCreateBlock(item: ClientWalletFuelsBlockRequest) {
    return this.http.post(this.globals.getApiString() + '/Clients/WalletFuelsBlock', JSON.stringify(item), httpOptions);
  }

  getClientsSettingsList(): Observable<ClientsSettingsList[]>{
    return this.http
      .get<ClientsSettingsList[]>(this.globals.getApiString() + '/Clients/Settings',
        httpOptions);
  }

  getClientsInsurancePolicy(): Observable<ClientsInsurancePolicy[]>{
    return this.http
      .get<ClientsInsurancePolicy[]>(this.globals.getApiString() + '/Clients/InsurancePolicy',
        httpOptions);
  }


}
