import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GiftsTransaction } from '../_models';
import { Globals } from '../globals';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Headers, RequestOptions } from '@angular/http';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({ providedIn: 'root' })
export class TransactionsGiftsService {
  constructor(private http: HttpClient, private globals: Globals) { }

  getTransGifts(dateFrom: string, dateTo: string): Observable<GiftsTransaction[]>{
    return this.http
      .get<GiftsTransaction[]>(this.globals.getApiString() + '/Transactions/trans-gifts?dateFrom=' + dateFrom + '&dateTo=' + dateTo,
        httpOptions);
  }
}
