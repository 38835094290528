import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Clients, ValueId } from '../../_models';
import { ClientsService, AuthenticationService } from '../../_services';

import DataSource from "devextreme/data/data_source";
import ArrayStore from "devextreme/data/array_store";
import ODataStore from "devextreme/data/odata/store";
import 'devextreme/data/odata/store';
import { Globals } from '../../globals';
import { DxDataGridComponent } from 'devextreme-angular';

import notify from 'devextreme/ui/notify';
import { Router } from '@angular/router';

@Component({
    templateUrl: 'clients-list-remote.component.html'
})
export class ClientsListRemoteComponent implements OnInit, AfterViewInit {
    @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;

    dataSource: DataSource;
    dsCards: DataSource;

    //items: Clients[] = [];
    loading = false;
    error = '';
    clientGridViewType: number = 1;

    genderList: ValueId[];
    maritalStatusList: ValueId[];

    delItem: any;
    isDeletePopupVisible: boolean = false;

    canBatch = false;

    public constructor(
        private titleService: Title,
        private clientsService: ClientsService,
        private authenticationService: AuthenticationService,
        private globals: Globals,
        private router: Router
    ) {
        this.titleService.setTitle("Клієнти");
    }

    ngAfterViewInit(): void {

    }

    ngOnInit(): void {
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser.UserType == 1) {
            this.canBatch = true;
        }
        var allowed = [1, 10, 40];

        if(!allowed.includes(currentUser.UserType)){
            this.router.navigateByUrl("404")
        }
        this.genderList = this.clientsService.getGenders();
        this.maritalStatusList = this.clientsService.getMaritalStatuses();
        this.loadClientGridViewType()
        this.initDataSource();
    }

    calculateMobApp(e) {
        let client = e.Client != null ? e.Client : e;
        if(client.Authorized == true && client.HasFcmToken == true) {
            return true;
        }
        return false;
    }

    initDataSource() {
        let clientsODataUrl = this.globals.serverName + "/odata/Clients";
        this.dataSource = new DataSource({
            store: {
                type: "odata",
                url: clientsODataUrl,
                key: "Id",
                version: 4,
                beforeSend: (e) => {
                    //console.log("Before send started");
                    this.initODataHeader(e);
                    //console.log("Before send ended");
                }
            },
            expand: ["Area","City","VehicleBrand","VehicleType"]
            ,
            filter: ["IsDeleted", "=", +"0"]
        });

        clientsODataUrl = this.globals.serverName + "/odata/Cards";
        this.dsCards = new DataSource({
            store: {
                type: "odata",
                url: clientsODataUrl,
                key: "Id",
                version: 4,
                beforeSend: (e) => {
                    //console.log("Before send started");
                    this.initODataHeader(e);
                    //console.log("Before send ended");
                }
            },
            expand: ["Client","Client.Area","Client.City","Client.VehicleBrand","Client.VehicleType"]
            ,
            filter: ["IsDeleted", "=", +"0"]
        });
    }

    initODataHeader(e) {
        //Делаем вызов псевдо метода для обновления AccessToken если он просрочен

        this.authenticationService.checkAuthAjax();
        let authToken = 'Bearer ' + this.authenticationService.getAccessToken();
        //console.log("Before send use: " + authToken);
        e.headers = {
            "Content-Type": "application/json",
            "Authorization": authToken
        }
    }



    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                visible: this.clientGridViewType == 1,
                options: {
                    icon: 'fa fa-credit-card',
                    onClick: this.chooseCardsView.bind(this),
                    hint: 'По карткам',
                    width: "45"
                }
            },
            {
                location: 'after',
                widget: 'dxButton',
                visible: this.clientGridViewType == 2,
                options: {
                    icon: 'fa fa-address-card-o',
                    onClick: this.chooseClientsView.bind(this),
                    hint: 'По клієнтам',
                    width: "45"
                }
            },
            {
                location: 'after',
                widget: 'dxButton',

                options: {
                    icon: 'fa fa-filter',
                    onClick: this.refreshDataGrid.bind(this),
                    hint: 'Очистити фільтри'
                }
            }
        );
    }

    loadClientGridViewType(){
        let val = localStorage.getItem("ClientGridViewType");
        if(val){
            this.clientGridViewType = +val;
        }
    }

    chooseCardsView(){
        this.clientGridViewType = 2;
        localStorage.setItem("ClientGridViewType", this.clientGridViewType + "");
    }
    chooseClientsView(){
        this.clientGridViewType = 1;
        localStorage.setItem("ClientGridViewType", this.clientGridViewType + "");
    }

    refreshDataGrid() {

        this.dataGrid.instance.clearFilter();
    }

    deleteItem(data) {
        this.delItem = data.data as Clients;
        this.isDeletePopupVisible = true;
    }

    deleteCancel() {
        this.delItem = null;
        this.isDeletePopupVisible = false;
    }

    deleteConfirm() {
        this.loading = true;
        this.clientsService.deleteItem(this.delItem.Id)
            .subscribe(item => {
                // let index = this.items.findIndex(x => x.id == this.delItem.Id);
                // this.items[index].isDeleted = 1;

                this.initDataSource();
                this.deleteCancel();
                notify("Запис успішно видалений", "success", 600);
                this.loading = false;
            },
                error => {
                    this.error = error;
                    this.loading = false;
                });
    }
}
