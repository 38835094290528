import { Clients } from "..";

export class WalletFuelsRefundTransactions {
    id: number;
    creadtedLocalDate: string;
    updatedLocalDate: string;
    clientId: number;
    origOrderId: number | null;
    origPmtId: number | null;
    fuelId: number;
    fuelName: string;
    amount: number;
    price: number;
    discPerLitr: number;
    summaRefund: number;
    status: number;
    blockDocId: number | null;
    description: string;
    userName: string;
    paymentSystem: number;
}

export class WalletFuelsRefundRequest {
    origOrderId: number;
    amount: number;
    summaRefundPredict: number;
}

export class WalletFuelsRefundPrepare {
    client: Clients;
    origOrderId: number | null;
    origPmtId: number | null;
    clientId: number;
    fuelExtId: number;
    fuelName: string;
    pricePay: number;
    amountPay: number;
    discountPay: number;
    discPerLitr: number;
    summaPay: number;
    amountAvailable: number;
    amountRefundAvailable: number;
}