import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Tab, VmClientTransLine} from '../_models';
import { Globals } from '../globals';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Headers, RequestOptions } from '@angular/http';
import {TransPromo} from '../_models/trans-promo';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

let transTabs: Tab[] = [
  {
    id: 0,
    text: "За номером транзакції",
    icon: "fa fa-list"
  },
  {
    id: 1,
    text: "По клієнтам",
    icon: "fa fa-address-card-o"
  },
  {
    id: 2,
    text: "По АЗК",
    icon: "fa fa-building-o"
  }
];

@Injectable({ providedIn: 'root' })
export class TransactionsService {
  constructor(private http: HttpClient, private globals: Globals) { }

  getTransTabs(): Tab[]{
    return transTabs;
  }

  getTransByParams(transNum: number, clientId: number, visibleNumber: string, stationId: number, dayReport: string): Observable<VmClientTransLine[]>{
    return this.http
      .get<VmClientTransLine[]>(this.globals.getApiString() + '/Transactions/?trans_id=' + transNum + '&client_id=' + clientId + '&visible_number=' + visibleNumber + '&station_id=' + stationId + '&day_report=' + dayReport,
        httpOptions);
  }

  getTransCalc(transNum: number): Observable<TransPromo>{
    return this.http
      .get<TransPromo>(this.globals.getApiString() + '/Transactions/trans-calc?trans_id=' + transNum,
        httpOptions);
  }
}
