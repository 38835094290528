import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Tab, ClientsSettingsList, DeductionZSUTransactions, AddSumaMonobanka, MonoBankaSettings } from '../../_models';
import { DeductionZSUService, ClientsService } from '../../_services';
import { Router } from '@angular/router';
import notify from 'devextreme/ui/notify';
import 'devextreme/data/odata/store';
import {DxDataGridComponent } from 'devextreme-angular';
import {DatePipe} from '@angular/common';
import { Globals } from '../../globals';

@Component({
  templateUrl: 'transactions-deduction-zsu.component.html'
})
export class TransactionsDeductionZSUComponent implements OnInit, AfterViewInit {
  @ViewChild("transDeductionZSUDataGrid") dataTransGrid: DxDataGridComponent;
  @ViewChild("clientsSettingsDataGrid") dataClientsSettingsGrid: DxDataGridComponent;

  transTabs: Tab[];
  isShowTrans: boolean = false;
  isShowSettings: boolean = false;
  isShowBlago: boolean = false;

  loading: boolean = false;
  saving: boolean = false;
  iconSave: string;

  clientId: number;
  error = '';

  dateFrom: Date;
  dateTo: Date;

  dsTransDeductionZSULines: DeductionZSUTransactions[];
  dsClientsSettingsLines: ClientsSettingsList[];

  addSumaMonobanka: AddSumaMonobanka;

  caption = 'Відрахування ЗСУ';

  public constructor(
    private titleService: Title,
    private deductionZSUService: DeductionZSUService,
    private clientsService: ClientsService,
    private router: Router,
    private datePipe: DatePipe,
    private globals: Globals,
  )
  {
    this.titleService.setTitle(this.caption);

  }

  ngAfterViewInit(): void {

  }

  ngOnInit(): void {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));

    var allowed = [1, 10, 40, 400];

    if(!allowed.includes(currentUser.UserType)){
      this.router.navigateByUrl("404")
    }

    this.dateFrom = new Date();
    this.dateTo = new Date();
    this.dateFrom = this.add_months(this.dateFrom, -1);
    
    this.addSumaMonobanka = new AddSumaMonobanka();
    this.addSumaMonobanka.dataMonoBanka = new MonoBankaSettings();
    this.transTabs = this.deductionZSUService.getDeductionZSUTabs();
    this.showTransTabId(0);

  }

  add_months(dt, n) {
    return new Date(dt.setMonth(dt.getMonth() + n));
  }

  add_days(dt, n) {
    return new Date(dt.setDate(dt.getDate() + n));
  }

  showTransTabId(id: number) {
    this.isShowTrans = id == 0;
    this.isShowSettings = id == 1;
    this.isShowBlago = id == 2;

    if (id == 0) { }
    else if (id == 1) { }
    else if (id == 2) {
      this.getDataBlago();
     }
  }

  selectTransTab(e) {
    this.showTransTabId(e.itemData.id);
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',

        options: {
          icon: 'fa fa-filter',
          onClick: this.refreshDataTransGrid.bind(this),
          hint: 'Очистити фільтри'
        }
    });
  }

  refreshDataTransGrid() {
    if(this.isShowTrans) {
      this.dataTransGrid.instance.clearFilter();
    }
    else if(this.isShowSettings) {
      this.dataClientsSettingsGrid.instance.clearFilter();
    }
  }

  onContentReady(e) {
    if(e.element.id === 'transDeductionZSUDataGridContainer' && !this.dsTransDeductionZSULines) {      
      this.getTrans();
    }
    else if(e.element.id === 'clientsSettingsDataGridContainer' && !this.dsClientsSettingsLines) {
      this.getClientsSettings();
    }
  }

  onInitialized(e) {
    
  }

  apply() {
      if(this.isShowTrans) {
        this.getTrans();
      }
      else if(this.isShowSettings){
        this.getClientsSettings();
      }
  }

  getTrans() {

    if(!this.dateFrom) {
      notify("Введіть дату З", "error", 1000);
      return;
    }

    if(!this.dateTo) {
      notify("Введіть дату ПО", "error", 1000);
      return;
    }

    this.dataTransGrid.instance.beginCustomLoading('');

    this.deductionZSUService.getTransDeductionZSU(this.datePipe.transform(this.dateFrom, 'yyyy-MM-dd'), this.datePipe.transform(this.dateTo, 'yyyy-MM-dd'))
      .subscribe(item => {
          this.dsTransDeductionZSULines = item;
          this.dataTransGrid.instance.endCustomLoading();
        },
        error => {
          this.error = error;
          this.dataTransGrid.instance.endCustomLoading();
          notify(!error.error ? 'Під час виконання запиту сталаса помилка' : error.error, "error", 1000);
        });

  }

  getClientsSettings() {

    this.dataClientsSettingsGrid.instance.beginCustomLoading('');

    this.clientsService.getClientsSettingsList()
      .subscribe(item => {
          this.dsClientsSettingsLines = item;
          this.dataClientsSettingsGrid.instance.endCustomLoading();
        },
        error => {
          this.error = error;
          this.dataClientsSettingsGrid.instance.endCustomLoading();
          notify(!error.error ? 'Під час виконання запиту сталаса помилка' : error.error, "error", 1000);
        });

  }

  getDataBlago() {
    this.loading = true;
    this.deductionZSUService.getAddSumaMonobanka()
    .subscribe(item => {
      this.loading = false;
      this.addSumaMonobanka = item;

      this.addSumaMonobanka.dataMonoBanka.lastUpdateView = (new Date(item.dataMonoBanka.lastUpdate)).toLocaleString();
      this.addSumaMonobanka.totalSuma = (item.sumaAdditional + item.monoSuma).toFixed(2);
    },
    error => {
      this.error = error;
      this.loading = false;
      notify(!error.error ? 'Під час виконання запиту сталаса помилка' : error.error, "error", 1000);
    });

  }

  setAddSumaBlago() {
    this.saving = true;
    this.deductionZSUService.updateAddSuma(this.addSumaMonobanka.sumaAdditional)
    .subscribe(item => {
      this.saving = false;
      this.getDataBlago();    
    },
    error => {
      this.error = error;
      this.saving = false;
      notify(!error.error ? 'Під час виконання запиту сталаса помилка' : error.error, "error", 1000);
    });
  }

}
