import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Tab, DigestInfoResp } from '../../_models';
import { DigestInfoService } from '../../_services';
import { Router } from '@angular/router';
import notify from 'devextreme/ui/notify';
import 'devextreme/data/odata/store';
import {DxDataGridComponent } from 'devextreme-angular';
import {DatePipe} from '@angular/common';
import { Globals } from '../../globals';

@Component({
  templateUrl: 'digest-info.component.html'
})
export class DigestInfoComponent implements OnInit, AfterViewInit {
  @ViewChild("clientsDataGrid") dataClientsGrid: DxDataGridComponent;
  @ViewChild("cardsDataGrid") dataCardsGrid: DxDataGridComponent;
  @ViewChild("goodsDataGrid") dataGoodsGrid: DxDataGridComponent;
  @ViewChild("segmentsDataGrid") dataSegmentsGrid: DxDataGridComponent;
  @ViewChild("stationsDataGrid") dataStationsGrid: DxDataGridComponent;
  @ViewChild("countersDataGrid") dataCountersGrid: DxDataGridComponent;
  @ViewChild("promotionsDataGrid") dataPromotionsGrid: DxDataGridComponent;
  @ViewChild("appsDataGrid") dataAppsGrid: DxDataGridComponent;

  digestTabs: Tab[];

  isShowClients: boolean = false;
  isShowCards: boolean = false;
  isShowGoods: boolean = false;
  isShowSegments: boolean = false;
  isShowStations: boolean = false;
  isShowCounters: boolean = false;
  isShowPromotions: boolean = false;
  isShowApps: boolean = false;

  loading: boolean = false;
  saving: boolean = false;
  iconSave: string;

  error = '';

  dsClients: DigestInfoResp[];
  dsCards: DigestInfoResp[];
  dsGoods: DigestInfoResp[];
  dsSegments: DigestInfoResp[];
  dsStations: DigestInfoResp[];
  dsCounters: DigestInfoResp[];
  dsPromotions: DigestInfoResp[];
  dsApps: DigestInfoResp[];

  caption = 'Дайджест ПЛ';

  public constructor(
    private titleService: Title,
    private digestInfoService: DigestInfoService,
    private router: Router,
    private datePipe: DatePipe,
    private globals: Globals,
  )
  {
    this.titleService.setTitle(this.caption);

  }

  ngAfterViewInit(): void {

  }

  ngOnInit(): void {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));

    var allowed = [1];

    if(!allowed.includes(currentUser.UserType)){
      this.router.navigateByUrl("404")
    }

    this.digestTabs = this.digestInfoService.getDigestInfoTabs();
    this.showTransTabId(0);

  }

  showTransTabId(id: number) {
    this.isShowClients = id == 0;
    this.isShowCards = id == 1;
    this.isShowGoods = id == 2;
    this.isShowSegments = id == 3;
    this.isShowStations = id == 4;
    this.isShowCounters = id == 5;
    this.isShowPromotions = id == 6;
    this.isShowApps = id == 7;

    if (id == 0) { 

    }
  }

  selectTransTab(e) {
    this.showTransTabId(e.itemData.id);
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',

        options: {
          icon: 'fa fa-filter',
          onClick: this.refreshDataTransGrid.bind(this),
          hint: 'Очистити фільтри'
        }
    });
  }

  refreshDataTransGrid() {
    if(this.isShowClients) {
      this.dataClientsGrid.instance.clearFilter();
    }
    else if(this.isShowCards) {
      this.dataCardsGrid.instance.clearFilter();
    }
    else if(this.isShowGoods) {
      this.dataGoodsGrid.instance.clearFilter();
    }
    else if(this.isShowSegments) {
      this.dataSegmentsGrid.instance.clearFilter();
    }
    else if(this.isShowStations) {
      this.dataStationsGrid.instance.clearFilter();
    }
    else if(this.isShowCounters) {
      this.dataCountersGrid.instance.clearFilter();
    }
    else if(this.isShowPromotions) {
      this.dataPromotionsGrid.instance.clearFilter();
    }
    else if(this.isShowApps) {
      this.dataAppsGrid.instance.clearFilter();
    }
  }

  onContentReady(e) {
    if(e.element.id === 'clientsInfoGridContainer' && !this.dsClients) {      
      this.getClientsInfo();
    }
    else if(e.element.id === 'cardsInfoGridContainer' && !this.dsCards) {
      this.getCardsInfo();
    }
    else if(e.element.id === 'goodsInfoGridContainer' && !this.dsGoods) {
      this.getGoodsInfo();
    }
    else if(e.element.id === 'segmentsInfoGridContainer' && !this.dsSegments) {
      this.getSegmentsInfo();
    }
    else if(e.element.id === 'stationsInfoGridContainer' && !this.dsStations) {
      this.getStationsInfo();
    }
    else if(e.element.id === 'countersInfoGridContainer' && !this.dsCounters) {
      this.getCountersInfo();
    }
    else if(e.element.id === 'promotionsInfoGridContainer' && !this.dsPromotions) {
      this.getPromotionsInfo();
    }
    else if(e.element.id === 'appsInfoGridContainer' && !this.dsApps) {
      this.getAppsInfo();
    }
  }

  onInitialized(e) {
    
  }

  getAppsInfo() {
    this.dataAppsGrid.instance.beginCustomLoading('');

    this.digestInfoService.getAppsInfo()
      .subscribe(item => {
          this.dsApps = item;
          this.dataAppsGrid.instance.endCustomLoading();
        },
        error => {
          this.error = error;
          this.dataAppsGrid.instance.endCustomLoading();
          notify(!error.error ? 'Під час виконання запиту сталаса помилка' : error.error, "error", 1000);
        });
  }

  getPromotionsInfo() {
    this.dataPromotionsGrid.instance.beginCustomLoading('');

    this.digestInfoService.getPromotionsInfo()
      .subscribe(item => {
          this.dsPromotions = item;
          this.dataPromotionsGrid.instance.endCustomLoading();
        },
        error => {
          this.error = error;
          this.dataPromotionsGrid.instance.endCustomLoading();
          notify(!error.error ? 'Під час виконання запиту сталаса помилка' : error.error, "error", 1000);
        });
  }

  getCountersInfo() {
    this.dataCountersGrid.instance.beginCustomLoading('');

    this.digestInfoService.getCountersInfo()
      .subscribe(item => {
          this.dsCounters = item;
          this.dataCountersGrid.instance.endCustomLoading();
        },
        error => {
          this.error = error;
          this.dataCountersGrid.instance.endCustomLoading();
          notify(!error.error ? 'Під час виконання запиту сталаса помилка' : error.error, "error", 1000);
        });
  }

  getStationsInfo() {
    this.dataStationsGrid.instance.beginCustomLoading('');

    this.digestInfoService.getStationsInfo()
      .subscribe(item => {
          this.dsStations = item;
          this.dataStationsGrid.instance.endCustomLoading();
        },
        error => {
          this.error = error;
          this.dataStationsGrid.instance.endCustomLoading();
          notify(!error.error ? 'Під час виконання запиту сталаса помилка' : error.error, "error", 1000);
        });
  }

  getSegmentsInfo() {
    this.dataSegmentsGrid.instance.beginCustomLoading('');

    this.digestInfoService.getSegmentsInfo()
      .subscribe(item => {
          this.dsSegments = item;
          this.dataSegmentsGrid.instance.endCustomLoading();
        },
        error => {
          this.error = error;
          this.dataSegmentsGrid.instance.endCustomLoading();
          notify(!error.error ? 'Під час виконання запиту сталаса помилка' : error.error, "error", 1000);
        });
  }

  getGoodsInfo() {
    this.dataGoodsGrid.instance.beginCustomLoading('');

    this.digestInfoService.getGoodsInfo()
      .subscribe(item => {
          this.dsGoods = item;
          this.dataGoodsGrid.instance.endCustomLoading();
        },
        error => {
          this.error = error;
          this.dataGoodsGrid.instance.endCustomLoading();
          notify(!error.error ? 'Під час виконання запиту сталаса помилка' : error.error, "error", 1000);
        });
  }

  getCardsInfo() {
    this.dataCardsGrid.instance.beginCustomLoading('');

    this.digestInfoService.getCardsInfo()
      .subscribe(item => {
          this.dsCards = item;
          this.dataCardsGrid.instance.endCustomLoading();
        },
        error => {
          this.error = error;
          this.dataCardsGrid.instance.endCustomLoading();
          notify(!error.error ? 'Під час виконання запиту сталаса помилка' : error.error, "error", 1000);
        });
  }

  getClientsInfo() {

    this.dataClientsGrid.instance.beginCustomLoading('');

    this.digestInfoService.getClientsInfo()
      .subscribe(item => {
          this.dsClients = item;
          this.dataClientsGrid.instance.endCustomLoading();
        },
        error => {
          this.error = error;
          this.dataClientsGrid.instance.endCustomLoading();
          notify(!error.error ? 'Під час виконання запиту сталаса помилка' : error.error, "error", 1000);
        });

  }

}
