import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PromotionsWholesaleDocs } from '../_models';
import { Globals } from '../globals';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

@Injectable({ providedIn: 'root' })
export class PromotionsWholesaleDocsService {
    constructor(private http: HttpClient, private globals: Globals) { }

    getAllDocs() {
        return this.http.get<PromotionsWholesaleDocs[]>(this.globals.getApiString() + '/ext1c/promo-wholesale-docs-list', {});
    }

    getDocById(id: number){
        return this.http.get<PromotionsWholesaleDocs>(this.globals.getApiString() + '/ext1c/promo-wholesale-docs/' + id, {});
    }
}
