import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Users } from '../../_models';
import { UsersService } from '../../_services';
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";
import { ValueId } from '../../_models/value-id';
import { Router } from '@angular/router';
import notify from 'devextreme/ui/notify';
import { DxDataGridComponent } from 'devextreme-angular';

@Component({
    templateUrl: 'users-list.component.html'
  })
  export class UsersListComponent implements OnInit, AfterViewInit {
    @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;

    store: ArrayStore;
    dataSource: DataSource;
    dsUserTypes: ValueId[];

    items: Users[] = [];
    loading = false;
    error = '';

    delItem: Users;
    isDeletePopupVisible: boolean = false;

    public constructor(
        private titleService: Title,
        private usersService: UsersService,
        private router: Router
    )
    {
        this.titleService.setTitle("Користувачі");
    }

    ngAfterViewInit(): void {

    }
    ngOnInit(): void {
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    var allowed = [1];

    if(!allowed.includes(currentUser.UserType)){
        this.router.navigateByUrl("404")
    }
        //https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/CascadingLookups/Angular/Light/
        let tmp = new ValueId();
        tmp.id = 1;
        tmp.value = "Web адміністратор";
        
        this.dsUserTypes = this.usersService.getUserTypes();

        this.loading = true;
        this.usersService.getAll()
        .subscribe(items => {
            this.items = items;
            this.initDataSource();
            this.loading = false;
        },
        error => {
            this.error = error;
            this.loading = false;
        });
    }

    initDataSource(){
        this.dataSource = new DataSource({
            store: new ArrayStore({
                key: "id",
                data: this.items,
                // Other ArrayStore options go here
            }),
            filter: [ "isDeleted", "=", "0" ]
        });
    }


    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(  {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'fa fa-filter',
                    onClick: this.refreshDataGrid.bind(this),
                    hint: 'Очистити фільтри'
                }
            });
    }

    refreshDataGrid() {
        this.dataGrid.instance.clearFilter();
      }

      deleteItem(data){
        this.delItem = data.data as Users;
        //console.log("delItem Id " + this.delItem.id);
        this.isDeletePopupVisible = true;
    }

    deleteCancel(){
        this.delItem = null;
        this.isDeletePopupVisible = false;
    }

    deleteConfirm(){
        this.loading = true;
        this.usersService.deleteItem(this.delItem.id)
        .subscribe(item => {
            let index = this.items.findIndex(x => x.id == this.delItem.id);
            this.items[index].isDeleted = 1;

            this.initDataSource();
            this.deleteCancel();
            notify("Запис успішно видалена", "success", 600);
            this.loading = false;
        },
        error => {
            this.error = error;
            this.loading = false;
        });
    }



  }


