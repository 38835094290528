export class Users {
    id: number;
    guid: string;
    userTypeId: number;
    eMail: string;
    user: string;
    password: string;
    comment: string;
    isDeleted: number;
    isBlocked: number;
}

export class ApiUserChange {
    id: number;
    guid: string;
    password: string;
    isBlocked: number;
}