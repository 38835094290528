import { GoodsGroupsGoods } from "./goods-groups-goods";
import {GoodsLimits } from "./goods-limits";

export class Goods {
    id: number;
    name: string;
    extId: string;
    measureId: number;
    isDeleted: number;
    goodsGroups: GoodsGroupsGoods[];
    goodsLimits: GoodsLimits[];
}