import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { GoodsGroups } from '../../_models';
import { GoodsGroupsService } from '../../_services';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import notify from 'devextreme/ui/notify';
import { Router } from '@angular/router';

import {
  DxDropDownBoxModule,
  DxTreeViewModule,
  DxDataGridModule,
  DxTreeViewComponent,
  DxTextBoxModule,
  DxDropDownBoxComponent
} from 'devextreme-angular';
import { Globals } from '../../globals';

@Component({
  templateUrl: 'goods-groups-edit.component.html'
})
export class GoodsGroupsEditComponent implements OnInit, AfterViewInit {
  @ViewChild(DxTreeViewComponent) treeView;
  @ViewChild(DxDropDownBoxComponent) ddb;

  _shouldClose: boolean = false;

  loading = false;
  saving = false;
  error = '';

  caption: string = "";
  private isNewItem: boolean = false;

  iconSave: string;

  private id;
  item: GoodsGroups;
  findItem: GoodsGroups;
  groupsList: GoodsGroups[] = [];
  itemGroups: GoodsGroups[] = [];

  public constructor(
    private titleService: Title,
    private goodsGroupsService: GoodsGroupsService,
    private route: ActivatedRoute,
    private location: Location,
    private globals: Globals,
    private router: Router
  ) {

  }

  refreshButtonIcon(){
    if(this.saving){
      this.iconSave = this.globals.ICON_SPINNER;
    } else {
      this.iconSave = this.globals.ICON_FLOPPY;
    }
  }

  onOpened(e) {
    this._shouldClose = true;
  }

  ngAfterViewInit(): void {

  }
  ngOnInit(): void {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));

        var allowed = [1];

        if(!allowed.includes(currentUser.UserType)){
            this.router.navigateByUrl("404")
        }
    this.refreshButtonIcon();

    this.route.params.subscribe(params => {
      this.id = +params['id']; // (+) converts string 'id' to a number
    });

    if (Number.isNaN(this.id)) {
      this.caption = 'Додавання групи';
      this.titleService.setTitle(this.caption);
      this.item = new GoodsGroups();
      this.item.id = 0;
      this.loading = true;

      this.goodsGroupsService.getAll()
        .subscribe(items => {
          this.groupsList = items;
          this.loading = false;
        },
          error => {
            this.error = error;
            this.loading = false;
          });
    }
    else {
      this.caption = 'Редагування групи';
      this.titleService.setTitle(this.caption);
      this.loading = true;

      this.goodsGroupsService.getAll()
        .subscribe(items => {
          this.groupsList = items;
          let i = items.findIndex(x => x.id == this.id);
          this.item = this.groupsList[i];
          this.loading = false;
        },
          error => {
            this.error = error;
            this.loading = false;
          });

    }

  }

  goBack() {
    this.location.back();
  }

  update() {
    this.saving = true;
    this.refreshButtonIcon();
    this.goodsGroupsService.updateItem(this.item)
      .subscribe(res => {
        console.log("update id: " + res.id);
        this.saving = false;
        this.refreshButtonIcon();
        notify("Дані збережено", "success", 600);
        this.goBack();
      },
        error => {
          this.error = error;

        });
  }

  syncTreeViewSelection() {
    if (!this.treeView) return;

    if (!this.item.parentId) {
      this.treeView.instance.unselectAll();
    } else {
      this.treeView.instance.selectItem(this.item.parentId);
    }
  }

  filterValueChanged(data) {
    console.log(data.value);
  }

  checkRecursiveParentId(newParentId: number) {
    let error = false;
    if(newParentId == this.item.id){
      error = true;
    }
    let curLevel = 0;
    let curId = newParentId;
    while(curId){
      curLevel = curLevel + 1;
      if(curLevel > 100){
        error = true;
        curId = null;
        break;
      }
      let curGroupIndex = this.groupsList.findIndex(x => x.id == curId);
      if(curGroupIndex >= 0){
        let curGroup = this.groupsList[curGroupIndex];
        if(curGroup){
          curId = curGroup.parentId;
          if(curId == this.item.id){
            curId = null;
            error = true;
          }
        } else {
          curId = null;
        }

      } else{
        curId = null;
      }
    }

    if(error){
      notify("Виявлена циклічна залежність", "error", 600);
      return false;
    } else {
      return true;
    }
  }

  treeView_itemSelectionChanged(e) {
    if (e.component.getSelectedNodesKeys() != 0) {
      if (this._shouldClose) {
        let parentId = e.component.getSelectedNodesKeys()[0];
        if (this.checkRecursiveParentId(parentId)) {
          this.item.parentId = e.component.getSelectedNodesKeys()[0];
        }
        this.ddb.instance.close();
        this._shouldClose = false;
      }
    }

  }
}
