import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { VehicleBrands, Calendar, ValueId } from '../../_models';
import { VehicleBrandsService, CalendarService } from '../../_services';

import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";

import notify from 'devextreme/ui/notify';
import { DxDataGridComponent } from 'devextreme-angular';
import { Router } from '@angular/router';

import DataGrid from "devextreme/ui/data_grid";

@Component({
    templateUrl: 'calendar.component.html',
    styleUrls: ['calendar.component.css']
})
export class CalendarComponent implements OnInit, AfterViewInit {
    loading = false;
    saving = false;
    error = '';
    currentValue: Date = new Date();
    cellTemplate = "custom";
    curYear: number;
    curMonth: number;
    curItems: Calendar[];
    selectedDayTypeId: number;
    dayTypes: ValueId[];

    public constructor(
        private titleService: Title,
        private calendarService: CalendarService,
        private router: Router
    ) {
        this.titleService.setTitle("Календар");
    }

    ngAfterViewInit(): void {

    }
    ngOnInit(): void {
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));

        var allowed = [1];

        if(!allowed.includes(currentUser.UserType)){
            this.router.navigateByUrl("404")
        }
        this.dayTypes = this.calendarService.getDayTypes();
        this.changeCurDate(this.currentValue);



    }

    changeCurDate(newDate) {
        this.error = null;
        this.loading = false;

        let newMonth = newDate.getMonth() + 1;
        let newYear = newDate.getFullYear();
        if (this.curYear != newYear || this.curMonth != newMonth) {
            this.curYear = newYear;
            this.curMonth = newMonth;

            this.loadCalendar();
            //console.log("change curDate " + this.curYear + " year " + this.curMonth);
        }
    }

    loadCalendar(){
        this.loading = true;
        this.calendarService.getMonth(this.curYear, this.curMonth)
            .subscribe(items => {
                this.curItems = items;
                this.loading = false;
            },
                error => {
                    this.error = error;
                    this.loading = false;
                    console.log(error);
                });
    }

    findDateIndex(date) {
        if (date.getMonth() + 1 != this.curMonth) return -1;
        if (date.getFullYear() != this.curYear) return -1;

        //console.log("!!!!find dat " + date + " ;  dat time " + date.getTime());
        for (let i = 0; i < this.curItems.length; i++) {
            //console.log("compare dat " + new Date(this.curItems[i].dat) + " ;  dat time " + new Date(this.curItems[i].dat).getTime());
            if (new Date(this.curItems[i].dat).getTime() == date.getTime()) {
                //console.log("MATCH!!!!!");
                return i;
            }
        }
        return -1;
    }

    getDateType(date: Date) {
        let result = 1;
        if (this.isWeekend(date)) result = 2;

        if (this.curItems) {
            let index = this.findDateIndex(date);
            if (index >= 0) {
                //console.log("MATCH INDEX " + index);
                result = this.curItems[index].dayTypeId;
            }
        }

        return result;
    }

    isWeekend(date) {
        var day = date.getDay();

        return day === 0 || day === 6;
    }

    getCellCssClass(date) {
        let dayType = this.getDateType(date);
        var cssClass = "";

        switch (dayType) {
            case 1:
                break;
            case 2:
                cssClass = "weekend"
                break;
            case 3:
                cssClass = "holyday";
                break;
        }

        return cssClass;
    }

    changeSelectedValue(dat) {
        let findDat = new Date(dat.getFullYear(), dat.getMonth(), dat.getDate());
        //let findDat = new Date(dat.getDate());
        //console.log("changeSelectedValue " + findDat);
        this.selectedDayTypeId = this.getDateType(findDat);
    }

    onValueChanged(e) {
        //console.log("onValueChanged " + e.value);
        this.changeSelectedValue(e.value);
    }

    onOptionChanged(e) {
        if (e.name === "currentDate") {
            this.changeCurDate(e.value);
        }

    }

    getDateStr(dat) {
        return dat.toDateString();
    }

    update() {
        this.saving = true;
        let updateDat = new Date(this.currentValue.getFullYear(), this.currentValue.getMonth(), this.currentValue.getDate());
        console.log("updateDat " + updateDat);
        let index = this.findDateIndex(updateDat);
        let val = new Calendar();;
        if(index >= 0){
            this.curItems[index].dayTypeId = this.selectedDayTypeId;
        }
        val.dat = updateDat;
        val.dayTypeId = this.selectedDayTypeId;


        this.calendarService.updateDay(val)
            .subscribe(item => {

                this.saving = false;
                this.loadCalendar();
            },
                error => {
                    this.error = error;
                    this.saving = false;
                    console.log(error);
                });
    }
}
