export class AddressArea {
    id: number;
    name: string;
}

export class AddressCity {
    id: number;
    name: string;
    region: string;
}

export class AddressFindCityRequest {
    areaId: number;
    query: string;
    locale: string;
    MaxResultCnt: number;
}

export class AddressFindStreetRequest {
    cityId: number;
    query: string;
    locale: string;
    maxResultCnt: number;
}


