import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {TelegramUsers, TelegramMailing} from '../../_models';
import {AuthenticationService, TelegramBotService } from '../../_services';
import { Router } from '@angular/router';
import notify from 'devextreme/ui/notify';
import {DxDataGridComponent } from 'devextreme-angular';
import {Globals} from '../../globals';
import {DatePipe} from '@angular/common';
import { element } from 'protractor';

@Component({
  templateUrl: 'telegram-bot-list.component.html'  
})
export class TelegramBotListComponent implements OnInit, AfterViewInit {
   @ViewChild("telegramDataGrid") dataGrid: DxDataGridComponent;


   dsTelegram: TelegramUsers[];
   telegramMailing: TelegramMailing = new TelegramMailing();
   loadingMailing: boolean = false;
   caption = 'Телеграм Бот';
   cntSender : number = 0;

   public constructor(
     private titleService: Title,
     private telegramBotService: TelegramBotService,
     private authenticationService: AuthenticationService,     
     private globals: Globals,
     private router: Router,
     private datePipe: DatePipe
   )
   {
     this.titleService.setTitle(this.caption);
   }

   ngAfterViewInit(): void {

   }

   ngOnInit(): void {
     let currentUser = JSON.parse(localStorage.getItem('currentUser'));

     var allowed = [1];

     if(!allowed.includes(currentUser.UserType)){
       this.router.navigateByUrl("404")
     }



   }

   onToolbarPreparing(e) {

    e.toolbarOptions.items.unshift(
       {
         location: 'after',
         widget: 'dxButton',

         options: {
           icon: 'fa fa-filter',
           onClick: this.refreshDataGrid.bind(this),
           hint: 'Очистити фільтри'
         }
     });
   }

   refreshDataGrid() {
     if(this.dsTelegram) {
       this.dataGrid.instance.clearFilter();
     }
   }

   onContentReady(e) {
     console.log("onContentReady" + e.component);
   }

   onInitialized(e) {

      this.dataGrid.instance.beginCustomLoading('');

     this.telegramBotService.getTelegramUsers()
       .subscribe(item => {
           this.dsTelegram = item;           
           this.dataGrid.instance.endCustomLoading();
         },
         error => {
           //this.error = error;
           this.dataGrid.instance.endCustomLoading();
           notify("Під час виконання запиту сталаса помилка", "error", 1000);
         });

     //console.log("onInitialized" + e.component);
   }

   selectAll() {
    this.cntSender = 0;
    this.dsTelegram.forEach(element => {
      element.is_mailing_send = true;      
      this.cntSender++;
    });
   }

   unSelectAll() {
    this.cntSender = 0;
    this.dsTelegram.forEach(element => {
      element.is_mailing_send = false;      
    });
   }

   reversSelect() {
    this.cntSender = 0;      
    this.dsTelegram.forEach(element => {
      element.is_mailing_send = element.is_mailing_send ? false : true;
      if(element.is_mailing_send) { this.cntSender++;}
    });

   }

   sendMailing() {

    this.telegramMailing.chatId = [];
    this.dsTelegram.forEach(element => {
      if(element.is_mailing_send) {
        this.telegramMailing.chatId.push(element.chat_id);
      }
    });

    if(this.telegramMailing.chatId.length == 0) {
      notify("Не встановлено жодного одержувача для розсилки", "error", 3000);
      return;
    }

    if(!this.telegramMailing.message) {
      notify("Не введений основний текст повідомлення", "error", 3000);
      return;
    }

    if(confirm("Ви дійсно бажаєте виконати розсилку повідомлень?")){
      this.loadingMailing = true;
      this.telegramBotService.telegramSendMailing(this.telegramMailing)
        .subscribe(res => {        
        notify("Розсилку виконано", "success", 1000);        
        this.loadingMailing = false;
      },
        error => {  
          notify("Нажаль, під час виконання розсилки виникла помилка", "error", 1000);        
          this.loadingMailing = false;
        });
    }

   }

   clearMailing() {
    this.telegramMailing = new TelegramMailing();        
   }

   rowUpdated(e) {
    
    if(e.data.is_mailing_send) {      
      this.cntSender++;
    }
    else {
      this.cntSender--;
    }
   }
}