import { PromoSchemaRange } from "./promo-schema-range";
import { PromoSchemaProduct } from "./promo-schema-product";
import { ValueId } from "../value-id";
import { PromoExceptGoods } from "./promo-except-goods";
import { PromoExceptGroups } from "./promo-except-groups";

export class PromoSchema {
    //Условия схемы
    typeId: number;
    counterId: number;
    applyId: number;
    calculationId: number;
    productConditionId: number;
    conditionId: number;

    //для общих значений
    reward: number; 

    //Настройка диапазона
    rangeCriteriaId: number;
    rangeCounterId: number;
    rangePeriodId: number;

    //Значение бесконечного диапазона
    isInfiniteRange: boolean;
    infiniteRangeWidth: number;
    infiniteRangeReward: number;

    //Диапазоны
    ranges: PromoSchemaRange[];

    //Продукты
    products: PromoSchemaProduct[];
    exceptProducts: PromoExceptGoods[];
    exceptGroups: PromoExceptGroups[];

    wholesaleDocId: number;

    constructor(){
        this.ranges = [];
        this.products = [];
        this.exceptProducts = [];
        this.exceptGroups = [];
        this.isInfiniteRange = false;
    }
 }