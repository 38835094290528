import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Counters, ValueId } from '../../_models';
import { CountersService } from '../../_services';

import DataSource from "devextreme/data/data_source";
import ArrayStore from "devextreme/data/array_store";

import notify from 'devextreme/ui/notify';
import { DxDataGridComponent } from 'devextreme-angular';
import { Router } from '@angular/router';

@Component({
    templateUrl: 'counters-list.component.html'
  })
  export class CountersListComponent implements OnInit, AfterViewInit {
    @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;

    items: Counters[] = [];
    loading = false;
    error = '';

    counterTypes: ValueId[];

    store: ArrayStore;
    dataSource: DataSource;

    delItem: Counters;
    isDeletePopupVisible: boolean = false;

    public constructor(
        private titleService: Title,
        private countersService: CountersService,
        private router: Router
    )
    {
        this.titleService.setTitle("Лічильники");
    }

    ngAfterViewInit(): void {

    }
    ngOnInit(): void {
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));

        var allowed = [1];

        if(!allowed.includes(currentUser.UserType)){
            this.router.navigateByUrl("404")
        }
        this.counterTypes = this.countersService.getTypes();

        this.loading = true;
        this.countersService.getAll()
        .subscribe(items => {
            this.items = items;
            this.initDataSource();
            this.loading = false;
        },
        error => {
            this.error = error;
            this.loading = false;
        });
    }

    initDataSource(){
        this.dataSource = new DataSource({
            store: new ArrayStore({
                key: "id",
                data: this.items,
                // Other ArrayStore options go here
            }),
            filter: [ "isDeleted", "=", "0" ]
        });
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(  {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'fa fa-filter',
                    onClick: this.refreshDataGrid.bind(this),
                    hint: 'Очистити фільтри'
                }
            });
    }

    refreshDataGrid() {
        this.dataGrid.instance.clearFilter();
      }

    deleteItem(data){
        this.delItem = data.data as Counters;
        this.isDeletePopupVisible = true;
    }

    deleteCancel(){
        this.delItem = null;
        this.isDeletePopupVisible = false;
    }

    deleteConfirm(){
        this.loading = true;
        this.countersService.deleteItem(this.delItem.id)
        .subscribe(item => {
            let index = this.items.findIndex(x => x.id == this.delItem.id);
            this.items[index].isDeleted = 1;

            this.initDataSource();
            this.deleteCancel();
            notify("Запис успішно видалено", "success", 600);
            this.loading = false;
        },
        error => {
            this.error = error;
            this.loading = false;
        });
    }
  }
