import { NgModule }       from '@angular/core';
import { CommonModule }       from '@angular/common';

import { FormsModule }    from '@angular/forms';

import { StationsRoutingModule } from './stations-routing.module';
import { StationsListComponent } from './stations-list.component';
import { StationsEditComponent } from './stations-edit.component';
import { DxButtonModule, DxTextBoxModule, DxPopupModule, DxDataGridModule } from 'devextreme-angular';

@NgModule({
    imports: [
      StationsRoutingModule,
      CommonModule,
      FormsModule,
      DxButtonModule,
      DxTextBoxModule,
      DxPopupModule,
      DxDataGridModule
    ],
    declarations: [
      StationsEditComponent,
        StationsListComponent   
    ]
  })
  export class StationsModule {

  }
