export class TransWalletSpillsRest {
    clientId: number;
    clientSurname: string;
    clientName: string;
    clientMiddleName: string;
    phoneConfirmed: string;
    fuelId: number;
    fuelName: string;
    amount: number;
    summa: number;
    avgPrice: number;
    avgSum: number;
    avgAmount: number;
}