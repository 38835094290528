import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Users, ApiUserChange, EmitRefreshToken } from '../../_models';
import { UsersService, AuthenticationService } from '../../_services';
import notify from 'devextreme/ui/notify';
import { DxPopupComponent } from 'devextreme-angular';
import { Router } from '@angular/router';

const ICON_KEY: string = "fa fa-lg fa-key ";
const ICON_SPINNER: string = "fa fa-lg fa-spinner fa-spin";
const ICON_LOCK: string = "fa fa-lock";
const ICON_UNLOCK: string = "fa fa-unlock";

@Component({
    templateUrl: 'users-edit.component.html'
  })
  export class UsersEditComponent implements OnInit, AfterViewInit {
    @ViewChild(DxPopupComponent) popupComponent;
    loading = false;
    saving = false;
    error = '';
    caption : string = "";
    private id;
    item: Users;
    iconSave: string = "fa fa-lg fa-floppy-o";
    emitRefreshToken: string;

    iconChangePassword: string = ICON_KEY;
    popupVisible: boolean = false;
    popupVisibleGenerateKey: boolean = false;
    passwordValue1: string = "";
    passwordValue2: string = "";

    passwordErrors: string[] = [];

    captionLock: string;
    iconLock: string;
    iconKey: string;

      public constructor(
      private titleService: Title,
      private route: ActivatedRoute,
      private location: Location,
      private usersServices: UsersService,
      private authService: AuthenticationService,
      private router: Router
    )
    {
      this.caption = "Редагування користувача";
      this.titleService.setTitle(this.caption);
    }

    ngAfterViewInit(): void {

    }
    ngOnInit(): void {
      let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    var allowed = [1];

    if(!allowed.includes(currentUser.UserType)){
        this.router.navigateByUrl("404")
    }
      this.route.params.subscribe(params => {
        this.id = +params['id']; // (+) converts string 'id' to a number
      });

      this.loading = true;
      this.usersServices.getItem(this.id)
        .subscribe(item => {
          this.item = item;
          this.loading = false;
          this.initUserLockButton();
      },
      error => {
          this.error = error;
          this.loading = false;
      });
    }


    initUserLockButton(){
      if(this.item.isBlocked == 0){
        this.captionLock = "Заблокувати";
        this.iconLock = ICON_LOCK;
      }
      if(this.item.isBlocked == 1){
        this.captionLock = "Розблокувати";
        this.iconLock = ICON_UNLOCK;
      }
    }

    goBack(){
      this.location.back();
    }



    update(){
      this.iconSave = ICON_SPINNER;
      this.usersServices.updateItem(this.item)
        .subscribe(res => {
          this.saving = false;
          notify("Дані збережено", "success", 600);
          this.goBack();
        },
        error => {
          this.error = error;

        });
    }

    openChangePassword(){
      this.passwordErrors = [];
      this.popupVisible = true;
      this.passwordValue1 = "";
      this.passwordValue2 = "";
    }

    cancelGenerateKeyPopup(){
      this.popupVisibleGenerateKey = false;
    }

    openGenerateKey(){
      this.popupVisibleGenerateKey = true;
    }

    applyChangePassword(){
      this.passwordErrors = [];
      if(this.passwordValue1 == undefined ){
        this.passwordErrors.push("Необхідно ввести пароль");
        return;
      }
      if(this.passwordValue1.length === 0){
        this.passwordErrors.push("Необхідно ввести пароль");
        return;
      }
      if(this.passwordValue1 != this.passwordValue2){
        this.passwordErrors.push("Введені паролі не збігаються");
        return;
      }

      this.iconChangePassword = ICON_SPINNER;
      this.usersServices.checkPassword(this.passwordValue1)
        .subscribe(res => {
         this.passwordErrors = res;
         if(this.passwordErrors.length == 0){
           let userChange = new ApiUserChange();
           userChange.id = this.id;
           userChange.password = this.passwordValue1;
           this.usersServices.changePassword(userChange)
            .subscribe(res => {
              notify("Пароль змінений", "success", 600);
              this.popupVisible = false;
              this.iconChangePassword = ICON_KEY;
            },
            error => {
              this.error = error;
              this.iconChangePassword = ICON_KEY;
            });
         } else {
          this.iconChangePassword = ICON_KEY;
         }
      },
      error => {
        this.error = error;
        this.iconChangePassword = ICON_KEY;
        //console.log(this.error);
      });
    }

    cancelChangePassword(){
      this.popupVisible = false;
    }

    generateKey(){


      this.iconKey = ICON_SPINNER;
      this.executeEmitRefreshToken(this.item.guid);

    }

    executeEmitRefreshToken(userGuid: string){
      let emit = new EmitRefreshToken();
      emit.userGuid = userGuid;
      this.authService.emitRefreshToken(emit)
      .subscribe(res => {
        this.iconKey = ICON_KEY;
        this.emitRefreshToken = res.refreshToken;
      },
      error => {
        this.error = error;

      });


    }

    lockUser(){
      let userChange = new ApiUserChange();
      userChange.id = this.id;
      if(this.item.isBlocked == 0){
        userChange.isBlocked = 1;
      }
      else {
        userChange.isBlocked = 0;
      }

      this.iconLock = ICON_SPINNER;
      this.usersServices.changeBlock(userChange)
      .subscribe(res => {
        if(this.item.isBlocked == 1){
          notify("Користувач розблокований", "success", 600);
          this.item.isBlocked = 0;
          this.initUserLockButton();
        } else if(this.item.isBlocked == 0){
          notify("Користувач заблокований", "success", 600);
          this.item.isBlocked = 1;
          this.initUserLockButton();
        }

      },
      error => {
        this.error = error;

      });
    }

    checkUserApi(typeId: number) {
      if(typeId == 200 || typeId == 210 || typeId == 300 || typeId == 500 || typeId == 1000) {
        return true;
      }
      else { return false; }
    }

  }
