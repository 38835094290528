export class VmClientTransCounter {
    transId: number;
    dat: Date;
    checknum: number;
    cardId: number;
    visibleNumber: string;
    stationId: number;
    stationName: string;
    stationAddress: string;
    stationCode: number;
    transTypeId: number;
    counterId: number;
    counterName: string;
    promoId: number;
    promoName: string;
    amount: number;
    typeId: number;
}