import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Counters, ValueId } from '../../_models';
import { CountersService, AuthenticationService, ClientsService } from '../../_services';

const ICON_SPINNER: string = "fa fa-lg fa-spinner fa-spin";
const ICON_FLOPPY: string = "fa fa-lg fa-floppy-o";
import notify from 'devextreme/ui/notify';
import { CountersManualDocs, CountersManualDocsLines } from '../../_models/counters-manual-docs';
import { CountersManualDocsService } from '../../_services/counters-manual-docs.service';
import DataSource from "devextreme/data/data_source";
import { DxDataGridComponent } from 'devextreme-angular';
import { Globals } from '../../globals';
import { Router } from '@angular/router';
import { isUndefined } from 'util';

@Component({
  templateUrl: 'counters-manual-docs-edit.component.html',
  styleUrls: ['./counters-manual-docs-edit.css']
})
export class CountersManualDocsEditComponent implements OnInit, AfterViewInit {

  loading = false;
  saving = false;
  error = '';
  caption: string = "";
  private id;
  item: CountersManualDocs;
  iconSave: string = ICON_FLOPPY;

  isView: boolean = false;
  popupClientsVisible: boolean = false;

  isPopupFindCard: boolean = false;
  visibleNumber: string = "";
  notFoundCards: string[] = [];

  counters: ValueId[] = [];
  operationTypes: ValueId[] = [];

  clientsDataSource: DataSource;

  genderList: ValueId[];
  maritalStatusList: ValueId[];

  @ViewChild('fileImportInput') fileImportInput: any;
  @ViewChild("clientsDataGrid") clientsDataGrid: DxDataGridComponent;

  public constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private location: Location,
    private manualDocsService: CountersManualDocsService,
    private countersService: CountersService,
    private authenticationService: AuthenticationService,
    private clientsService: ClientsService,
    private globals: Globals,
    private router: Router
  ) {

  }

  ngAfterViewInit(): void {

  }
  ngOnInit(): void {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));

        var allowed = [1, 40];

        if(!allowed.includes(currentUser.UserType)){
            this.router.navigateByUrl("404")
        }

        this.genderList = this.clientsService.getGenders();
        this.maritalStatusList = this.clientsService.getMaritalStatuses();

        let clientsODataUrl = this.globals.serverName + "/odata/Clients";
        this.clientsDataSource = new DataSource({
            store: {
                type: "odata",
                url: clientsODataUrl,
                key: "Id",
                version: 4,
                beforeSend: (e) => {
                    //console.log("Before send started");
                    this.initODataHeader(e);
                    //console.log("Before send ended");
                }
            },
            expand: ["Area","City","VehicleBrand","VehicleType"]
            ,
            filter: ["IsDeleted", "=", +"0"]
        });

    this.countersService.getAll()
      .subscribe(items => {
        items.forEach(element => {
          let addCounter = new ValueId();
          addCounter.id = element.id;
          addCounter.value = element.name;
          this.counters.push(addCounter);
        });
      },
        error => {
          this.error = error;
          this.loading = false;
        });

    this.operationTypes = this.manualDocsService.getOperationTypes();

    this.route.params.subscribe(params => {
      this.id = +params['id']; // (+) converts string 'id' to a number
    });

    if (Number.isNaN(this.id)) {
      this.caption = "Додавання ручної зміни лічильника";
      this.titleService.setTitle(this.caption);
      this.item = new CountersManualDocs();
      this.item.id = 0;
      this.item.lines = [];
    } else {
      this.isView = true;
      this.loading = true;
      this.caption = "Перегляд ручної зміни лічильника";
      this.titleService.setTitle(this.caption);

      this.manualDocsService.getItem(this.id)
        .subscribe(item => {
          this.item = item;
          this.loading = false;
        },
          error => {
            this.error = error;
            this.loading = false;
          });

    }

  }

  initODataHeader(e) {
    //Делаем вызов псевдо метода для обновления AccessToken если он просрочен

    this.authenticationService.checkAuthAjax();
    let authToken = 'Bearer ' + this.authenticationService.getAccessToken();
    console.log("Before send use: " + authToken);
    e.headers = {
      "Content-Type": "application/json",
      "Authorization": authToken
    }
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'fa fa-filter',
        onClick: this.refreshDataGrid.bind(this),
        hint: 'Очистити фільтри'
      }
    });
  }

  refreshDataGrid() {
    this.clientsDataGrid.instance.clearFilter();
  }


  goBack() {
    this.location.back();
  }


  update() {
    this.iconSave = ICON_SPINNER;

    if(this.item.operationTypeId != 4 && !this.item.val) {
      notify("Поле 'Значення' не заповнено", "error", 600);
      this.iconSave = ICON_FLOPPY;
      return;
    }

    this.manualDocsService.updateItem(this.item)
      .subscribe(res => {
        this.saving = false;
        notify("Дані збережено", "success", 600);
        this.goBack();
      },
        error => {
          this.error = error;
          this.iconSave = ICON_FLOPPY;
        });
  }

  onClientAdd() {
    this.popupClientsVisible = true;
  }

  chooseClient(value: number) {
    this.clientsService.getItem(value)
      .subscribe(item => {
        //console.log("chooseClient : " + item.name);
        let index = this.item.lines.findIndex(x => x.clientId == item.id);
        if (index >= 0) {
          notify("Клієнт вже є в списку", "error", 600);
          return;
        }

        let line = new CountersManualDocsLines();
        line.client = item;
        line.clientId = item.id;
        this.item.lines.push(line);
        notify("Клієнт доданий в документ", "success", 600);
      },
        error => {
          notify("Помилка при підборі клієнта", "error", 600);
        });
    //console.log("chooseClient : " + value);
  }

  deleteClient(data) {
    //console.log("delete client id: " + data.value);
    let index = this.item.lines.findIndex(x => x.clientId == data.value);
    if (index >= 0) {
      //console.log("delete client index: " + index);
      this.item.lines.splice(index, 1);
    }
    //console.log("delete client id: " + JSON.stringify(data.data));
  }

  fileChangeListener($event) {
    var text = [];
    var files = $event.target.files;
    var input = $event.target;
    var reader = new FileReader();
    reader.readAsText(input.files[0]);

    reader.onload = (data) => {
      let csvData = reader.result;
      let csvRecordsArray = csvData.split(/\r\n|\n/);
      let visibleNumbers = [];

      //console.log('records', csvRecordsArray);

      for (let i = 0; i < csvRecordsArray.length; i++) {
        let data = csvRecordsArray[i].split(",");
        if (data.length > 1) {
          return;
        }

        //console.log("length " + data[0].length);
        if (data[0].length < 5) continue;
        visibleNumbers.push(data[0]);
      }
      //console.log('numbers ', JSON.stringify(numbers));
      this.fileImportInput.nativeElement.value = "";

      var foundCards = [];
      this.notFoundCards = [];
      this.clientsService.getByVisibleNumbers(visibleNumbers)
        .subscribe(res => {
          res.forEach(findClient => {
            let index = this.item.lines.findIndex(x => x.clientId == findClient.id);
            if (index < 0) {
              let line = new CountersManualDocsLines();
              line.client = findClient;;
              line.clientId = findClient.id;
              findClient.cards.forEach(element => {
                if (visibleNumbers.indexOf(element.visibleNumber) != -1) {
                  line.visibleNumber = element.visibleNumber;
                  foundCards.push(element.visibleNumber);
                }
              });
              this.item.lines.push(line);
            }
          });

          visibleNumbers.forEach(element => {
            if (foundCards.indexOf(element) == -1) {
              this.notFoundCards.push(element);
            }
          });
          console.log(this.notFoundCards);

          //console.log("result " + JSON.stringify(res));
        },
          error => {
            this.error = error;

          });
    }

    reader.onerror = function () {
      alert('Unable to read ' + input.files[0]);
    };




  }

  onClientAddByCard() {
    this.isPopupFindCard = true;
    this.visibleNumber = "";
  }

  addByCardNumber() {
    if (isUndefined(this.visibleNumber)) {
      return;
    }

    this.visibleNumber = this.visibleNumber.trim().toUpperCase();
    if (this.visibleNumber == "") {
      return;
    }
    var visibleNumbers = [this.visibleNumber];
    this.clientsService.getByVisibleNumbers(visibleNumbers)
        .subscribe(res => {

          if (res.length == 0) {
            notify("Картку з таким номером не знайдено!", "error" ,1500);
          } else {
          res.forEach(findClient => {
            let index = this.item.lines.findIndex(x => x.clientId == findClient.id);
            if (index < 0) {
              let line = new CountersManualDocsLines();
              line.client = findClient;;
              line.clientId = findClient.id;
              line.visibleNumber = this.visibleNumber;
              this.item.lines.push(line);
            } else {
              notify("Такий клієнт вже в списку", "info" ,1500);
            }
          });
          this.isPopupFindCard = false;
          this.visibleNumber = "";
        }
        },
          error => {
            this.error = error;

          });

  }




}
