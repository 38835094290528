export class WalletSellsFuels {
    createdUTC: Date;
    localDate: Date;
    clientId: number;
    amount: number;
    documentId: number;
    fuelId: number;
    fuelName: string;
    fullFuelName: string;
    id: number;
    price: number;
    status: number;
    summa: number;
    discount: number;
    isExistRefund: boolean;
    paymentSystem: number;
    pmtId: number;
}