import { NgModule }       from '@angular/core';
import { CommonModule }       from '@angular/common';
import { FormsModule }    from '@angular/forms';
import { TransactionsDeductionZSUComponent } from './transactions-deduction-zsu.component';
import {DxButtonModule, DxTextBoxModule, DxPopupModule, DxDataGridModule, DxTabsModule, DevExtremeModule} from 'devextreme-angular';
import {TransactionsDeductionZSURoutingModule} from './transactions-deduction-zsu-routing.module';

@NgModule({
  imports: [
    TransactionsDeductionZSURoutingModule,
    CommonModule,
    FormsModule,
    DxButtonModule,
    DxTextBoxModule,
    DxPopupModule,
    DxDataGridModule,
    DxTabsModule,
    DevExtremeModule
  ],
  declarations: [
    TransactionsDeductionZSUComponent
  ]
})
export class TransactionsDeductionZSUModule {

}
