import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Globals } from '../globals';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Headers, RequestOptions } from '@angular/http';
import { ConfigSystem } from '../_models/config-system';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({ providedIn: 'root' })
export class ConfigService {
  constructor(private http: HttpClient, private globals: Globals) { }

   getConfig(): Observable<ConfigSystem>{
     return this.http
       .get<ConfigSystem>(this.globals.getApiString() + '/configuration',
         httpOptions);
   }
}
