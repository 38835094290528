import { PromoPos } from "./promo-pos";
import { PromoPeriod } from "./promo-period";
import { PromoSegment } from "./promo-segment";
import { PromoSegmentCondition } from "./promo-segment-condition";
import { PromoCondition } from "./promo-condition";
import { PromotionsListComponent } from "src/app/_components/promotions/promotions-list.component";
import { PromoLimit } from "./promo-limit";
import { PromoCoordination } from "./promo-coordination";
import { PromoSchema } from "./promo-schema";
import { PromoOtherActions } from "./promo-other-actions";

export class PromoData {
    segmentsConditions: PromoSegmentCondition;
    useAllPoses: boolean;
    poses: PromoPos[];
    useAllPeriods: boolean;
    periods: PromoPeriod[];
    conditions: PromoCondition;
    limits: PromoLimit[];
    coordination: PromoCoordination;
    schema: PromoSchema;
    otherActions: PromoOtherActions;

    constructor(){
        this.poses = [];
        this.periods = [];
        this.limits = [];
        this.segmentsConditions = new PromoSegmentCondition();
        this.conditions = new PromoCondition();
        this.coordination = new PromoCoordination();
        this.schema = new PromoSchema();
        this.otherActions = new PromoOtherActions();
    }
}
