import { Component } from '@angular/core';
import { AuthenticationService } from '../../_services/index';

@Component({
  selector: 'navbar',
  templateUrl: 'navbar.component.html'
})

export class NavbarComponent  {
  constructor(
        private authenticationService: AuthenticationService) {   }
  
  ngGetToken(): string{
    return this.authenticationService.token;
  }

  getUserName(): string{
    return this.authenticationService.getUserName();
  }

  getUserType(): string{
    return this.authenticationService.getUserType();
  }

  onLoggedout(){
    this.authenticationService.logout();
    location.reload(true);
  }
}
