export class FcmSchedule {
    deliveryAt: string;
    deliveryAtLocal: Date;
    finishDeliveryAt: string;
    finishDeliveryAtLocal: Date;
    scheduleType: number;
    completed: boolean;
    lastFcmScheduleStatusAndroid: number;
    lastFcmScheduleStatusAndroidUpdateDateTime: Date;
    lastFcmScheduleStatusAndroidUpdateDateTimeLocal: Date;
    lastFcmScheduleStatusAndroidUpdateDateTimeLocalView: string;
    lastFcmScheduleStatusAndroidString: string;
    lastFcmScheduleStatusIphone: number;
    lastFcmScheduleStatusIphoneUpdateDateTime: Date;
    lastFcmScheduleStatusIphoneUpdateDateTimeLocal: Date;
    lastFcmScheduleStatusIphoneUpdateDateTimeLocalView: string;
    lastFcmScheduleStatusIphoneString: string;
    lastFcmScheduleStatusHuawei: number;
    lastFcmScheduleStatusHuaweiUpdateDateTime: Date;
    lastFcmScheduleStatusHuaweiUpdateDateTimeLocal: Date;
    lastFcmScheduleStatusHuaweiUpdateDateTimeLocalView: string;
    lastFcmScheduleStatusHuaweiString: string;
}

export class PersonDelivery {
    clientId: number;
    templateId: number;
    surname: string;
    name: string;
    mName: string;
    phoneConfirmed: string;
}

export class Pushes {
    id: number;
    type: number;
    title: string;
    header: string;
    content: string;
    footer: string;
    image: string;
    link: string;
    addressingType: number;
    topic: string;
    fcmSchedule: FcmSchedule;
    createdUtc: Date;
    createdLocal: Date;
    editedUtc: Date;
    editedLocal: Date;    
    author: string;
    editor: string;
    deleted: boolean;
    isActive: boolean;
    personDelivery: PersonDelivery[];
    density: number;
    status: number;
    pushType: string;
    pushId: number;
    authorizedOnly: boolean;
}

export interface MessageTemplate {
    id: number;
    type: number;
    title: string;
    header: string | null;
    content: string;
    footer: string | null;
    image: string | null;
    link: string | null;
    addressingType: number;
    topic: string | null;
    fcmScheduleId: number;
    fcmSchedule: FcmSchedule;
    createdUtc: string | null;
    editedUtc: string | null;
    author: string | null;
    editor: string | null;
    deleted: boolean;
    personDelivery: PersonDelivery[];
}

export class PushTaskProgress {
    id: number;
    extId: string;
    projectId: string;
    sourceType?: any;
    type?: any;
    name: string;
    status: number;
    addressingType: number;
    recipients: number;
    density: number;
    startDate: Date;
    finishDate: Date;
    editDateUtc: Date;
    editDateLocal: string;
    successful: number;
    error: number;
    executed: number;
    resultFile: PushTaskResultFile;
}

export class PushTaskResultFile {
    id: number;
    guId: string;
    name: string;
    size: number;
    contentType: string;
    editDateUtc: Date;
    pushTask?: any;
    pushTaskId: number;
}