import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {GiftsTransaction } from '../../_models';
import { TransactionsGiftsService } from '../../_services';
import ArrayStore from "devextreme/data/array_store";
import { Router } from '@angular/router';
import notify from 'devextreme/ui/notify';
import 'devextreme/data/odata/store';
import {DxDataGridComponent} from 'devextreme-angular';
import {DatePipe} from '@angular/common';

@Component({
  templateUrl: 'transactions-gifts.component.html'
})
export class TransactionsGiftsComponent implements OnInit, AfterViewInit {
  @ViewChild("transGiftsDataGrid") dataTransGrid: DxDataGridComponent;

  clientId: number;
  error = '';

  dateFrom: Date;
  dateTo: Date;

  dsTransGifts: GiftsTransaction[];
  caption = 'Розіграш подарунків';

  public constructor(
    private titleService: Title,
    private transactionsGiftsService: TransactionsGiftsService,
    private router: Router,
    private datePipe: DatePipe
  )
  {
    this.titleService.setTitle(this.caption);
  }

  ngAfterViewInit(): void {

  }

  ngOnInit(): void {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));

    var allowed = [1, 10, 40, 400];

    if(!allowed.includes(currentUser.UserType)){
      this.router.navigateByUrl("404")
    }

    this.dateFrom = new Date();
    this.dateTo = new Date();
    this.dateFrom = this.add_days(this.dateFrom, -3);
  }

  add_months(dt, n) {
    return new Date(dt.setMonth(dt.getMonth() + n));
  }

  add_days(dt, n) {
    return new Date(dt.setDate(dt.getDate() + n));
  }

  onToolbarPreparingTrans(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',

        options: {
          icon: 'fa fa-filter',
          onClick: this.refreshDataTransGrid.bind(this),
          hint: 'Очистити фільтри'
        }
    });
  }

  onToolbarPreparingTransFuels(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',

        options: {
          icon: 'fa fa-filter',
          onClick: this.refreshDataTransGrid.bind(this),
          hint: 'Очистити фільтри'
        }
    });
  }

  refreshDataTransGrid() {
    if(this.dsTransGifts) {
      this.dataTransGrid.instance.clearFilter();
    }
  }

  onContentReady(e) {
    if(e.element.id === 'transGiftsDataGrid' && !this.dsTransGifts) {      
      this.getTransGifts();
    }
    // else if(e.element.id === 'transWalletFuelsDataGridContainer' && !this.dsTransFuelsLines) {
    //   this.getTransFuels();
    // }
    // // else if(e.element.id === 'transWalletClientMoveRestPivotGridContainer' && !this.dsTransClientMoveRest) {
    // //   this.getTransMoveRest();
    // // }
    // else if(e.element.id === 'transWalletClientMoveRestDataGridContainer' && !this.dsTransClientMoveRest) {
    //   this.getTransMoveRest();
    // }

  }

  onInitialized(e) {
    
  }

  applyTransGifts() {
    this.getTransGifts();
      // if(this.isShowTrans) {
      //   this.getTrans();
      // }
      // else if(this.isShowFuels){
      //   this.getTransFuels();
      // }
      // else if(this.isShowMoveRest) {
      //   this.getTransMoveRest();
        
      // }
  }

  getTransGifts() {

    if(!this.dateFrom) {
      notify("Введіть дату З", "error", 1000);
      return;
    }

    if(!this.dateTo) {
      notify("Введіть дату ПО", "error", 1000);
      return;
    }

    this.dataTransGrid.instance.beginCustomLoading('');

    this.transactionsGiftsService.getTransGifts(this.datePipe.transform(this.dateFrom, 'yyyy-MM-dd'), this.datePipe.transform(this.dateTo, 'yyyy-MM-dd'))
      .subscribe(item => {
          this.dsTransGifts = item;
          this.dataTransGrid.instance.endCustomLoading();
        },
        error => {
          this.error = error;
          this.dataTransGrid.instance.endCustomLoading();
          notify(!error.error ? 'Під час виконання запиту сталаса помилка' : error.error, "error", 1000);
        });

  }
}
