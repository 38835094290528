import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Promotions, Tab, ValueId, ValueName, Segments, Stations, Goods, GoodsGroups, Counters, NumberValueId, SegmentCondition, PromotionsWholesaleDocs, ValueIdStr, TriggerMessageTemplate } from '../../_models';
import { PromotionsService, SegmentsService, StationsService, GoodsService, GoodsGroupsService, CountersService, PromotionsWholesaleDocsService, PushesTriggersService } from '../../_services';

const ICON_SPINNER: string = "fa fa-lg fa-spinner fa-spin";
const ICON_FLOPPY: string = "fa fa-lg fa-floppy-o";
import notify from 'devextreme/ui/notify';
import { PromoData } from 'src/app/_models/promo/promo-data';

import DataSource from "devextreme/data/data_source";
import ArrayStore from "devextreme/data/array_store";
import { VmChoosePos, PromoPos } from 'src/app/_models/promo/promo-pos';
import { forEach } from '@angular/router/src/utils/collection';
import { element } from '@angular/core/src/render3/instructions';
import { DxPopupComponent } from 'devextreme-angular';
import { JsonHubProtocol } from '@aspnet/signalr';
import { PromoPeriod } from 'src/app/_models/promo/promo-period';
import { PromoSegmentCondition } from 'src/app/_models/promo/promo-segment-condition';
import { PromoRequireGoods } from 'src/app/_models/promo/promo-require-goods';
import { PromoCondition } from 'src/app/_models/promo/promo-condition';
import { PromoConditionOther } from 'src/app/_models/promo/promo-condition-other';
import { PromoLimit } from 'src/app/_models/promo/promo-limit';
import { PromoSchema } from 'src/app/_models/promo/promo-schema';
import { PromoSchemaRange } from 'src/app/_models/promo/promo-schema-range';
import { PromoSchemaProduct } from 'src/app/_models/promo/promo-schema-product';
import { PromoExceptGoods } from 'src/app/_models/promo/promo-except-goods';
import { PromoExceptGroups } from 'src/app/_models/promo/promo-except-groups'
import { Router } from '@angular/router';
import { PromoSegment } from 'src/app/_models/promo/promo-segment';
import { PromoOtherActions } from 'src/app/_models/promo/promo-other-actions';
import { PromoOtherAfterExec } from 'src/app/_models/promo/promo-other-after-exec';

@Component({
  templateUrl: 'promotions-edit.component.html',
  styleUrls: ['./promotions-edit.css']
})

export class PromotionsEditComponent implements OnInit, AfterViewInit {
  @ViewChild('fileImportInput1') fileImportInput1: any;
  @ViewChild('fileImportInput2') fileImportInput2: any;
  @ViewChild('fileImportInput3') fileImportInput3: any;
  @ViewChild('fileImportInput4') fileImportInput4: any;


  loading = false;
  saving = false;
  error = '';
  caption: string = "";
  namePromo: string;
  private id;
  item: Promotions;
  iconSave: string = ICON_FLOPPY;
  isNewItem: boolean = false;

  promoTypes: ValueId[];
  promoStatuses: ValueId[];

  tabs: Tab[];
  segmentsTabs: Tab[];
  conditionTabs: Tab[];
  coordinationTabs: Tab[];

  isShowMainTab: boolean = false;
  isShowSegmentTab: boolean = false;
  isShowPosTab: boolean = false;
  isShowPeriodTab: boolean = false;
  isShowConditionTab: boolean = false;
  isShowSchemaTab: boolean = false;
  isShowLimitTab: boolean = false;
  isShowCoordinationTab: boolean = false;
  isShowOtherTab : boolean = false;
  //Segments tabs
  isShowSegmentRequireTab: boolean = true;
  isShowSegmentExceptTab: boolean = false;
  //Condition tabs
  isShowConditionRequireTab: boolean = true;
  isShowConditionExceptTab: boolean = false;
  isShowConditionOtherTab: boolean = false;
  //Coordination tabs
  isShowCoordinationOrderTab: boolean = true;
  isShowCoordinationBlockTab: boolean = false;
  isShowCoordinationBlockedTab: boolean = false;
  isShowCoordinationApplyWithTab: boolean = false;

  isShowSchemaPanelFixedReward: boolean = false;
  isShowSchemaPanelRangeCondition: boolean = false;
  isShowSchemaPanelProducts: boolean = false;
  isShowSchemaPanelWholasaleDiscounts: boolean = false;
  isShowSchemaPanelExceptProducts: boolean = false;
  isShowSchemaPanelExceptGroups: boolean = false;
  isShowSchemaPanelProductsReward: boolean = false;
  isShowSchemaPanelProductsRanges: boolean = false;
  schemaPanelProductsRewardCaption: string;


  popupSegmentsVisible: boolean = false;
  popupStationsVisible: boolean = false;
  popupGoodsVisible: boolean = false;
  popupGroupsVisible: boolean = false;
  popupPromotionsVisible: boolean = false;
  popupSchemaVisible: boolean = false;
  popupSchemaProductVisible: boolean = false;
  popupProductLimitVisible: boolean = false;
  popupDocDetailedVisible: boolean = false;

  segments: Segments[] = [];
  stations: Stations[] = [];
  goods: Goods[] = [];
  groups: GoodsGroups[] = [];
  counters: Counters[] = [];
  promotionsWholesaleDocs : PromotionsWholesaleDocs[] = [];
  promotionsWholesaleDocsDet : PromotionsWholesaleDocs;
  promotions: Promotions[] = [];
  triggersPushes: TriggerMessageTemplate[] = [];

  segmentsDataSource: DataSource;
  goodsDataSource: DataSource;
  groupsDataSource: DataSource;
  promoWholesaleDocDetDataSource: DataSource;
  choosePoses: VmChoosePos[] = [];
  lastConditionSegmentsTypeId: number; //1 - Require, 2 - except
  lastConditionGoodsTypeId: number; //1 - Require, 2 - except
  lastSchemaGoodsTypeId: number; //1- Товары с кол-вом, 2 - исключенные товары
  promotionsDataSource: DataSource;
  lastCoordinationTabId: number;
  promoOrderDataSource: DataSource;
  //promoPoses: PromoPos[] = [];

  timePeriods: ValueId[] = [];
  repeatTypes: ValueId[] = [];
  includeTypes: ValueId[] = []; //любой или все
  otherConditionsTypes: ValueId[] = [];
  otherConditionsPeriods: ValueId[] = [];
  otherConditionsPeriodsCounters: ValueId[] = [];
  otherConditionsOperators: ValueId[] = [];
  otherConditionsStringOperators: ValueIdStr[] = [];
  otherConditionsPaytypes: ValueId[] = [];

  //Schema
  schemaAwardTypes: ValueId[] = [];
  schemaAwardApplies: ValueId[] = [];
  schemaAwardCalculationsFull: ValueId[] = [];
  schemaAwardCalculations: ValueId[] = [];
  schemaAwardProductConditions: ValueId[] = [];
  schemaAwardConditions: ValueId[] = [];
  schemaTempEdit: PromoSchema;
  schemaRangeCriteriaTypes: ValueId[] = [];
  schemaRangeCriteriaPeriods: ValueId[] = [];

  countersDropdownList: ValueId[] = [];
  triggerPushesDropdownList: ValueId[] = [];
  promotionsWholesaleDocsDropdownList: ValueId[] = [];

  limitTypes: ValueId[] = [];
  limitApplyTypes: ValueId[] = [];
  limitPeriods: ValueId[] = [];

  behaviorTypes: ValueId[] = [];

  otherAfterExecTypes: ValueId[] = [];
  otherAfterExecBlockTypes: ValueId[] = [];
  otherAfterExecCallbackTypes: ValueId[] = [];
  otherAfterExecGiftsTypes: ValueId[] = [];
  otherAfterExecCountersOperTypes: ValueId[] = [];

  currentEditLimit: PromoLimit;

  isLoadPromotionNames: boolean = false;

  public constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private location: Location,
    private dataService: PromotionsService,
    private segmentsService: SegmentsService,
    private stationsService: StationsService,
    private goodsService: GoodsService,
    private goodsGroupsService: GoodsGroupsService,
    private countersService: CountersService,
    private promotionsWholesaleService: PromotionsWholesaleDocsService,
    private pushesTriggersService: PushesTriggersService,
    private router: Router
  ) {

  }

  ngAfterViewInit(): void {

  }
  ngOnInit(): void {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    var allowed = [1];

        if(!allowed.includes(currentUser.UserType)){
            this.router.navigateByUrl("404")
        }
    this.route.params.subscribe(params => {
      this.id = +params['id']; // (+) converts string 'id' to a number
    });

    this.segmentsService.getAll()
      .subscribe(items => {
        this.segments = items;
        this.initSegmentsDataSource();
      },
        error => {
          this.error = error;
        });

    this.stationsService.getAll()
      .subscribe(items => {
        this.stations = items;
        this.initPosesDataSource();
      },
        error => {
          this.error = error;
        });

    this.countersService.getAll()
      .subscribe(items => {
        this.counters = items;
        this.countersDropdownList = [];
        this.counters.forEach(item => {
          let newItem = new ValueId();
          newItem.id = item.id;
          newItem.value = item.name;
          this.countersDropdownList.push(newItem);
        });
      },
        error => {
          this.error = error;
        });

        this.promotionsWholesaleService.getAllDocs()
        .subscribe(items => {
          this.promotionsWholesaleDocs = items;
          this.promotionsWholesaleDocsDropdownList = [];
          this.promotionsWholesaleDocs.forEach(item => {
            let newItem = new ValueId();
            newItem.id = item.id;
            newItem.value = item.docName;
            this.promotionsWholesaleDocsDropdownList.push(newItem);
          });
        },
          error => {
            this.error = error;
          });    

    this.goodsService.getAll()
      .subscribe(items => {
        this.goods = items;
        this.initGoodsDataSource();
      },
        error => {
          this.error = error;
        });

    this.goodsGroupsService.getAll()
        .subscribe(items => {
            this.groups = items;
            this.initGroupsDataSource();
        },
            error => {
                this.error = error;
            });        

    this.dataService.getAll()
      .subscribe(items => {
        this.promotions = items;
        this.initPromotionsDataSource();
        this.loadNames();
      },
        error => {
          this.error = error;
        });

      this.pushesTriggersService.getTemplatesOnlyActive()
        .subscribe(items => {
          this.triggersPushes = items;
          this.triggerPushesDropdownList = [];
          this.triggersPushes.forEach(item => {
            let newItem = new ValueId();
            newItem.id = item.id;
            newItem.value = item.name;
            this.triggerPushesDropdownList.push(newItem);
          });
        },
          error => {
            this.error = error;
          });    

    this.tabs = this.dataService.getTabs();
    this.segmentsTabs = this.dataService.getSegmentsTabs();
    this.conditionTabs = this.dataService.getConditionTabs();
    this.coordinationTabs = this.dataService.getCoordinationTabs();

    this.promoTypes = this.dataService.getPromoTypes();
    this.promoStatuses = this.dataService.getPromoStatuses();

    this.timePeriods = this.dataService.getTimePeriods();
    this.repeatTypes = this.dataService.getRepeatTypes();
    this.includeTypes = this.dataService.getIncludeTypes();
    this.otherConditionsTypes = this.dataService.getOtherConditionsTypes();
    this.otherConditionsPeriods = this.dataService.getOtherConditionsPeriods();
    this.otherConditionsPeriodsCounters = this.dataService.getOtherConditionsPeriodsCounters();
    this.otherConditionsOperators = this.dataService.getOtherConditionsOperators();
    this.otherConditionsStringOperators = this.dataService.getOtherConditionsStringOperators();
    this.otherConditionsPaytypes = this.dataService.getOtherConditionsPaytypes();
    this.limitTypes = this.dataService.getLimitTypes();
    this.limitApplyTypes = this.dataService.getLimitApplyTypes();
    this.limitPeriods = this.dataService.getLimitPeriods();
    this.behaviorTypes = this.dataService.getBehaviorTypes();
    
    //Schema
    this.schemaAwardTypes = this.dataService.getSchemaAwardTypes();
    this.schemaAwardApplies = this.dataService.getSchemaAwardApplies();
    this.schemaAwardCalculationsFull = this.dataService.getSchemaAwardCalculations();

    this.schemaAwardCalculations = JSON.parse(JSON.stringify(this.schemaAwardCalculationsFull));
    let i = this.schemaAwardCalculations.findIndex(x => x.id == 3);
    this.schemaAwardCalculations.splice(i, 1);
    let i2 = this.schemaAwardCalculations.findIndex(x => x.id == 4);
    this.schemaAwardCalculations.splice(i2, 1);

    this.schemaAwardProductConditions = this.dataService.getSchemaAwardProductConditions();
    this.schemaAwardConditions = this.dataService.getSchemaAwardConditions();
    this.schemaRangeCriteriaTypes = this.dataService.getSchemaRangeCriteriaTypes();
    this.schemaRangeCriteriaPeriods = this.dataService.getSchemaRangeCriteriaPeriods();   


    this.otherAfterExecTypes = this.dataService.getOtherAfterExecTypes();
    this.otherAfterExecBlockTypes = this.dataService.getOtherAfterExecBlockTypes();
    this.otherAfterExecCallbackTypes = this.dataService.getOtherAfterExecCallbackTypes();
    this.otherAfterExecGiftsTypes = this.dataService.getOtherAfterExecGiftsTypes();
    this.otherAfterExecCountersOperTypes = this.dataService.getOtherAfterExecCountersOperTypes();


    this.showTabId(0);

    if (Number.isNaN(this.id)) {
      this.caption = "Додавання акції";
      this.titleService.setTitle(this.caption);
      this.item = new Promotions();
      this.item.id = 0;

      this.item.data = new PromoData();
      this.item.data.useAllPoses = false;
      this.item.data.useAllPeriods = false;      
      this.item.data.periods = [];
      this.isNewItem = true;  
        
    } else {

      this.loading = true;
      this.caption = "Редагування акції";
      this.titleService.setTitle(this.caption);

      this.dataService.getItem(this.id)
        .subscribe(item => {
          this.item = item;
          this.namePromo = item.name;
          if (item.data == null) {
            this.item.data = new PromoData();
            this.item.data.useAllPoses = false;
            this.item.data.useAllPeriods = false;
            this.item.data.periods = [];
            this.item.data.poses = [];         
          }
          if (item.data.poses == null) {
            this.item.data.poses = [];
          }

          if (item.data.periods == null) {
            this.item.data.periods = [];
          }
          if (item.data.conditions == null) {
            this.item.data.conditions = new PromoCondition();
          }
          if (item.data.conditions.otherCondition == null) {
            item.data.conditions.otherCondition = [];
          }
          if(item.data.segmentsConditions == null) {
            item.data.segmentsConditions = new PromoSegmentCondition();
          }
          
          if(item.data.otherActions == null) {
            this.item.data.otherActions = new PromoOtherActions();
          }
          if(item.data.otherActions.afterExecution == null) {
            item.data.otherActions.afterExecution = [];
          }


          this.loadNames();
          this.schemaEditApply(false);              
          this.loading = false;
        },
          error => {
            this.error = error;
            this.loading = false;
          });

    }

  }

  loadNames() {
    this.loadPromotionsNames();
  }

  loadPromotionsNames() {
    if (this.isLoadPromotionNames) return;
    if (!this.item) return;
    if (!this.promotions) return;
    if (this.promotions.length == 0) return;
    if (this.item.data.coordination.applyWithPromos) {
      this.item.data.coordination.applyWithPromos.forEach(element => {
        element.name = this.promotions.find(x => x.id == element.id).name;
      });
    }

    if (this.item.data.coordination.blockPromos) {
      this.item.data.coordination.blockPromos.forEach(element => {
        element.name = this.promotions.find(x => x.id == element.id).name;
      });
    }

    if (this.item.data.coordination.blockedByPromos) {
      this.item.data.coordination.blockedByPromos.forEach(element => {
        element.name = this.promotions.find(x => x.id == element.id).name;
      });
    }
    this.isLoadPromotionNames = true;
  }

  showTabId(id: number) {
    this.isShowMainTab = id == 0;
    this.isShowSegmentTab = id == 1;
    this.isShowPosTab = id == 2;
    this.isShowPeriodTab = id == 3;
    this.isShowConditionTab = id == 4;
    this.isShowSchemaTab = id == 5;
    this.isShowLimitTab = id == 6;
    this.isShowCoordinationTab = id == 7;
    this.isShowOtherTab = id == 8;
  }

  selectTab(e) {
    this.showTabId(e.itemData.id);
  }

  showConditionTabId(id: number) {
    this.isShowConditionRequireTab = id == 0;
    this.isShowConditionExceptTab = id == 1;
    this.isShowConditionOtherTab = id == 2;
  }

  showSegmentTabId(id: number) {
    this.isShowSegmentRequireTab = id == 0;
    this.isShowSegmentExceptTab = id == 1;
  }

  selectSegmentTab(e) {
    this.showSegmentTabId(e.itemData.id);
  }

  selectConditionTab(e) {
    this.showConditionTabId(e.itemData.id);
  }

  showCoordinationTabId(id: number) {
    this.isShowCoordinationOrderTab = id == 0;
    this.isShowCoordinationBlockTab = id == 1;
    this.isShowCoordinationBlockedTab = id == 2;
    this.isShowCoordinationApplyWithTab = id == 3;
  }

  selectCoordinationTab(e) {
    this.showCoordinationTabId(e.itemData.id);
  }


  initSegmentsDataSource() {
    this.segmentsDataSource = new DataSource({
      store: new ArrayStore({
        key: "id",
        data: this.segments,
        // Other ArrayStore options go here
      }),
      filter: ["isDeleted", "=", "0"]
    });
  }

  initGoodsDataSource() {
    this.goodsDataSource = new DataSource({
      store: new ArrayStore({
        key: "id",
        data: this.goods,
        // Other ArrayStore options go here
      }),
      filter: ["isDeleted", "=", "0"]
    });
  }

  initGroupsDataSource() {
    this.groupsDataSource = new DataSource({
        store: new ArrayStore({
            key: "id",
            data: this.groups,
            // Other ArrayStore options go here
        }),
        filter: ["isDeleted", "=", "0"]
    });
}

  initPromotionsDataSource() {
    this.promotionsDataSource = new DataSource({
      store: new ArrayStore({
        key: "id",
        data: this.promotions,
        // Other ArrayStore options go here
      }),
      filter: ["isDeleted", "=", "0"]
    });

    this.promoOrderDataSource = new DataSource({
      store: new ArrayStore({
        key: "id",
        data: this.promotions,
        // Other ArrayStore options go here
      }),
      filter: ["isDeleted", "=", "0"],
      sort: "promoOrder"
    });
  }

  initPosesDataSource() {
    this.stations.forEach(station => {
      if (station.isDeleted != 0) {
        return;
      }

      let pos = new VmChoosePos();
      pos.id = station.id.toString();
      pos.stationId = station.id;
      pos.name = station.name;
      pos.address = station.address;
      pos.terminalId = 0;
      pos.stationName = station.name;
      this.choosePoses.push(pos);

      if (station.terminals != null) {
        station.terminals.forEach(terminal => {
          let term = new VmChoosePos();
          term.id = "T" + terminal.id.toString();
          term.stationId = station.id;
          term.name = terminal.name;
          term.terminalId = terminal.id;
          //term.address = station.address;
          term.stationName = station.name;
          term.terminalName = terminal.name;
          term.parentId = station.id.toString();
          this.choosePoses.push(term);
        });
      }
    });

  }


  goBack() {
    this.location.back();
  }

  //onSegmentAdd() {
  //  this.popupSegmentsVisible = true;
 // }

  onStationAdd() {

    this.popupStationsVisible = true;

  }

  onSegmentAdd(val) {
    this.lastConditionSegmentsTypeId = val;
    this.popupSegmentsVisible = true;
  }

  onCondGoodsAdd(val) {
    this.lastConditionGoodsTypeId = val;
    this.popupGoodsVisible = true;
  }

  onCoordinationPromoAdd(val) {
    //console.log(val.id);
    this.lastCoordinationTabId = val.id;
    this.popupPromotionsVisible = true;
  }

  onExceptGroupAdd() {
    this.popupGroupsVisible = true;
}

deleteExceptGroup(group) {
  let index = this.item.data.schema.exceptGroups.indexOf(group);
  this.item.data.schema.exceptGroups.splice(index, 1);
}

addExceptGroup(value) {
  let index = this.groups.findIndex(x => x.id == value);
  if (index < 0) {
      notify("Помилка при підборі групи", "error", 600);
      return;
  }
  let group = this.groups[index];

  if(!this.item.data.schema.exceptGroups) {
    this.item.data.schema.exceptGroups = [];
  }

  index = this.item.data.schema.exceptGroups.findIndex(x => x.id == value);
  if (index >= 0) {
      notify("Група '" + group.name + "' вже є в списку", "error", 600);
      return;
  }
  let exceptGroup = new PromoExceptGroups();
  exceptGroup.id = group.id;
  exceptGroup.extId = group.extId;
  exceptGroup.name = group.name;
  this.item.data.schema.exceptGroups.push(exceptGroup);
  notify("Група '" + group.name + "' успішно додана", "success", 600);
}

treeView_itemSelectionChanged(e) {
  if (e.component.getSelectedNodesKeys() != 0) {
      let groupId = e.component.getSelectedNodesKeys()[0];

      this.addExceptGroup(groupId);
  }
}

  onPeriodAdd() {
    let period = new PromoPeriod();
    period.allDay = false;
    period.allPeriod = false;

    period.onMondays = false;
    period.onTuesdays = false;
    period.onWednesdays = false;
    period.onThursdays = false;
    period.onFridays = false;
    period.onSaturdays = false;
    period.onSundays = false;

    this.item.data.periods.push(period);
  }

  onOtherCondAdd(value) {
    if (value == null) return;
    let otherCond = new PromoConditionOther();
    otherCond.conditionTypeId = value;
    this.item.data.conditions.otherCondition.push(otherCond);
    //console.log(value);
  }

  getOtherConditionName(typeId) {
    let i = this.otherConditionsTypes.findIndex(x => x.id == typeId);
    if (i >= 0) {
      return this.otherConditionsTypes[i].value;
    }
    return "";
  }

  onLimitAdd(value) {
    if (value == null) return;
    let limit = new PromoLimit();
    limit.limitTypeId = value;
    this.item.data.limits.push(limit);
    //console.log(value);
  }

  getLimitTypeName(limitTypeId) {
    let i = this.limitTypes.findIndex(x => x.id == limitTypeId);
    if (i >= 0) {
      return this.limitTypes[i].value;
    }
    return "";
  }

  deleteLimit(limit: PromoLimit) {
    let index = this.item.data.limits.indexOf(limit);
    this.item.data.limits.splice(index, 1);
  }

  deletePeriod(period) {
    //console.log(JSON.stringify(period));
    let index = this.item.data.periods.indexOf(period);
    this.item.data.periods.splice(index, 1);
  }

  deleteConditionOther(cond: PromoConditionOther) {
    let index = this.item.data.conditions.otherCondition.indexOf(cond);
    this.item.data.conditions.otherCondition.splice(index, 1);
  }

  isHiddenLimitInput(limitTypeId, inputId): boolean {
    switch (limitTypeId) {
      case 1: //Максимальная общая скидка
        switch (inputId) {
          case 1: return false; //Применить для
          case 2: return false; //Период
          case 3: return false; //Значение
          case 4: return true; //Список для товаров
        }
      case 2: //Максимальная немедленная скидка
        switch (inputId) {
          case 1: return true; //Применить для
          case 2: return true; //Период
          case 3: return false; //Значение
          case 4: return true; //Список для товаров
        }
      case 3: //Кол-во применений акции
        switch (inputId) {
          case 1: return false; //Применить для
          case 2: return false; //Период
          case 3: return false; //Значение
          case 4: return true; //Список для товаров
        }
      case 4: //Общая скидка на отдельные товары
        switch (inputId) {
          case 1: return false; //Применить для
          case 2: return false; //Период
          case 3: return true; //Значение
          case 4: return false; //Список для товаров
        }
      case 5: //Объем реализованной продукции
        switch (inputId) {
          case 1: return false; //Применить для
          case 2: return false; //Период
          case 3: return true; //Значение
          case 4: return false; //Список для товаров
        }

      default: return true;

    }
  }

  isHiddenConditionOtherInput(conditionTypeId, inputId): boolean {
    console.log(conditionTypeId, inputId);
    switch (conditionTypeId) {
      case 1: //Сумма корзины
        switch (inputId) {
          case 1: return false; //Все фильтры
          case 2: return true; //Период
          case 3: return true; //Счетчик
          case 4: return false; //Условие
          case 5: return false; //Значение
          case 6: return true; //Метод оплаты
          case 7: return true; //Условие текстовое
          case 8: return true; //Значение текстовое
        }
      case 2: //Посещение той же точки
        switch (inputId) {
          case 1: return false; //Все фильтры
          case 2: return false; //Период
          case 3: return true; //Счетчик
          case 4: return false; //Условие
          case 5: return false; //Значение
          case 6: return true; //Метод оплаты
          case 7: return true; //Условие текстовое
          case 8: return true; //Значение текстовое
        }
      case 3: //Счетчики
        switch (inputId) {
          case 1: return false; //Все фильтры
          case 2: return false; //Период
          case 3: return false; //Счетчик
          case 4: return false; //Условие
          case 5: return false; //Значение
          case 6: return true; //Метод оплаты
          case 7: return true; //Условие текстовое
          case 8: return true; //Значение текстовое
        }
      case 4: //День рождения
        switch (inputId) {
          case 1: return true; //Все фильтры
          case 2: return false; //Период
          case 3: return false; //Счетчик
          case 4: return false; //Условие
          case 5: return false; //Значение
          case 6: return true; //Метод оплаты
          case 7: return true; //Условие текстовое
          case 8: return true; //Значение текстовое
        }
      case 5: //Для новых карт
        switch (inputId) {
          case 1: return true; //Все фильтры
          case 2: return false; //Период
          case 3: return false; //Счетчик
          case 4: return false; //Условие
          case 5: return false; //Значение
          case 6: return true; //Метод оплаты
          case 7: return true; //Условие текстовое
          case 8: return true; //Значение текстовое
        }
      case 6: //Метод оплаты
        switch (inputId) {
          case 1: return false; //Все фильтры
          case 2: return true; //Период
          case 3: return true; //Счетчик
          case 4: return true; //Условие
          case 5: return true; //Значение
          case 6: return false; //Метод оплаты
          case 7: return true; //Условие текстовое
          case 8: return true; //Значение текстовое
        }
      case 7: // Общее кол-во товара в корзине
        switch (inputId) {
          case 1: return false; //Все фильтры
          case 2: return true; //Период
          case 3: return true; //Счетчик
          case 4: return false; //Условие
          case 5: return false; //Значение
          case 6: return true; //Метод оплаты
          case 7: return true; //Условие текстовое
          case 8: return true; //Значение текстовое
        }
      case 8: // Кол-во товара среди необходимых
        switch (inputId) {
          case 1: return false; //Все фильтры
          case 2: return true; //Период
          case 3: return true; //Счетчик
          case 4: return false; //Условие
          case 5: return false; //Значение
          case 6: return true; //Метод оплаты
          case 7: return true; //Условие текстовое
          case 8: return true; //Значение текстовое
        }
      case 9: // Внутр. номер картки
        switch (inputId) {
          case 1: return false; //Все фильтры
          case 2: return true; //Период
          case 3: return true; //Счетчик
          case 4: return true; //Условие
          case 5: return true; //Значение
          case 6: return true; //Метод оплаты
          case 7: return false; //Условие текстовое
          case 8: return false; //Значение текстовое
        }               

      default: return true;

    }

  }

  getOtherConditionsPeriods(conditionTypeId) {

    // счетчики
    if(conditionTypeId === 3) {
      return this.otherConditionsPeriodsCounters;
    }
    return this.otherConditionsPeriods;
  }

  choosePos(value: string) {
    ;
    let index = this.choosePoses.findIndex(x => x.id == value);
    if (index < 0) {
      notify("Помилка при підборі торгової точки", "error", 600);
    }
    let selectedPos = this.choosePoses[index];
    //console.log("add " + JSON.stringify(selectedPos))
    index = this.item.data.poses.findIndex(x => x.id == value);
    if (index >= 0) {
      notify("Обрана торгова точка вже є в списку", "error", 600);
      return;
    } else {
      let indexParent = this.item.data.poses.findIndex(x => x.id == selectedPos.parentId);
      if (indexParent >= 0) {
        notify("Обрана торгова точка вже є в списку", "error", 600);
        return;
      }
    }

    let promoPos = new PromoPos();
    promoPos.id = selectedPos.id;
    promoPos.stationName = selectedPos.stationName;
    promoPos.stationId = selectedPos.stationId;
    promoPos.stationAddress = selectedPos.address;
    promoPos.terminalName = selectedPos.terminalName;
    promoPos.terminalId = selectedPos.terminalId;
    this.item.data.poses.push(promoPos);
    notify("Торгова точка успішно додана", "success", 600);
  }

  chooseSegment(value: number) {

    let index = this.segments.findIndex(x => x.id == value);
    if (index < 0) {
      notify("Помилка при підборі сегменту", "error", 600);
    }
    let segment = this.segments[index];
    let segmentsArray = null;
    if (this.lastConditionSegmentsTypeId == 1) {
      segmentsArray = this.item.data.segmentsConditions.requireSegments;
    } else if (this.lastConditionSegmentsTypeId == 2) {
      segmentsArray = this.item.data.segmentsConditions.exceptSegments;
    }

    index = segmentsArray.findIndex(x => x.id == value);
    if (index >= 0) {
      notify("Сегмент '" + segment.name + "' вже є в списку", "error", 600);
      return;
    }
    let promoSegment = new PromoSegment();
    promoSegment.id = segment.id;
    promoSegment.name = segment.name;
    segmentsArray.push(promoSegment);
    notify("Сегмент '" + segment.name + "' успішно доданий", "success", 600);
  }

  chooseGoods(value: number) {
    if (this.lastConditionGoodsTypeId > 2) {

      this.chooseSchemaProduct(value);
      return;
    }
    let index = this.goods.findIndex(x => x.id == value);
    if (index < 0) {
      notify("Помилка при підборі товару", "error", 600);
    }
    let goods = this.goods[index];
    let goodsArray = null;
    if (this.lastConditionGoodsTypeId == 1) {
      goodsArray = this.item.data.conditions.requireGoods;
    } else if (this.lastConditionGoodsTypeId == 2) {
      goodsArray = this.item.data.conditions.exceptGoods;
    }

    index = goodsArray.findIndex(x => x.id == value);
    if (index >= 0) {
      notify("Товар '" + goods.name + "' вже є в списку", "error", 600);
      return;
    }
    let promoGoods = new PromoRequireGoods();
    promoGoods.id = goods.id;
    promoGoods.name = goods.name;
    promoGoods.amount = 1;
    goodsArray.push(promoGoods);
    notify("Товар '" + goods.name + "' успішно доданий", "success", 600);
  }

  choosePromotion(value: number) {
    let index = this.promotions.findIndex(x => x.id == value);
    if (index < 0) {
      notify("Помилка при підборі акції", "error", 600);
    }
    let promotion = this.promotions[index];
    let promoArray = null;
    //console.log("lastCoordinationTabId " + this.lastCoordinationTabId);
    if (this.lastCoordinationTabId == 1) {
      promoArray = this.item.data.coordination.blockPromos;
    } else if (this.lastCoordinationTabId == 2) {
      promoArray = this.item.data.coordination.blockedByPromos;
    } else if (this.lastCoordinationTabId == 3) {
      promoArray = this.item.data.coordination.applyWithPromos;
    }

    index = promoArray.findIndex(x => x.id == value);
    if (index >= 0) {
      notify("Акція '" + promotion.name + "' вже є в списку", "error", 600);
      return;
    }
    let promoValueName = new ValueName();
    promoValueName.id = promotion.id;
    promoValueName.name = promotion.name;
    promoArray.push(promoValueName);
    notify("Акція '" + promotion.name + "' успішно додана", "success", 600);
  }

  deleteCoordinationBlock(data) {
    let index = this.item.data.coordination.blockPromos.findIndex(x => x.id == data.value);
    if (index >= 0) {
      //console.log("delete client index: " + index);
      this.item.data.coordination.blockPromos.splice(index, 1);
    }
  }

  deleteCoordinationBlockedBy(data) {
    let index = this.item.data.coordination.blockedByPromos.findIndex(x => x.id == data.value);
    if (index >= 0) {
      //console.log("delete client index: " + index);
      this.item.data.coordination.blockedByPromos.splice(index, 1);
    }
  }

  deleteCoordinationApplyWith(data) {
    let index = this.item.data.coordination.applyWithPromos.findIndex(x => x.id == data.value);
    if (index >= 0) {
      //console.log("delete client index: " + index);
      this.item.data.coordination.applyWithPromos.splice(index, 1);
    }
  }

  deleteRequireSegments(data) {
    let index = this.item.data.segmentsConditions.requireSegments.findIndex(x => x.id == data.value);
    if (index >= 0) {
      //console.log("delete client index: " + index);
      this.item.data.segmentsConditions.requireSegments.splice(index, 1);
    }
  }

  deleteExceptSegments(data) {
    let index = this.item.data.segmentsConditions.exceptSegments.findIndex(x => x.id == data.value);
    if (index >= 0) {
      //console.log("delete client index: " + index);
      this.item.data.segmentsConditions.exceptSegments.splice(index, 1);
    }
  }

  deleteRequireGoods(data) {
    let index = this.item.data.conditions.requireGoods.findIndex(x => x.id == data.value);
    if (index >= 0) {
      //console.log("delete client index: " + index);
      this.item.data.conditions.requireGoods.splice(index, 1);
    }
  }

  deleteExceptGoods(data) {
    let index = this.item.data.conditions.exceptGoods.findIndex(x => x.id == data.value);
    if (index >= 0) {
      //console.log("delete client index: " + index);
      this.item.data.conditions.exceptGoods.splice(index, 1);
    }
  }

  deletePos(data) {
    let index = this.item.data.poses.findIndex(x => x.id == data.value);
    if (index >= 0) {
      //console.log("delete client index: " + index);
      this.item.data.poses.splice(index, 1);
    }
  }

  update() {
    //console.log(JSON.stringify(this.item));
    this.iconSave = ICON_SPINNER;
    this.dataService.updateItem(this.item)
      .subscribe(res => {
        this.saving = false;
        notify("Дані збережено", "success", 600);
        this.goBack();
      },
        error => {
          this.error = error;
          this.iconSave = ICON_FLOPPY;
        });
  }

  onSchemaEdit() {
    this.schemaTempEdit = new PromoSchema();

    this.popupSchemaVisible = true;
    //console.log(JSON.stringify(this.schemaTempEdit));
  }

  onChangeSchemaAward(dropDownId, event) {
    if (!this.popupSchemaVisible) return;
    if (dropDownId <= 5) {
      this.schemaTempEdit.conditionId = null;
    }
    if (dropDownId <= 4) {
      this.schemaTempEdit.productConditionId = null;
    }
    if (dropDownId <= 3) {
      this.schemaTempEdit.calculationId = null;
    }
    if (dropDownId <= 2) {
      this.schemaTempEdit.applyId = null;
    }
    if (dropDownId <= 1) {
      this.schemaTempEdit.counterId = null;
    }

  }

  isAwardComboHidden(comboId) {
    if (!this.popupSchemaVisible) return;
    if (comboId == 1) { //Тип награды
      return false;
    } else if (comboId == 2) { //Счетчик
      if (this.schemaTempEdit.typeId == 2) {
        return false;
      }
    } else if (comboId == 3) { //Вознаграждение для
      if (this.schemaTempEdit.typeId == 1 || this.schemaTempEdit.typeId == 3) {
        return false;
      } else if (this.schemaTempEdit.counterId >= 1) {
        return false;
      }
    } else if (comboId == 4) { //Расчет награды
      if (this.schemaTempEdit.applyId != null) {
        return false;
      }
    } else if (comboId == 5) { //Условие для товаров
      if (this.schemaTempEdit.calculationId != null && this.schemaTempEdit.calculationId != 4 && 
        this.schemaTempEdit.applyId == 2) {
        return false;
      }
    } else if (comboId == 6) { //Условие награды
      if (this.schemaTempEdit.productConditionId != null) {
        return false;
      }
      if (this.schemaTempEdit.calculationId != null &&
        this.schemaTempEdit.applyId != null &&
        this.schemaTempEdit.applyId != 2) {
        return false;
      }
    } else if (comboId == 7) { //Кнопка сохранения
      if (this.schemaTempEdit.conditionId != null) {
        return false;
      }
    }
    return true;
  }

  schemaEditApply(isNeedToReset: boolean = true) {
    if (isNeedToReset) {
      this.item.data.schema = this.schemaTempEdit;
    }

    this.popupSchemaVisible = false;

    this.isShowSchemaPanelFixedReward = false; //Отображаем панель с фиксированной наградой
    this.isShowSchemaPanelRangeCondition = false; //Отображаем панель c настройками диапазона
    this.isShowSchemaPanelProducts = false; //Отображаем панель со списком товаров
    this.isShowSchemaPanelWholasaleDiscounts = false; // отображаем панель с выбором документа оптовых скидок
    this.isShowSchemaPanelProductsReward = false;
    this.isShowSchemaPanelProductsRanges = false;
    this.isShowSchemaPanelExceptProducts = false;
    this.isShowSchemaPanelExceptGroups = false;


    //Отображаем панель с фиксированной наградой
    if (this.item.data.schema.conditionId == 2 &&
      this.item.data.schema.productConditionId != 1) {
      this.isShowSchemaPanelFixedReward = true;
    }

    if (this.item.data.schema.conditionId == 2 &&
      this.item.data.schema.productConditionId == 1) {
      this.isShowSchemaPanelProductsReward = true;
      this.schemaPanelProductsRewardCaption = "Нагорода";
    }

    if (this.item.data.schema.applyId == 1) { //Весь чек
      this.isShowSchemaPanelExceptProducts = true;
      this.isShowSchemaPanelExceptGroups = true;
    }

    if (this.item.data.schema.applyId == 3) { //Набор товаров
      this.schemaPanelProductsRewardCaption = "Кіл-ть";
      this.isShowSchemaPanelProductsReward = true;
    }

    //Отображаем панель c настройками диапазона
    if (this.item.data.schema.conditionId == 1) {
      this.isShowSchemaPanelRangeCondition = true;
    }

    //Отображаем панель со списком товаров
    if (this.item.data.schema.typeId !=0 && this.item.data.schema.applyId != 1 && this.item.data.schema.calculationId != 4) {
      this.isShowSchemaPanelProducts = true;
      if (this.item.data.schema.productConditionId == 1 &&
        this.item.data.schema.conditionId == 1) {
        this.isShowSchemaPanelProductsRanges = true;
      }
    }

    //Отображаем панель со выбором документа скидок
    if (this.item.data.schema.typeId == 1 && this.item.data.schema.applyId == 2 && this.item.data.schema.calculationId == 4) {
      this.isShowSchemaPanelWholasaleDiscounts = true;
    }
  }



  getArrayValue(array: ValueId[], id: number) {
    let index = array.findIndex(x => x.id == id);
    if (index >= 0) {
      return array[index].value;
    }
    else return "Не вибрано";
  }


  appendArrayTextItem(srcStr: string, appendStr: string): string {
    if (srcStr.length > 0) {
      srcStr = srcStr + ", ";
    }
    return srcStr + appendStr;
  }

  getSchemaConditionsText() {

    let result = "";
    if (this.item.data.schema.productConditionId != null) {
      result = this.appendArrayTextItem(result, this.getArrayValue(this.schemaAwardProductConditions, this.item.data.schema.productConditionId));
    }
    if (this.item.data.schema.calculationId != null) {
      result = this.appendArrayTextItem(result, this.getArrayValue(this.schemaAwardCalculationsFull, this.item.data.schema.calculationId));
    }
    if (this.item.data.schema.conditionId != null) {
      result = this.appendArrayTextItem(result, this.getArrayValue(this.schemaAwardConditions, this.item.data.schema.conditionId));
    }

    if (result == "") {
      return "Не вибрано";
    } else {
      return result;
    }
  }

  onChangeSchemaRangeCriteria() {
    this.item.data.schema.rangeCounterId = null;
    this.item.data.schema.rangePeriodId = null;
  }

  onSchemaRangeAdd() {
    if (this.item.data.schema.ranges == null) {
      this.item.data.schema.ranges = [];
    }
    let range = new PromoSchemaRange();
    this.item.data.schema.ranges.push(range);
  }

  deleteSchemaRange(range) {
    let index = this.item.data.schema.ranges.indexOf(range);
    this.item.data.schema.ranges.splice(index, 1);
    //Обнуляем значения в продуктах по диапазонах при удалении диапазона
    this.item.data.schema.products.forEach(element => {
      element.rewards.splice(index, 1);
    });
  }

  onSchemaProductAdd(val) {
    this.popupSchemaProductVisible = true;
    this.lastSchemaGoodsTypeId = val;
  }

  chooseSchemaProduct(value: number) {
    let index = this.goods.findIndex(x => x.id == value);
    if (index < 0) {
      notify("Помилка при підборі товару", "error", 600);
    }
    let goods = this.goods[index];

    let array = [];

    if (this.lastConditionGoodsTypeId == 3) {
      if (!this.item.data.schema.products) {
        this.item.data.schema.products = [];
      }
      array = this.item.data.schema.products;
    } else if (this.lastConditionGoodsTypeId == 4) {
      if (!this.item.data.schema.exceptProducts) {
        this.item.data.schema.exceptProducts = [];        
      }      
      array = this.item.data.schema.exceptProducts;
    }

    index = array.findIndex(x => x.id == value);
    if (index >= 0) {
      notify("Товар '" + goods.name + "' вже є в списку", "error", 600);
      return;
    }
    let promoGoods;
    if (this.lastConditionGoodsTypeId == 3) {
      promoGoods = new PromoSchemaProduct();
      promoGoods.id = goods.id;
      promoGoods.name = goods.name;
    } else if (this.lastConditionGoodsTypeId == 4) {
      promoGoods = new PromoExceptGoods();
      promoGoods.id = goods.id;
      promoGoods.name = goods.name;
    }

    array.push(promoGoods);
    notify("Товар '" + goods.name + "' успішно доданий", "success", 600);
  }

  getGoodsName(id) {
    let index = this.goods.findIndex(x => x.id == id);
    if (index < 0) {
      return "Не виявлено";
    } else {
      return this.goods[index].name;
    }
  }

  getGoodByExtId(extid) {
    let index = this.goods.findIndex(x => x.extId == extid);
    if (index < 0) {
      return null;
    } else {
      return this.goods[index];
    }
  }


  getSchemaRangeHeader(range: PromoSchemaRange) {
    let valFrom = "";
    let valTo = "";

    if (range.conditionFrom) {
      valFrom = range.conditionFrom.toString();
    }
    if (range.conditionTo) {
      valTo = range.conditionTo.toString();
    }

    return valFrom + " - " + valTo;
  }

  deleteSchemaProduct(product) {
    let index = this.item.data.schema.products.indexOf(product);
    this.item.data.schema.products.splice(index, 1);
  }

  deleteSchemaExceptProduct(product) {
    let index = this.item.data.schema.exceptProducts.indexOf(product);
    this.item.data.schema.exceptProducts.splice(index, 1);
  }

  onProductLimitEdit(limit: PromoLimit) {
    //console.log("limit " + JSON.stringify(limit));
    if (!limit.vals) {
      limit.vals = [];
    }
    this.currentEditLimit = limit;
    this.popupProductLimitVisible = true;
  }

  getProductLimitVal(product: PromoSchemaProduct) {
    //console.log("getLimitVal " + product.id);
    if (this.currentEditLimit.vals) {
      let i = this.currentEditLimit.vals.findIndex(x => x.id == product.id);
      if (i >= 0) {
        return this.currentEditLimit.vals[i].value;
      } else {
        //console.log("getLimitVal Zero 1 val " + i);
        return 0;
      }
    }
    else {
      //console.log("getLimitVal Zero 2");
      return 0;
    }
  }

  onLimitProductValChanged(event, product: PromoSchemaProduct) {
    //console.log("onLimitProductValChanged " + product.id);
    if (!this.currentEditLimit.vals) {
      this.currentEditLimit.vals = [];
      //console.log("onLimitProductValChanged create new array");
    }
    let i = this.currentEditLimit.vals.findIndex(x => x.id == product.id);
    if (i >= 0) {
      //console.log("onLimitProductValChanged found " + i);
      this.currentEditLimit.vals[i] = event.value;
    } else {
      //console.log("onLimitProductValChanged NOT found");
      let newVal = new NumberValueId();
      newVal.id = product.id;
      newVal.value = event.value;
      this.currentEditLimit.vals.push(newVal);
    }
    //console.log("newVal = " + event.value);
  }

  getProductLimit(productId: number): NumberValueId {
    let i = this.currentEditLimit.vals.findIndex(x => x.id == productId);
    if (i >= 0) {
      return this.currentEditLimit.vals[i];
    } else {
      let newVal = new NumberValueId();
      newVal.id = productId;
      this.currentEditLimit.vals.push(newVal);
      return newVal;
    }
  }

  inputGoodsfileChangeListener($event, inputId) {
    var text = [];
    var files = $event.target.files;
    var input = $event.target;
    var reader = new FileReader();
    reader.readAsText(input.files[0]);

    reader.onload = (data) => {
      let csvData = reader.result;
      let csvRecordsArray = csvData.toString().split(/\r\n|\n/);
      let ids = [];
      //console.log('records', csvRecordsArray);

      for (let i = 0; i < csvRecordsArray.length; i++) {
        let data = csvRecordsArray[i].split(",");
        if (data.length > 1) {
          return;
        }
        //console.log("length " + data[0].length);
        //if (data[0].length < 5) continue;

        ids.push(+data[0]);
      }

      //console.log('numbers ', JSON.stringify(numbers));
      switch (inputId) {
        case 1:
          this.fileImportInput1.nativeElement.value = "";
          ids.forEach(itemExtId => {
            let good = this.getGoodByExtId(itemExtId);
            if (good == null) {
              notify("Не знайдено товар с зовн. ID:  "+itemExtId, "warning", 1500);
            } else {
              let index = this.item.data.conditions.requireGoods.findIndex(x => x.id == good.id);
              if (index < 0) {
                let line = new PromoRequireGoods();
                line.id = good.id;
                line.amount = 1;
                line.name = good.name;
                this.item.data.conditions.requireGoods.push(line);
              }
            }
          });
          break;
        case 2:
          this.fileImportInput2.nativeElement.value = "";
          ids.forEach(itemExtId => {
            let good = this.getGoodByExtId(itemExtId);
            if (good == null) {
              notify("Не знайдено товар с зовн. ID:  "+itemExtId, "warning", 1500);
            } else {
              let index = this.item.data.conditions.exceptGoods.findIndex(x => x.id == good.id);
              if (index < 0) {
                let line = new PromoExceptGoods();
                line.id = good.id;
                line.name = good.name;
                this.item.data.conditions.exceptGoods.push(line);
              }
            }
          });
         
          break;
        case 3:
          this.fileImportInput3.nativeElement.value = "";
          ids.forEach(itemExtId => {
            let good = this.getGoodByExtId(itemExtId);
            if (good == null) {
              notify("Не знайдено товар с зовн. ID:  "+itemExtId, "warning", 1500);
            } else {
              let index = this.item.data.schema.products.findIndex(x => x.id == good.id);
              if (index < 0) {
                let line = new PromoSchemaProduct();
                line.id = good.id;
                line.name = good.name;
                this.item.data.schema.products.push(line);
              }
            }
          });
          break;
        case 4:
          this.fileImportInput4.nativeElement.value = "";
          ids.forEach(itemExtId => {
            let good = this.getGoodByExtId(itemExtId);
            if (good == null) {
              notify("Не знайдено товар с зовн. ID:  "+itemExtId, "warning", 1500);
            } else {
              let index = this.item.data.schema.exceptProducts.findIndex(x => x.id == good.id);
              if (index < 0) {
                let line = new PromoExceptGoods();
                line.id = good.id;
                line.name = good.name;
                this.item.data.schema.exceptProducts.push(line);
              }
            }
          });
          break;
      }
      //console.log("ids " + JSON.stringify(ids));

    }

    reader.onerror = function () {
      alert('Unable to read ' + input.files[0]);
    };

  }

  clickImportInput() {
    //console.log('clickImportInput');
    this.fileImportInput1.nativeElement.click();
  }

  onOtherActionsAfterExecAdd(value) {
    if (value == null) return;
    let otherAE = new PromoOtherAfterExec();
    otherAE.typeId = value;
    this.item.data.otherActions.afterExecution.push(otherAE);
    //console.log(value);
  }

  getOtherActionsAfterExecName(typeId) {
    let i = this.otherAfterExecTypes.findIndex(x => x.id == typeId);
    if (i >= 0) {
      return this.otherAfterExecTypes[i].value;
    }
    return "";
  }

  deleteOtherActionsAfterExec(action: PromoOtherAfterExec) {
    let index = this.item.data.otherActions.afterExecution.indexOf(action);
    this.item.data.otherActions.afterExecution.splice(index, 1);
  }

  isHiddenOtherActionsAfterExecInput(actionTypeId, inputId): boolean {
    //console.log(actionTypeId, inputId);
    switch (actionTypeId) {
      case 1: // Блокування
        switch (inputId) {
          case 1: return false; //Все поля
          case 2: return false; //Объект ValueId
          case 3: return true; //Счетчики
          case 4: return true; //Действие
          case 5: return true; //Значение
          case 6: return true; //Значение рядок
        }
      case 2: //Callback
        switch (inputId) {
          case 1: return false; //Все поля
          case 2: return false; //Объект ValueId
          case 3: return true; //Счетчики
          case 4: return true; //Действие
          case 5: return true; //Значение
          case 6: return true; //Значение рядок
        }
      case 3: //Призи
        switch (inputId) {
          case 1: return false; //Все поля
          case 2: return false; //Объект ValueId
          case 3: return true; //Счетчики
          case 4: return true; //Действие
          case 5: return true; //Значение
          case 6: return true; //Значение рядок
        }
      case 4: //Лічильники
        switch (inputId) {
          case 1: return false; //Все поля
          case 2: return true; //Объект ValueId
          case 3: return false; //Счетчики
          case 4: return false; //Действие
          case 5: return false; //Значение
          case 6: return true; //Значение рядок
        }
      case 5: //Лічильники
        switch (inputId) {
          case 1: return false; //Все поля
          case 2: return true; //Объект ValueId
          case 3: return true; //Счетчики
          case 4: return true; //Действие
          case 5: return true; //Значение
          case 6: return false; //Значение рядок
        }
      case 6: // Trigger Push
        switch (inputId) {
          case 1: return false; //Все поля
          case 2: return false; //Объект ValueId
          case 3: return true; //Счетчики
          case 4: return true; //Действие
          case 5: return true; //Значение
          case 6: return true; //Значение рядок
        }          
      default: return true;
    }
  }

  getOtherActionsAfterExecObjectType(actionTypeId) {
    switch(actionTypeId) {
      case 1: return this.otherAfterExecBlockTypes;
      case 2: return this.otherAfterExecCallbackTypes;
      case 3: return this.otherAfterExecGiftsTypes;
      case 6: return this.triggerPushesDropdownList;
      default: return [];         
    }
  }

  onShowWholesaleDocDetailed(docId) {
    this.popupDocDetailedVisible = true;
    this.promotionsWholesaleService.getDocById(docId)
    .subscribe(items => {
      this.promotionsWholesaleDocsDet = items;
      this.initPromoWholesaleDocDet();
    },
      error => {
        this.error = error;
      });
  }

  initPromoWholesaleDocDet() {
    this.promoWholesaleDocDetDataSource = new DataSource({
      store: new ArrayStore({
        key: "id",
        data: this.promotionsWholesaleDocsDet.lines,
        // Other ArrayStore options go here
      })      
    });
  }

}
