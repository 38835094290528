import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Tab, WalletSellsTransaction, WalletSellsFuels, WalletClientMoveRest, ValueId, WalletFuelsExchangeTransactions, 
  WalletFuelsRestoreTransactions, WalletFuelsRefundTransactions, WalletFuelsRefundRequest, WalletFuelsRefundPrepare, WalletFuelsBlockTransactions } from '../_models';
import { Globals } from '../globals';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Headers, RequestOptions } from '@angular/http';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

let transTabs: Tab[] = [
  {
    id: 0,
    text: "Транзакції покупки",
    icon: "fa fa-credit-card"
  },
  {
    id: 1,
    text: "По видам пального",
    icon: "fa fa-list-ol"
  },
  // {
  //   id: 2,
  //   text: "Рух по клієнтам",
  //   icon: "fa fa-address-card-o"
  // },
  {
    id: 3,
    text: "Заміна пального",
    icon: "fa fa-retweet"
  },
  {
    id: 4,
    text: "Відновлення пального",
    icon: "fa fa-undo"
  },
  {
    id: 5,
    text: "Повернення коштів",
    icon: "fa fa-reply-all"
  },
  {
    id: 6,
    text: "Блокування пального",
    icon: "fa fa-ban"
  }
];

let paymentSystem: ValueId[] = [
  {
    id: 0,
    value: "MasterPass"
  },
  {
    id: 1,
    value: "GPay"
  },
  {
    id: 2,
    value: "MonoBank"
  },
  {
    id: 3,
    value: "APay"
  }
];

let fuelsExchangeStatuses: ValueId[] = [
  {
    id: 1,
    value: "Створено"
  },
  {
    id: 2,
    value: "Помилка повернення"
  },
  {
    id: 3,
    value: "Повернено"
  },
  {
    id: 4,
    value: "Помилка нарахування"
  },
  {
    id: 5,
    value: "Заявка успішна"
  }
];

let fuelsRestoreStatuses: ValueId[] = [
  {
    id: 1,
    value: "Створено"
  },
  {
    id: 2,
    value: "Помилка нарахування"
  },
  {
    id: 3,
    value: "Заявка успішна"
  }
];

let fuelsRefundStatuses: ValueId[] = [
  {
    id: 1,
    value: "Створено"
  },
  {
    id: 2,
    value: "Помилка блокування пального"
  },
  {
    id: 3,
    value: "Блокування пального успішне"
  },
  {
    id: 4,
    value: "Помилка повернення коштів"
  },
  {
    id: 5,
    value: "Повернення коштів успішне"
  }
];

let fuelsBlockStatuses: ValueId[] = [
  {
    id: 1,
    value: "Створено"
  },
  {
    id: 2,
    value: "Помилка блокування"
  },
  {
    id: 3,
    value: "Заблоковано"
  }
];

@Injectable({ providedIn: 'root' })
export class TransactionsWalletService {
  constructor(private http: HttpClient, private globals: Globals) { }

  getTransTabs(): Tab[]{
    return transTabs;
  }

  getPaymentSystems(): ValueId[] {
    return paymentSystem;
  }

  getExchangeStasuses(): ValueId[] {
    return fuelsExchangeStatuses;
  }

  getRestoreStasuses(): ValueId[] {
    return fuelsRestoreStatuses;
  }

  getRefundStasuses(): ValueId[] {
    return fuelsRefundStatuses;
  }

  getBlockStasuses(): ValueId[] {
    return fuelsBlockStatuses;
  }

  getTransWallet(dateFrom: string, dateTo: string): Observable<WalletSellsTransaction[]>{
    return this.http
      .get<WalletSellsTransaction[]>(this.globals.getApiString() + '/TransactionsFWPayments/WalletSellsTransaction?dateFrom=' + dateFrom + '&dateTo=' + dateTo,
        httpOptions);
  }

  getTransWalletFuels(dateFrom: string, dateTo: string): Observable<WalletSellsFuels[]>{
    return this.http
      .get<WalletSellsFuels[]>(this.globals.getApiString() + '/TransactionsFWPayments/WalletSellsFuels?dateFrom=' + dateFrom + '&dateTo=' + dateTo,
        httpOptions);
  }

  getTransClientMoveRest(dateFrom: string, dateTo: string): Observable<WalletClientMoveRest[]>{
    return this.http
      .get<WalletClientMoveRest[]>(this.globals.getApiString() + '/TransactionsFWPayments/ClientCustomersMoveWithRest?dateFrom=' + dateFrom + '&dateTo=' + dateTo,
        httpOptions);
  }

  getTransFuelExchange(dateFrom: string, dateTo: string): Observable<WalletFuelsExchangeTransactions[]>{
    return this.http
      .get<WalletFuelsExchangeTransactions[]>(this.globals.getApiString() + '/TransactionsFWPayments/WalletFuelsExchangeTransactions?dateFrom=' + dateFrom + '&dateTo=' + dateTo,
        httpOptions);
  }

  getTransFuelRestore(dateFrom: string, dateTo: string): Observable<WalletFuelsRestoreTransactions[]>{
    return this.http
      .get<WalletFuelsRestoreTransactions[]>(this.globals.getApiString() + '/TransactionsFWPayments/WalletFuelsRestoreTransactions?dateFrom=' + dateFrom + '&dateTo=' + dateTo,
        httpOptions);
  }

  getTransFuelRefund(dateFrom: string, dateTo: string): Observable<WalletFuelsRefundTransactions[]>{
    return this.http
      .get<WalletFuelsRefundTransactions[]>(this.globals.getApiString() + '/TransactionsFWPayments/WalletFuelsRefundTransactions?dateFrom=' + dateFrom + '&dateTo=' + dateTo,
        httpOptions);
  }

  getTransFuelBlock(dateFrom: string, dateTo: string): Observable<WalletFuelsBlockTransactions[]>{
    return this.http
      .get<WalletFuelsBlockTransactions[]>(this.globals.getApiString() + '/TransactionsFWPayments/WalletFuelsBlockTransactions?dateFrom=' + dateFrom + '&dateTo=' + dateTo,
        httpOptions);
  }

  getFuelRefundPrepare(origOrderId: number): Observable<WalletFuelsRefundPrepare>{
    return this.http
      .get<WalletFuelsRefundPrepare>(this.globals.getApiString() + '/Clients/WalletFuelsRefundPrepare?origOrderId=' + origOrderId,
        httpOptions);
  }

  fwCreateRefund(item: WalletFuelsRefundRequest) {
    return this.http.post(this.globals.getApiString() + '/Clients/WalletFuelsRefund', JSON.stringify(item), httpOptions);
  }

}
