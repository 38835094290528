export class PromoPos {
    id: string;
    stationId: number;
    stationName: string;
    stationAddress: string;
    terminalId: number;
    terminalName: string;
}

export class VmChoosePos {
    id: string;
    name: string;
    address: string;
    terminalId: number;
    terminalName: string;

    stationId: number;
    stationName: string;
    parentId: string;    
}