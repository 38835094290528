import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Counters, VehicleBrands } from '../_models';
import { Globals } from '../globals';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Headers, RequestOptions } from '@angular/http';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

@Injectable({ providedIn: 'root' })
export class VehicleBrandsService {
    constructor(private http: HttpClient, private globals: Globals) { }

    getAll() {
        return this.http.get<VehicleBrands[]>(this.globals.getApiString() + `/VehicleBrands`, {});
    }

    getItem(id: number){
        return this.http.get<VehicleBrands>(this.globals.getApiString() + `/VehicleBrands/`+ id, {});
    }

    updateItem(item: VehicleBrands): Observable<VehicleBrands>{
        return this.http
            .post<VehicleBrands>(this.globals.getApiString() + '/VehicleBrands', 
                JSON.stringify(item), 
                httpOptions);
    }

    deleteItem(id: number): Observable<void>{
        return this.http
            .delete<void>(this.globals.getApiString() + '/VehicleBrands/' + id, 
                httpOptions); 
    }
}