import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Promotions, ValueId } from '../../_models';
import { PromotionsService } from '../../_services';

import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";

import notify from 'devextreme/ui/notify';
import { DxDataGridComponent } from 'devextreme-angular';
import { Router } from '@angular/router';

import DataGrid from "devextreme/ui/data_grid";

@Component({
    templateUrl: 'promotions-list.component.html'
  })
  export class PromotionsListComponent implements OnInit, AfterViewInit {
    @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;

    items: Promotions[] = [];
    loading = false;
    error = '';

    promoTypes: ValueId[];
    promoStatuses: ValueId[];

    store: ArrayStore;
    dataSource: DataSource;

    delItem: Promotions;
    isDeletePopupVisible: boolean = false;

    public constructor(
        private titleService: Title,
        private dataService: PromotionsService,
        private router: Router
    )
    {
        this.titleService.setTitle("Акції");
    }

    ngAfterViewInit(): void {

    }
    ngOnInit(): void {
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        var allowed = [1];

        if(!allowed.includes(currentUser.UserType)){
            this.router.navigateByUrl("404")
        }
        this.loading = true;

        this.promoTypes = this.dataService.getPromoTypes();
        this.promoStatuses = this.dataService.getPromoStatuses();

        this.dataService.getAll()
        .subscribe(items => {
            this.items = items;
            this.initDataSource();
            this.loading = false;
        },
        error => {
            this.error = error;
            this.loading = false;
        });
    }

    initDataSource(){
        this.dataSource = new DataSource({
            store: new ArrayStore({
                key: "id",
                data: this.items,
                // Other ArrayStore options go here
            }),
            filter: [ "isDeleted", "=", "0" ]
        });
        this.dataGrid.instance.updateDimensions();
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(  {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'fa fa-filter',
                    onClick: this.refreshDataGrid.bind(this),
                    hint: 'Очистити фільтри'
                }
            });
    }

    refreshDataGrid() {
        this.dataGrid.instance.clearFilter();
      }

    deleteItem(data){
        this.delItem = data.data as Promotions;
        this.isDeletePopupVisible = true;
    }

    deleteCancel(){
        this.delItem = null;
        this.isDeletePopupVisible = false;
    }

    deleteConfirm(){
        this.loading = true;
        this.dataService.deleteItem(this.delItem.id)
        .subscribe(item => {
            let index = this.items.findIndex(x => x.id == this.delItem.id);
            this.items[index].isDeleted = 1;

            this.initDataSource();
            this.deleteCancel();
            notify("Запис успішно видалено", "success", 600);
            this.loading = false;
        },
        error => {
            this.error = error;
            this.loading = false;
        });
    }



    onRowClick(e){
        let component = e.component;
        if ((!component.clickCount) || (component.clickCount != 1) || (component.clickKey != e.key) ) {
            initialClick();
        }
        else if (component.clickKey == e.key) {
            if (((Date.now()) - component.clickDate) <= 300){
                this.router.navigate(['/promotions/edit/'+e.key]);
                //console.log('second click');
                component.clickCount = 0;
                component.clickKey = 0;
                component.clickDate = null;
            }
            else
                initialClick();
        }

        function initialClick() {
            //console.log('initial click for key ' + e.key);
            component.clickCount = 1;
            component.clickKey = e.key;
            component.clickDate = Date.now();
        }

        function doubleClick() {

        }
    }
  }
