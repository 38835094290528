import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {Tab, TransWalletSpillsStations, Stations, ValueId, TransWalletSpillsAvg, TransWalletSpillsRest} from '../../_models';
import {AuthenticationService, StationsService, TransactionsWalletSpillsService} from '../../_services';
import ArrayStore from "devextreme/data/array_store";
import { Router } from '@angular/router';
import notify from 'devextreme/ui/notify';
import DataSource from "devextreme/data/data_source";
import 'devextreme/data/odata/store';
import {DxDataGridComponent, DxDropDownBoxComponent} from 'devextreme-angular';
import {Globals} from '../../globals';
import {DatePipe} from '@angular/common';

@Component({
  templateUrl: 'transactions-wallet-spills.component.html'
})
export class TransactionsWalletSpillsComponent implements OnInit, AfterViewInit {
  @ViewChild("transWalletSpillsDataGrid") dataGrid: DxDataGridComponent;
  @ViewChild("clientsDataGrid") clientsDataGrid: DxDataGridComponent;
  @ViewChild("termGridBox") termGridBox: DxDropDownBoxComponent;
  @ViewChild("transWalletAvgSpillDataGrid") dataTransWalletAvgSpillDataGrid: DxDataGridComponent;
  @ViewChild("RestDataGrid") dataRestGrid: DxDataGridComponent;
  @ViewChild("RestAmountsDataGrid") dataRestAmountsGrid: DxDataGridComponent;

  transTabs: Tab[];
  isShowNumTrans: boolean = false;
  isShowClients: boolean = false;
  isShowStations: boolean = false;
  isShowAvgSpill: boolean = false;
  isShowRest: boolean = false;
  isShowRestAmounts: boolean = false;

  numTransValue: number;
  visibleCardNum: string = "";
  clientId: number;
  error = '';

  popupClientsVisible: boolean = false;
  //popupTransPromoVisible: boolean = false;

  store: ArrayStore;

  dateFrom: Date;
  dateTo: Date;

  today: Date;
  termDayReport: Date = new Date();

  dsTransWalletSpillsLines: TransWalletSpillsStations[];
  dsTransAvgSpill: TransWalletSpillsAvg[];
  dsRestClients: TransWalletSpillsRest[];
  dsRestAmountsClients: TransWalletSpillsRest[];
  dsTransType: ValueId[];
  clientsDataSource: DataSource;
  termDataSource: Stations[];
  termBoxValue: any;

  caption = 'Паливний гаманець (проливи)';

  term_displayExpr(item){
    return item.extCode + " " + item.name;
  }

  term_onSelectionChanged(e: any) {
    this.termGridBox.instance.close();
  }

  public constructor(
    private titleService: Title,
    private transactionsWalletSpillsService: TransactionsWalletSpillsService,
    private authenticationService: AuthenticationService,
    private stationsService: StationsService,
    private globals: Globals,
    private router: Router,
    private datePipe: DatePipe
  )
  {
    this.titleService.setTitle(this.caption);
  }

  ngAfterViewInit(): void {

  }

  ngOnInit(): void {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));

    var allowed = [1, 10, 40, 400];

    if(!allowed.includes(currentUser.UserType)){
      this.router.navigateByUrl("404")
    }

    this.dsTransType = this.transactionsWalletSpillsService.getTransType();

    this.dateFrom = new Date();
    this.dateTo = new Date();
    //this.dateFrom = this.add_days(this.dateFrom, -1);

    let url = this.globals.serverName + "/odata/Clients";
    this.clientsDataSource = new DataSource({
      store: {
        type: "odata",
        url: url,
        key: "Id",
        version: 4,
        beforeSend: (e) => {
          console.log("Before send started");
          this.initODataHeader(e);


          console.log("Before send ended");
        }
      },
      expand: ["VehicleBrand"]
    });

    this.transTabs = this.transactionsWalletSpillsService.getTransTabs();
    this.showTransTabId(0);

  }

  add_months(dt, n) {
    return new Date(dt.setMonth(dt.getMonth() + n));
  }

  add_days(dt, n) {
    return new Date(dt.setDate(dt.getDate() + n));
  }

  initODataHeader(e) {
    //Делаем вызов псевдо метода для обновления AccessToken если он просрочен

    this.authenticationService.checkAuthAjax();
    let authToken = 'Bearer ' + this.authenticationService.getAccessToken();
    console.log("Before send use: " + authToken);
    e.headers = {
      "Content-Type": "application/json",
      "Authorization": authToken
    }
  }

  showTransTabId(id: number) {
    this.isShowNumTrans = id == 0;
    this.isShowClients = id == 1;
    this.isShowStations = id == 2;
    this.isShowAvgSpill = id == 3;
    this.isShowRest = id == 4;
    this.isShowRestAmounts = id == 5;

    if (id == 0) { }
    if (id == 1) { }
    if (id == 2) {

      this.stationsService.getActual()
        .subscribe(items => {
            this.termDataSource = items;
            //this.termBoxValue = items[0];
          },
          error => {
            this.error = error;
          });
    }
  }

  selectTransTab(e) {
    this.showTransTabId(e.itemData.id);
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',

        options: {
          icon: 'fa fa-filter',
          onClick: this.refreshDataGrid.bind(this),
          hint: 'Очистити фільтри'
        }
    });
  }

  onToolbarPreparingAvgSpill(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',

        options: {
          icon: 'fa fa-filter',
          onClick: this.refreshAvgSpillDataGrid.bind(this),
          hint: 'Очистити фільтри'
        }
    });
  }

  onToolbarPreparingRest(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',

        options: {
          icon: 'fa fa-filter',
          onClick: this.refreshRestDataGrid.bind(this),
          hint: 'Очистити фільтри'
        }
    });
  }

  onToolbarPreparingRestAmounts(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',

        options: {
          icon: 'fa fa-filter',
          onClick: this.refreshRestAmountsDataGrid.bind(this),
          hint: 'Очистити фільтри'
        }
    });
  }

  refreshDataGrid() {
    if(this.dsTransWalletSpillsLines) {
      this.dataGrid.instance.clearFilter();
    }
  }

  refreshAvgSpillDataGrid() {
    if(this.dsTransAvgSpill) {
      this.dataTransWalletAvgSpillDataGrid.instance.clearFilter();
    }
  }

  refreshRestDataGrid() {
    if(this.dsRestClients) {
      this.dataRestGrid.instance.clearFilter();
    }
  }

  refreshRestAmountsDataGrid() {
    if(this.dsRestAmountsClients) {
      this.dataRestAmountsGrid.instance.clearFilter();
    }
  }

  onContentReady(e) {
    console.log("onContentReady" + e.component);

    if(e.element.id === 'transWalletAvgSpillDataGridContainer' && !this.dsTransAvgSpill) {
      this.getTransAvgSpill();
    }
    else if(e.element.id === 'RestDataGridContainer' && !this.dsRestClients) {
      this.getTransRest();
    }
    else if(e.element.id === 'RestAmountsDataGridContainer' && !this.dsRestAmountsClients) {
      this.getTransRestAmounts();
    }

  }

  onInitialized(e) {
    console.log("onInitialized" + e.component);
  }

  applyTransNum() {
    if(!this.numTransValue) {
      notify("Введіть номер транзакції", "error", 1000);
      return;
    }

    this.dataGrid.instance.beginCustomLoading('');

    this.transactionsWalletSpillsService.getTransByParams(this.numTransValue, 0, '', 0, '')
      .subscribe(item => {
          this.dsTransWalletSpillsLines = item;
          this.dataGrid.instance.endCustomLoading();
        },
        error => {
          this.error = error;
          this.dataGrid.instance.endCustomLoading();
          notify("Під час виконання запиту сталаса помилка", "error", 1000);
        });

  }

  onClientAdd() {
    this.popupClientsVisible = true;
  }

  chooseClient(value: number) {
    this.clientId = value;
    this.popupClientsVisible = false;
  }

  applyClients() {
    if((!this.visibleCardNum || this.visibleCardNum === '') && !this.clientId) {
      notify("Введіть номер картки або код клієнта", "error", 1000);
      return;
    }

    this.dataGrid.instance.beginCustomLoading('');

    this.transactionsWalletSpillsService.getTransByParams(0, !this.clientId ? 0 : this.clientId, this.visibleCardNum , 0, '')
      .subscribe(item => {
          this.dsTransWalletSpillsLines = item;
          this.dataGrid.instance.endCustomLoading();
        },
        error => {
          this.error = error;
          this.dataGrid.instance.endCustomLoading();
          notify(!error.error ? 'Під час виконання запиту сталаса помилка' : error.error, "error", 1000);
        });
  }

  applyTermDate() {

    if(!this.termGridBox.value) {
      notify("Виберіть АЗК", "error", 1000);
      return;
    }

    if(!this.termDayReport) {
      notify("Введіть дату звіту", "error", 1000);
      return;
    }

    this.dataGrid.instance.beginCustomLoading('');

    this.transactionsWalletSpillsService.getTransByParams(0,  0 , '', this.termGridBox.value[0].extCode, this.datePipe.transform(this.termDayReport, 'yyyy-MM-dd'))
      .subscribe(item => {
          this.dsTransWalletSpillsLines = item;
          this.dataGrid.instance.endCustomLoading();
        },
        error => {
          this.error = error;
          this.dataGrid.instance.endCustomLoading();
          notify(!error.error ? 'Під час виконання запиту сталаса помилка' : error.error, "error", 1000);
        });

  }

  applyTransWalletSpillRest() {
    if(this.isShowAvgSpill) {
      this.getTransAvgSpill();
    }
    else if(this.isShowRest) {
      this.getTransRest();
    }
    else if(this.isShowRestAmounts) {
      this.getTransRestAmounts();
    }
  }

  getTransAvgSpill() {
    if(!this.dateTo) {
      notify("Введіть дату", "error", 1000);
      return;
    }

    this.dataTransWalletAvgSpillDataGrid.instance.beginCustomLoading('');

    this.transactionsWalletSpillsService.getTransWalletSpillsAvg(this.datePipe.transform(this.dateTo, 'yyyy-MM-dd'))
      .subscribe(item => {
          this.dsTransAvgSpill = item;
          this.dataTransWalletAvgSpillDataGrid.instance.endCustomLoading();
        },
        error => {
          this.error = error;
          this.dataTransWalletAvgSpillDataGrid.instance.endCustomLoading();
          notify(!error.error ? 'Під час виконання запиту сталаса помилка' : error.error, "error", 1000);
        });
  }

  getTransRest() {
    if(!this.dateTo) {
      notify("Введіть дату", "error", 1000);
      return;
    }

    this.dataRestGrid.instance.beginCustomLoading('');

    this.transactionsWalletSpillsService.getTransWalletRest(this.datePipe.transform(this.dateTo, 'yyyy-MM-dd'))
      .subscribe(item => {
          this.dsRestClients = item;
          this.dataRestGrid.instance.endCustomLoading();
        },
        error => {
          this.error = error;
          this.dataRestGrid.instance.endCustomLoading();
          notify(!error.error ? 'Під час виконання запиту сталаса помилка' : error.error, "error", 1000);
        });
  }

  getTransRestAmounts() {
    if(!this.dateTo) {
      notify("Введіть дату", "error", 1000);
      return;
    }

    this.dataRestAmountsGrid.instance.beginCustomLoading('');

    this.transactionsWalletSpillsService.getTransWalletRestAmounts(this.datePipe.transform(this.dateTo, 'yyyy-MM-dd'))
      .subscribe(item => {
          this.dsRestAmountsClients = item;
          this.dataRestAmountsGrid.instance.endCustomLoading();
        },
        error => {
          this.error = error;
          this.dataRestAmountsGrid.instance.endCustomLoading();
          notify(!error.error ? 'Під час виконання запиту сталаса помилка' : error.error, "error", 1000);
        });
  }

}
