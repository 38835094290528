import { Terminals } from "./terminals";

export class Stations {
    id: number;
    name: string;
    address: string;
    extId: string;
    latitude: number;
    longitude: number;
    isDeleted: number;
    terminals: Terminals[];
}