export class VmClientTransLine {
    transId: number;
    dat: Date;
    checknum: number;
    clientId: number;
    cardId: number;
    visibleNumber: string;
    stationId: number;
    stationName: string;
    stationAddress: string;
    stationCode: number;
    transTypeId: number;
    itemId: number;
    itemName: string;
    price: number;
    amount: number;
    discount: number;    
    discountUnit: number;
    deductionZSU: number;
    summa: number;
}

