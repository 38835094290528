import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Tab, DigestInfoResp } from '../_models';
import { Globals } from '../globals';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Headers, RequestOptions } from '@angular/http';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

let digestInfoTabs: Tab[] = [
  {
    id: 0,
    text: "Клієнти",
    icon: "fa fa-address-card-o"
  },
  {
    id: 1,
    text: "Картки",
    icon: "fa fa-credit-card"
  },
  {
    id: 2,
    text: "Товари",
    icon: "fa fa-shopping-basket"
  },
  {
    id: 3,
    text: "Сегменти",
    icon: "fa fa-database"
  },
  {
    id: 4,
    text: "АЗК",
    icon: "fa fa-building-o"
  },
  {
    id: 5,
    text: "Лічильникі",
    icon: "fa fa-calculator"
  },
  {
    id: 6,
    text: "Акції",
    icon: "fa fa-gift"
  },
  {
    id: 7,
    text: "Моб.додатки",
    icon: "fa fa-mobile"
  }
];


@Injectable({ providedIn: 'root' })
export class DigestInfoService {
  constructor(private http: HttpClient, private globals: Globals) { }

  getDigestInfoTabs(): Tab[]{
    return digestInfoTabs;
  }

  getClientsInfo(): Observable<DigestInfoResp[]>{
    return this.http
      .get<DigestInfoResp[]>(this.globals.getApiString() + '/digestinfo/clients',
        httpOptions);
  }

  getCardsInfo(): Observable<DigestInfoResp[]>{
    return this.http
      .get<DigestInfoResp[]>(this.globals.getApiString() + '/digestinfo/cards',
        httpOptions);
  }

  getGoodsInfo(): Observable<DigestInfoResp[]>{
    return this.http
      .get<DigestInfoResp[]>(this.globals.getApiString() + '/digestinfo/goods',
        httpOptions);
  }

  getSegmentsInfo(): Observable<DigestInfoResp[]>{
    return this.http
      .get<DigestInfoResp[]>(this.globals.getApiString() + '/digestinfo/segments',
        httpOptions);
  }

  getStationsInfo(): Observable<DigestInfoResp[]>{
    return this.http
      .get<DigestInfoResp[]>(this.globals.getApiString() + '/digestinfo/stations',
        httpOptions);
  }

  getCountersInfo(): Observable<DigestInfoResp[]>{
    return this.http
      .get<DigestInfoResp[]>(this.globals.getApiString() + '/digestinfo/counters',
        httpOptions);
  }

  getPromotionsInfo(): Observable<DigestInfoResp[]>{
    return this.http
      .get<DigestInfoResp[]>(this.globals.getApiString() + '/digestinfo/promotions',
        httpOptions);
  }

  getAppsInfo(): Observable<DigestInfoResp[]>{
    return this.http
      .get<DigestInfoResp[]>(this.globals.getApiString() + '/digestinfo/apps',
        httpOptions);
  }
  
}
