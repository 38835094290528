import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SegmentsListComponent } from './segments-list.component';
import { SegmentsEditComponent } from './segments-edit.component';
import { AuthGuard } from '../../_guards/auth.guard';

const routes: Routes = [
    { path: 'segments',  component: SegmentsListComponent, canActivate: [AuthGuard] },
    { path: 'segments/edit/:id', component: SegmentsEditComponent, canActivate: [AuthGuard] },
    { path: 'segments/create', component: SegmentsEditComponent, canActivate: [AuthGuard] }
  ];
  
  @NgModule({
    imports: [
      RouterModule.forChild(routes)
    ],
    exports: [
      RouterModule
    ]
  })
  export class SegmentsRoutingModule { }