import { Component } from '@angular/core';
import { isDevMode } from '@angular/core';

@Component({
    selector: 'app',
    templateUrl: 'app.component.html'
})

export class AppComponent {
    year = new Date().getFullYear();

    ngOnInit() {
        if (!isDevMode()) {
            if (location.protocol === 'http:') {
                window.location.href = location.href.replace('http', 'https');
            }
        }
    }
}

