import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Clients, ValueId, AddressArea, AddressFindStreetRequest, VehicleBrands, CardsBatch, Cards } from '../../_models';
import { ClientsService, AddressService, VehicleBrandsService } from '../../_services';

const ICON_SPINNER: string = "fa fa-lg fa-spinner fa-spin";
const ICON_UPLOAD: string = "fa fa-cloud-upload";

import notify from 'devextreme/ui/notify';
import { Observable, throwError } from 'rxjs';
import DataSource from "devextreme/data/data_source";
import ArrayStore from "devextreme/data/array_store";
import { DxValidatorComponent, DxFileUploaderComponent, DxDataGridComponent } from 'devextreme-angular';

import { Router } from '@angular/router';
import { error } from 'protractor';
import * as signalR from '@aspnet/signalr';
import { Globals } from 'src/app/globals';

@Component({
  templateUrl: 'clients-batchcreate.component.html',
  styleUrls: ['./clients-batchcreate.component.css']
})
export class ClientsBatchCreateComponent implements OnInit, AfterViewInit {
  @ViewChild('fileImportInput')
  fileImportInput: any;
  private _hubConnection: signalR.HubConnection;

  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;


  iconUpload: string = ICON_UPLOAD;
  saving = false;
  error = '';
  caption: string = "";
  status: string = "";


  items: CardsBatch[] = [];
  dataSource: DataSource;
  duplicatedCards: Cards[] = [];
  duplicatedCardsVisible: Cards[] = [];
  duplicatedCardsNumbers: string[] = [];
  duplicatedCardsVisibleNumbers: string[] = [];

  public constructor(
    private globals: Globals,
    private titleService: Title,
    private route: ActivatedRoute,
    private location: Location,
    private clientService: ClientsService,
    private router: Router
  ) {

  }

  ngAfterViewInit(): void {

  }
  ngOnInit(): void {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));

    var allowed = [1];

    if(!allowed.includes(currentUser.UserType)){
        this.router.navigateByUrl("404")
    }

    this.caption = "Пакетне завантаження карток";
    this.titleService.setTitle(this.caption);

    let url = this.globals.getServerName() + "/notify";
    this._hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(url)
            .build();

        this._hubConnection
            .start()
            .then(() => console.log('Connection started!'))
            .catch(err => console.log('Error while establishing connection :('));

        this._hubConnection.on('BroadcastMessage', (type: string, payload: string) => {
            //console.log("BroadcastMessage " + type + " " + payload);
            this.status = payload;

        });
  }

  fileChangeListener($event) {
    var text = [];
    var files = $event.target.files;
    var input = $event.target;
    this.status = "";
    var reader = new FileReader();
    reader.readAsText(input.files[0]);

    reader.onload = (data) => {
      let csvData = reader.result;
      let csvRecordsArray = csvData.split(/\r\n|\n/);

      //console.logconsole.log('records', csvRecordsArray);

      for (let i = 0; i < csvRecordsArray.length; i++) {
        if(csvRecordsArray[i] == ""){
          continue;
        }
        let data = csvRecordsArray[i].split(",");
        if (data.length < 9) {
          continue;
        }
        let cardsBatch = new CardsBatch();
        cardsBatch.internalNumber1 = data[0];
        cardsBatch.visibleNumber1 = data[1];
        cardsBatch.pin1 = data[2];

        cardsBatch.internalNumber2 = data[3];
        cardsBatch.visibleNumber2 = data[4];
        cardsBatch.pin2 = data[5];
        cardsBatch.text1 = data[6];
        cardsBatch.text2 = data[7];
        cardsBatch.text3 = data[8];
        cardsBatch.text4 = data[9];

        if ((cardsBatch.internalNumber1 != "") && !cardsBatch.internalNumber1.toUpperCase().startsWith("R") ||
            (cardsBatch.internalNumber2 != "") && !cardsBatch.internalNumber2.toUpperCase().startsWith("R")) {
            notify("Помилка при завантаженні карт - внутрішній номер починається не з R", "error", 3000);
          return ;
        } else  {
          this.items.push(cardsBatch);
        }



      }
      this.initDataSource();

      this.fileImportInput.nativeElement.value = "";
    }

    reader.onerror = function () {
      alert('Unable to read ' + input.files[0]);
    };


  }

  initDataSource() {
    this.dataSource = new DataSource({
      store: new ArrayStore({
        key: "internalNumber1",
        data: this.items,
        // Other ArrayStore options go here
      })
    });
  }

  uploadCards() {
    this.iconUpload = ICON_SPINNER;
    this.saving = true;
    this.status = "";

    this.clientService.batchCreate(this.items)
      .subscribe(res => {

        this.duplicatedCardsNumbers = res.dInternalNumbers;
        this.duplicatedCardsVisibleNumbers = res.dVisibleNumbers;

        this.duplicatedCards = res.dInternal;
        this.duplicatedCardsVisible = res.dVisible;

        this.saving = false;
        this.iconUpload = ICON_UPLOAD;
        if(this.duplicatedCards.length == 0 && this.duplicatedCardsVisible.length == 0 &&
          this.duplicatedCardsVisibleNumbers.length == 0 && this.duplicatedCardsNumbers.length == 0){
          notify("Картки успішно завантажені на сервер", "success", 3000);
          this.items = [];
          this.initDataSource();
        } else {
          notify("Помилка при завантаженні карток", "error", 3000);
        }

      },
      error => {
        this.error = error;
        this.iconUpload = ICON_UPLOAD;
        this.saving = false;
      });


  }

  openFileInput() {
    this.fileImportInput.click();
    //this.fileImportInput.click();
  }

  deleteCardsBatch(value) {
    let index = this.items.findIndex(x => x.internalNumber1 == value);
    if(index >= 0){
      this.items.splice(index, 1);
      this.initDataSource();
    }
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'fa fa-filter',
        onClick: this.refreshDataGrid.bind(this),
        hint: 'Очистити фільтри'
      }
    });
  }
  refreshDataGrid() {
    this.dataGrid.instance.clearFilter();
  }

}
