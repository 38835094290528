import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Counters, ValueId } from '../_models';
import { Globals } from '../globals';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Headers, RequestOptions } from '@angular/http';
import { CountersManualDocs } from '../_models/counters-manual-docs';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  let operationTypes: ValueId[] =
  [
      {
          id: 1,
          value: "Додати"
      },
      {
          id: 2,
          value: "Відняти"
      },
      {
          id: 3,
          value: "Встановити"
      },
      {
          id: 4,
          value: "Видалити"
      }
  ];

@Injectable({ providedIn: 'root' })
export class CountersManualDocsService {
    constructor(private http: HttpClient, private globals: Globals) { }

    getAll() {
        return this.http.get<CountersManualDocs[]>(this.globals.getApiString() + `/CountersManualDocs`, {});
    }

    getItem(id: number){
        return this.http.get<CountersManualDocs>(this.globals.getApiString() + `/CountersManualDocs/`+ id, {});
    }

    updateItem(item: CountersManualDocs): Observable<CountersManualDocs>{
        return this.http
            .post<CountersManualDocs>(this.globals.getApiString() + '/CountersManualDocs',
                JSON.stringify(item),
                httpOptions);
    }

    deleteItem(id: number): Observable<void>{
        return this.http
            .delete<void>(this.globals.getApiString() + '/CountersManualDocs/' + id,
                httpOptions);
    }

    getOperationTypes(): ValueId[] {
        return operationTypes;
    }
}
