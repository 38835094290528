import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Segments, SegmentConditionBlocks, SegmentCondition, SegmentParamStruct, SegmentOperatorStruct, ValueId, Goods, Counters, GoodsGroups } from '../../_models';
import { SegmentsService, GoodsService, GoodsGroupsService, CountersService } from '../../_services';
import notify from 'devextreme/ui/notify';
import DataSource from "devextreme/data/data_source";
import ArrayStore from "devextreme/data/array_store";
import { Router } from '@angular/router';

const ICON_KEY: string = "fa fa-lg fa-key ";
const ICON_SPINNER: string = "fa fa-lg fa-spinner fa-spin";
const ICON_FLOPPY: string = "fa fa-lg fa-floppy-o";
const TEXT_ADD_CONDITION: string = "Додати умову";
const TEXT_ADD_CONDITION_AND: string = "І";

@Component({
  templateUrl: 'segments-edit.component.html',
  styleUrls: ['./segments-edit.component.css']
})
export class SegmentsEditComponent implements OnInit, AfterViewInit {

  loading = false;
  saving = false;
  error = '';
  caption: string = "";
  private id;
  item: Segments;
  isNewItem: boolean = false;
  popupVisibleConditionType: boolean = false;
  popupVisibleGoodsChoose: boolean = false;
  popupVisibleGroupsChoose: boolean = false;

  addBlockText: string = TEXT_ADD_CONDITION;

  dsConditionsType: DataSource;

  periodTypes: ValueId[];

  dsCounters: DataSource;
  counters: Counters[];

  isBlockAdded: boolean = true;
  isShowPeriod: boolean = false;
  isShowPeriodDates: boolean = false;
  curBlock: SegmentConditionBlocks;
  curCondition: SegmentCondition;
  curConditionsType: number;
  conditionTypes: ValueId[];

  goodsItems: Goods[] = [];
  goodsDataSource: DataSource;

  groupsItems: GoodsGroups[] = [];
  groupsDataSource: DataSource;

  iconSave: string = ICON_FLOPPY;

  public constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private location: Location,
    private segmentsService: SegmentsService,
    private goodsService: GoodsService,
    private goodsGroupsService: GoodsGroupsService,
    private counterService: CountersService,
    private router: Router
  ) {

  }

  ngAfterViewInit(): void {

  }
  ngOnInit(): void {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        var allowed = [1];

        if(!allowed.includes(currentUser.UserType)){
            this.router.navigateByUrl("404")
        }
    this.periodTypes = this.segmentsService.getPeriods();
    this.conditionTypes = this.segmentsService.getConditionTypes();
    this.loadCounters();

    this.dsConditionsType = new DataSource({
      store: {
        type: "array",
        key: "id",
        data: this.conditionTypes
      }
    });

    this.route.params.subscribe(params => {
      this.id = +params['id']; // (+) converts string 'id' to a number
    });

    if (Number.isNaN(this.id)) {
      this.caption = "Додавання сегмента";
      this.titleService.setTitle(this.caption);
      this.item = new Segments();
      this.item.id = 0;

      this.isNewItem = true;

    } else {
      this.loading = true;
      this.caption = "Редагування сегмента";
      this.titleService.setTitle(this.caption);


      this.segmentsService.getItem(this.id)
        .subscribe(item => {
          this.item = item;

          if (this.item.conditionBlocks == undefined) {
            this.item.conditionBlocks = [];
          }

          this.onConditionsChanged();
          this.loading = false;
        },
          error => {
            this.error = error;
            this.loading = false;
          });
    }

    this.loadGoods();
    this.loadGroupsGoods();

  }

  loadCounters(){
    this.counterService.getAll()
      .subscribe(items => {
        this.counters = items;
        this.dsCounters = new DataSource({
          store: new ArrayStore({
            key: "id",
            data: this.counters,
            // Other ArrayStore options go here
          }),
          filter: ["isDeleted", "=", "0"]
          // Other DataSource options go here
        });
      },
        error => {
          this.error = error;
        });
  }

  loadGroupsGoods() {
    this.goodsGroupsService.getAll()
      .subscribe(items => {
        this.groupsItems = items;
        this.groupsDataSource = new DataSource({
          store: new ArrayStore({
            key: "id",
            data: this.groupsItems,
            // Other ArrayStore options go here
          }),
          filter: ["isDeleted", "=", "0"]
          // Other DataSource options go here
        });
      },
        error => {
          this.error = error;
        });
  }

  loadGoods() {
    this.goodsService.getAll()
      .subscribe(items => {
        this.goodsItems = items;
        this.goodsDataSource = new DataSource({
          store: new ArrayStore({
            key: "id",
            data: this.goodsItems,
            // Other ArrayStore options go here
          }),
          filter: ["isDeleted", "=", "0"]
          // Other DataSource options go here
        });
      },
        error => {
          this.error = error;
        });
  }


  goBack() {
    this.location.back();
  }

  fixDatesBeforeSave() {
    if (this.item.dateFrom) {
      //Date.prototype.
    }

  }


  update() {
    //console.log(this.item);
    this.saving = true;
    this.iconSave = ICON_SPINNER;
    this.segmentsService.updateItem(this.item)
      .subscribe(res => {
        this.saving = false;
        this.iconSave = ICON_FLOPPY;
        notify("Дані збережено", "success", 600);
        this.goBack();
      },
        error => {
          this.error = error;
          this.iconSave = ICON_FLOPPY;
        });
  }

  onConditionsChanged() {
    if (this.item.conditionBlocks == undefined) return;
    this.isShowPeriod = false;

    if (this.item.conditionBlocks.length == 0) {
      this.addBlockText = TEXT_ADD_CONDITION;
    } else {
      this.addBlockText = TEXT_ADD_CONDITION_AND;

      let _condTypeIdPeriod = [2,3,6,7,8];
      let _condTypeIdDecimal = [2,3,4,5,6,7,8];
      for (let block of this.item.conditionBlocks) {
        for (let condition of block.conditions) {
          if (_condTypeIdPeriod.includes(condition.typeId)) {
            this.isShowPeriod = true;
            this.checkPeriodDatesShow(this.item.periodTypeId);
            //break;
          }
          if(_condTypeIdDecimal.includes(condition.typeId) && !condition.val) {
            condition.val = "0";
          }
        }
      }
    }
    if (!this.isShowPeriod) {
      this.item.periodTypeId = 0;
    }
  }

  addConditionPopup(block: SegmentConditionBlocks) {
    this.curConditionsType = 0;
    this.isBlockAdded = false;
    this.curBlock = block;
    this.popupVisibleConditionType = true;
  }

  addConditionBlockPopup() {
    this.isBlockAdded = true;
    this.curBlock = null;
    this.popupVisibleConditionType = true;
  }

  cancelConditionTypePopup() {
    this.popupVisibleConditionType = false;
  }

  addCondition() {
    if (this.curConditionsType == undefined || this.curConditionsType == 0) {
      notify("Необхідно вибрати тип умови", "error", 600);
      return;
    }


    this.popupVisibleConditionType = false;

    if (this.isBlockAdded) {
      let newblock = new SegmentConditionBlocks();
      let newCondition = new SegmentCondition();
      newCondition.typeId = this.curConditionsType;
      newblock.conditions.push(newCondition);
      this.item.conditionBlocks.push(newblock);
    } else {
      let newCondition = new SegmentCondition();
      newCondition.typeId = this.curConditionsType;
      this.curBlock.conditions.push(newCondition);
    }

    this.curConditionsType = 0;
    this.onConditionsChanged();
  }

  deleteBlock(block: SegmentConditionBlocks) {
    let index = this.item.conditionBlocks.indexOf(block);
    this.item.conditionBlocks.splice(index, 1);
    this.onConditionsChanged();
  }

  deleteCondition(block: SegmentConditionBlocks, condition: SegmentCondition) {
    let index = block.conditions.indexOf(condition);
    block.conditions.splice(index, 1);
    //Если не осталось условий, то удаляем блок полностью
    if (block.conditions.length == 0) {
      this.deleteBlock(block);
    } else {
      this.onConditionsChanged();
    }

  }

  onConditionsParamChanged(e, selectOperator, condition: SegmentCondition) {
    selectOperator.dataSource = this.getOperatorsDataSource(condition);
    //textValue.mask = "$9999,99";
    //console.log("value " + e.value);
  }

  getParamsDataSource(conditionTypeId: number): SegmentParamStruct[] {
    if (conditionTypeId == 1) {
      return this.segmentsService.getStaticParams();
    } else if (conditionTypeId == 2) {
      return this.segmentsService.getDynamicParams();
    }
  }

  getDataTypeId(paramValue: string) {
    let param = this.segmentsService.getStaticParams().find(x => x.id == paramValue);
    if (param != undefined) {
      return param.dataTypeId;
    } else {
      param = this.segmentsService.getDynamicParams().find(x => x.id == paramValue);
      if (param != undefined) {
        return param.dataTypeId;
      }
    }
    return 0;
  }

  getOperatorsDataSource(condition: SegmentCondition): SegmentOperatorStruct[] {
    if(condition.typeId == 4 || condition.typeId == 5){
      return this.segmentsService.getNumberOrNullOperators();
    } else if(condition.typeId == 3 || condition.typeId == 6 || condition.typeId == 7 || condition.typeId == 8){
      return this.segmentsService.getNumberOperators();
    }

    let dataTypeId = this.getDataTypeId(condition.param);
    if (dataTypeId == 1) {
      return this.segmentsService.getStringOperators();
    } else if (dataTypeId == 2) {
      return this.segmentsService.getNumberOperators();
    } else if (dataTypeId == 3) {
      return this.segmentsService.getDateOperators();
    }

  }

  openGoodsChoosePopup(condition) {
    this.curCondition = condition;
    this.popupVisibleGoodsChoose = true;
  }

  openGroupsChoosePopup(condition) {
    this.curCondition = condition;
    this.popupVisibleGroupsChoose = true;
  }

  chooseGoodsId(goodsId: number) {
    this.popupVisibleGoodsChoose = false;
    this.curCondition.param = goodsId.toString();
    //alert(goodsId);
  }

  chooseGroupsId(groupId: number) {
    this.popupVisibleGroupsChoose = false;
    this.curCondition.param = groupId.toString();
    //alert(goodsId);
  }

  getGoodsName(goodsId: string) {
    let index = this.goodsItems.findIndex(x => x.id == +goodsId);
    if (index < 0) return undefined;
    if (this.goodsItems[index] == undefined) return undefined;
    return this.goodsItems[index].name;
  }

  getGroupName(groupId: string) {
    let index = this.groupsItems.findIndex(x => x.id == +groupId);
    if (index < 0) return undefined;
    if (this.groupsItems[index] == undefined) return undefined;
    return this.groupsItems[index].name;
  }

  getConditionTypeCaption(conditionTypeId: number){
    let index = this.conditionTypes.findIndex(x => x.id == conditionTypeId);
    return this.conditionTypes[index].value;
  }

  checkPeriodDatesShow(value) {
    if (value == 5) {
      this.isShowPeriodDates = true;
    } else {
      this.isShowPeriodDates = false;
      if (this.item) {
        console.log("clear period dates");
        this.item.dateTo = null;
        this.item.dateFrom = null;
      }

    }
  }

  onPeriodChanged(e) {
    //console.log("onPeriodChanged: " + e.value)
    this.checkPeriodDatesShow(e.value);
  }

  treeView_itemSelectionChanged(e) {
    if (e.component.getSelectedNodesKeys() != 0) {
        let groupId = e.component.getSelectedNodesKeys()[0];
        this.chooseGroupsId(groupId);
    }
}

}

