export class WalletFuelsBlockTransactions {
    id: number;
    createdUtc: Date;
    creadtedLocalDate: Date;
    clientId: number;
    fuelId: number;
    fuelName: string;
    amount: number;
    status: number;
    blockDocId: number | null;
    description: string;
    reason: string;
    userName: string;
}