import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClientsListComponent } from './clients-list.component';
import { ClientsEditComponent } from './clients-edit.component';
import { AuthGuard } from '../../_guards/auth.guard';
import { ClientsBatchCreateComponent } from './clients-batchcreate.component';
import { ClientsFillFormComponent } from './clients-fill-form.component';
import { ClientsFindComponent } from './clients-find.component';
import { ClientsListRemoteComponent } from './clients-list-remote.component';

const routes: Routes = [
    { path: 'clients/list-local',  component: ClientsListComponent, canActivate: [AuthGuard] },
    { path: 'clients/edit/:id', component: ClientsEditComponent, canActivate: [AuthGuard] },
    { path: 'clients/create', component: ClientsEditComponent, canActivate: [AuthGuard] },
    { path: 'clients/batch-create', component: ClientsBatchCreateComponent, canActivate: [AuthGuard] },
    { path: 'clients/fill-form', component: ClientsFillFormComponent, canActivate: [AuthGuard] },
    { path: 'clients/find', component: ClientsFindComponent, canActivate: [AuthGuard] },
    { path: 'clients', component: ClientsListRemoteComponent, canActivate: [AuthGuard] }
  ];
  
  @NgModule({
    imports: [
      RouterModule.forChild(routes)
    ],
    exports: [
      RouterModule
    ]
  })
  export class ClientsRoutingModule { }