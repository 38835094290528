import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Goods, ValueId } from '../_models';
import { Globals } from '../globals';
import { Observable } from 'rxjs';
import { map, timeout } from 'rxjs/operators';
import { Headers, RequestOptions } from '@angular/http';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  let typeLimitsPeriod : ValueId[] = [
    {
        id: 1,
        value: "День"
    },
    {
        id: 2,
        value: "Тиждень"
    },
    {
        id: 3,
        value: "Місяць"
    },
    {
        id: 4,
        value: "Рік"
    }
  ];

@Injectable({ providedIn: 'root' })
export class GoodsService {
    constructor(private http: HttpClient, private globals: Globals) { }

    getAll() {
        return this.http.get<Goods[]>(this.globals.getApiString() + '/Goods', {});
    }

    getByIds(ids: number[]): Observable<Goods[]>{
        return this.http
            .post<Goods[]>(this.globals.getApiString() + '/Goods/get-ids', 
                JSON.stringify(ids), 
                httpOptions);  
    }

    getItem(id: number){
        return this.http.get<Goods>(this.globals.getApiString() + `/Goods/`+ id, {});
    }

    updateItem(item: Goods): Observable<Goods>{
        return this.http
            .post<Goods>(this.globals.getApiString() + '/Goods', 
                JSON.stringify(item), 
                httpOptions);
    }

    deleteItem(id: number): Observable<void>{
        return this.http
            .delete<void>(this.globals.getApiString() + '/Goods/' + id, 
                httpOptions); 
    }

    getLimitsPeriod() {
        return typeLimitsPeriod;
    }
}