import { Time } from "@angular/common";

export class TriggerFcmSchedule {
    id: number;
    uniqId: number;
    templateId: number;
    template: TriggerMessageTemplate | null;
    clientId: number;    
    deliveryAt: string;
    deliveryAtLocal: Date;
    typeExecution: number;
    serviceData: string | null;
    pushId: number | null;
    status: number;
    completed: boolean;
    isPrepared: boolean;
    dynamicData: string | null;
}

export class TriggerMessageTemplate {
    id: number;
    type: number;
    triggerType: number;
    name: string;    
    title: string;
    header: string | null;
    content: string;
    footer: string | null;
    image: string | null;
    link: string | null;
    delayMinutesSend: number | null;
    //pushType: string | null;
    triggerFcmSchedules: TriggerFcmSchedule[] | null;
    createdUtc: string | null;
    editedUtc: string | null;
    author: string | null;
    editor: string | null;
    deleted: boolean;
    isActive: boolean;
}

export class TriggerTaskPlan {
    id: number;
    name: string;
    templateId: number;
    nextRunAt: Date;
    nextRunAtLocal: Date;
    conditions: TriggerTaskPlanConditions | null;
    schedule: TriggerTaskPlanSchedule | null;
    isActive: boolean;
    isTest: boolean;
    author: string | null;
    editor: string | null;
    deleted: boolean;
    status: number;
    taskTypeExecution: number;
    countExecution: number;
    completed: boolean;
    editedUtc: string | null;
    triggerTaskPlanLogExec: TriggerTaskPlanLogExec[] | null;

    constructor(){
        this.conditions = new TriggerTaskPlanConditions();
        this.schedule = new TriggerTaskPlanSchedule();
    }

 }

 export class TriggerTaskPlanLogExec {
    id: number;
    isTestRun: boolean;
    editedLocal: Date;
    startRunAt: Date;
    startRunAtLocal: Date;
    endRunAt: Date;
    endRunAtLocal: Date;
    executionCond: string | null;
    executionCondSec: number;
    fileResContType: string | null;
    fileResGuId: string | null;
    fileResName: string | null;
    fileResSize: number;
    countReceivers: number;    
 }

 export class TriggerTaskPlanSchedule {
    typePeriodic: number;
    dayWeek: number;
    dayMonth: number;
    countExecute: number;
    timeExecute: Time;
 }

 export class TriggerTaskPlanConditions {
    periodType: number | null;
    dateFrom: string | null;
    dateTo: string | null;
    conditionBlocks: TriggerTaskPlanConditionBlock[];

    constructor(){
        this.conditionBlocks = [];
    }
}

export class TriggerTaskPlanConditionBlock {
    conditionLines: TriggerTaskPlanConditionLine[];

    constructor(){
        this.conditionLines = [];
    }
}

export class TriggerTaskPlanConditionLine {
    typeId: number;
    param: string;
    operator: string;
    val: string;
}

export class TriggerTaskPlanCondParamStruct{
    id: string;
    caption: string;
}

export class TriggerTaskPlanCondOperatorStruct{
    id: string;
    caption: string;
}