import { NgModule }       from '@angular/core';
import { CommonModule }       from '@angular/common';
import { FormsModule }    from '@angular/forms';
import { PushesRoutingModule } from './pushes-routing.module';
import { PushesListComponent } from './pushes-list.component';
import { PushesEditComponent } from './pushes-edit.component';
import { DxButtonModule, DxDataGridModule, DxTreeListModule, DxTreeViewModule, DxDropDownBoxModule, DxTextBoxModule, DxPopupModule, DxTextAreaModule, DxSelectBoxModule, DxDateBoxModule, DxCheckBoxModule, DxSliderModule  } from 'devextreme-angular';
import { Pushes } from '../../_models';

@NgModule({
    imports: [
      PushesRoutingModule,
      CommonModule,
      FormsModule,
      DxButtonModule,
      DxDataGridModule,
      DxTreeListModule,
      DxTreeViewModule,
      DxDropDownBoxModule,
      DxTextBoxModule,
      DxPopupModule,
      DxTextAreaModule,
      DxSelectBoxModule,
      DxDateBoxModule,
      DxCheckBoxModule,
      DxSliderModule
    ],
    declarations: [
        PushesEditComponent,
        PushesListComponent   
    ]
  })
  export class PushesModule {
    items: Pushes[] = [];
  }
