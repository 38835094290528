export class WalletFuelsRestoreTransactions {
    id: number;
    createdUtc: Date;
    creadtedLocalDate: Date;
    clientId: number;
    fuelId: number;
    fuelName: string;
    amount: number;    
    status: number;
    blockDocId: number | null;
    creditDocId: number | null;
    description: string;
    userName: string;
}