import { NgModule }       from '@angular/core';
import { CommonModule }       from '@angular/common';

import { FormsModule }    from '@angular/forms';

import { UsersRoutingModule } from './users-routing.module';
import { UsersListComponent } from './users-list.component';
import { UsersEditComponent } from './users-edit.component';
import { UsersCreateComponent } from './users-create.component';
import { DxSelectBoxModule, DxDataGridModule, DxButtonModule, DxTextBoxModule, DxPopupModule, DxTextAreaModule } from 'devextreme-angular';

@NgModule({
    imports: [
      UsersRoutingModule,
      CommonModule,
      FormsModule,
      DxSelectBoxModule,
      DxDataGridModule,
      DxButtonModule,
      DxTextBoxModule,
      DxPopupModule,
      DxTextAreaModule
    ],
    declarations: [
        UsersEditComponent,
        UsersListComponent,
        UsersCreateComponent   
    ]
  })
  export class UsersModule {

  }
