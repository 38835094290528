import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { PromoDocs, PromoDocsJsonData, PromoDocsSettings, PromoDocsPos, Stations } from '../../_models';
import { VmChoosePos } from 'src/app/_models/promo/promo-pos';
import { PromoDocsService, StationsService } from '../../_services';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import notify from 'devextreme/ui/notify';
import { Router } from '@angular/router';
import { Globals } from '../../globals';
import { DxTextBoxComponent } from 'devextreme-angular';

@Component({
  templateUrl: 'promo-docs-edit.component.html',
  styleUrls: ['./promo-docs-edit.css']
})

export class PromoDocsEditComponent implements OnInit, AfterViewInit {
  @ViewChild('name') public title: DxTextBoxComponent;
  @ViewChild('description') public header: DxTextBoxComponent;
  @ViewChild('fileImportMedia') fileImportMedia: any;
  
  _shouldClose: boolean = false;

  loading = false;
  saving = false;
  upload = false;
  error: string = "";
  isFileUpload = false;
  popupStationsVisible: boolean = false;

  caption: string = "";
  iconSave: string;

  private id;
  item: PromoDocs;
  stations: Stations[] = [];
  choosePoses: VmChoosePos[] = [];

  public constructor(
    private titleService: Title,
    private promoDocsService: PromoDocsService,
    private stationsService: StationsService,
    private route: ActivatedRoute,
    private location: Location,
    private globals: Globals,
    private router: Router
  ) {

  }

  refreshButtonIcon(){
    if(this.saving){
      this.iconSave = this.globals.ICON_SPINNER;
    } else {
      this.iconSave = this.globals.ICON_FLOPPY;
    }
  }

  ngAfterViewInit(): void {

  }

  ngOnInit(): void {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));

        var allowed = [1];

        if(!allowed.includes(currentUser.UserType)){
            this.router.navigateByUrl("404")
        }

    this.refreshButtonIcon();

    this.route.params.subscribe(params => {
      this.id = +params['id']; // (+) converts string 'id' to a number      
    });

    if (Number.isNaN(this.id)) {
      this.caption = 'Додавання матеріалу';
      this.titleService.setTitle(this.caption);      
      this.item = new PromoDocs();    
      this.item.id = 0;
      this.item.data = new PromoDocsJsonData();
      this.item.data.settings = new PromoDocsSettings();
      this.item.data.settings.useAllPoses = true;
      this.item.data.settings.poses = [];
      this.item.isActiveBool = true;      
      this.isFileUpload = false;
      this.item.jsonData = JSON.stringify(this.item.data);

    }
    else {
      this.caption = 'Редагування матеріалу';
      this.titleService.setTitle(this.caption);

      this.promoDocsService.getPromoDocsById(this.id)
        .subscribe(item => {
          this.item = item; 
          this.loading = false;

          if(this.item.data && this.item.data.fileInfo) {
            this.isFileUpload = true;
          }
        },
          error => {
            this.error = error.error;
            this.loading = false;
          });
    }

    this.stationsService.getAll()
      .subscribe(items => {
        this.stations = items;
        this.initPosesDataSource();
      },
        error => {
          this.error = error;
        });

  }

  initPosesDataSource() {
    this.stations.forEach(station => {
      if (station.isDeleted != 0) {
        return;
      }

      let pos = new VmChoosePos();
      pos.id = station.id.toString();
      pos.stationId = station.id;
      pos.name = station.name;
      pos.address = station.address;
      pos.terminalId = 0;
      pos.stationName = station.name;
      this.choosePoses.push(pos);

      if (station.terminals != null) {
        station.terminals.forEach(terminal => {
          let term = new VmChoosePos();
          term.id = "T" + terminal.id.toString();
          term.stationId = station.id;
          term.name = terminal.name;
          term.terminalId = terminal.id;
          //term.address = station.address;
          term.stationName = station.name;
          term.terminalName = terminal.name;
          term.parentId = station.id.toString();
          this.choosePoses.push(term);
        });
      }
    });

  }

  onStationAdd() {
    this.popupStationsVisible = true;
  }

  choosePos(value: string) {
    ;
    let index = this.choosePoses.findIndex(x => x.id == value);
    if (index < 0) {
      notify("Помилка при підборі торгової точки", "error", 600);
    }
    let selectedPos = this.choosePoses[index];
    //console.log("add " + JSON.stringify(selectedPos))
    index = this.item.data.settings.poses.findIndex(x => x.id == value);
    if (index >= 0) {
      notify("Обрана торгова точка вже є в списку", "error", 600);
      return;
    } else {
      let indexParent = this.item.data.settings.poses.findIndex(x => x.id == selectedPos.parentId);
      if (indexParent >= 0) {
        notify("Обрана торгова точка вже є в списку", "error", 600);
        return;
      }
    }

    let promoDocsPos = new PromoDocsPos();
    promoDocsPos.id = selectedPos.id;
    promoDocsPos.stationName = selectedPos.stationName;
    promoDocsPos.stationId = selectedPos.stationId;
    promoDocsPos.stationAddress = selectedPos.address;
    promoDocsPos.terminalName = selectedPos.terminalName;
    promoDocsPos.terminalId = selectedPos.terminalId;
    this.item.data.settings.poses.push(promoDocsPos);
    notify("Торгова точка успішно додана", "success", 600);
  }

  deletePos(data) {
    let index = this.item.data.settings.poses.findIndex(x => x.id == data.value);
    if (index >= 0) {
      //console.log("delete client index: " + index);
      this.item.data.settings.poses.splice(index, 1);
    }
  }

  fileChangeListenerMedia($event) { 

    if($event.target.files[0].size >= this.globals.maxSizeMediaFile) {
      notify("Вибраний файл занадто великий. Макс. розмір 100 мБ", "error", 1000);
      return;
    }

    this.upload = true;
    this.error = '';
    this.promoDocsService.uploadFile($event.target.files[0])
        .subscribe(res => {
          this.upload = false;
          this.item.data.fileInfo = res;
          this.item.fileStorageGuId = res.guId;
          this.isFileUpload = true;
          notify("Файл завантажено", "success", 1000);
        },
        error => {
          this.upload = false;
          console.log(error);
          notify("Не вдалося завантажити файл", "error", 1000);
    });
  
  }

  goBack() {
    this.location.back();
  }

  save() {
    if(!this.validationForm()) {
      return;
    }
    this.updatePromoDoc();
  }

  validationForm() {

    if(!this.item.name) {
      notify("Заповніть назву", "error", 1000);
      this.title.instance.focus();
      return false;
    }
    return true;
  }

  updatePromoDoc() {
    this.saving = true;
    this.refreshButtonIcon();
    this.promoDocsService.updatePromoDocs(this.item)
      .subscribe(res => {
        this.saving = false;
        this.refreshButtonIcon();
        notify("Дані збережено", "success", 600);
        this.goBack();
      },
        error => {          
          this.saving = false;
          this.refreshButtonIcon();
          this.error = error.error;

        });
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'fa fa-filter',
        onClick: this.refreshDataGrid.bind(this),
        hint: 'Очистити фільтри'
      }
    });
  }

  refreshDataGrid() {
    //this.clientsDataGrid.instance.clearFilter();
  }

  downloadFile(fileGuId) {   
    window.open(this.globals.getStorageUrl() + "/" + fileGuId);
  }

}
