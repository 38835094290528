import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TransactionsGiftsComponent } from './transactions-gifts.component';
import { AuthGuard } from '../../_guards/auth.guard';

const routes: Routes = [
  { path: 'transactions-gifts',  component: TransactionsGiftsComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class TransactionsGiftsRoutingModule { }
