import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Stations, Terminals, EmitRefreshToken } from '../../_models';
import { StationsService, AuthenticationService } from '../../_services';
import notify from 'devextreme/ui/notify';
import { using } from 'rxjs';
import { Router } from '@angular/router';
const ICON_KEY: string = "fa fa-lg fa-key ";
const ICON_SPINNER: string = "fa fa-lg fa-spinner fa-spin";
const ICON_FLOPPY: string = "fa fa-lg fa-floppy-o";

@Component({
    templateUrl: 'stations-edit.component.html'
  })
  export class StationsEditComponent implements OnInit, AfterViewInit {

    loading = false;
    saving = false;
    error = '';
    caption : string = "";
    private id;
    item: Stations;
    isNewItem: boolean = false;
    newTerminal: Terminals;
    popupVisibleNewTerminal: boolean = false;
    popupVisibleGenerateKey: boolean = false;
    popupVisibleEditTerminal: boolean = false;
    curTerminal: Terminals;
    emitRefreshToken: string;
    iconKey: string = ICON_KEY;
    iconSave: string = ICON_FLOPPY;

      public constructor(
      private titleService: Title,
      private route: ActivatedRoute,
      private location: Location,
      private stationsService: StationsService,
      private authService: AuthenticationService,
      private router: Router
    )
    {

    }

    ngAfterViewInit(): void {


    }

    ngOnInit(): void {
      let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        var allowed = [1, 400];

        if(!allowed.includes(currentUser.UserType)){
            this.router.navigateByUrl("404")
        }
      this.route.params.subscribe(params => {
        this.id = +params['id']; // (+) converts string 'id' to a number
      });

      if(Number.isNaN(this.id))
      {
        this.caption = "Додавання АЗК";
        this.titleService.setTitle(this.caption);
        this.item = new Stations();
        this.item.id = 0;
        this.item.longitude = 0;
        this.item.latitude = 0;
        this.isNewItem = true;
        this.item.terminals = new Array<Terminals>();
        this.isNewItem = true;

      } else {

        this.loading = true;
        this.caption = "Редагування АЗК";
        this.titleService.setTitle(this.caption);

        this.stationsService.getItem(this.id)
          .subscribe(item => {
            this.item = item;
            this.loading = false;
        },
        error => {
            this.error = error;
            this.loading = false;
        });

      }

    }

    addTerminalPopup(){
      this.newTerminal = new Terminals();
      this.popupVisibleNewTerminal = true;
    }

    cancelTerminalPopup(){
      this.popupVisibleNewTerminal = false;
      this.popupVisibleEditTerminal = false;
    }

    addTerminal(){
      this.item.terminals.push(this.newTerminal);
      this.popupVisibleNewTerminal = false;
    }

    editTerminal() {
      let _term = this.item.terminals.find(x => x.id == this.curTerminal.id);
      if(_term) {
        _term.name = this.curTerminal.name;
        _term.standId = this.curTerminal.standId;
      }

      this.popupVisibleEditTerminal = false;
    }

    goBack(){
      this.location.back();
    }

    terminalDelete(terminal: Terminals){
      terminal.isDeleted = 1;
    }

    terminalEdit(terminal: Terminals) {
      this.curTerminal = terminal;
      this.popupVisibleEditTerminal = true;
    }

    update(){
      this.saving = true;
      this.iconSave = ICON_SPINNER;
      this.stationsService.updateItem(this.item)
      .subscribe(res => {
        this.saving = false;
        this.iconSave = ICON_FLOPPY;
        notify("Дані збережено", "success", 600);
        this.goBack();
      },
      error => {
        this.error = error;
        this.iconSave = ICON_FLOPPY;
      });
    }

    cancelGenerateKeyPopup(){
      this.popupVisibleGenerateKey = false;
    }

    generateKeyPopup(terminal){
      this.emitRefreshToken = "";
      this.curTerminal = terminal;
      this.popupVisibleGenerateKey = true;
    }


    generateKey(){
      this.iconKey = ICON_SPINNER;
      if(this.curTerminal.userGuid == undefined){
        this.stationsService.createTerminalUser(this.curTerminal)
        .subscribe(res => {
          this.curTerminal.userGuid = res.userGuid;
          this.executeEmitRefreshToken(this.curTerminal.userGuid);
        },
        error => {
          this.error = error;

        });
      } else {
        this.executeEmitRefreshToken(this.curTerminal.userGuid);
      }

      //this.emitRefreshToken = "dsfrghaeryh3gq3g";
    }

    executeEmitRefreshToken(userGuid: string){
      let emit = new EmitRefreshToken();
      emit.userGuid = userGuid;
      this.authService.emitRefreshToken(emit)
      .subscribe(res => {
        this.iconKey = ICON_KEY;
        this.emitRefreshToken = res.refreshToken;
      },
      error => {
        this.error = error;

      });


    }





  }
