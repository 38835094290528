export class ClientTransFW {
    date: Date;
    docId: number;    
    docType: number;
    station: number;
    stationAddress: string;
    fuelId: number;
    fuelName: string;
    fullFuelName: string;    
    amount: number;
}