import { NgModule }       from '@angular/core';
import { CommonModule }       from '@angular/common';

import { FormsModule }    from '@angular/forms';

import { PromotionsRoutingModule } from './promotions-routing.module';
import { PromotionsListComponent } from './promotions-list.component';
import { PromotionsEditComponent } from './promotions-edit.component';

import { DxSelectBoxModule, DxDataGridModule, DxButtonModule, DxSwitchModule, DxTextBoxModule, DxPopupModule, DxScrollViewModule, DxTabsModule, DxTextAreaModule, DxCheckBoxModule, DxTreeListModule, DxDateBoxModule, DxValidatorModule, DxRadioGroupModule, DxNumberBoxModule, DxListModule, DxTemplateModule, DxAccordionModule, DxTreeViewModule } from 'devextreme-angular';
import { Router, RouterModule } from '@angular/router';
import { PromotionsOrderComponent } from './promotions-order.component';
import { PromotionsTesterComponent } from './promotions-tester.component';
import { PromotionsOfflineComponent } from './promotions-offline.component';
import { PromotionsDigestComponent } from './promotions-digest.component';

@NgModule({
    imports: [
      PromotionsRoutingModule,
      CommonModule,
      FormsModule,
      DxSelectBoxModule,
      DxDataGridModule,
      DxButtonModule,
      DxSwitchModule,
      DxScrollViewModule,
      DxTextBoxModule,
      DxPopupModule,
      DxTabsModule,
      DxTextAreaModule,
      DxCheckBoxModule,
      DxTreeListModule,
      DxDateBoxModule,
      DxValidatorModule,
      DxRadioGroupModule,
      DxNumberBoxModule,
      DxListModule,
      DxTemplateModule,
      DxTreeViewModule,
      RouterModule
    ],
    declarations: [
      PromotionsEditComponent,
      PromotionsListComponent,
      PromotionsOrderComponent,
      PromotionsTesterComponent,
      PromotionsOfflineComponent,
      PromotionsDigestComponent,
    ]
  })
  export class PromotionsModule {

  }
