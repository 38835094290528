import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Tab, DeductionZSUTransactions, AddSumaMonobanka } from '../_models';
import { Globals } from '../globals';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Headers, RequestOptions } from '@angular/http';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

let deductionZSUTabs: Tab[] = [
  {
    id: 0,
    text: "Суми відрахувань",
    icon: "fa fa-credit-card"
  },
  {
    id: 1,
    text: "Встановлений відсоток",
    icon: "fa fa-list-ol"
  },
  {
    id: 2,
    text: "Благодійність",
    icon: "fa fa-handshake-o"
  }
];


@Injectable({ providedIn: 'root' })
export class DeductionZSUService {
  constructor(private http: HttpClient, private globals: Globals) { }

  getDeductionZSUTabs(): Tab[]{
    return deductionZSUTabs;
  }

  getTransDeductionZSU(dateFrom: string, dateTo: string): Observable<DeductionZSUTransactions[]>{
    return this.http
      .get<DeductionZSUTransactions[]>(this.globals.getApiString() + '/deductionzsu/trans-deduction-zsu?dateFrom=' + dateFrom + '&dateTo=' + dateTo,
        httpOptions);
  }

  getAddSumaMonobanka() {
    return this.http.get<AddSumaMonobanka>(this.globals.getApiString() + '/deductionzsu/additional-suma-monobanka', {});
  }

  updateAddSuma(suma: number) {
    return this.http
        .post<{}>(this.globals.getApiString() + '/deductionzsu/add-suma-deduction',
        JSON.stringify({
          suma: suma
        }),
        httpOptions);
  }

  
}
