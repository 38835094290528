export class TelegramUsers {
    is_mailing_send : boolean;
    user_id : number;
    chat_id : number;
    chat_first_name : string;
    chat_last_name : string;
    chat_user_name : string;
    first_message : Date;
    last_message : Date;
    is_auth : boolean;
    client_id : number;
    card_num_ext : number;
    card_num_int : number;
    is_send_trans : boolean;
}

export class TelegramMailing {
    chatId : number[];
    caption: string;
    message : string;    
    urlImg : string;
}