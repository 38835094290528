import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { PersonDelivery, Pushes, PushTaskProgress } from '../_models';
import { Globals } from '../globals';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Headers, RequestOptions } from '@angular/http';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

@Injectable({ providedIn: 'root' })
export class PushesService {
    constructor(private http: HttpClient, private globals: Globals) { }

    getPushes() {
        return this.http.get<Pushes[]>(this.globals.getApiString() + `/Pushes/Template`, {});
    }

    getPushById(id: number){
        return this.http.get<Pushes>(this.globals.getApiString() + `/Pushes/Template/`+ id, {});
    }

    newPush(item: Pushes): Observable<Pushes>{
        return this.http
            .post<Pushes>(this.globals.getApiString() + '/Pushes/Template', 
                JSON.stringify(item), 
                httpOptions);
    }

    updatePush(item: Pushes): Observable<Pushes>{
        return this.http
            .put<Pushes>(this.globals.getApiString() + '/Pushes/Template', 
                JSON.stringify(item), 
                httpOptions);
    }

    deletePushes(id: number){
        return this.http.delete(this.globals.getApiString() + `/Pushes/Template/`+ id, {});
    }

    pushesTypes() {
        return [
            {id: 0, value: "Загальна (негайна)"},
            {id: 2, value: "Загальна (адаптивна)"},            
            {id: 1, value: "Індивідуальна"}
        ];
    }

    pushesContentTypes() {
        return [
            {id: 0, value: "Стаття"},            
            {id: 1, value: "Посилання"}
        ];
    }

    pushesStatuses() {
        return [
            // {id: 0, value: "Очікування"},            
            {id: 1, value: "Виконується"},
            {id: 2, value: "Виконано"},
            {id: 3, value: "Припинено"},
            {id: 4, value: "Припинення"}
        ];
    }

    getClientsByVisibleNumbers(cards: string[]): Observable<PersonDelivery[]> {
        return this.http
            .post<PersonDelivery[]>(this.globals.getApiString() + '/Pushes/FcmByVisibleNumber',
            JSON.stringify(cards),
            httpOptions);
    }

    getClientsById(ids: string[]): Observable<PersonDelivery[]> {
        return this.http
            .post<PersonDelivery[]>(this.globals.getApiString() + '/Pushes/FcmByClientId',
            JSON.stringify(ids),
            httpOptions);
    }

    getPushTaskProgress(pushId: number){
        return this.http.get<PushTaskProgress>(this.globals.getApiString() + `/Pushes/PushTask/Progress/`+ pushId, {});
    }

    cancelTaskExecute(pushId: number) {
        return this.http
            .post<{}>(this.globals.getApiString() + '/Pushes/PushTask/Cancel/' + pushId,
            JSON.stringify({}),
            httpOptions);
    }

    getFileResult(guIdFile: string): Observable<any> {
        return this.http
            .post(this.globals.getPushesUrl() + '/PushTask/ResultTaskFile',
            JSON.stringify({fileGuId: guIdFile}),
            {
                headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
                responseType: "blob"
            });
    }
}