import { NgModule }       from '@angular/core';
import { CommonModule }       from '@angular/common';

import { FormsModule }    from '@angular/forms';

import { GoodsGroupsRoutingModule } from './goods-groups-routing.module';

import { GoodsGroupsListComponent } from './goods-groups-list.component';
import { GoodsGroupsEditComponent } from './goods-groups-edit.component';
import { DxButtonModule, DxDataGridModule, DxTreeListModule, DxTreeViewModule, DxDropDownBoxModule, DxTextBoxModule, DxPopupModule  } from 'devextreme-angular';
import { GoodsGroups } from '../../_models';

@NgModule({
    imports: [
      GoodsGroupsRoutingModule,
      CommonModule,
      FormsModule,
      DxButtonModule,
      DxDataGridModule,
      DxTreeListModule,
      DxTreeViewModule,
      DxDropDownBoxModule,
      DxTextBoxModule,
      DxPopupModule
    ],
    declarations: [
        GoodsGroupsEditComponent,
        GoodsGroupsListComponent   
    ]
  })
  export class GoodsGroupsModule {
    items: GoodsGroups[] = [];
  }
