import { NgModule }       from '@angular/core';
import { CommonModule }       from '@angular/common';

import { FormsModule }    from '@angular/forms';

import { VehicleBrandsRoutingModule } from './vehicle-brands-routing.module';
import { VehicleBrandsListComponent } from './vehicle-brands-list.component';
import { VehicleBrandsEditComponent } from './vehicle-brands-edit.component';

import { DxSelectBoxModule, DxDataGridModule, DxButtonModule, DxTextBoxModule, DxPopupModule } from 'devextreme-angular';
import { Router, RouterModule } from '@angular/router';

@NgModule({
    imports: [
      VehicleBrandsRoutingModule,
      CommonModule,
      FormsModule,
      DxSelectBoxModule,
      DxDataGridModule,
      DxButtonModule,
      DxTextBoxModule,
      DxPopupModule,
      RouterModule
    ],
    declarations: [
      VehicleBrandsEditComponent,
      VehicleBrandsListComponent   
    ]
  })
  export class VehicleBrandsModule {

  }
