import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { PromotionsWholesaleDocs } from '../../_models';
import { PromotionsWholesaleDocsService } from '../../_services';
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";
import { DxDataGridComponent } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';
import { Router } from '@angular/router';

@Component({
    templateUrl: 'promotions-wholesale-docs-list.component.html'
  })
  export class PromotionsWholesaleDocsListComponent implements OnInit, AfterViewInit {
    @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;

    items: PromotionsWholesaleDocs[] = [];
    itemsDetailed: PromotionsWholesaleDocs;    
    popupDocDetailedVisible = false;
    loading = false;
    error = '';

    store: ArrayStore;
    dataSource: DataSource;
    docDetailedDataSource: DataSource;

    public constructor(
        private titleService: Title,
        private promotionsWholesaleDocsService: PromotionsWholesaleDocsService,
        private router: Router
    )
    {
        this.titleService.setTitle("Оптові знижки");
    }

    ngAfterViewInit(): void {

    }
    ngOnInit(): void {
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        var allowed = [1];

        if(!allowed.includes(currentUser.UserType)){
            this.router.navigateByUrl("404")
        }
        
        this.loading = true;        
        this.loadDataSrouce();
    }

    loadDataSrouce(){
        this.promotionsWholesaleDocsService.getAllDocs()
        .subscribe(items => {
            this.items = items;
            this.initDataSource();
            this.loading = false;
        },
        error => {
            this.error = error;
            this.loading = false;
        });
    }

    initDataSource(){
        this.dataSource = new DataSource({
            store: new ArrayStore({
                key: "id",
                data: this.items,
                // Other ArrayStore options go here
            })
            // ,
            //  filter: [ "isDelete", "=", 0 ]
        });
    }

    initDataSourceDetailed(){
        this.docDetailedDataSource = new DataSource({
            store: new ArrayStore({
                key: "id",
                data: this.itemsDetailed.lines,
                // Other ArrayStore options go here
            })
            // ,
            //  filter: [ "isDelete", "=", 0 ]
        });
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(  {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'fa fa-filter',
                    onClick: this.refreshDataGrid.bind(this),
                    hint: 'Очистити фільтри'
                }
            });
    }

    refreshDataGrid() {
        //this.dataGrid.instance.refresh();
        this.dataGrid.instance.clearFilter();
    }

    showDocLines(e) {
        this.popupDocDetailedVisible = true;
        this.promotionsWholesaleDocsService.getDocById(e.data.id)
        .subscribe(items => {
            this.itemsDetailed = items;
            this.initDataSourceDetailed();
            this.loading = false;
        },
        error => {
            this.error = error;
            this.loading = false;
        });
    }
}