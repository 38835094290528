import { NgModule }       from '@angular/core';
import { CommonModule }       from '@angular/common';
import { FormsModule }    from '@angular/forms';
import { InsurancePolicyComponent } from './insurance-policy.component';
import {DxButtonModule, DxTextBoxModule, DxPopupModule, DxDataGridModule, DxTabsModule, DevExtremeModule} from 'devextreme-angular';
import {InsurancePolicyRoutingModule} from './insurance-policy-routing.module';

@NgModule({
  imports: [
    InsurancePolicyRoutingModule,
    CommonModule,
    FormsModule,
    DxButtonModule,
    DxTextBoxModule,
    DxPopupModule,
    DxDataGridModule,
    DxTabsModule,
    DevExtremeModule
  ],
  declarations: [
    InsurancePolicyComponent
  ]
})
export class InsurancePolicyModule {

}
