import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ValueId } from '../_models';
import { Users, ApiUserChange } from '../_models';
import { Globals } from '../globals';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Headers, RequestOptions } from '@angular/http';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  let userTypes: ValueId[] =
  [
    { id: 1, value: "Web адміністратор" },
    { id: 10, value: "Web користувач" },
    { id: 20, value: "Web анкети" },
    { id: 40, value: "Web лінія підтримки" },
    { id: 200, value: "API клієнт. Кабінет" },
    { id: 210, value: "API клієнт. FCSD" },
    { id: 300, value: "API клієнт. Товари" },
    { id: 400, value: "Web каса" },
    { id: 500, value: "API клієнт. Slots" },
    { id: 1000, value: "API клієнт. Virtual Cards" }
  ];  

@Injectable({ providedIn: 'root' })
export class UsersService {
    constructor(private http: HttpClient, private globals: Globals) { }

    getAll() {
        return this.http.get<Users[]>(this.globals.getApiString() + `/Users`, {});
    }

    getItem(id: number){
        return this.http.get<Users>(this.globals.getApiString() + `/Users/`+ id, {});
    }

    checkPassword(password: string){
        return this.http.get<string[]>(this.globals.getApiString() + `/Users/password-check?password=` + password, {});
    }

    changePassword(item: ApiUserChange): Observable<ApiUserChange>{
        return this.http
            .post<ApiUserChange>(this.globals.getApiString() + '/Users/password-change', 
            JSON.stringify(item), 
            httpOptions);
    }

    changeBlock(item: ApiUserChange): Observable<ApiUserChange>{
        return this.http
            .post<ApiUserChange>(this.globals.getApiString() + '/Users/user-block', 
            JSON.stringify(item), 
            httpOptions);
    }

    delete(guid: string): Observable<string>{
        return this.http
            .delete<string>(this.globals.getApiString() + '/Users', 
            httpOptions);
    }


    createItem(item: Users): Observable<Users>{
        return this.http
            .put<Users>(this.globals.getApiString() + '/Users', 
                JSON.stringify(item), 
                httpOptions);
    }

    updateItem(item: Users): Observable<Users>{
        return this.http
            .post<Users>(this.globals.getApiString() + '/Users', 
                JSON.stringify(item), 
                httpOptions);
    }

    deleteItem(id: number): Observable<void>{
        return this.http
            .delete<void>(this.globals.getApiString() + '/Users/' + id, 
                httpOptions); 
    }

    getUserTypes(): ValueId[] {
        return userTypes;
    }
}