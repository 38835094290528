import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CalendarComponent } from './calendar.component';
import { AuthGuard } from '../../_guards/auth.guard';

const routes: Routes = [
    { path: 'calendar',  component: CalendarComponent, canActivate: [AuthGuard] }
  ];
  
  @NgModule({
    imports: [
      RouterModule.forChild(routes)
    ],
    exports: [
      RouterModule
    ]
  })
  export class CalendarRoutingModule { }