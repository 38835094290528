export class WalletFuelsExchangeTransactions {
    id: number;
    createdUtc: Date;
    creadtedLocalDate: Date;
    clientId: number;
    fromFuelId: number;
    fromFuelName: string;
    fromAmount: number;
    fromPrice: number;
    toFuelId: number;
    toFuelName: string;
    toAmount: number;
    toPrice: number;
    status: number;
    fromDocId: number | null;
    toDocId: number | null;
    description: string;
    userName: string;
}