import { PromoSegment } from "./promo-segment";

export class PromoSegmentCondition {
    requireTypeId: number;
    requireSegments: PromoSegment[];

    exceptTypeId: number;
    exceptSegments: PromoSegment[];

    constructor(){
        this.requireSegments = [];
        this.exceptSegments = [];
        this.requireTypeId = 1;
        this.exceptTypeId = 1;
    }
}