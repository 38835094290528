import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TransactionsDeductionZSUComponent } from './transactions-deduction-zsu.component';
import { AuthGuard } from '../../_guards/auth.guard';

const routes: Routes = [
  { path: 'transactions-deduction-zsu',  component: TransactionsDeductionZSUComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class TransactionsDeductionZSURoutingModule { }
