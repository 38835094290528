import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TransactionsWalletBonusComponent } from './transactions-wallet-bonus.component';
import { AuthGuard } from '../../_guards/auth.guard';

const routes: Routes = [
  { path: 'transactions-wallet-bonus',  component: TransactionsWalletBonusComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class TransactionsWalletBonusRoutingModule { }
