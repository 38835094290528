import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {Tab, VmClientTransLine, Stations, PromoCalcMessage} from '../../_models';
import {AuthenticationService, TransactionsService, ClientsService, StationsService} from '../../_services';
import ArrayStore from "devextreme/data/array_store";
import { Router } from '@angular/router';
import notify from 'devextreme/ui/notify';
import DataSource from "devextreme/data/data_source";
import 'devextreme/data/odata/store';
import {DxDataGridComponent, DxDropDownBoxComponent} from 'devextreme-angular';
import {Globals} from '../../globals';
import {DatePipe} from '@angular/common';
import {TransPromo} from '../../_models/trans-promo';
import {forEach} from '@angular/router/src/utils/collection';

@Component({
  templateUrl: 'transactions-list.component.html',
  styleUrls: ['./promo-calc.css']
})
export class TransactionsListComponent implements OnInit, AfterViewInit {
  @ViewChild("transDataGrid") dataGrid: DxDataGridComponent;
  @ViewChild("clientsDataGrid") clientsDataGrid: DxDataGridComponent;
  @ViewChild("termGridBox") termGridBox: DxDropDownBoxComponent;

  transTabs: Tab[];
  isShowNumTrans: boolean = false;
  isShowClients: boolean = false;
  isShowStations: boolean = false;

  numTransValue: number;
  visibleCardNum: string;
  clientId: number;
  error = '';

  popupClientsVisible: boolean = false;
  popupTransPromoVisible: boolean = false;

  store: ArrayStore;

  today: Date;
  termDayReport: Date = new Date();

  dsTransLines: VmClientTransLine[];
  clientsDataSource: DataSource;
  termDataSource: Stations[];
  transPromo: TransPromo;
  termBoxValue: any;

  caption = 'Транзакції';

  transPromoTransId: number;
  transPromoDate: string;

  calcTotalSummaWithDisc: number = 0;
  calcTotalDisc: number = 0;
  calcTotalZSU: number = 0;

  term_displayExpr(item){
    return item.extCode + " " + item.name;
  }

  term_onSelectionChanged(e: any) {
    this.termGridBox.instance.close();
  }

  public constructor(
    private titleService: Title,
    private transactionsService: TransactionsService,
    private authenticationService: AuthenticationService,
    private clientsService: ClientsService,
    private stationsService: StationsService,
    private globals: Globals,
    private router: Router,
    private datePipe: DatePipe
  )
  {
    this.titleService.setTitle(this.caption);
  }

  ngAfterViewInit(): void {

  }

  ngOnInit(): void {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));

    var allowed = [1, 10, 40, 400];

    if(!allowed.includes(currentUser.UserType)){
      this.router.navigateByUrl("404")
    }

    let url = this.globals.serverName + "/odata/Clients";
    this.clientsDataSource = new DataSource({
      store: {
        type: "odata",
        url: url,
        key: "Id",
        version: 4,
        beforeSend: (e) => {
          console.log("Before send started");
          this.initODataHeader(e);


          console.log("Before send ended");
        }
      },
      expand: ["VehicleBrand"]
    });

    this.transTabs = this.transactionsService.getTransTabs();
    this.showTransTabId(0);

  }

  initODataHeader(e) {
    //Делаем вызов псевдо метода для обновления AccessToken если он просрочен

    this.authenticationService.checkAuthAjax();
    let authToken = 'Bearer ' + this.authenticationService.getAccessToken();
    console.log("Before send use: " + authToken);
    e.headers = {
      "Content-Type": "application/json",
      "Authorization": authToken
    }
  }

  showTransTabId(id: number) {
    this.isShowNumTrans = id == 0;
    this.isShowClients = id == 1;
    this.isShowStations = id == 2;
    if (id == 0) {

    }
    if (id == 1) {

    }
    if (id == 2) {



      this.stationsService.getActual()
        .subscribe(items => {
            this.termDataSource = items;
            //this.termBoxValue = items[0];
          },
          error => {
            this.error = error;
          });
    }
  }

  selectTransTab(e) {
    this.showTransTabId(e.itemData.id);
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',

        options: {
          icon: 'fa fa-filter',
          onClick: this.refreshDataGrid.bind(this),
          hint: 'Очистити фільтри'
        }
    });
  }

  refreshDataGrid() {
    if(this.dsTransLines) {
      this.dataGrid.instance.clearFilter();
    }
  }

  onContentReady(e) {
    console.log("onContentReady" + e.component);
  }

  onInitialized(e) {
    console.log("onInitialized" + e.component);
  }

  applyTransNum() {
    if(!this.numTransValue) {
      notify("Введіть номер транзакції", "error", 1000);
      return;
    }

    this.dataGrid.instance.beginCustomLoading('');

    this.transactionsService.getTransByParams(this.numTransValue, 0, '', 0, '')
      .subscribe(item => {
          this.dsTransLines = item;
          this.dataGrid.instance.endCustomLoading();
        },
        error => {
          this.error = error;
          this.dataGrid.instance.endCustomLoading();
          notify("Під час виконання запиту сталаса помилка", "error", 1000);
        });

  }

  onClickRow(e) {
    this.transactionsService.getTransCalc(e.data.transId)
      .subscribe(item => {
          if(!item) {
            notify("Інформація по даній транзакції відсутня", "error", 1000);
            return;
          }

          this.transPromo = item;

          if(!this.transPromo.calcResponse.dat) {
            notify("Інформація по даній транзакції відсутня", "error", 1000);
            return;
          }

          this.calcTotalDisc = 0;
          this.calcTotalZSU = 0;
          this.calcTotalSummaWithDisc = 0;
          this.transPromoTransId = e.data.transId;
          this.transPromoDate = this.datePipe.transform(this.transPromo.calcResponse.dat, 'yyyy-MM-dd HH:mm:ss');
          this.popupTransPromoVisible = true;

          this.transPromo.calcResponse.lines.forEach(value => {
            this.calcTotalSummaWithDisc += (value.amount * value.price) - value.discount;
            this.calcTotalDisc += value.discount;
            this.calcTotalZSU += value.deductionZSU;
          });

          },
        error => {
          this.error = error;
          notify("Під час виконання запиту сталаса помилка", "error", 1000);
          return;
        });
  }

  isShowMessagesPrint(item) {
    if(item != null && item.length > 0) {
      let _messages = item.filter(e => e.typeId == 5);
      if( _messages.length > 0) {
        return true;
      }
    }

    return false;
  }

  filteredAfterExecutionMessages(item) {
    return item.filter(e => e.typeId == 5);
  }

  cuttedMessagePrint(item) {
    if(item.valStr != null) {
      return this.splitStringIntoChunks(item.valStr, 43);
    }
    return [];
  }

  splitStringIntoChunks(inputString: string, chunkSize: number): string[] {
    const regex = new RegExp(`.{1,${chunkSize}}`, 'g');
    return inputString.match(regex) || [];
  }

  getMessagePromoCalc(message: PromoCalcMessage) {
    let result = "";
    if (message.typeId >= 100) {
      result += "Помилка: ";
    }
    return result + message.message;
  }

  onClientAdd() {
    this.popupClientsVisible = true;
  }

  chooseClient(value: number) {
    this.clientId = value;
    this.popupClientsVisible = false;
  }

  applyClients() {
    if(!this.visibleCardNum && !this.clientId) {
      notify("Введіть номер картки або код клієнта", "error", 1000);
      return;
    }

    this.dataGrid.instance.beginCustomLoading('');

    this.transactionsService.getTransByParams(0, !this.clientId ? 0 : this.clientId, this.visibleCardNum , 0, '')
      .subscribe(item => {
          this.dsTransLines = item;
          this.dataGrid.instance.endCustomLoading();
        },
        error => {
          this.error = error;
          this.dataGrid.instance.endCustomLoading();
          notify(!error.error ? 'Під час виконання запиту сталаса помилка' : error.error, "error", 1000);
        });
  }

  applyTermDate() {

    if(!this.termGridBox.value) {
      notify("Виберіть АЗК", "error", 1000);
      return;
    }

    if(!this.termDayReport) {
      notify("Введіть дату звіту", "error", 1000);
      return;
    }

    this.dataGrid.instance.beginCustomLoading('');

    this.transactionsService.getTransByParams(0,  0 , '', this.termGridBox.value[0].id, this.datePipe.transform(this.termDayReport, 'yyyy-MM-dd'))
      .subscribe(item => {
          this.dsTransLines = item;
          this.dataGrid.instance.endCustomLoading();
        },
        error => {
          this.error = error;
          this.dataGrid.instance.endCustomLoading();
          notify(!error.error ? 'Під час виконання запиту сталаса помилка' : error.error, "error", 1000);
        });

  }
}
