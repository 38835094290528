import { PromoRequireGoods } from "./promo-require-goods";
import { PromoExceptGoods } from "./promo-except-goods";
import { PromoConditionOther } from "./promo-condition-other";

export class PromoCondition {
    requireTypeId: number;
    requireGoods: PromoRequireGoods[];

    exceptTypeId: number;
    exceptGoods: PromoExceptGoods[];

    otherCondition: PromoConditionOther[];

    constructor(){
        this.requireGoods = [];
        this.exceptGoods = [];
        this.otherCondition = [];
        this.requireTypeId = 1;
        this.exceptTypeId = 2;
    }
}