export class WalletBonusOperations {
    id: string
    clientId: number
    operationType: string
    processingStatus: string
    description: string
    amount: number
    finalAmount: number
    createdDate: string
    lines: BasketLines[]
  }
  
  export class BasketLines {
    name: string
    quantity: number
    amount: number
    unit: string
  }