import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { PromoDigest } from '../../_models/promo/promo-digest';
import { PromotionsService } from '../../_services';

import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";

import notify from 'devextreme/ui/notify';
import { DxDataGridComponent } from 'devextreme-angular';
import { Router } from '@angular/router';
import { saveAs } from 'file-saver';

import DataGrid from "devextreme/ui/data_grid";

@Component({
    templateUrl: 'promotions-digest.component.html'
  })
  export class PromotionsDigestComponent implements OnInit, AfterViewInit {
    @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;

    items: PromoDigest[] = [];
    itemsOrig: PromoDigest[] = [];
    loading = false;
    error = '';

    store: ArrayStore;
    dataSource: DataSource;

    public constructor(
        private titleService: Title,
        private dataService: PromotionsService,
        private router: Router
    )
    {
        this.titleService.setTitle("Дайджест акцій");
    }

    ngAfterViewInit(): void {

    }
    ngOnInit(): void {
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        var allowed = [1];

        if(!allowed.includes(currentUser.UserType)){
            this.router.navigateByUrl("404")
        }
        this.loading = true;

        this.dataService.getDigest()
        .subscribe(items => {

            this.itemsOrig = JSON.parse(JSON.stringify(items));

            items.forEach(e => {
                if(e.segments != null) {
                    e.segments = e.segments.replace(/\n/g, "<br />");
                }
                if(e.poses != null) {
                    e.poses = e.poses.replace(/\n/g, "<br />");
                }
                if(e.periods != null) {
                    e.periods = e.periods.replace(/\n/g, "<br />");
                }
                if(e.conditions != null) {
                    e.conditions = e.conditions.replace(/\n/g, "<br />");
                }
                if(e.schema != null) {
                    e.schema = e.schema.replace(/\n/g, "<br />");
                }
                if(e.limits != null) {
                    e.limits = e.limits.replace(/\n/g, "<br />");
                }
                if(e.coordination != null) {
                    e.coordination = e.coordination.replace(/\n/g, "<br />");
                }
                if(e.otherActions != null) {
                    e.otherActions = e.otherActions.replace(/\n/g, "<br />");
                }
            });


            this.items = items;
            this.initDataSource();
            this.loading = false;
        },
        error => {
            this.error = error;
            this.loading = false;
        });
    }


    initDataSource(){
        this.dataSource = new DataSource({
            store: new ArrayStore({
                key: "id",
                data: this.items,
                // Other ArrayStore options go here
            }),
            //filter: [ "isDeleted", "=", "0" ]
        });
        this.dataGrid.instance.updateDimensions();
    }

    replaceNewlines(value) {
        if (typeof value === 'string') {
          return value.replace(/\n/g, ' | '); // Замінюємо /n на переноси рядка, якщо тип даних - рядок
        }
        return value; // Повертаємо інше значення без змін
      }

      onExportData() {
        const replacer = (key, value) => value === null ? '' : this.replaceNewlines(value); // Замінюємо /n на переноси рядка
        const header = Object.keys(this.itemsOrig[0]);
        let csv = this.itemsOrig.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
        csv.unshift(header.join(','));
      
        // Перетворюємо масив у рядок з роздільниками коми та переносами рядка
        let csvArray = csv.join('\r\n');
      
        // Створюємо Blob з нашими даними та задаємо кодування файлу UTF-8
        var blob = new Blob(["\ufeff", csvArray], { type: 'text/csv;charset=utf-8;' });
      
        // Зберігаємо файл за допомогою бібліотеки file-saver
        saveAs(blob, "PromoDigest.csv");
      }


    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'fa fa-file-text-o',
                    onClick: this.onExportData.bind(this),
                    hint: 'Експорт'
                }
            }
        );

        e.toolbarOptions.items.unshift(  {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'fa fa-filter',
                    onClick: this.refreshDataGrid.bind(this),
                    hint: 'Очистити фільтри'
                }
            }            
        );
    }

    refreshDataGrid() {
        this.dataGrid.instance.clearFilter();
      }
  }
