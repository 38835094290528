import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TelegramBotListComponent } from './telegram-bot-list.component';
import { AuthGuard } from '../../_guards/auth.guard';

const routes: Routes = [
  { path: 'telegram',  component: TelegramBotListComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class TelegramBotRoutingModule { }
