import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { PromoCalc, PromoCalcCommit, ValueId } from '../_models';
import { Globals } from '../globals';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Headers, RequestOptions } from '@angular/http';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

let calcLevels: ValueId[] =
  [
      {
          id: 1,
          value: "Продакшен + Тест"
      },
      {
          id: 2,
          value: "Тест"
      },
      {
          id: 3,
          value: "Продакшен"
      }
  ];


@Injectable({ providedIn: 'root' })
export class PromoTesterService {
    constructor(private http: HttpClient, private globals: Globals) { }

    calculate(item: PromoCalc): Observable<PromoCalc>{
        return this.http
            .post<PromoCalc>(this.globals.getApiString() + '/PromoTester', 
                JSON.stringify(item), 
                httpOptions);
    }

    commit(item: PromoCalcCommit): Observable<PromoCalcCommit>{
        return this.http
            .post<PromoCalcCommit>(this.globals.getApiString() + '/PromoTester/commit', 
                JSON.stringify(item), 
                httpOptions);
    }

    getCalcLevels(): ValueId[] {
        return calcLevels;
    }

   
}