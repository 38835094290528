import { ValueName } from "..";

export class PromoCoordination {
    behaviorTypeId: number;
    blockPromos: ValueName[];
    blockedByPromos: ValueName[];
    applyWithPromos: ValueName[];

    constructor(){
        this.behaviorTypeId = 1;
        this.blockPromos = [];
        this.blockedByPromos = [];
        this.applyWithPromos = [];
    }
}