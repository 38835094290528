import { NgModule }       from '@angular/core';
import { CommonModule }       from '@angular/common';

import { FormsModule }    from '@angular/forms';

import { CountersManualDocsRoutingModule } from './counters-manual-docs-routing.module';
import { CountersManualDocsListComponent } from './counters-manual-docs-list.component';
import { CountersManualDocsEditComponent } from './counters-manual-docs-edit.component';

import { DxSelectBoxModule, DxDataGridModule, DxButtonModule, DxTextBoxModule, DxPopupModule, DxTextAreaModule, DxNumberBoxComponent, DxNumberBoxModule, DxValidationGroupModule, DxValidationSummaryModule, DxValidatorModule, DxDateBoxModule } from 'devextreme-angular';

@NgModule({
    imports: [
      CountersManualDocsRoutingModule,
      CommonModule,
      FormsModule,
      DxSelectBoxModule,
      DxDataGridModule,
      DxButtonModule,
      DxTextBoxModule,
      DxPopupModule,
      DxTextAreaModule,
      DxNumberBoxModule,
      DxValidatorModule,
      DxValidationSummaryModule,
      DxValidationGroupModule,
      DxDateBoxModule
    ],
    declarations: [
      CountersManualDocsEditComponent,
      CountersManualDocsListComponent   
    ]
  })
  export class CountersManualDocsModule {

  }
