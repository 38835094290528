import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(
        private router: Router
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser && currentUser.AccessToken) {
            request = request.clone({
                setHeaders: { 
                    Authorization: `Bearer ${currentUser.AccessToken}`
                }
            });
        }
        
        if(currentUser.User.indexOf('fillform') > -1){
            //console.log("process fillform");
            this.router.navigateByUrl("/clients/fill-form")
        }

        if(currentUser.User.indexOf('hotline') > -1){
            //console.log("process hotline");
            if(this.router.url.indexOf('/clients') == -1){
                this.router.navigateByUrl("/clients")
            }
   
        }

        return next.handle(request);
    }
}