export class PromoSchemaProduct {
    id: number;
    name: string;
    amount: number;
    rewards: PromoSchemaProductReward[];

    constructor(){
        this.rewards = [];
    }
}

export class PromoSchemaProductReward {
    reward: number;
}