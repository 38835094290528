import { Injectable } from '@angular/core';
import { Http, Headers, Response, RequestOptions } from '@angular/http';
import { map, share } from 'rxjs/operators';
import { Globals } from '../globals';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EmitRefreshToken } from '../_models';
import { Observable } from 'rxjs';


declare var jquery: any;
declare var $: any;

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    public token: string;
    public refreshToken: string;

    constructor(private http: Http, private httpClient: HttpClient, private globals: Globals) {
        // set token if saved in local storage
        this.readTokens();

    }

    checkcaptcha(token: string) {
        
        let jsonHeaders = new Headers({ 
            'Content-Type': 'application/json',
         });
        let options = new RequestOptions({ headers: jsonHeaders });
        let body = JSON.stringify({ token: token });
        let uri = this.globals.authServerName + '/api/v1/token/CheckCaptcha';
        let res: boolean = false;
        return this.http.post(uri, body, options).pipe();
    }

    login(username: string, password: string) {
        let jsonHeaders = new Headers({ 'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: jsonHeaders });
        let body = JSON.stringify({ User: username, Password: password });
        return this.http.post(this.globals.authServerName + '/api/v1/token/PasswordLogin', body, options)
            .pipe(
                map((response: Response) => {
                    // login successful if there's a jwt token in the response
                    let res = response.json();

                    if (res && res.AccessToken) {
                        this.token = res.AccessToken;
                        // store user details and jwt token in local storage to keep user logged in between page refreshes
                        localStorage.setItem('currentUser', JSON.stringify({ User: res.User, AccessToken: res.AccessToken, RefreshToken: res.RefreshToken, ExpiresIn: res.ExpiresIn, Issued: res.Issued, Expires: res.Expires, UserType: res.UserType}));
                    }
                    else {
                        this.logout();
                    }
                })
            );

    }

    authRefreshToken() {
        let jsonHeaders = new Headers({ 'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: jsonHeaders });
        let body = JSON.stringify({ RefreshToken: this.refreshToken });
        return this.http.post(this.globals.authServerName + '/api/v1/token/RefreshTokenLogin', body, options)
            .pipe(
                share(),
                map((response: Response) => {
                    // login successful if there's a jwt token in the response
                    let res = response.json();

                    if (res && res.AccessToken) {
                        this.token = res.AccessToken;
                        // store user details and jwt token in local storage to keep user logged in between page refreshes
                        //console.log("write accesstoken to local storage " + res.data.AccessToken);
                        localStorage.setItem('currentUser', JSON.stringify({ User: res.User, AccessToken: res.AccessToken, RefreshToken: res.RefreshToken, ExpiresIn: res.ExpiresIn, Issued: res.Issued, Expires: res.Expires, UserType: res.UserType }));
                    }
                    else {
                        this.logout();
                    }
                })
            );
    }

    emitRefreshToken(item: EmitRefreshToken): Observable<EmitRefreshToken> {
        return this.httpClient
            .post<EmitRefreshToken>(this.globals.authServerName + '/api/v1/token/emit-terminal-refreshtoken',
                JSON.stringify(item),
                httpOptions);
    }

    readTokens() {
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser) {
            this.token = currentUser.AccessToken;
            this.refreshToken = currentUser.RefreshToken;
        }
    }

    getAccessToken() {
        this.readTokens();
        return this.token;
    }

    getUserName() {
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser) {
            return currentUser.User;
        }

    }

    getUserType() {
        var currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser) {
            return currentUser.UserType;
        }

    }

    async checkAuth() {
        console.log("check auth begin");
        let res;

        await this.httpClient
            .get(this.globals.authServerName + '/api/v1/token/check-auth',
                httpOptions)
            .toPromise()
            .then((r) => res = r)
            .catch(error => {
                console.log("ERROR PROMISE: " + JSON.stringify(error));
            });
        console.log("check auth end");
        return true;
    }

    checkAuthAjax() {
        let token = this.getAccessToken();
        let checkUrl = this.globals.authServerName + "/api/v1/token/check-auth";
        let refreshTokenUrl = this.globals.authServerName + "/api/v1/token/RefreshTokenLogin";
        let body = JSON.stringify({ RefreshToken: this.refreshToken });
        $.ajax({
            method: "GET",
            url: checkUrl,
            beforeSend: function (xhr) { xhr.setRequestHeader('Authorization', 'Bearer ' + token) },
            async: false
        })
            .done(function () {
                //console.log("ajax finished");
            })
            .fail(function () {
                //console.log("ajax failed");
                $.ajax({
                    method: "POST",
                    url: refreshTokenUrl,
                    beforeSend: function (xhr) { xhr.setRequestHeader('Content-Type', 'application/json') },
                    async: false,
                    data: body
                })
                    .done(function (e) {
                        //console.log("RefreshTokenAjax received: " + JSON.stringify(e));
                        localStorage.setItem('currentUser', JSON.stringify({ User: e.User, AccessToken: e.AccessToken, RefreshToken: e.RefreshToken, ExpiresIn: e.ExpiresIn, Issued: e.Issued, Expires: e.Expires, UserType: e.UserType }));
                    })
                    .fail(function () {
                        //console.log("RefreshTokenAjax failed");
                        localStorage.removeItem('currentUser');
                    });
            });
    }


    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
    }
}