import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, switchMap, filter, take } from 'rxjs/operators';
import { Globals } from '../globals';

import { AuthenticationService } from '../_services';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    private refreshTokenInProgress = false;
    // Refresh Token Subject tracks the current token, or is null if no token is currently
    // available (e.g. refresh pending).
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
        null
    );

    constructor(private authenticationService: AuthenticationService, private globals: Globals) { }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError(err => {
                //Для запросов содержащих строки не применять этот перехватчик
                /*
                if(request.url.includes("RefreshToken") ||
                    request.url.includes("PasswordLogin")){
                    //Если ошибка произошла при авторизации через RefreshToken тогда auto logout и перекидываем на страницу Login для авторизации
                    if (request.url.includes("RefreshTokenLogin")) {
                        this.authenticationService.logout();
                        location.reload(true);
                    }
                    return throwError(err);    
                }
                */
                if (request.url.includes("check-auth")) {
                    return throwError(err);
                }
                //Если ошибка не 401, не перехватываем её
                if (err.status != 401) {
                    return throwError(err);
                }

                // если получили 401 из LoyaltyStorage
                if(err.status == 401 && err.url.includes(this.globals.getStorageApiString())) {
                    return throwError(err);
                }

                console.log(err);

                if (this.refreshTokenInProgress) {
                    // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
                    // – which means the new token is ready and we can retry the request again
                    return this.refreshTokenSubject.pipe(
                        filter(result => result !== null),
                        take(1),
                        switchMap(() => next.handle(this.addAuthenticationToken(request)))
                    );
                }
                else {
                    this.refreshTokenInProgress = true;

                    // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
                    this.refreshTokenSubject.next(null);

                    return this.authenticationService
                        .authRefreshToken()
                        .pipe(
                            switchMap((token: any) => {
                                //When the call to refreshToken completes we reset the refreshTokenInProgress to false
                                // for the next time the token needs to be refreshed
                                this.refreshTokenInProgress = false;
                                this.refreshTokenSubject.next(token);

                                return next.handle(this.addAuthenticationToken(request));
                            }),
                            catchError((err: any) => {
                                this.refreshTokenInProgress = false;
                                if (err.status == 401) {
                                    this.authenticationService.logout();
                                    location.reload(true);
                                }

                                return throwError(err);
                            })
                        );

                }

            })) as any;
    }

    addAuthenticationToken(request) {
        // Get access token from Local Storage
        const accessToken = this.authenticationService.getAccessToken();

        // If access token is null this means that user is not logged in
        // And we return the original request
        if (!accessToken) {
            return request;
        }

        // We clone the request, because the original request is immutable
        return request.clone({
            setHeaders: {
                Authorization: `Bearer ` + this.authenticationService.getAccessToken()
            }
        });
    }



}