import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Globals } from '../globals';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Headers, RequestOptions } from '@angular/http';
import { TelegramMailing, TelegramUsers } from '../_models';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable({ providedIn: 'root' })
export class TelegramBotService {
  constructor(private http: HttpClient, private globals: Globals) { }

   getTelegramUsers(): Observable<TelegramUsers[]>{
     return this.http
       .get<TelegramUsers[]>(this.globals.getTelegramBotString() + '/Tools/telegram-users',
         httpOptions);
   }

   telegramSendMailing(item : TelegramMailing): Observable<TelegramMailing>{
     return this.http
       .post<TelegramMailing>(this.globals.getTelegramBotString() + '/Tools/telegram-send-mailing',
        JSON.stringify(item),
         httpOptions);
   }
}
