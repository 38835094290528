import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Pushes } from '../../_models';
import { PushesService } from '../../_services';
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";
import { DxDataGridComponent } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';
import { Router } from '@angular/router';
import { ValueId } from '../../_models/value-id';

@Component({
    templateUrl: 'pushes-list.component.html'
  })
  export class PushesListComponent implements OnInit, AfterViewInit {
    @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;

    items: Pushes[] = [];
    loading = false;
    error = '';

    store: ArrayStore;
    dataSource: DataSource;
    dsPushesContentTypes: ValueId[];
    dsPushesTypes: ValueId[];
    dsPushesStatuses: ValueId[];

    delItem: Pushes;
    isDeletePopupVisible: boolean = false;

    public constructor(
        private titleService: Title,
        private pushesService: PushesService,
        private router: Router
    )
    {
        this.titleService.setTitle("Розсилки");
    }

    ngAfterViewInit(): void {

    }
    ngOnInit(): void {
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        var allowed = [1];

        if(!allowed.includes(currentUser.UserType)){
            this.router.navigateByUrl("404")
        }

        this.dsPushesTypes = this.pushesService.pushesTypes();
        this.dsPushesContentTypes = this.pushesService.pushesContentTypes();
        this.dsPushesStatuses = this.pushesService.pushesStatuses();
        
        this.loading = true;        
        this.loadDataSrouce();
    }

    loadDataSrouce(){
        this.pushesService.getPushes()
        .subscribe(items => {
            this.items = items;
            this.initDataSource();
            this.loading = false;
        },
        error => {
            this.error = error;
            this.loading = false;
        });
    }

    initDataSource(){
        this.dataSource = new DataSource({
            store: new ArrayStore({
                key: "id",
                data: this.items,
                // Other ArrayStore options go here
            }),
             filter: [ "deleted", "=", false ]
        });
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(  {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'fa fa-filter',
                    onClick: this.refreshDataGrid.bind(this),
                    hint: 'Очистити фільтри'
                }
            });
    }

    refreshDataGrid() {
        //this.dataGrid.instance.refresh();
        this.dataGrid.instance.clearFilter();
      }

    deleteItem(data){
        this.delItem = data.data as Pushes;
        this.isDeletePopupVisible = true;
    }

    deleteCancel(){
        this.delItem = null;
        this.isDeletePopupVisible = false;
    }

    deleteConfirm(){
        this.loading = true;
        this.pushesService.deletePushes(this.delItem.id)
        .subscribe(item => {
            this.loadDataSrouce();
            this.deleteCancel();
            notify("Розсилку успішно видалено", "success", 600);
            this.loading = false;
        },
        error => {
            this.error = error;
            this.loading = false;
        });
    }
}