export class PromoDocsPos {
    id: string;
    stationId: number;
    stationName: string;
    stationAddress: string;
    terminalId: number;
    terminalName: string;
}

export class PromoDocsFileInfo {
    guId: string;
    name: string;
    size: number;
    contentType: string;
}

export class PromoDocsSettings {
    useAllPoses: boolean;
    poses: PromoDocsPos[];
}


export class PromoDocsJsonData {
    fileInfo: PromoDocsFileInfo;
    settings: PromoDocsSettings;
}

export class PromoDocs {
    id: number;
    fileStorageGuId: string;
    modifDate: string;
    name: string;
    description: string;
    jsonData: string;
    isActive: number;
    isDelete: number;
    isActiveBool: boolean;
    data: PromoDocsJsonData;
}