import { Component } from '@angular/core';
import { Title }     from '@angular/platform-browser';
import { Location } from '@angular/common';

@Component({
  templateUrl: 'not-found.html'
})

export class PageNotFoundComponent {
  public constructor(
    private titleService: Title,
    private _location: Location
    ) {
    this.titleService.setTitle('Сторінку не знайдено');
  }

back()
{
  this._location.back();
}
}

