import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { TriggerFcmSchedule, TriggerMessageTemplate, Tab, TriggerTaskPlan, TriggerTaskPlanCondOperatorStruct, TriggerTaskPlanCondParamStruct, ValueId } from '../_models';
import { Globals } from '../globals';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Headers, RequestOptions } from '@angular/http';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  let trPsTabs: Tab[] = [
    {
      id: 0,
      text: "Черга повідомлень",
      icon: "fa fa-list-ol"
    },
    {
      id: 1,
      text: "Шаблони повідомлень",
      icon: "fa fa-file-image-o"
    },
    {
        id: 2,
        text: "Планувальник",
        icon: "fa fa-calendar"
      }
  ];
  
  let trTaskPlanTabs: Tab[] = [
    {
      id: 0,
      text: "Розклад",
      icon: "fa fa-table"
    },
    {
      id: 1,
      text: "Умови",
      icon: "fa fa-code-fork"
    }
  ];

  let trTaskPlanCondParamDataSales: TriggerTaskPlanCondParamStruct[] = [
    {
        id: "TotalCount",
        caption: "Кількість покупок (транзакцій)"
    },        
    {
        id: "TotalSumma",
        caption: "Сума (грн.) всіх покупок"
    }
];

  let trTaskPlanCondParamCards: TriggerTaskPlanCondParamStruct[] = [
    {
        id: "VisibleNumber",
        caption: "Зовніш.№ карты"
    },
    {
        id: "InternalNumber",
        caption: "Внутр.№ карты"
    },
];

let trTaskPlanCondParamProfile: TriggerTaskPlanCondParamStruct[] = [
    {
        id: "Surname",
        caption: "Прізвище"
    },
    {
        id: "Name",
        caption: "І'мя"
    },
    {
        id: "MName",
        caption: "По батькові"
    },
    {
        id: "Birthdate",
        caption: "Дата народження"
    },
    {
        id: "FillDate",
        caption: "Дата заповнення"
    },
    {
        id: "Postcode",
        caption: "Індекс"
    },
    {
        id: "AreaStr",
        caption: "Область"
    },
    {
        id: "CityStr",
        caption: "Місто/село"
    },
    {
        id: "Street",
        caption: "Вулиця"
    },
    {
        id: "House",
        caption: "Будинок"
    },
    {
        id: "Flat",
        caption: "Квартира"
    },
    {
        id: "PhoneConfirmed",
        caption: "Підтверджений тел."
    },
    {
        id: "PhoneCell",
        caption: "Моб.тел."
    },
    {
        id: "PhoneContact",
        caption: "Контакт.тел."
    },
    {
        id: "EMail",
        caption: "E-Mail"
    },
    {
        id: "VehicleType",
        caption: "Тип транс.засобу"
    },
    {
        id: "VehicleBrandName",
        caption: "Марка транс.засобу"
    },
    {
        id: "VehicleYear",
        caption: "Рік випуску"
    },
    {
        id: "TankVolume",
        caption: "Обсяг бака"
    },
    {
        id: "Gender",
        caption: "Стать"
    },
    {
        id: "MaritalStatus",
        caption: "Сімейний стан"
    },
    {
        id: "SecretWord",
        caption: "Кодове слово/пароль"
    },
    {
        id: "IsSubscribed",
        caption: "Згода отрим.новини"
    },
    {
        id: "Text1",
        caption: "Текст 1"
    },
    {
        id: "Text2",
        caption: "Текст 2"
    },
    {
        id: "Text3",
        caption: "Текст 3"
    },
    {
        id: "Text4",
        caption: "Текст 4"
    }
];

let trTaskPlanCondPeriodTypes : ValueId[] = [
    {
        id: 1,
        value: "День"
    },
    {
        id: 2,
        value: "Тиждень"
    },
    {
        id: 3,
        value: "Місяць"
    },
    {
        id: 4,
        value: "Рік"
    },
    {
        id: 5,
        value: "Діапазон дат"
    }
];

let trTaskPlanCondOperatorStr : TriggerTaskPlanCondOperatorStruct[] = [
    {
        id: "contains",
        caption: "Містить"
    },
    {
        id: "notContains",
        caption: "Не містить"
    },
    {
        id: "beginWith",
        caption: "Починається з"
    },
    {
        id: "equal",
        caption: "Дорівнює"
    },
    {
        id: "notEqual",
        caption: "Не дорівнює"
    }
];

let trTaskPlanCondOperatorNumber : TriggerTaskPlanCondOperatorStruct[] = [
    {
        id: "equal",
        caption: "Дорівнює"
    },
    {
        id: "notEqual",
        caption: "Не дорівнює"
    },
    {
        id: "great",
        caption: "Більше"
    },
    {
        id: "less",
        caption: "Меньше"
    },
    {
        id: "greatEqual",
        caption: "Більше або дорівнює"
    }
    ,
    {
        id: "lessEqual",
        caption: "Меньше або дорівнює"
    }
];

let trTaskPlanCondTypes : ValueId[] = [
    {
        id:1,
        value: "Дані анкети"
    },
    {
        id:2,
        value: "Картки клієнта"
    },
    {
        id:3,
        value: "К-ть балів за період"
    },
    {
        id:4,
        value: "Дані продажу (к-ть або сума)"
    },
    {
        id:5,
        value: "Кіл-ть проданого товару"
    },
    {
        id:6,
        value: "Сума проданого товару"
    },
    {
        id:7,
        value: "Кіл-ть проданого товару (по групам)"
    },
    {
        id:8,
        value: "Сума проданого товару (по групам)"
    },
    {
        id:9,
        value: "День народження"
    }
];

@Injectable({ providedIn: 'root' })
export class PushesTriggersService {
    constructor(private http: HttpClient, private globals: Globals) { }

    getTemplates() {
        return this.http.get<TriggerMessageTemplate[]>(this.globals.getApiString() + `/PushesTriggers/Templates`, {});
    }

    getTemplatesOnlyActive() {
        return this.http.get<TriggerMessageTemplate[]>(this.globals.getApiString() + `/PushesTriggers/Templates?isActive=true`, {});
    }

    getTemplatesById(id: number){
        return this.http.get<TriggerMessageTemplate>(this.globals.getApiString() + `/PushesTriggers/Template/`+ id, {});
    }

    newTemplate(item: TriggerMessageTemplate): Observable<TriggerMessageTemplate>{
        return this.http
            .post<TriggerMessageTemplate>(this.globals.getApiString() + '/PushesTriggers/Template', 
                JSON.stringify(item), 
                httpOptions);
    }

    updateTemplate(item: TriggerMessageTemplate): Observable<TriggerMessageTemplate>{
        return this.http
            .put<TriggerMessageTemplate>(this.globals.getApiString() + '/PushesTriggers/Template', 
                JSON.stringify(item), 
                httpOptions);
    }

    deleteTemplate(id: number){
        return this.http.delete(this.globals.getApiString() + `/PushesTriggers/Template/`+ id, {});
    }

    getSchedules(dateFrom: string, dateTo: string): Observable<TriggerFcmSchedule[]>{
        return this.http
          .get<TriggerFcmSchedule[]>(this.globals.getApiString() + '/PushesTriggers/Schedule?dateFrom=' + dateFrom + '&dateTo=' + dateTo,
            httpOptions);
      }

    getTaskPlanes() {
        return this.http.get<TriggerTaskPlan[]>(this.globals.getApiString() + `/PushesTriggers/TaskPlan`, {});
    }

    getTaskPlanesOnlyActive() {
        return this.http.get<TriggerTaskPlan[]>(this.globals.getApiString() + `/PushesTriggers/TaskPlan?isActive=true`, {});
    }

    getTaskPlaneById(id: number){
        return this.http.get<TriggerTaskPlan>(this.globals.getApiString() + `/PushesTriggers/TaskPlan/`+ id, {});
    }

    newTaskPlane(item: TriggerTaskPlan): Observable<TriggerTaskPlan>{
        return this.http
            .post<TriggerTaskPlan>(this.globals.getApiString() + '/PushesTriggers/TaskPlan', 
                JSON.stringify(item), 
                httpOptions);
    }

    updateTaskPlane(item: TriggerTaskPlan): Observable<TriggerTaskPlan>{
        return this.http
            .put<TriggerTaskPlan>(this.globals.getApiString() + '/PushesTriggers/TaskPlan', 
                JSON.stringify(item), 
                httpOptions);
    }

    deleteTaskPlane(id: number){
        return this.http.delete(this.globals.getApiString() + `/PushesTriggers/TaskPlan/`+ id, {});
    }

    getTaskPlanFileResult(guIdFile: string): string {
        return this.globals.getLoyaltyPushesUrl() + `/LoyaltyTriggerPushes/TaskPlan/ExecResFile/`+ guIdFile;
    }

    pushesTriggerTypes() {
        return [
            {id: 0, value: "Статичне повідомлення"},
            {id: 1, value: "Динамічне повідомлення"}
        ];
    }

    pushesTriggerContentTypes() {
        return [
            {id: 0, value: "Стаття"},            
            {id: 1, value: "Посилання"}
        ];
    }

    pushesTriggerTypeExecution() {
        return [
            {id: 0, value: "Після акції"},            
            {id: 1, value: "Планувальник"}
        ];
    }

    pushesTriggerStatuses() {
        return [
            {id: 0, value: "Очікування"},            
            {id: 1, value: "Виконується"},
            {id: 2, value: "Виконано"},
            {id: 3, value: "Припинено"},
            {id: 4, value: "Припинення"}
        ];
    }

    triggerTaskPlanTypeExecution()  {
        return [
            {id: 0, value: "Разовий"},            
            {id: 1, value: "Періодичний"}
        ];
    }

    triggerTaskPlanScheduleTypePeriod() {
        return [
            {id: 0, value: "Щодня"},            
            {id: 1, value: "Щотижня"},            
            {id: 2, value: "Щомісяця"}
        ];
    }

    triggerTaskPlanScheduleDaysNames() {
        return [
            {id: 1, value: "Понеділок"},            
            {id: 2, value: "Вівторок"},            
            {id: 3, value: "Середа"},
            {id: 4, value: "Четвер"},
            {id: 5, value: "П'ятниця"},
            {id: 6, value: "Субота"},
            {id: 7, value: "Неділя"}
        ];
    }

    triggerTaskPlanCondOperatorStr() : TriggerTaskPlanCondOperatorStruct[] {
        return trTaskPlanCondOperatorStr;
    }

    triggerTaskPlanCondOperatorNumber() : TriggerTaskPlanCondOperatorStruct[] {
        return trTaskPlanCondOperatorNumber;
    }

    triggerTaskPlanCondTypes() : ValueId[] {
        return trTaskPlanCondTypes;
    }

    triggerTaskPlanCondParamCards() : TriggerTaskPlanCondParamStruct[] {
        return trTaskPlanCondParamCards;
    }

    triggerTaskPlanCondParamProfile() : TriggerTaskPlanCondParamStruct[]  {
        return trTaskPlanCondParamProfile;
    }

    triggerTaskPlanCondParamDataSales() : TriggerTaskPlanCondParamStruct[] {
        return trTaskPlanCondParamDataSales;
    }

    triggerTaskPlanCondPeriodTypes() : ValueId[] {        
        return trTaskPlanCondPeriodTypes;
    }

    pushesTriggerTabs() {
        return trPsTabs;
    }

    pushesTriggerTaskPlanTabs() {
        return trTaskPlanTabs;
    }


}