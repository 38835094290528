import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Promotions, ValueId, Id } from '../../_models';
import { PromotionsService } from '../../_services';

import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";

import notify from 'devextreme/ui/notify';
import { DxDataGridComponent, DxListComponent } from 'devextreme-angular';
import { Router } from '@angular/router';
import { Location } from '@angular/common';


const ICON_SPINNER: string = "fa fa-lg fa-spinner fa-spin";
const ICON_FLOPPY: string = "fa fa-lg fa-floppy-o";

@Component({
    templateUrl: 'promotions-order.component.html'
  })
  export class PromotionsOrderComponent implements OnInit, AfterViewInit {
    @ViewChild(DxListComponent) promoList: DxListComponent;

    iconSave: string = ICON_FLOPPY;

    items: Promotions[] = [];
    loading = false;
    error = '';
    saving = false;

    ids: Id[] = [];

    store: ArrayStore;
    dataSource: DataSource;

    public constructor(
        private titleService: Title,
        private dataService: PromotionsService,
        private location: Location,
        private router: Router
    )
    {
        this.titleService.setTitle("Порядок акцій");
    }

    ngAfterViewInit(): void {

    }
    ngOnInit(): void {
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        var allowed = [1];

        if(!allowed.includes(currentUser.UserType)){
            this.router.navigateByUrl("404")
        }
        this.loading = true;

        this.dataService.getAll()
        .subscribe(items => {
            this.items = items;
            this.initDataSource();
            this.loading = false;
        },
        error => {
            this.error = error;
            this.loading = false;
        });
    }

    initDataSource(){
        this.dataSource = new DataSource({
            store: new ArrayStore({
                key: "id",
                data: this.items,
                // Other ArrayStore options go here
            }),
            filter: [ "isDeleted", "=", "0" ],
            sort: "promoOrder"
        });
    }

    onItemReordered(event){
        console.log(event);
    }

    goBack(){
        this.location.back();
    }

    update(){
        this.iconSave = ICON_SPINNER;
        //console.log(JSON.stringify(this.promoList.items));

        this.ids = [];
        this.promoList.items.forEach(item => {
            let promo = item as Promotions;
            let newVal = new Id();
            newVal.id = promo.id;
            this.ids.push(newVal);
            //console.log(JSON.stringify(promo));
        });
        console.log(JSON.stringify(this.ids));
        this.dataService.updateOrder(this.ids)
        .subscribe(res => {
          this.saving = false;
          notify("Дані збережено", "success", 600);
          this.goBack();
        },
        error => {
          this.error = error;
          this.iconSave = ICON_FLOPPY;
        });


    }


  }
