import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Clients } from '../../_models';
import { ClientsService } from '../../_services';

import DataSource from "devextreme/data/data_source";
import ArrayStore from "devextreme/data/array_store";
import { Router } from '@angular/router';

@Component({
    templateUrl: 'clients-list.component.html'
  })
  export class ClientsListComponent implements OnInit, AfterViewInit {

    dataSource: DataSource;

    items: Clients[] = [];
    loading = false;
    error = '';

    canBatch = false;

    public constructor(
        private titleService: Title,
        private clientsService: ClientsService,
        private router: Router
    )
    {
        this.titleService.setTitle("Клієнти");
    }

    ngAfterViewInit(): void {

    }

    ngOnInit(): void {
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser.UserType == 1) {
            this.canBatch = true;
        }
        var allowed = [1, 10, 40];

        if(!allowed.includes(currentUser.UserType)){
            this.router.navigateByUrl("404")
        }
        this.loading = true;
        this.clientsService.getAll()
        .subscribe(items => {
            this.items = items;
            this.dataSource = new DataSource({
                store: new ArrayStore({
                    key: "id",
                    data: this.items,
                    // Other ArrayStore options go here
                })
            });
            this.loading = false;
        },
        error => {
            this.error = error;
            this.loading = false;
        });
    }


    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(  {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'fa fa-filter',
                    onClick: this.refreshDataGrid.bind(this),
                    hint: 'Очистити фільтри'
                }
            });
    }

    refreshDataGrid() {
        //this.dataGrid.instance.clearFilter();
      }

    deleteItemId(id: number){

    }
  }
