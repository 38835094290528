import { NgModule }       from '@angular/core';
import { CommonModule }       from '@angular/common';
import { FormsModule }    from '@angular/forms';
import { PromotionsWholesaleDocsRoutingModule } from './promotions-wholesale-docs-routing.module';
import { PromotionsWholesaleDocsListComponent } from './promotions-wholesale-docs-list.component';
import { DxButtonModule, DxDataGridModule, DxTreeListModule, DxTreeViewModule, DxDropDownBoxModule, DxTextBoxModule, DxPopupModule, DxTextAreaModule, DxSelectBoxModule, DxDateBoxModule, DxCheckBoxModule  } from 'devextreme-angular';
import { PromotionsWholesaleDocs } from '../../_models';

@NgModule({
    imports: [
      PromotionsWholesaleDocsRoutingModule,
      CommonModule,
      FormsModule,
      DxButtonModule,
      DxDataGridModule,
      DxTreeListModule,
      DxTreeViewModule,
      DxDropDownBoxModule,
      DxTextBoxModule,
      DxPopupModule,
      DxTextAreaModule,
      DxSelectBoxModule,
      DxDateBoxModule,
      DxCheckBoxModule
    ],
    declarations: [
      PromotionsWholesaleDocsListComponent   
    ]
  })
  export class PromotionsWholesaleDocsModule {
    items: PromotionsWholesaleDocs[] = [];
  }
