import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { CountersService, GoodsGroupsService, GoodsService } from '../../_services';
import { Counters, Goods, GoodsGroups, GoodsGroupsGoods, GoodsLimits, ValueId } from '../../_models';

import DataSource from "devextreme/data/data_source";
import ArrayStore from "devextreme/data/array_store";
import { DxPopupComponent } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';
import { Globals } from '../../globals';
import { Router } from '@angular/router';
@Component({
    templateUrl: 'goods-edit.component.html'
  })
  export class GoodsEditComponent implements OnInit, AfterViewInit {
    @ViewChild(DxPopupComponent) popup;

    loading = false;
    saving = false;
    error = '';
    caption : string = "";

    dsMeasures: DataSource;
    dsGroups: DataSource;
    dsLimits: DataSource;
    dsLimitsTypePeriod: ValueId[];
    dsLimitsKoefCounters: Counters[];
    currLimitAddAmount: number = 0;
    currLimitAddPeriod: number = 1;
    currLimitCounterId: number;
    isPopupLimitsAdd: boolean = false;

    itemLimits: GoodsLimits[] = [];
    itemGroups: GoodsGroups[] = [];

    iconSave: string;

    popupVisible = false;

    private id;
    item: Goods;
    findItem: Goods;
    groupsList: GoodsGroups[] = [];

      public constructor(
      private titleService: Title,
      private goodsGroupsService: GoodsGroupsService,
      private goodsSerivce: GoodsService,
      private countersService: CountersService,
      private route: ActivatedRoute,
      private location: Location,
      private globals: Globals,
      private router: Router
    )
    {

    }

    refreshButtonIcon(){
      if(this.saving){
        this.iconSave = this.globals.ICON_SPINNER;
      } else {
        this.iconSave = this.globals.ICON_FLOPPY;
      }
    }

    ngAfterViewInit(): void {

    }
    ngOnInit(): void {
      let currentUser = JSON.parse(localStorage.getItem('currentUser'));

        var allowed = [1];

        if(!allowed.includes(currentUser.UserType)){
            this.router.navigateByUrl("404")
        }
      this.refreshButtonIcon();

      this.route.params.subscribe(params => {
        this.id = +params['id']; // (+) converts string 'id' to a number
      });

      this.dsMeasures = new DataSource({
        store: {
          type: "array",
          key: "id",
          data: [
            {id: 1, value: "шт."},
            {id: 2, value: "л."}
          ]
        }
      });

      this.dsLimitsTypePeriod = this.goodsSerivce.getLimitsPeriod();

      if(Number.isNaN(this.id)){
        this.caption = 'Додавання товару';
        this.titleService.setTitle(this.caption);
        this.item = new Goods();
        this.itemLimits = [];
        this.item.id = 0;
        //this.loading = true;
        this.initGroupsList();
        this.initDsCounters();
      }
      else
      {
        this.caption = 'Редагування товару';
        this.titleService.setTitle(this.caption);
        this.loading = true;

        this.goodsSerivce.getItem(this.id)
          .subscribe(item => {
            this.item = item;
            this.itemLimits = item.goodsLimits !== null ? item.goodsLimits : [];
            this.loading = false;
            this.initGroupsList();
            this.initDsLimits();
            this.initDsCounters();
        },
        error => {
            this.error = error;
            this.loading = false;
        });
      }
    }

    initGroupsList(){
      this.loading = true;
      this.goodsGroupsService.getAll()
      .subscribe(items => {
          this.groupsList = items;
          if(this.item.goodsGroups){
            this.item.goodsGroups.forEach(element => {
              let i = this.groupsList.findIndex(x => x.id == element.goodsGroupsId);
              this.itemGroups.push(this.groupsList[i]);
            });
          }
          this.loading = false;
          this.initDsGroups();


      },
      error => {
          this.error = error;
          this.loading = false;
      });
    }

    initDsGroups(){
      this.dsGroups = new DataSource({
        store: new ArrayStore({
            key: "id",
            data: this.itemGroups,
            // Other ArrayStore options go here
        }),
        filter: [ "isDeleted", "=", "0" ]
        // Other DataSource options go here
    });
    }

    initDsLimits(){
      this.dsLimits = new DataSource({
        store: new ArrayStore({
            key: "typePeriod",
            data: this.itemLimits,
            // Other ArrayStore options go here
        }),
        filter: [ "isDeleted", "=", "0" ]
        // Other DataSource options go here
    });
    }

    initDsCounters(){
      this.loading = true;
      this.countersService.getAll()
      .subscribe(items => {
          this.dsLimitsKoefCounters = items;
          this.loading = false;
      },
      error => {
          this.error = error;
          this.loading = false;
      });
    }

    deleteItemId(id){
      let i = this.itemGroups.findIndex(x => x.id == id);
      this.itemGroups[i].isDeleted = 1;

      this.dsGroups.load();
      this.initDsGroups();
    }

    goBack(){
      this.location.back();
    }

    onGroupAdd(){
      this.popupVisible = true;
    }

    update(){
      this.saving = true;
      this.refreshButtonIcon();

      let ggg: GoodsGroupsGoods[] = [];
      this.itemGroups.forEach(element => {
        let newGgg = new GoodsGroupsGoods();
        newGgg.goodsGroupsId = element.id;
        newGgg.isDeleted = element.isDeleted;
        ggg.push(newGgg);
      });
      this.item.goodsGroups = ggg;
      this.item.goodsLimits = this.itemLimits;
      this.goodsSerivce.updateItem(this.item)
        .subscribe(res => {
          this.saving = false;
          this.refreshButtonIcon();
          notify("Дані збережено", "success", 600);
          this.goBack();
        },
        error => {
          this.error = error;

        });
    }

    addGroup(id: number){
      //Если группа уже добавлена, то не добавляем
      if(this.itemGroups.findIndex(x => x.id == id) >= 0)
      {
        notify("Групу вже присвоєно", "error", 600);
        return;
      }

      let i = this.groupsList.findIndex(x => x.id == id);
      this.itemGroups.push(this.groupsList[i]);

      this.initDsGroups();
    }

    treeView_itemSelectionChanged(e){
      if(e.component.getSelectedNodesKeys() != 0){
        let groupId = e.component.getSelectedNodesKeys()[0];
        this.popupVisible = false;
        this.addGroup(groupId);
      }
    }

    onAddLimitShow(){
      this.isPopupLimitsAdd = true;
    }

    deleteLimit(e) {
      let _item = this.itemLimits[this.itemLimits.findIndex(x => x.typePeriod === e.data.typePeriod)];
      _item.isDeleted = 1;
      this.initDsLimits();
    }

    onAddLimit() {
      if(this.itemLimits.findIndex(x => x.typePeriod === this.currLimitAddPeriod) >= 0) {
        let _item = this.itemLimits[this.itemLimits.findIndex(x => x.typePeriod === this.currLimitAddPeriod)];
        _item.isDeleted = 0;
        _item.limitAmount = this.currLimitAddAmount;
        _item.counterId = this.currLimitCounterId;
      }
      else {
        let _item = new GoodsLimits();
        _item.isDeleted = 0;
        _item.limitAmount = this.currLimitAddAmount;
        _item.typePeriod = this.currLimitAddPeriod;
        _item.counterId = this.currLimitCounterId;
        this.itemLimits.push(_item);
      }

      this.initDsLimits();
      this.isPopupLimitsAdd = false;
    }

    onAddLimitCancel() {
      this.isPopupLimitsAdd = false;
    }

  }
