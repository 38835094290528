import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Pushes, FcmSchedule, PersonDelivery, PushTaskProgress } from '../../_models';
import { PushesService, AuthenticationService, ClientsService, ConfigService } from '../../_services';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import notify from 'devextreme/ui/notify';
import { Router } from '@angular/router';
import { ValueId } from '../../_models/value-id';

import { Globals } from '../../globals';
import { DxTextAreaComponent, DxTextBoxComponent, DxDataGridComponent } from 'devextreme-angular';
import DataSource from "devextreme/data/data_source";
import { DatePipe } from '@angular/common';
import { saveAs } from 'file-saver';

@Component({
  templateUrl: 'pushes-edit.component.html',
  styleUrls: ['./pushes-edit.css']
})

export class PushesEditComponent implements OnInit, AfterViewInit {
  @ViewChild('title') public title: DxTextBoxComponent;
  @ViewChild('header') public header: DxTextBoxComponent;
  @ViewChild('content') public content: DxTextAreaComponent;
  @ViewChild('link') public link: DxTextBoxComponent;
  @ViewChild("clientsDataGrid") clientsDataGrid: DxDataGridComponent;
  @ViewChild('fileImportInputClients') fileImportInputClients: any;
  @ViewChild('fileImportInputCards') fileImportInputCards: any;
  
  _shouldClose: boolean = false;

  loading = false;
  saving = false;
  error: string = "";

  caption: string = "";
  private isNewItem: boolean = false;
  isAllowEdit: boolean = true;
  isMessageContent: boolean = true;
  isPersonPush: boolean = false;
  showDensity: boolean = false;
  popupClientsVisible: boolean = false;
  iconSave: string;
  isPopupCancelTaskPushConfirm: boolean = false;

  isFCMV2Result: boolean = false;
  intervalProgress: any;

  countValidTokens: number = 0;
  pushesDensityDelay: number = 0;

  calculatedLengthTime: string = "";
  packetTimeSec: number = 3;

  dsPushesTypes: ValueId[];
  dsPushesContentTypes: ValueId[];

  clientsDataSource: DataSource;

  genderList: ValueId[];
  maritalStatusList: ValueId[];


  private id;
  private copy_from;
  item: Pushes;
  itemProgress: PushTaskProgress;

  public constructor(
    private titleService: Title,
    private pushesService: PushesService,
    private authenticationService: AuthenticationService,
    private configService: ConfigService,
    private clientsService: ClientsService,
    private route: ActivatedRoute,
    private location: Location,
    private globals: Globals,
    private router: Router,
    private datepipe: DatePipe
  ) {

  }

  refreshButtonIcon(){
    if(this.saving){
      this.iconSave = this.globals.ICON_SPINNER;
    } else {
      this.iconSave = this.globals.ICON_FLOPPY;
    }
  }

  onOpened(e) {
    this._shouldClose = true;
  }

  ngAfterViewInit(): void {

  }

  ngOnInit(): void {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));

        var allowed = [1];

        if(!allowed.includes(currentUser.UserType)){
            this.router.navigateByUrl("404")
        }
    this.refreshButtonIcon();

    this.genderList = this.clientsService.getGenders();
    this.maritalStatusList = this.clientsService.getMaritalStatuses();

    this.route.params.subscribe(params => {
      this.id = +params['id']; // (+) converts string 'id' to a number      
    });

    this.route.queryParams.subscribe(params => {
      this.copy_from = +params['copy_from'];
    });

    this.dsPushesTypes = this.pushesService.pushesTypes();
    this.dsPushesContentTypes = this.pushesService.pushesContentTypes();

    let clientsODataUrl = this.globals.serverName + "/odata/Pushes";
        this.clientsDataSource = new DataSource({
            store: {
                type: "odata",
                url: clientsODataUrl,
                key: "Id",
                version: 4,
                beforeSend: (e) => {
                    //console.log("Before send started");
                    this.initODataHeader(e);
                    //console.log("Before send ended");
                }
            },
            expand: ["Area","City","VehicleBrand","VehicleType"]
            ,
            filter: ["IsDeleted", "=", +"0"]
        });

    if (Number.isNaN(this.id)) {
      var startTime = new Date();
      var endTime = new Date();

      endTime.setHours(21);
      endTime.setMinutes(0);
      endTime.setSeconds(0);

      this.caption = 'Додавання розсилки';
      this.isNewItem = true;
      this.titleService.setTitle(this.caption);      
      this.item = new Pushes();    
      this.item.fcmSchedule = new FcmSchedule();
      this.item.personDelivery = [];
      this.item.id = 0;
      this.item.fcmSchedule.scheduleType = 0;
      this.item.fcmSchedule.deliveryAtLocal = startTime;
      //this.item.fcmSchedule.finishDeliveryAtLocal = this.add_days(new Date(), 1);
      this.item.fcmSchedule.finishDeliveryAtLocal = endTime;
      this.item.type = 0;
      this.item.addressingType = 2;
      this.item.density = 5;
      this.item.isActive = false;

      this.showDensity = true;

      if(!Number.isNaN(this.copy_from)) {
        this.loading = true;
        this.pushesService.getPushById(this.copy_from)
        .subscribe(item => {
          this.loading = false;

          let _item = item[0];
          this.item.type = _item.type;
          this.item.topic = _item.topic;
          this.item.title = _item.title;
          this.item.header = _item.header;
          this.item.content = _item.content;
          this.item.footer = _item.footer;
          this.item.image = _item.image;
          this.item.link = _item.link;
          this.item.addressingType = _item.addressingType;
          
          if(this.item.type === 0) {
            this.isMessageContent = true;
          }
          else {
            this.isMessageContent = false;
          }
          
          if(this.item.addressingType === 1) {
            this.isPersonPush = true;
          }
          else {
            this.isPersonPush = false;
          }

          if(this.item.addressingType > 0) {
            this.showDensity = true;
          }
          else {
            this.showDensity = false;
          }

          // this.item.fcmSchedule = new FcmSchedule();
          // this.item.fcmSchedule.scheduleType = 0;
          // this.item.fcmSchedule.deliveryAtLocal = new Date();
          // this.item.personDelivery = [];
    
//          this.item.isActive = false;

        },
          error => {
            this.error = error.error;
            this.loading = false;
          });
      }

    }
    else {
      this.caption = 'Редагування розсилки';
      this.titleService.setTitle(this.caption);

      this.pushesService.getPushById(this.id)
        .subscribe(item => {
          this.item = item[0]; 
          this.loading = false;
          
          if(this.item.type === 0) {
            this.isMessageContent = true;
          }
          else {
            this.isMessageContent = false;
          }
          
          if(this.item.addressingType === 1) {
            this.isPersonPush = true;
          }
          else {
            this.isPersonPush = false;
          }

          if(this.item.addressingType > 0) {
            this.showDensity = true;
          }
          else {
            this.showDensity = false;
          }

          if(this.item.fcmSchedule.completed &&
            (this.item.fcmSchedule.lastFcmScheduleStatusAndroidUpdateDateTime ||
            this.item.fcmSchedule.lastFcmScheduleStatusIphoneUpdateDateTime ||
            this.item.fcmSchedule.lastFcmScheduleStatusHuaweiUpdateDateTime)) {

              this.isAllowEdit = false;
              this.isFCMV2Result = false;

              this.item.fcmSchedule.lastFcmScheduleStatusAndroidUpdateDateTimeLocalView = this.datepipe.transform(this.item.fcmSchedule.lastFcmScheduleStatusAndroidUpdateDateTimeLocal, 'yyyy-MM-dd HH:mm:ss');
              this.item.fcmSchedule.lastFcmScheduleStatusIphoneUpdateDateTimeLocalView = this.datepipe.transform(this.item.fcmSchedule.lastFcmScheduleStatusIphoneUpdateDateTimeLocal, 'yyyy-MM-dd HH:mm:ss');
              this.item.fcmSchedule.lastFcmScheduleStatusHuaweiUpdateDateTimeLocalView = this.datepipe.transform(this.item.fcmSchedule.lastFcmScheduleStatusHuaweiUpdateDateTimeLocal, 'yyyy-MM-dd HH:mm:ss');
          }          
          else if(this.item.pushId) {
            this.isAllowEdit = false;
            this.isFCMV2Result = true;

            this.getPushTaskProgress();
            this.intervalProgress = setInterval(() => {
              this.getPushTaskProgress();
            }, 5000);
          }

          if(this.isAllowEdit) {
            this.calculateLengthTime();
          }
        },
          error => {
            this.error = error.error;
            this.loading = false;
          });
    }

    this.configService.getConfig().subscribe(res => {
      if(res.validTokens) {
        this.countValidTokens = res.validTokens;
      }
      if(res.pushesDensityDelay) {
        this.pushesDensityDelay = res.pushesDensityDelay;
      }

      if(this.isAllowEdit && this.item && this.item.addressingType) {
        this.calculateLengthTime();
      }
    });

  }

  getPushTaskProgress() {
    this.pushesService.getPushTaskProgress(this.item.pushId)
    .subscribe(item => {
      this.itemProgress = item;
      this.itemProgress.editDateLocal = this.datepipe.transform(new Date(item.editDateUtc), 'dd MMM yyyy, HH:mm:ss');
    });
  }

  ngOnDestroy(): void { 
    if(this.intervalProgress) {
      clearInterval(this.intervalProgress);
    }
  }

  add_months(dt, n) {
    return new Date(dt.setMonth(dt.getMonth() + n));
  }

  add_days(dt, n) {
    return new Date(dt.setDate(dt.getDate() + n));
  }

  initODataHeader(e) {
    //Делаем вызов псевдо метода для обновления AccessToken если он просрочен

    this.authenticationService.checkAuthAjax();
    let authToken = 'Bearer ' + this.authenticationService.getAccessToken();
    console.log("Before send use: " + authToken);
    e.headers = {
      "Content-Type": "application/json",
      "Authorization": authToken
    }
  }

  fileChangeListenerClients($event) { 

    // var text = [];
    // var files = $event.target.files;
    var input = $event.target;
    var reader = new FileReader();
    reader.readAsText(input.files[0]);

    reader.onload = (data) => {
      let csvData = reader.result;
      let csvRecordsArray = csvData.split(/\r\n|\n/);
      let clientsId = [];

      //console.log('records', csvRecordsArray);

      for (let i = 0; i < csvRecordsArray.length; i++) {

        if(csvRecordsArray[i] !== '') {
          clientsId.push(csvRecordsArray[i]);
        }
      }
      //console.log('numbers ', JSON.stringify(numbers));
      this.fileImportInputClients.nativeElement.value = "";

      // if(clientsId.length > 500) {
      //   notify("Кількість рядків не повина перевищувати 500", "error", 500);
      //   return;
      // }

      this.loading = true;
      this.error = '';
      this.pushesService.getClientsById(clientsId)
        .subscribe(res => {
          this.loading = false;
          if(res.length > 0) {
            //this.item.personDelivery = this.item.personDelivery.concat(res);
            this.item.personDelivery = res;
          }
          notify("Додано " + res.length + " клієнтів", "success", 1000);
        },
          error => {
            this.loading = false;
            this.error = JSON.stringify(error);

          });
    }

    reader.onerror = function () {
      alert('Unable to read ' + input.files[0]);
    };
  }

  fileChangeListenerCards($event) { 

    // var text = [];
    // var files = $event.target.files;
    var input = $event.target;
    var reader = new FileReader();
    reader.readAsText(input.files[0]);

    reader.onload = (data) => {
      let csvData = reader.result;
      let csvRecordsArray = csvData.split(/\r\n|\n/);
      let cards = [];

      //console.log('records', csvRecordsArray);

      for (let i = 0; i < csvRecordsArray.length; i++) {

        if(csvRecordsArray[i] !== '') {
          cards.push(csvRecordsArray[i]);
        }
      }
      
      this.fileImportInputCards.nativeElement.value = "";

      // if(cards.length > 500) {
      //   notify("Кількість рядків не повина перевищувати 500", "error", 500);
      //   return;
      // }

      this.loading = true;
      this.error = '';
      this.pushesService.getClientsByVisibleNumbers(cards)
        .subscribe(res => {
          this.loading = false;
          if(res.length > 0) {
            //this.item.personDelivery = this.item.personDelivery.concat(res);
            this.item.personDelivery = res;
          }
          notify("Додано " + res.length + " клієнтів", "success", 1000);
        },
          error => {
            this.loading = false;
            this.error = error;

          });
    }

    reader.onerror = function () {
      alert('Unable to read ' + input.files[0]);
    };
  }

  chooseClient(value: number) {
    this.clientsService.getItem(value)
      .subscribe(item => {
        let index = this.item.personDelivery.findIndex(x => x.clientId == item.id);
        if (index >= 0) {
          notify("Клієнт вже є в списку", "error", 600);
          return;
        }

        let line = new PersonDelivery();
        line.clientId = item.id;
        line.surname = item.surname;
        line.name = item.name;
        line.mName = item.mName;
        line.phoneConfirmed = item.phoneConfirmed;
        this.item.personDelivery.push(line);
        notify("Клієнт доданий в документ", "success", 600);
      },
        error => {
          notify("Помилка при підборі клієнта", "error", 600);
        });
  }

  goBack() {
    this.location.back();
  }

  save() {

    if(!this.validationForm()) {
      return;
    }

    if(this.isNewItem) {
      if(this.item.addressingType === 0) {
        this.item.topic = "/topics/all";
      }
      this.newPush();
    }
    else {
      this.updatePush();
    }
  }

  validationForm() {

    if(!this.item.title) {
      notify("Заповніть заголовок", "error", 1000);
      this.title.instance.focus();
      return false;
    }

    if(!this.item.header) {
      notify("Заповніть вступ", "error", 1000);
      this.header.instance.focus();
      return false;
    }

    if(this.item.type === 0) {
      if(!this.item.content) {
        notify("Заповніть повний текст", "error", 1000);
        this.content.instance.focus();
        return false;
      }      
    }

    if(this.item.type === 1) {
      if(!this.item.link) {
        notify("Заповніть посилання", "error", 1000);
        this.link.instance.focus();
        return false;
      }      
    }

    if(this.item.addressingType === 1) {
      if(!this.item.personDelivery || this.item.personDelivery.length == 0) {
        notify("Вкажіть клієнтів-одержувачів розсилки", "error", 1000);
        return false;
      }
    }

    if(!this.item.fcmSchedule.deliveryAtLocal) {
      notify("Заповніть дату відправки", "error", 1000);
      return false;      
    }
    else {
      this.item.fcmSchedule.deliveryAt = new Date(this.item.fcmSchedule.deliveryAtLocal).toISOString();
    }

    if(!this.item.fcmSchedule.finishDeliveryAtLocal) {
      notify("Заповніть дату припинення відправки", "error", 1000);
      return false;      
    }
    else {
      this.item.fcmSchedule.finishDeliveryAt = new Date(this.item.fcmSchedule.finishDeliveryAtLocal).toISOString();
    }

    // if(this.item.personDelivery.length > 500) {
    //   notify("Кількість одержувачів індивідуальної розсилки має бути менше 500", "error", 1000);
    //   return false;      
    // }
    // else {
    //   this.item.fcmSchedule.deliveryAt = new Date(this.item.fcmSchedule.deliveryAtLocal).toISOString();
    // }

    this.item.fcmSchedule.deliveryAt = new Date(this.item.fcmSchedule.deliveryAtLocal).toISOString();
    this.item.fcmSchedule.finishDeliveryAt = new Date(this.item.fcmSchedule.finishDeliveryAtLocal).toISOString();

    return true;
  }

  newPush() {
    this.saving = true;    
    this.refreshButtonIcon();    
    this.pushesService.newPush(this.item)
      .subscribe(res => {
//        console.log("update id: " + res.id);
        this.saving = false;
        this.refreshButtonIcon();
        notify("Дані збережено", "success", 600);
        this.goBack();
      },
        error => {
          this.saving = false;
          this.refreshButtonIcon();
          this.error = error.error;
        });
  }

  updatePush() {
    this.saving = true;
    this.refreshButtonIcon();
    this.pushesService.updatePush(this.item)
      .subscribe(res => {
//        console.log("update id: " + res.id);
        this.saving = false;
        this.refreshButtonIcon();
        notify("Дані збережено", "success", 600);
        this.goBack();
      },
        error => {          
          this.saving = false;
          this.refreshButtonIcon();
          this.error = error.error;

        });
  }

  onPushContentTypeChanged(e) {
    if (e.value == 0) // контент
    {
      this.isMessageContent = true;
    } 
    else  // ссылка
    {
      this.isMessageContent = false;
    }
  }

  onPushTypeChanged(e) {
    this.isPersonPush = e.value === 1;
    this.showDensity = e.value > 0;

    if(e.value > 0) {
      this.calculateLengthTime();
    }

  }

  onDensityChanged(e) {
    this.item.density = e.value;
    this.calculateLengthTime();
  }

  calculateLengthTime() {

    let t1;
    let t2;

    if(this.item.addressingType == 1) {      
      t1 = (this.item.personDelivery.length / 500 * ((this.item.density > 0 ? (this.pushesDensityDelay / 1000 * this.item.density) : 1) + this.packetTimeSec));
      t2 = this.formatTime(t1);
    }
    else {
      t1 = (this.countValidTokens / 500 * ((this.item.density > 0 ? (this.pushesDensityDelay / 1000 * this.item.density) : 1) + this.packetTimeSec));
      t2 = this.formatTime(t1);
    }

    this.calculatedLengthTime = "Приблизний час відправки: ~ " + t2;
  }

  formatTime(e) {
    let text;

    e = e < 1 ? 1 : e;

    if(e <= 60) {
      text = e.toFixed(0) + " сек";
    }
    else if(e <= 3600) {
      e = e / 60 + 1;
      text = e.toFixed(0) + " хв";
    }
    else {
      e = e / 3600;
      text = e.toFixed(2) + " год";
    }
    return text;
  }

  onClientAdd() {
    this.popupClientsVisible = true;
  }

  deleteClient(data) {
    let index = this.item.personDelivery.findIndex(x => x.clientId == data.value);
    if (index >= 0) {
      this.item.personDelivery.splice(index, 1);
    }
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'fa fa-filter',
        onClick: this.refreshDataGrid.bind(this),
        hint: 'Очистити фільтри'
      }
    });
  }

  refreshDataGrid() {
    this.clientsDataGrid.instance.clearFilter();
  }

  cancelExecuteTask() {

    if(this.item.pushId) {
      this.pushesService.cancelTaskExecute(this.item.pushId)
      .subscribe(item => {
        this.item.status = 4;
        this.isPopupCancelTaskPushConfirm = false;
      });
    }

      this.item.status = 4;
      this.isPopupCancelTaskPushConfirm = false;

  }

  onExitCancelTaskPush() {
    this.isPopupCancelTaskPushConfirm = false;
  }

  showCancelExecuteTask() {
    this.isPopupCancelTaskPushConfirm = true;
  }

  downloadResultFile() {
    this.pushesService.getFileResult(this.itemProgress.resultFile.guId)
    .subscribe(data => {
        saveAs(data, this.itemProgress.resultFile.name);
      },
      err => {
        notify("Не вдалося завантажити файл", "error", 1000);
      }
    );
  }

  calculateProgress(e) {
    let _percent = (e.executed * 100 / e.recipients);
    return _percent <= 100 ? _percent.toFixed(0) : 100;
  }

}
