import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Counters, VehicleBrands, Calendar, ValueId } from '../_models';
import { Globals } from '../globals';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Headers, RequestOptions } from '@angular/http';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

let dayTypes: ValueId[] =
    [
        {
            id: 1,
            value: "Робочий"
        },
        {
            id: 2,
            value: "Вихідний"
        },
        {
            id: 3,
            value: "Святковий"
        }
    ];

@Injectable({ providedIn: 'root' })
export class CalendarService {
    constructor(private http: HttpClient, private globals: Globals) { }

    getMonth(year: number, month: number): Observable<Calendar[]> {
        return this.http.get<Calendar[]>(this.globals.getApiString() + `/Calendar?year=` + year.toString() + `&month=` + month.toString(), {});
    }

    updateDay(item: Calendar): Observable<Calendar> {
        return this.http
            .post<Calendar>(this.globals.getApiString() + '/Calendar/update-day',
                JSON.stringify(item),
                httpOptions);
    }

    updateBulk(items: Calendar[]): Observable<Calendar[]> {
        return this.http
            .post<Calendar[]>(this.globals.getApiString() + '/Calendar/update-bulk',
                JSON.stringify(items),
                httpOptions);
    }

    getDayTypes(): ValueId[]{
        return dayTypes;
    }

}
