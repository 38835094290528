import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Promotions, ValueId, Stations, Terminals, Goods, PromoCalc, PromoCalcLine, Tab, PromoCalcMessage, PromoCalcCommit } from '../../_models';
import { PromotionsService, StationsService, GoodsService, PromoTesterService } from '../../_services';

import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";

import notify from 'devextreme/ui/notify';
import { DxDataGridComponent } from 'devextreme-angular';
import { Router } from '@angular/router';

import { isDevMode } from '@angular/core';
import { Globals } from '../../globals';
import { Observable, of } from "rxjs";


const ICON_SPINNER: string = "fa fa-lg fa-spinner fa-spin";
const ICON_CALCULATOR: string = "fa fa-lg fa-calculator";

@Component({
  templateUrl: 'promotions-tester.component.html',
  styleUrls: ['./promotions-tester.css']
})
export class PromotionsTesterComponent implements OnInit, AfterViewInit {
  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;
  @ViewChild('fileImportInput') fileImportInput: any;

  items: Promotions[] = [];
  loading = false;
  error = '';

  isShowCheck = false;
  isShowPromoResult = false;

  iconCalculate: string = ICON_CALCULATOR;

  item: PromoCalc;
  paytypes: ValueId[] = [];
  stations: Stations[] = [];
  terminals: Terminals[] = [];
  goods: Goods[] = [];

  calcLevels: ValueId[] = [];


  store: ArrayStore;

  stationsDataSource: DataSource;
  terminalsDataSource: DataSource;
  goodsDataSource: DataSource;

  popupGoodsVisible: boolean = false;

  tabs: Tab[] = [
    {
      id: 0,
      text: "Чек",
      icon: ""
    },
    {
      id: 1,
      text: "Результат по акціях",
      icon: ""
    }
  ];

  public constructor(
    private titleService: Title,
    private dataService: PromotionsService,
    private stationService: StationsService,
    private goodsService: GoodsService,
    private globals: Globals,
    private promoTesterService: PromoTesterService,
    private router: Router
  ) {
    this.titleService.setTitle("Перевірка акцій");
  }

  ngAfterViewInit(): void {

  }
  ngOnInit(): void {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        var allowed = [1];

        if(!allowed.includes(currentUser.UserType)){
            this.router.navigateByUrl("404")
        }
    this.paytypes = this.dataService.getOtherConditionsPaytypes();
    this.calcLevels = this.promoTesterService.getCalcLevels();

    this.item = new PromoCalc();
    this.item.dat = new Date();
    this.item.paytypeId = 1;
    this.item.calcLevelId = 1;

    this.showTabId(0);
    //this.loading = true;

    this.stationService.getAll()
      .subscribe(items => {
        this.stations = items;
        this.initStationsDataSource();
        //this.loading = false;
      },
        error => {
          this.error = error;
          //this.loading = false;
        });

    if(isDevMode()) {
      this.goodsService.getByIds(this.globals.getPromoTesterProducts())
      .subscribe(items => {
        this.goods = items;
        this.initGoodsDataSource();
      },
        error => {
          this.error = error;
        });
    }    
    else {
      this.goodsService.getAll()
      .subscribe(items => {
        this.goods = items;
        this.initGoodsDataSource();
      },
        error => {
          this.error = error;
        });
    }
  }

  initGoodsDataSource() {
    this.goodsDataSource = new DataSource({
      store: new ArrayStore({
        key: "id",
        data: this.goods,
        // Other ArrayStore options go here
      }),
      filter: ["isDeleted", "=", "0"]
    });
  }

  initStationsDataSource() {
    this.stationsDataSource = new DataSource({
      store: new ArrayStore({
        key: "id",
        data: this.stations,
        // Other ArrayStore options go here
      }),
      filter: ["isDeleted", "=", "0"]
    });
  }

  initTerminalsDataSource(station: Stations) {
    //console.log("Station initTerminalsDataSource " + JSON.stringify(station));
    this.terminalsDataSource = new DataSource({
      store: new ArrayStore({
        key: "id",
        data: station.terminals,
        // Other ArrayStore options go here
      }),
      filter: ["isDeleted", "=", "0"]
    });

  }

  onStationChanged(event) {
    //console.log(event);
    let index = this.stations.findIndex(x => x.id == event.value);
    if (index > -1) {
      this.initTerminalsDataSource(this.stations[index]);
    }
  }

  onLineAdd() {
    this.popupGoodsVisible = true;
  }

  chooseGoods(value: number) {
    let index = this.goods.findIndex(x => x.id == value);
    if (index < 0) {
      notify("Помилка при підборі товару", "error", 600);
    }
    let goods = this.goods[index];

    index = this.item.lines.findIndex(x => x.id == value);
    if (index >= 0) {
      notify("Товар '" + goods.name + "' вже є в списку", "error", 600);
      return;
    }

    let line = new PromoCalcLine();
    line.id = goods.id;
    line.name = goods.name;
    line.amount = 1;
    //line.price = 1;
    this.item.lines.push(line);
    notify("Товар '" + goods.name + "' успішно доданий", "success", 600);
  }

  deleteLine(data) {
    let index = this.item.lines.findIndex(x => x.id == data.value);
    if (index >= 0) {
      //console.log("delete client index: " + index);
      this.item.lines.splice(index, 1);
    }
  }

  private setting = {
    element: {
      dynamicDownload: null as HTMLElement
    }
  }

  saveToFile() {
    this.exportItemJson().subscribe((res) => {
      this.dyanmicDownloadByHtmlTag({
        fileName: 'PromoTester.json',
        text: JSON.stringify(this.item)
      });
    });
  }

  fileImportChangeListener($event) {
    var input = $event.target;
    var reader = new FileReader();
    reader.readAsText(input.files[0]);
    reader.onload = (data) => {
      this.item = JSON.parse(reader.result.toString());
      this.fileImportInput.nativeElement.value = "";
      //console.log(reader.result);
    }

  }

  private dyanmicDownloadByHtmlTag(arg: {
    fileName: string,
    text: string
  }) {
    if (!this.setting.element.dynamicDownload) {
      this.setting.element.dynamicDownload = document.createElement('a');
    }
    const element = this.setting.element.dynamicDownload;
    const fileType = arg.fileName.indexOf('.json') > -1 ? 'text/json' : 'text/plain';
    element.setAttribute('href', `data:${fileType};charset=utf-8,${encodeURIComponent(arg.text)}`);
    element.setAttribute('download', arg.fileName);

    var event = new MouseEvent("click");
    element.dispatchEvent(event);
  }

  exportItemJson() {
    return of(JSON.stringify(this.item));
  }

  onCalcDiscount() {
    this.dataGrid.instance.saveEditData();
    this.iconCalculate = ICON_SPINNER;
    this.promoTesterService.calculate(this.item)
      .subscribe(result => {
        this.item = result;
        this.iconCalculate = ICON_CALCULATOR;
      },
        error => {
          this.error = error;
          this.iconCalculate = ICON_CALCULATOR;
        });
  }

  selectTab(e) {
    this.showTabId(e.itemData.id);
  }

  showTabId(id: number) {
    this.isShowCheck = id == 0;
    this.isShowPromoResult = id == 1;
  }

  getMessage(message: PromoCalcMessage) {
    let result = "";
    if (message.typeId >= 100) {
      result += "Помилка: ";
    }
    return result + message.message;
  }

  isShowMessagesPrint(item) {
    if(item != null && item.length > 0) {
      let _messages = item.filter(e => e.typeId == 5);
      if( _messages.length > 0) {
        return true;
      }
    }

    return false;
  }

  filteredAfterExecutionMessages(item) {
    return item.filter(e => e.typeId == 5);
  }

  cuttedMessagePrint(item) {
    if(item.valStr != null) {
      return this.splitStringIntoChunks(item.valStr, 43);
    }
    return [];
  }

  splitStringIntoChunks(inputString: string, chunkSize: number): string[] {
    const regex = new RegExp(`.{1,${chunkSize}}`, 'g');
    return inputString.match(regex) || [];
  }

  onCommitCalc(){
    this.iconCalculate = ICON_SPINNER;
    let commit = new PromoCalcCommit();
    commit.calcId = this.item.calcId;
    commit.transId = this.item.transId;
    this.promoTesterService.commit(commit)
      .subscribe(result => {
        this.iconCalculate = ICON_CALCULATOR;
        this.item.calcId = null;
        this.item.transId = null;
      },
        error => {
          this.error = error;
          this.iconCalculate = ICON_CALCULATOR;
        });
  }
}
