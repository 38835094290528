import { NgModule }       from '@angular/core';
import { CommonModule }       from '@angular/common';

import { FormsModule }    from '@angular/forms';

import { ClientsRoutingModule } from './clients-routing.module';
import { ClientsListComponent } from './clients-list.component';
import { ClientsEditComponent } from './clients-edit.component';
import { ClientsBatchCreateComponent } from './clients-batchcreate.component';
import { ClientsFillFormComponent } from './clients-fill-form.component';
import { ClientsFindComponent } from './clients-find.component';
import { ClientsListRemoteComponent } from './clients-list-remote.component';

import { 
    DxSelectBoxModule, 
    DxDataGridModule, 
    DxButtonModule, 
    DxTextBoxModule, 
    DxPopupModule,
    DxDateBoxModule,
    DxAutocompleteModule,
    DxCheckBoxModule,
    DxNumberBoxModule,
    DxValidatorModule,
    DxValidationSummaryModule,
    DxValidationGroupModule,
    DxFileUploaderModule,
    DxTabsModule
    } from 'devextreme-angular';   

@NgModule({
    imports: [
      ClientsRoutingModule,
      CommonModule,
      FormsModule,
      DxSelectBoxModule,
      DxDataGridModule,
      DxButtonModule,
      DxTextBoxModule,
      DxPopupModule,
      DxDateBoxModule,
      DxAutocompleteModule,
      DxCheckBoxModule,
      DxNumberBoxModule,
      DxValidatorModule,
      DxValidationSummaryModule,
      DxValidationGroupModule,
      DxFileUploaderModule,
      DxTabsModule
      
    ],
    declarations: [
      ClientsListComponent,
      ClientsEditComponent,
      ClientsBatchCreateComponent,
      ClientsFillFormComponent,
      ClientsFindComponent,
      ClientsListRemoteComponent
    ]
  })
  export class ClientsModule {

  }
