import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { PromoDocs, PromoDocsFileInfo } from '../_models';
import { Globals } from '../globals';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Headers, RequestOptions } from '@angular/http';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

// const httpOptionsFileUpload = {
//     headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data' })    
// }  

@Injectable({ providedIn: 'root' })
export class PromoDocsService {
    constructor(private http: HttpClient, private globals: Globals) { }

    getPromoDocs() {
        return this.http.get<PromoDocs[]>(this.globals.getApiString() + `/PromoDocs`, {});
    }

    getPromoDocsById(id: number){
        return this.http.get<PromoDocs>(this.globals.getApiString() + `/PromoDocs/`+ id, {});
    }

    updatePromoDocs(item: PromoDocs): Observable<PromoDocs>{
        return this.http
            .post<PromoDocs>(this.globals.getApiString() + '/PromoDocs', 
                JSON.stringify(item), 
                httpOptions);
    }

    deletePromoDocs(id: number){
        return this.http.delete(this.globals.getApiString() + `/PromoDocs/`+ id, {});
    }

    uploadFile(file: File): Observable<PromoDocsFileInfo>{
        let formData:FormData = new FormData();
        formData.append('uploadedFile', file, file.name);

        let customHeaders = new HttpHeaders();
        //cutomHeader = customHeader.append('Content-Type': 'multipart/form-data');
        //customHeaders = customHeaders.append('Accept', 'application/json');
        customHeaders = customHeaders.append('X-API-KEY', this.globals.storageApiKey);

        const customOptions = { headers: customHeaders};

        return this.http
            .post<PromoDocsFileInfo>(this.globals.getStorageApiString() + '/files/upload',
                formData,
                customOptions);
    }
}