import { NgModule }       from '@angular/core';
import { CommonModule }       from '@angular/common';
import { FormsModule }    from '@angular/forms';
import { TransactionsWalletComponent } from './transactions-wallet.component';
import {DxButtonModule, DxTextBoxModule, DxPopupModule, DxDataGridModule, DxTabsModule, DevExtremeModule} from 'devextreme-angular';
import {TransactionsWalletRoutingModule} from './transactions-wallet-routing.module';

@NgModule({
  imports: [
    TransactionsWalletRoutingModule,
    CommonModule,
    FormsModule,
    DxButtonModule,
    DxTextBoxModule,
    DxPopupModule,
    DxDataGridModule,
    DxTabsModule,
    DevExtremeModule
  ],
  declarations: [
    TransactionsWalletComponent
  ]
})
export class TransactionsWalletModule {

}
