import { NgModule }       from '@angular/core';
import { CommonModule }       from '@angular/common';
import { FormsModule }    from '@angular/forms';
import { PromoDocsRoutingModule } from './promo-docs-routing.module';
import { PromoDocsListComponent } from './promo-docs-list.component';
import { PromoDocsEditComponent } from './promo-docs-edit.component';
import { DxButtonModule, DxDataGridModule, DxTreeListModule, DxTreeViewModule, DxDropDownBoxModule, DxTextBoxModule, DxPopupModule, DxTextAreaModule, DxSelectBoxModule, DxDateBoxModule, DxCheckBoxModule  } from 'devextreme-angular';
import { PromoDocs } from '../../_models';

@NgModule({
    imports: [
      PromoDocsRoutingModule,
      CommonModule,
      FormsModule,
      DxButtonModule,
      DxDataGridModule,
      DxTreeListModule,
      DxTreeViewModule,
      DxDropDownBoxModule,
      DxTextBoxModule,
      DxPopupModule,
      DxTextAreaModule,
      DxSelectBoxModule,
      DxDateBoxModule,
      DxCheckBoxModule
    ],
    declarations: [
        PromoDocsEditComponent,
        PromoDocsListComponent   
    ]
  })
  export class PromoDocsModule {
    items: PromoDocs[] = [];
  }
