import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StationsListComponent } from './stations-list.component';
import { StationsEditComponent } from './stations-edit.component';
import { AuthGuard } from '../../_guards/auth.guard';

const routes: Routes = [
    { path: 'stations',  component: StationsListComponent, canActivate: [AuthGuard] },
    { path: 'stations/edit/:id', component: StationsEditComponent, canActivate: [AuthGuard] },
    { path: 'stations/create', component: StationsEditComponent, canActivate: [AuthGuard] }
  ];
  
  @NgModule({
    imports: [
      RouterModule.forChild(routes)
    ],
    exports: [
      RouterModule
    ]
  })
  export class StationsRoutingModule { }