import { Users } from "./users";
import { Counters } from "./counters";
import { Clients } from "./clients";

export class CountersManualDocs {
    id: number;
    createDate: Date;
    userId: number;
    user: Users;
    comment: string;
    counterId: number;
    counter: Counters;
    operationTypeId: number;
    val: number;
    lines: CountersManualDocsLines[];
}

export class CountersManualDocsLines{
    id: number;
    visibleNumber: string;
    countersManualDocId: number;
    countersManualDoc: CountersManualDocs;
    clientId: number;
    client: Clients;
    valNew: number;
    valPrev: number;
}