import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TriggersPushesComponent } from './triggers-pushes.component';
import { TriggersPushesTemplatesEditComponent } from './triggers-pushes-templates-edit.component';
import { TriggersPushesTaskPlanEditComponent } from './triggers-pushes-taskplan-edit.component';
import { AuthGuard } from '../../_guards/auth.guard';

const routes: Routes = [
  { path: 'triggers-pushes',  component: TriggersPushesComponent, canActivate: [AuthGuard] },
  { path: 'triggers-pushes-templates/edit/:id', component: TriggersPushesTemplatesEditComponent, canActivate: [AuthGuard] },
  { path: 'triggers-pushes-templates/create', component: TriggersPushesTemplatesEditComponent, canActivate: [AuthGuard] },
  { path: 'triggers-pushes-taskplan/edit/:id', component: TriggersPushesTaskPlanEditComponent, canActivate: [AuthGuard] },
  { path: 'triggers-pushes-taskplan/create', component: TriggersPushesTaskPlanEditComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class TriggersPushesRoutingModule { }
