import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Counters, ValueId } from '../../_models';
import { CountersService } from '../../_services';

import DataSource from "devextreme/data/data_source";
import ArrayStore from "devextreme/data/array_store";

import notify from 'devextreme/ui/notify';
import { CountersManualDocsService } from '../../_services/counters-manual-docs.service';
import { CountersManualDocs } from '../../_models/counters-manual-docs';
import { DxDataGridComponent } from 'devextreme-angular';
import { Router } from '@angular/router';

@Component({
    templateUrl: 'counters-manual-docs-list.component.html'
  })
  export class CountersManualDocsListComponent implements OnInit, AfterViewInit {
    @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;

    items: CountersManualDocs[] = [];
    loading = false;
    error = '';

    operationTypes: ValueId[];

    store: ArrayStore;
    dataSource: DataSource;

    delItem: CountersManualDocs;
    isDeletePopupVisible: boolean = false;

    public constructor(
        private titleService: Title,
        private manualDocsService: CountersManualDocsService,
        private router: Router
    )
    {
        this.titleService.setTitle("Ручні зміни лічильників");
    }

    ngAfterViewInit(): void {

    }
    ngOnInit(): void {
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));

        var allowed = [1, 40];

        if(!allowed.includes(currentUser.UserType)){
            this.router.navigateByUrl("404")
        }
        this.operationTypes = this.manualDocsService.getOperationTypes();

        this.loading = true;
        this.manualDocsService.getAll()
        .subscribe(items => {
            this.items = items;
            this.initDataSource();
            this.loading = false;
        },
        error => {
            this.error = error;
            this.loading = false;
        });
    }

    initDataSource(){
        this.dataSource = new DataSource({
            store: new ArrayStore({
                key: "id",
                data: this.items,
                // Other ArrayStore options go here
            })
        });
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(  {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'fa fa-filter',
                    onClick: this.refreshDataGrid.bind(this),
                    hint: 'Очистити фільтри'
                }
            });
    }

    refreshDataGrid() {
        this.dataGrid.instance.clearFilter();
      }


  }
