import { Injectable } from '@angular/core';
import { isDevMode } from '@angular/core';

@Injectable()
export class Globals {
  serverName: string;
  authServerName: string;
  storageServerName: string;
  pushesServerName: string;
  loyaltyPushesServerName: string;
  apiVersion: string;
  apiStorageVersion: string;
  telegramBot : string;
  apiTelegramVersion: string;
  
  promoTesterProducts: number[];

  currentCityId: number = 0;
  maxSizeMediaFile: number = 104857600;
  storageApiKey = "7VPMXlaco5udjTI5QENe";

  public readonly ICON_SPINNER: string = "fa fa-lg fa-spinner fa-spin";
  public readonly ICON_FLOPPY: string = "fa fa-lg fa-floppy-o";

  public constructor(){
    if(isDevMode()) {

      //this.authServerName = 'http://localhost:5001';
      //this.serverName = 'http://localhost:55016';            

      this.authServerName = 'https://td1-dev.brsm-nafta.com';
      this.serverName = 'https://td2-dev.brsm-nafta.com';
      this.telegramBot = 'https://td3-dev.brsm-nafta.com';      
      this.storageServerName = 'https://td5-dev.brsm-nafta.com';
      this.pushesServerName = 'http://10.10.27.232:8771/api/v1';
      this.loyaltyPushesServerName = 'https://td6-dev.brsm-nafta.com/api/v1';

      this.apiVersion = '/api/v1';
      this.apiStorageVersion = '/api/v1';
      this.apiTelegramVersion = '/api/v1';

      this.promoTesterProducts = [13365,13371,13372,13377,13379,3219,12377,9871,24513];
    }
    else
    {
      this.authServerName = 'https://td1.brsm-nafta.com';
      this.serverName = 'https://td2.brsm-nafta.com';
      this.telegramBot = 'https://td3.brsm-nafta.com';      
      this.storageServerName = 'https://td5.brsm-nafta.com';
      this.pushesServerName = 'https://pushes1.brsm-nafta.com/api/v1';
      this.loyaltyPushesServerName = 'https://td6.brsm-nafta.com/api/v1';

      this.apiVersion = '/api/v1';
      this.apiStorageVersion = '/api/v1';
      this.apiTelegramVersion = '/api/v1';
    }
  }

  public getServerName(): string {
    return this.serverName;
  }

  public getApiString(): string {
    return this.serverName + this.apiVersion;
  }

  public getTelegramBotString() : string {
    return this.telegramBot + this.apiTelegramVersion;
  }

  public getStorageApiString() : string {
    return this.storageServerName + this.apiStorageVersion;
  }

  public getStorageUrl() : string {
    return this.storageServerName;
  }

  public getPushesUrl() : string {
    return this.pushesServerName;
  }

  public getLoyaltyPushesUrl() : string {
    return this.loyaltyPushesServerName;
  }

  public getPromoTesterProducts() : number[] {
    return this.promoTesterProducts;
  }

}
