import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {ClientsInsurancePolicy } from '../../_models';
import { ClientsService } from '../../_services';
import ArrayStore from "devextreme/data/array_store";
import { Router } from '@angular/router';
import notify from 'devextreme/ui/notify';
import 'devextreme/data/odata/store';
import {DxDataGridComponent} from 'devextreme-angular';

@Component({
  templateUrl: 'insurance-policy.component.html'
})
export class InsurancePolicyComponent implements OnInit, AfterViewInit {
  @ViewChild("insPolicyDataGrid") dataInsPolicyGrid: DxDataGridComponent;

  clientId: number;
  error = '';

  dsInsPolicy: ClientsInsurancePolicy[];
  caption = 'Поліси страхування';

  public constructor(
    private titleService: Title,
    private clientsService: ClientsService,
    private router: Router
  )
  {
    this.titleService.setTitle(this.caption);
  }

  ngAfterViewInit(): void {

  }

  ngOnInit(): void {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));

    var allowed = [1, 10, 40, 400];

    if(!allowed.includes(currentUser.UserType)){
      this.router.navigateByUrl("404")
    }
  }

  onToolbarPreparingTrans(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',

        options: {
          icon: 'fa fa-filter',
          onClick: this.refreshDataTransGrid.bind(this),
          hint: 'Очистити фільтри'
        }
    });
  }

  onToolbarPreparingTransFuels(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',

        options: {
          icon: 'fa fa-filter',
          onClick: this.refreshDataTransGrid.bind(this),
          hint: 'Очистити фільтри'
        }
    });
  }

  refreshDataTransGrid() {
    if(this.dsInsPolicy) {
      this.dataInsPolicyGrid.instance.clearFilter();
    }
  }

  onContentReady(e) {
    if(e.element.id === 'insPolicyDataGrid' && !this.dsInsPolicy) {      
      this.getClientsInsPolicy();
    }
    // else if(e.element.id === 'transWalletFuelsDataGridContainer' && !this.dsTransFuelsLines) {
    //   this.getTransFuels();
    // }
    // // else if(e.element.id === 'transWalletClientMoveRestPivotGridContainer' && !this.dsTransClientMoveRest) {
    // //   this.getTransMoveRest();
    // // }
    // else if(e.element.id === 'transWalletClientMoveRestDataGridContainer' && !this.dsTransClientMoveRest) {
    //   this.getTransMoveRest();
    // }

  }

  onInitialized(e) {
    
  }

  getClientsInsPolicy() {

    this.dataInsPolicyGrid.instance.beginCustomLoading('');

    this.clientsService.getClientsInsurancePolicy()
      .subscribe(item => {
          this.dsInsPolicy = item;
          this.dataInsPolicyGrid.instance.endCustomLoading();
        },
        error => {
          this.error = error;
          this.dataInsPolicyGrid.instance.endCustomLoading();
          notify(!error.error ? 'Під час виконання запиту сталаса помилка' : error.error, "error", 1000);
        });

  }
}
