import { NgModule }       from '@angular/core';
import { CommonModule }       from '@angular/common';
import { FormsModule }    from '@angular/forms';
import { TelegramBotListComponent } from './telegram-bot-list.component';
import {DxButtonModule, DxTextBoxModule, DxPopupModule, DxDataGridModule, DevExtremeModule} from 'devextreme-angular';
import {TelegramBotRoutingModule} from './telegram-bot-routing.module';

@NgModule({
  imports: [
    TelegramBotRoutingModule,
    CommonModule,
    FormsModule,
    DxButtonModule,
    DxTextBoxModule,
    DxPopupModule,
    DxDataGridModule,
    DevExtremeModule
  ],
  declarations: [
    TelegramBotListComponent
  ]
})
export class TelegramBotModule {

}
