import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TriggerTaskPlan, TriggerTaskPlanConditionBlock, TriggerTaskPlanConditionLine, TriggerTaskPlanConditions, TriggerTaskPlanSchedule, Goods, GoodsGroups, TriggerMessageTemplate,
   TriggerTaskPlanCondParamStruct, TriggerTaskPlanCondOperatorStruct, Tab } from '../../_models';
import { PushesTriggersService, AuthenticationService,  GoodsService, GoodsGroupsService } from '../../_services';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import notify from 'devextreme/ui/notify';
import { Router } from '@angular/router';
import { ValueId } from '../../_models/value-id';
import { Globals } from '../../globals';
import DataSource from "devextreme/data/data_source";
import ArrayStore from "devextreme/data/array_store";

const ICON_KEY: string = "fa fa-lg fa-key ";
const ICON_SPINNER: string = "fa fa-lg fa-spinner fa-spin";
const ICON_FLOPPY: string = "fa fa-lg fa-floppy-o";
const TEXT_ADD_CONDITION: string = "Додати умову";
const TEXT_ADD_CONDITION_AND: string = "І";


@Component({
  templateUrl: 'triggers-pushes-taskplan-edit.component.html',
  styleUrls: ['./triggers-pushes-edit.css']
})

export class TriggersPushesTaskPlanEditComponent implements OnInit, AfterViewInit {
 
  loading = false;
  saving = false;
  error = '';
  caption: string = "";
  private id;

  trTaskPlanTabs: Tab[];
  isShowSchedule: boolean = false;
  isShowConditions: boolean = false;

  item: TriggerTaskPlan;
  isNewItem: boolean = false;
  popupVisibleConditionType: boolean = false;
  popupVisibleGoodsChoose: boolean = false;
  popupVisibleGroupsChoose: boolean = false;

  addBlockText: string = TEXT_ADD_CONDITION;

  dsConditionsType: DataSource;
  dsTemplates: TriggerMessageTemplate[];

  taskTypeExecution: ValueId[];
  periodTypes: ValueId[];
  typePeriodic: ValueId[];
  dayNames: ValueId[];
  dayMonth:  number[] = Array.from({length: 31}, (_, i) => i + 1);;

  isBlockAdded: boolean = true;
  isShowPeriod: boolean = false;
  isShowPeriodDates: boolean = false;
  isShowSchedulePeriodic: boolean = false;

  curCondBlock: TriggerTaskPlanConditionBlock;
  curCondLine: TriggerTaskPlanConditionLine;
  curConditionsType: number;
  conditionTypes: ValueId[];

  goodsItems: Goods[] = [];
  goodsDataSource: DataSource;

  groupsItems: GoodsGroups[] = [];
  groupsDataSource: DataSource;

  iconSave: string = ICON_FLOPPY;

  public constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private location: Location,
    private triggerPushesService: PushesTriggersService,
    private goodsService: GoodsService,
    private goodsGroupsService: GoodsGroupsService,
    private router: Router
  ) {

  }

  ngAfterViewInit(): void {

  }
  ngOnInit(): void {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        var allowed = [1];

        if(!allowed.includes(currentUser.UserType)){
            this.router.navigateByUrl("404")
        }
    
    this.trTaskPlanTabs = this.triggerPushesService.pushesTriggerTaskPlanTabs();
    this.periodTypes = this.triggerPushesService.triggerTaskPlanCondPeriodTypes();
    this.conditionTypes = this.triggerPushesService.triggerTaskPlanCondTypes();
    this.taskTypeExecution = this.triggerPushesService.triggerTaskPlanTypeExecution();
    this.typePeriodic = this.triggerPushesService.triggerTaskPlanScheduleTypePeriod();
    this.dayNames = this.triggerPushesService.triggerTaskPlanScheduleDaysNames();    
    
    this.showTrPsTabId(0);
    this.loadTriggerPushesTemplates();

    this.dsConditionsType = new DataSource({
      store: {
        type: "array",
        key: "id",
        data: this.conditionTypes
      }
    });

    this.route.params.subscribe(params => {
      this.id = +params['id']; // (+) converts string 'id' to a number
    });

    if (Number.isNaN(this.id)) {
      this.caption = "Додавання завдання";
      this.titleService.setTitle(this.caption);
      this.item = new TriggerTaskPlan();
      this.item.schedule = new TriggerTaskPlanSchedule();
      this.item.conditions = new TriggerTaskPlanConditions();
      this.item.id = 0;
      this.item.schedule.countExecute = 1;
      this.item.isActive = false;
      this.item.isTest = false;

      this.isNewItem = true;

    } else {
      this.loading = true;
      this.caption = "Редагування завдання";
      this.titleService.setTitle(this.caption);


      this.triggerPushesService.getTaskPlaneById(this.id)
        .subscribe(item => {
          this.item = item;

          if (this.item.conditions == undefined) {
            this.item.conditions = new TriggerTaskPlanConditions();
          }

          if(this.item.schedule == undefined) {
            this.item.schedule = new TriggerTaskPlanSchedule();
          }

          this.onConditionsChanged();
          this.loading = false;
        },
          error => {
            this.error = error;
            this.loading = false;
          });
    }

    this.loadGoods();
    this.loadGroupsGoods();

  }


  showTrPsTabId(id: number) {
    this.isShowSchedule = id == 0;
    this.isShowConditions = id == 1;

    if (id == 0) {
     }
    else if (id == 1) {
     }
  }

  selectTrPsTab(e) {
    this.showTrPsTabId(e.itemData.id);
  }

  loadTriggerPushesTemplates(){
    this.triggerPushesService.getTemplates()
      .subscribe(items => {
        this.dsTemplates = items;
      },
        error => {
          this.error = error;
        });
  }

  loadGroupsGoods() {
    this.goodsGroupsService.getAll()
      .subscribe(items => {
        this.groupsItems = items;
        this.groupsDataSource = new DataSource({
          store: new ArrayStore({
            key: "id",
            data: this.groupsItems,
            // Other ArrayStore options go here
          }),
          filter: ["isDeleted", "=", "0"]
          // Other DataSource options go here
        });
      },
        error => {
          this.error = error;
        });
  }

  loadGoods() {
    this.goodsService.getAll()
      .subscribe(items => {
        this.goodsItems = items;
        this.goodsDataSource = new DataSource({
          store: new ArrayStore({
            key: "id",
            data: this.goodsItems,
            // Other ArrayStore options go here
          }),
          filter: ["isDeleted", "=", "0"]
          // Other DataSource options go here
        });
      },
        error => {
          this.error = error;
        });
  }


  goBack() {
    this.location.back();
  }

  // fixDatesBeforeSave() {
  //   if (this.item.dateFrom) {
  //     //Date.prototype.
  //   }

  // }


  update() {
    //console.log(this.item);
    this.saving = true;
    this.iconSave = ICON_SPINNER;
    this.item.nextRunAt = this.item.nextRunAtLocal;

    if(!this.validateFields()) {
      this.saving = false;
      this.iconSave = ICON_FLOPPY;
      return;
    }

    if(this.isNewItem == true) {
      this.triggerPushesService.newTaskPlane(this.item)
      .subscribe(res => {
        this.saving = false;
        this.iconSave = ICON_FLOPPY;
        notify("Дані збережено", "success", 600);
        this.goBack();
      },
        error => {
          this.error = error;
          this.iconSave = ICON_FLOPPY;
        });
    }
    else {
      this.triggerPushesService.updateTaskPlane(this.item)
      .subscribe(res => {
        this.saving = false;
        this.iconSave = ICON_FLOPPY;
        notify("Дані збережено", "success", 600);
        this.goBack();
      },
        error => {
          this.error = error;
          this.iconSave = ICON_FLOPPY;
        });
    }
  }

  validateFields() {

    if(!this.item) {
      notify("Сутність не є валідною", "error", 500);
      return false;
    }

    if(!this.item.name || this.item.name == '') {
      notify("Вкажіть назву завдання", "error", 500);
      return false;
    }

    if(this.item.templateId == undefined || this.item.templateId <= 0) {
      notify("Виберіть шаблон повідомлення", "error", 500);
      return false;
    }

    if(this.item.taskTypeExecution == undefined || this.item.taskTypeExecution < 0) {
      notify("Встановіть тип запуску завдання", "error", 500);
      return false;
    }
    else if(this.item.taskTypeExecution == 1) {

      if(!this.item.schedule) {
        notify("Розклад періодичного завдання не може бути порожнім", "error", 500);
        return false;
      }
      
      if(this.item.schedule.countExecute != undefined && this.item.schedule.countExecute < 0) {
        notify("Вкажіть кількість виконань завдання", "error", 500);
        return false;
      }

      if(this.item.schedule.typePeriodic != undefined && this.item.schedule.typePeriodic < 0) {
        notify("Вкажіть тип періоду запуску завдання", "error", 500);
        return false;
      }

      if(!this.item.schedule.timeExecute) {
        notify("Вкажіть час запуску завдання", "error", 500);
        return false;
      }

    }

    return true;

  }

  onConditionsChanged() {
    if (this.item.conditions.conditionBlocks == undefined) return;
    this.isShowPeriod = false;

    if (this.item.conditions.conditionBlocks.length == 0) {
      this.addBlockText = TEXT_ADD_CONDITION;
    } else {
      this.addBlockText = TEXT_ADD_CONDITION_AND;

      let _condTypeIdPeriod = [3,4,5,6,7,8];
      let _condTypeIdDecimal = [3,4,5,6,7,8];

      for (let block of this.item.conditions.conditionBlocks) {
        for (let condition of block.conditionLines) {
          if (_condTypeIdPeriod.includes(condition.typeId)) {
            this.isShowPeriod = true;
            this.checkPeriodDatesShow(this.item.conditions.periodType);
            //break;
          }
          if(_condTypeIdDecimal.includes(condition.typeId) && !condition.val) {
            condition.val = "0";
          }
        }
      }
    }
    if (!this.isShowPeriod) {
      this.item.conditions.periodType = 0;
    }
  }

  addConditionPopup(block: TriggerTaskPlanConditionBlock) {
    this.curConditionsType = 0;
    this.isBlockAdded = false;
    this.curCondBlock = block;
    this.popupVisibleConditionType = true;
  }

  addConditionBlockPopup() {
    this.isBlockAdded = true;
    this.curCondBlock = null;
    this.popupVisibleConditionType = true;
  }

  cancelConditionTypePopup() {
    this.popupVisibleConditionType = false;
  }

  addCondition() {
    if (this.curConditionsType == undefined || this.curConditionsType == 0) {
      notify("Необхідно вибрати тип умови", "error", 600);
      return;
    }


    this.popupVisibleConditionType = false;

    if (this.isBlockAdded) {
      let newblock = new TriggerTaskPlanConditionBlock();
      let newCondition = new TriggerTaskPlanConditionLine();
      newCondition.typeId = this.curConditionsType;
      newblock.conditionLines.push(newCondition);
      this.item.conditions.conditionBlocks.push(newblock);
    } else {
      let newCondition = new TriggerTaskPlanConditionLine();
      newCondition.typeId = this.curConditionsType;
      this.curCondBlock.conditionLines.push(newCondition);
    }

    this.curConditionsType = 0;
    this.onConditionsChanged();
  }

  deleteBlock(block: TriggerTaskPlanConditionBlock) {
    let index = this.item.conditions.conditionBlocks.indexOf(block);
    this.item.conditions.conditionBlocks.splice(index, 1);
    this.onConditionsChanged();
  }

  deleteCondition(block: TriggerTaskPlanConditionBlock, condition: TriggerTaskPlanConditionLine) {
    let index = block.conditionLines.indexOf(condition);
    block.conditionLines.splice(index, 1);
    //Если не осталось условий, то удаляем блок полностью
    if (block.conditionLines.length == 0) {
      this.deleteBlock(block);
    } else {
      this.onConditionsChanged();
    }

  }

  onConditionsParamChanged(e, selectOperator, condition: TriggerTaskPlanConditionLine) {
    selectOperator.dataSource = this.getOperatorsDataSource(condition);
    //textValue.mask = "$9999,99";
    //console.log("value " + e.value);
  }

  getParamsDataSource(conditionTypeId: number): TriggerTaskPlanCondOperatorStruct[] {
    if (conditionTypeId == 1) {
      return this.triggerPushesService.triggerTaskPlanCondParamProfile();
    } 
    else if (conditionTypeId == 2) {
      return this.triggerPushesService.triggerTaskPlanCondParamCards();
    }
    else if (conditionTypeId == 4) {
      return this.triggerPushesService.triggerTaskPlanCondParamDataSales();
    }
  }

  // getDataTypeId(paramValue: string) {
  //   let param = this.segmentsService.getStaticParams().find(x => x.id == paramValue);
  //   if (param != undefined) {
  //     return param.dataTypeId;
  //   } else {
  //     param = this.segmentsService.getDynamicParams().find(x => x.id == paramValue);
  //     if (param != undefined) {
  //       return param.dataTypeId;
  //     }
  //   }
  //   return 0;
  // }

  getOperatorsDataSource(condition: TriggerTaskPlanConditionLine): TriggerTaskPlanCondOperatorStruct[] {
    if([1,2].includes(condition.typeId)){
      return this.triggerPushesService.triggerTaskPlanCondOperatorStr();
    } 
    else {
      return this.triggerPushesService.triggerTaskPlanCondOperatorNumber();
    }
  }

  openGoodsChoosePopup(condition) {
    this.curCondLine = condition;
    this.popupVisibleGoodsChoose = true;
  }

  openGroupsChoosePopup(condition) {
    this.curCondLine = condition;
    this.popupVisibleGroupsChoose = true;
  }

  chooseGoodsId(goodsId: number) {
    this.popupVisibleGoodsChoose = false;
    this.curCondLine.param = goodsId.toString();
    //alert(goodsId);
  }

  chooseGroupsId(groupId: number) {
    this.popupVisibleGroupsChoose = false;
    this.curCondLine.param = groupId.toString();
    //alert(goodsId);
  }

  getGoodsName(goodsId: string) {
    let index = this.goodsItems.findIndex(x => x.id == +goodsId);
    if (index < 0) return undefined;
    if (this.goodsItems[index] == undefined) return undefined;
    return this.goodsItems[index].name;
  }

  getGroupName(groupId: string) {
    let index = this.groupsItems.findIndex(x => x.id == +groupId);
    if (index < 0) return undefined;
    if (this.groupsItems[index] == undefined) return undefined;
    return this.groupsItems[index].name;
  }

  getConditionTypeCaption(conditionTypeId: number){
    let index = this.conditionTypes.findIndex(x => x.id == conditionTypeId);
    return this.conditionTypes[index].value;
  }

  checkPeriodDatesShow(value) {
    if (value == 5) {
      this.isShowPeriodDates = true;
    } else {
      this.isShowPeriodDates = false;
      if (this.item) {
        console.log("clear period dates");
        this.item.conditions.dateTo = null;
        this.item.conditions.dateFrom = null;
      }

    }
  }

  onPeriodChanged(e) {
    //console.log("onPeriodChanged: " + e.value)
    this.checkPeriodDatesShow(e.value);
  }

  treeView_itemSelectionChanged(e) {
    if (e.component.getSelectedNodesKeys() != 0) {
        let groupId = e.component.getSelectedNodesKeys()[0];
        this.chooseGroupsId(groupId);
    }
  }

}
