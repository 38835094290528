import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Segments, SegmentParamStruct, SegmentOperatorStruct, ValueId } from '../_models';
import { Globals } from '../globals';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Headers, RequestOptions } from '@angular/http';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

let periodsTypes: ValueId[] =
    [
        {
            id: 1,
            value: "День"
        },
        {
            id: 2,
            value: "Тиждень"
        },
        {
            id: 3,
            value: "Місяць"
        },
        {
            id: 4,
            value: "Рік"
        },
        {
            id: 5,
            value: "Діапазон дат"
        }
    ];

let segmentStringOperators: SegmentOperatorStruct[] =
    [
        {
            id: "contains",
            caption: "Містить"
        },
        {
            id: "notContains",
            caption: "Не містить"
        },
        {
            id: "beginWith",
            caption: "Починається з"
        },
        {
            id: "equal",
            caption: "Дорівнює"
        },
        {
            id: "notEqual",
            caption: "Не дорівнює"
        }
    ];

let segmentNumberOrNullOperators: SegmentOperatorStruct[] =
[
    {
        id: "equal",
        caption: "Дорівнює"
    },
    {
        id: "notEqual",
        caption: "Не дорівнює"
    },
    {
        id: "great",
        caption: "Більше"
    },
    {
        id: "less",
        caption: "Меньше"
    },
    {
        id: "greatEqual",
        caption: "Більше або дорівнює"
    }
    ,
    {
        id: "lessEqual",
        caption: "Меньше або дорівнює"
    }
    ,
    {
        id: "null",
        caption: "Пусте"
    }
    ,
    {
        id: "notNull",
        caption: "Не пусте"
    }
];

let segmentNumberOperators: SegmentOperatorStruct[] =
    [
        {
            id: "equal",
            caption: "Дорівнює"
        },
        {
            id: "notEqual",
            caption: "Не дорівнює"
        },
        {
            id: "great",
            caption: "Більше"
        },
        {
            id: "less",
            caption: "Меньше"
        },
        {
            id: "greatEqual",
            caption: "Більше або дорівнює"
        }
        ,
        {
            id: "lessEqual",
            caption: "Меньше або дорівнює"
        }
    ];

    let segmentDateOperators: SegmentOperatorStruct[] =
    [
        {
            id: "equal",
            caption: "Дорівнює"
        },
        {
            id: "notEqual",
            caption: "Не дорівнює"
        },
        {
            id: "great",
            caption: "Більше"
        },
        {
            id: "less",
            caption: "Меньше"
        },
        {
            id: "greatEqual",
            caption: "Більше або дорівнює"
        }
        ,
        {
            id: "lessEqual",
            caption: "Меньше або дорівнює"
        }
    ];

let conditionTypes: ValueId[] = [
    {
        id:1,
        value: "Дані анкети"
    },
    {
        id:2,
        value: "Дані продажу (к-ть або сума)"
    },
    {
        id:3,
        value: "Кіл-ть проданого товару"
    },
    {
        id:7,
        value: "Сума проданого товару"
    },
    {
        id:6,
        value: "Кіл-ть проданого товару (по групам)"
    },
    {
        id:8,
        value: "Сума проданого товару (по групам)"
    },
    {
        id:4,
        value: "Знач. лічильника (поточ.знач)"
    },
    {
        id:5,
        value: "Знач. лічильника (поперед.знач)"
    }
];

let segmentStaticParams: SegmentParamStruct[] =
    [
        {
            id: "VisibleNumber",
            caption: "Зовніш.№ карты",
            dataTypeId: 1
        },
        {
            id: "InternalNumber",
            caption: "Внутр.№ карты",
            dataTypeId: 1
        },
        {
            id: "Surname",
            caption: "Прізвище",
            dataTypeId: 1
        },
        {
            id: "Name",
            caption: "І'мя",
            dataTypeId: 1
        },
        {
            id: "MName",
            caption: "По батькові",
            dataTypeId: 1
        },
        {
            id: "Birthdate",
            caption: "Дата народження",
            dataTypeId: 3
        },
        {
            id: "FillDate",
            caption: "Дата заповнення",
            dataTypeId: 3
        },
        {
            id: "Postcode",
            caption: "Індекс",
            dataTypeId: 1
        },
        {
            id: "AreaStr",
            caption: "Область",
            dataTypeId: 1
        },
        {
            id: "CityStr",
            caption: "Місто/село",
            dataTypeId: 1
        },
        {
            id: "Street",
            caption: "Вулиця",
            dataTypeId: 1
        },
        {
            id: "House",
            caption: "Будинок",
            dataTypeId: 1
        },
        {
            id: "Flat",
            caption: "Квартира",
            dataTypeId: 1
        },
        {
            id: "PhoneConfirmed",
            caption: "Підтверджений тел.",
            dataTypeId: 1
        },
        {
            id: "PhoneCell",
            caption: "Моб.тел.",
            dataTypeId: 1
        },
        {
            id: "PhoneContact",
            caption: "Контакт.тел.",
            dataTypeId: 1
        },
        {
            id: "EMail",
            caption: "E-Mail",
            dataTypeId: 1
        },
        {
            id: "VehicleType",
            caption: "Тип транс.засобу",
            dataTypeId: 1
        },
        {
            id: "VehicleBrandName",
            caption: "Марка транс.засобу",
            dataTypeId: 1
        },
        {
            id: "VehicleYear",
            caption: "Рік випуску",
            dataTypeId: 2
        },
        {
            id: "TankVolume",
            caption: "Обсяг бака",
            dataTypeId: 2
        },
        {
            id: "Gender",
            caption: "Стать",
            dataTypeId: 1
        },
        {
            id: "MaritalStatus",
            caption: "Сімейний стан",
            dataTypeId: 1
        },
        {
            id: "SecretWord",
            caption: "Кодове слово/пароль",
            dataTypeId: 1
        },
        {
            id: "IsSubscribed",
            caption: "Згода отрим.новини",
            dataTypeId: 1
        },
        {
            id: "Text1",
            caption: "Текст 1",
            dataTypeId: 1
        },
        {
            id: "Text2",
            caption: "Текст 2",
            dataTypeId: 1
        },
        {
            id: "Text3",
            caption: "Текст 3",
            dataTypeId: 1
        },
        {
            id: "Text4",
            caption: "Текст 4",
            dataTypeId: 1
        }
    ];

let segmentDynamicParams: SegmentParamStruct[] =
    [
        {
            id: "purchases",
            caption: "Кількість покупок (транзакцій)",
            dataTypeId: 2
        },        
        {
            id: "amount",
            caption: "Сума (грн.) всіх покупок",
            dataTypeId: 2
        }
    ];



@Injectable({ providedIn: 'root' })
export class SegmentsService {
    constructor(private http: HttpClient, private globals: Globals) { }

    getAll() {
        return this.http.get<Segments[]>(this.globals.getApiString() + `/Segments`, {});
    }

    getItem(id: number) {
        return this.http.get<Segments>(this.globals.getApiString() + `/Segments/` + id, {});
    }

    updateItem(item: Segments): Observable<Segments> {
        return this.http
            .post<Segments>(this.globals.getApiString() + '/Segments',
                JSON.stringify(item),
                httpOptions);
    }

    deleteItem(id: number): Observable<void>{
        return this.http
            .delete<void>(this.globals.getApiString() + '/Segments/' + id,
                httpOptions);
    }

    getStaticParams(): SegmentParamStruct[] {
        return segmentStaticParams;
    }

    getDynamicParams(): SegmentParamStruct[] {
        return segmentDynamicParams;
    }

    getStringOperators(): SegmentOperatorStruct[] {
        return segmentStringOperators;
    }

    getNumberOperators(): SegmentOperatorStruct[] {
        return segmentNumberOperators;
    }

    getDateOperators(): SegmentOperatorStruct[] {
        return segmentDateOperators;
    }

    getNumberOrNullOperators(): SegmentOperatorStruct[] {
        return segmentNumberOrNullOperators;
    }

    getPeriods(): ValueId[] {
        return periodsTypes;
    }

    getAssignedSegments(clinetId: number){
        return this.http.get<ValueId[]>(this.globals.getApiString() + `/Segments/assigned-segments?clientId=` + clinetId, {});
    }

    getConditionTypes(): ValueId[] {
        return conditionTypes;
    }
}
