import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Counters, ValueId } from '../../_models';
import { CountersService } from '../../_services';

const ICON_SPINNER: string = "fa fa-lg fa-spinner fa-spin";
const ICON_FLOPPY: string = "fa fa-lg fa-floppy-o";
import notify from 'devextreme/ui/notify';
import { Router } from '@angular/router';
@Component({
    templateUrl: 'counters-edit.component.html'
  })
  export class CountersEditComponent implements OnInit, AfterViewInit {

    loading = false;
    saving = false;
    error = '';
    caption : string = "";
    private id;
    item: Counters;
    iconSave: string = ICON_FLOPPY;
    isNewItem: boolean = false;
    isShowPeriod: boolean = false;
    isReadOnlyConterType: boolean = false;

    counterTypes: ValueId[];
    periodTypes: ValueId[];

      public constructor(
      private titleService: Title,
      private route: ActivatedRoute,
      private location: Location,
      private countersService: CountersService,
      private router: Router
    )
    {

    }

    ngAfterViewInit(): void {

    }
    ngOnInit(): void {
      let currentUser = JSON.parse(localStorage.getItem('currentUser'));

        var allowed = [1];

        if(!allowed.includes(currentUser.UserType)){
            this.router.navigateByUrl("404")
        }
      this.periodTypes = this.countersService.getPeriods();
      this.counterTypes = this.countersService.getTypes();

      this.route.params.subscribe(params => {
        this.id = +params['id']; // (+) converts string 'id' to a number
      });

      if(Number.isNaN(this.id))
      {
        this.caption = "Додавання лічильника";
        this.titleService.setTitle(this.caption);
        this.item = new Counters();
        this.item.id = 0;

        this.isNewItem = true;

      } else {

        this.loading = true;
        this.caption = "Редагування лічильника";
        this.titleService.setTitle(this.caption);
        this.isReadOnlyConterType = true;

        this.countersService.getItem(this.id)
          .subscribe(item => {
            this.item = item;
            this.checkPeriodShow(this.item.typeId);
            this.loading = false;
        },
        error => {
            this.error = error;
            this.loading = false;
        });

      }

    }


    goBack(){
      this.location.back();
    }


    update(){
      this.iconSave = ICON_SPINNER;
      this.countersService.updateItem(this.item)
        .subscribe(res => {
          this.saving = false;
          notify("Дані збережено", "success", 600);
          this.goBack();
        },
        error => {
          this.error = error;
          this.iconSave = ICON_FLOPPY;
        });
    }

    checkPeriodShow(counterTypeId){
      if(counterTypeId == 1){
        this.isShowPeriod = true;
      } else {
        this.isShowPeriod = false;
        this.item.periodId = null;
      }
    }

    onTypeChanged(e){
      this.checkPeriodShow(e.value);
    }



  }
